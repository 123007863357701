var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "imagine-checkboxes" }, [
    _vm.label
      ? _c(
          "p",
          {
            staticClass: "imagine-checkboxes__label",
            class: { "imagine-checkboxes__label--disabled": _vm.disabled },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.label))]),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "imagine-checkboxes__container" },
      _vm._l(_vm.choices, function (choice, index) {
        return _c("imagine-checkbox", {
          key: _vm.name + "-" + index,
          attrs: {
            label: choice.label,
            name: _vm.name + "-" + index,
            disabled: _vm.disabled || choice.disabled,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("input", _vm.emitedValues)
            },
          },
          model: {
            value: _vm.indexToCheckedMap[index],
            callback: function ($$v) {
              _vm.$set(_vm.indexToCheckedMap, index, $$v)
            },
            expression: "indexToCheckedMap[index]",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }