<template>
    <div class="imagine-autocomplete">
        <imagine-input :autocomplete="false"
                       :clearable="true"
                       :required="required"
                       :disabled="disabled"
                       :help="selected ? selected : help"
                       :label="label"
                       :violation="violation"
                       @focusin="autocompleteFocus()"
                       @focusout="hidePropositions()"
                       @input="$emit('search', term)"
                       icon="search"
                       :name="name + '-term'"
                       @clear="removeSelected()"
                       v-model="term"/>

        <div
            class="imagine-autocomplete__propositions md-menu-content-bottom-start md-menu-content-small md-menu-content md-theme-default"
            v-bind:style="{maxWidth: inputWidth + 'px', width: inputWidth + 'px', top: inputBottom + 'px'}"
            v-show="showPropositions">
            <div class="md-menu-content-container md-scrollbar md-theme-default">
                <ul class="md-list md-theme-default md-dense">
                    <li class="md-list-item md-menu-item md-theme-default"
                        v-if="create">
                        <div class="md-list-item-default md-list-item-container md-button-clean">
                            <div class="md-list-item-content md-ripple md-disabled">
                                {{ ucFirstEntity }} introuvable ?
                                <a @click.prevent="$emit('create')" href="#">créer un {{ entity }}</a>
                            </div>
                        </div>
                    </li>
                    <li class="md-list-item md-menu-item md-theme-default"
                        v-if="!create && firstPropositions.length <= 0">
                        <div class="md-list-item-default md-list-item-container md-button-clean">
                            <div class="md-list-item-content md-ripple md-disabled">
                                {{ ucFirstEntity }} introuvable
                            </div>
                        </div>
                    </li>
                    <li class="md-list-item md-menu-item md-theme-default"
                        v-for="proposition in firstPropositions">
                        <button @click.prevent="select(proposition)"
                                class="md-list-item-button md-list-item-container md-button-clean"
                                type="button">
                            <div class="md-list-item-content md-ripple">
                                {{ proposition.label }}
                            </div>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ImagineInput from './ImagineInput.vue';
import throttle from 'lodash-es/debounce';

const MAX_PROPOSITIONS = 30;

export default {
    props: {
        value: {default: null},
        label: {default: null},
        propositions: {default: null},
        required: {default: null},
        entity: {default: null},
        name: {default: null},
        help: {default: null},
        violation: {default: null},
        disabled: {default: null},
        create: {default: null}
    },
    components: {ImagineInput},
    computed: {
        firstPropositions() {
            return this.propositions.slice(0, MAX_PROPOSITIONS);
        },
        ucFirstEntity() {
            return this.entity.charAt(0).toUpperCase() + this.entity.slice(1);
        },
        selected() {
            if (this.innerValue) {
                return this.ucFirstEntity + ' sélectionné : ' + this.innerValue.label;
            }

            return null;
        }
    },
    data() {
        return {
            firstTimePropositions: true,
            showPropositions: false,
            inputWidth: null,
            inputBottom: null,
            term: this.value ? this.value.label : null,
            innerValue: this.value
        }
    },
    mounted() {
        document.addEventListener('scroll', this._updatePropositionsPosition, {passive: true});
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this._updatePropositionsPosition);
    },
    watch: {
        value(newValue) {
            this.term = newValue ? newValue.label : null;
            this.innerValue = newValue;
        }
    },
    methods: {
        autocompleteFocus() {
            if (this.disabled) {
                return;
            }

            this._updatePropositionsPosition();
            if (this.firstTimePropositions) {
                this.$emit('first');
            }
            setTimeout(() => this.showPropositions = true, 50);
            this.firstTimePropositions = false;
        },
        removeSelected() {
            this.innerValue = null;
            this.$emit('input', this.innerValue);
        },
        hidePropositions() {
            //leave enough time for click events inside propositions
            setTimeout(() => this.showPropositions = false, 200);
        },
        select(proposition) {
            this.showPropositions = false;
            this.term = proposition.label;
            this.innerValue = proposition;
            this.$emit('input', this.innerValue);
        },
        _updatePropositionsPosition: throttle(function () {
            const input = this.$el;
            this.inputWidth = input.clientWidth;
            const viewportOffset = input.getBoundingClientRect();
            this.inputBottom = viewportOffset.top + 48;
        }, 10)
    }
}
</script>

<style lang="scss">
.imagine-autocomplete {
    position: relative;

    &__propositions {
        position: fixed;
        top: 24px;
    }
}
</style>
