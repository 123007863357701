class ApplicationRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    references() {
        return this._http.get('/api/references');
    }
}

export default ApplicationRepository;
