<template>
    <div class="imagine-logo">
        <svg height="100%" version="1.1" viewBox="0, 0, 220, 60" width="100%" x="0" xmlns="http://www.w3.org/2000/svg"
             y="0">
            <g id="logo">
                <path class="letter"
                      d="M12.776,9.643 C14.56,9.643 15.986,8.276 15.986,6.433 C15.986,4.65 14.56,3.223 12.776,3.223 C10.933,3.223 9.566,4.65 9.566,6.433 C9.566,8.276 10.933,9.643 12.776,9.643"/>
                <path class="letter"
                      d="M5.37,43.16 L5.37,41.786 C9.584,41.786 10.42,40.713 10.458,34.579 L10.458,15.885 L15.213,15.885 L15.213,34.133 L15.214,34.133 C15.214,40.675 15.967,41.786 20.349,41.786 L20.349,43.16 z"/>
                <path class="letter"
                      d="M57.326,43.16 L57.326,41.786 C61.644,41.786 62.41,40.675 62.41,34.133 L62.41,24.801 C62.41,20.7 60.984,19.154 58.13,19.154 C52.008,19.154 49.808,26.168 49.273,28.606 L49.273,34.133 C49.273,40.675 50.003,41.786 54.385,41.786 L54.385,43.16 L39.406,43.16 L39.406,41.786 C43.723,41.786 44.517,40.675 44.517,34.133 L44.517,24.801 C44.517,20.7 43.09,19.154 40.237,19.154 C34.114,19.154 31.915,26.168 31.38,28.606 L31.38,34.123 C31.38,40.665 32.164,41.776 36.546,41.776 L36.546,43.15 L21.566,43.15 L21.566,41.776 C25.884,41.776 26.625,40.665 26.625,34.123 L26.625,15.885 L31.202,15.885 L31.202,21.294 C32.034,19.451 34.59,15.052 41.01,15.052 C44.934,15.052 48.025,16.717 48.916,21.65 C49.511,20.164 52.008,15.052 58.903,15.052 C63.54,15.052 67.166,17.608 67.166,23.434 L67.166,34.133 C67.166,40.675 67.923,41.786 72.305,41.786 L72.305,43.16 z"/>
                <path class="letter"
                      d="M172.806,43.16 L172.806,41.786 C177.124,41.786 177.905,40.675 177.905,34.133 L177.905,24.801 C177.905,20.7 176.479,19.154 173.626,19.154 C167.502,19.154 165.304,26.168 164.768,28.606 L164.768,34.133 C164.768,40.675 165.514,41.786 169.896,41.786 L169.896,43.16 L154.917,43.16 L154.917,41.786 C159.235,41.786 160.013,40.675 160.013,34.133 L160.013,15.885 L164.59,15.885 L164.59,21.294 C165.422,19.451 167.978,15.052 174.398,15.052 C179.035,15.052 182.662,17.608 182.662,23.434 L182.662,34.133 C182.662,40.675 183.403,41.786 187.785,41.786 L187.785,43.16 z"/>
                <path class="letter"
                      d="M203.455,18.975 C207.734,18.975 209.697,21.769 209.697,25.395 L209.697,26.465 L196.262,26.465 C196.975,21.294 199.651,18.975 203.455,18.975 M210.071,36.364 C209.048,38.427 206.899,40.078 203.812,40.078 C197.57,40.078 196.143,35.322 196.083,30.27 L214.63,30.27 L214.63,27.297 C214.63,18.975 210.767,15.052 203.573,15.052 C194.063,15.052 190.971,22.363 190.971,29.675 C190.971,39.483 195.607,44.001 203.692,44.001 C209.634,44.001 213.184,41.146 214.582,36.364 z"/>
                <path class="letter"
                      d="M93.316,33.718 C93.078,37.225 89.512,40.078 85.885,40.078 C83.091,40.078 81.486,38.592 81.486,36.274 C81.486,32.767 84.934,30.864 93.316,30.805 z M100.365,39.54 C97.891,39.584 98.071,37.693 98.071,35.799 L98.071,24.979 C98.071,18.084 95.218,15.052 88.203,15.052 C80.335,15.052 76.709,18.351 77.32,23.54 L82.03,23.54 C82.156,20.208 84.46,18.976 87.847,18.976 C92.068,18.976 93.316,20.937 93.316,25.633 L93.316,27.179 C83.032,27.238 76.374,30.389 76.374,36.631 C76.374,41.624 80.594,44.002 84.994,44.002 C89.274,44.002 92.484,41.624 93.791,39.187 C93.968,41.659 95.316,44.185 100.365,43.599 z"/>
                <path class="letter letter--primary"
                      d="M124.53,44.243 L124.508,44.254 C125.059,45.072 125.373,46.033 125.373,47.028 C125.373,49.774 123.139,52.008 120.393,52.008 C117.647,52.008 115.413,49.774 115.413,47.028 C115.413,44.282 117.647,42.049 120.393,42.049 C127.982,42.049 134.156,35.874 134.156,28.285 C134.156,20.696 127.982,14.522 120.393,14.522 C112.804,14.522 106.63,20.696 106.63,28.285 C106.63,32.122 108.215,35.678 110.935,38.249 C112.041,37.05 113.377,36.072 114.867,35.363 C112.687,33.664 111.397,31.088 111.397,28.285 C111.397,23.325 115.433,19.29 120.393,19.29 C125.353,19.29 129.389,23.325 129.389,28.285 C129.389,33.245 125.353,37.281 120.393,37.281 C115.019,37.281 110.646,41.653 110.646,47.028 C110.646,52.403 115.019,56.777 120.393,56.777 C125.768,56.777 130.14,52.403 130.14,47.028 C130.14,45.39 129.714,43.791 128.939,42.369 C127.585,43.195 126.104,43.834 124.53,44.243"/>
                <path class="letter"
                      d="M146.225,3.312 C148.008,3.312 149.435,4.739 149.435,6.522 C149.435,8.365 148.008,9.732 146.225,9.732 C144.382,9.732 143.014,8.365 143.014,6.522 C143.014,4.739 144.382,3.312 146.225,3.312"/>
                <path class="letter"
                      d="M138.735,43.16 L138.735,41.786 C143.053,41.786 143.847,40.675 143.847,34.133 L143.847,15.974 L148.602,15.974 L148.602,34.133 C148.602,40.675 149.332,41.786 153.714,41.786 L153.714,43.16 z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import '../scss/config';

.imagine-logo {
    font-size: 1.5rem;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60px;
    margin: 1rem 0;

    svg {
        .letter {
            fill: $mdc-theme-secondary;
            fill: var(--mdc-theme-secondary, $mdc-theme-secondary);

            &.letter--primary {
                fill: $mdc-theme-primary;
                fill: var(--mdc-theme-primary, $mdc-theme-primary);
            }
        }
    }
}
</style>
