class AccountEntryRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    chargeRents() {
        return this._http.post('/api/mandate-account-entry/rent');
    }

    rentsToCharge() {
        return this._http.get('/api/mandate-account-entry/rent');
    }

    save(data) {
        return this._http.post('/api/mandate-account-entry' + (data.id ? '/' + data.id : ''), data);
    }

    saveLetters(data) {
        return this._http.post('/api/mandate-account-entry/letter', data);
    }

    delete(id) {
        return this._http.delete('/api/mandate-account-entry/' + id);
    }
}

export default AccountEntryRepository;
