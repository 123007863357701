var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("imagine-icon-button", {
        staticClass: "mdc-top-app-bar__action-item",
        attrs: { svg: "itool" },
        on: {
          click: function ($event) {
            return _vm.externalRedirectTo("https://applis.itool.net")
          },
        },
      }),
      _vm._v(" "),
      _c("imagine-icon-button", {
        staticClass: "mdc-top-app-bar__action-item",
        attrs: { svg: "socredo" },
        on: {
          click: function ($event) {
            return _vm.externalRedirectTo(
              "https://www.socredo.pf/connexion/auth/login"
            )
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }