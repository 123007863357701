import container from '../container';

const repo = container.repos.block;

export default {
    namespaced: true,
    state: {
        all: [],
        statuses: ['project', 'ongoing'],
        lastTab: 'infos',
        bookedDivision: null
    },
    mutations: {
        setLastTab(state, tab) {
            state.lastTab = tab;
        },
        resetLastTab(state) {
            state.lastTab = 'infos';
        },
        setBookedDivision(state, division) {
            state.bookedDivision = division;
        },
        setStatuses(state, statuses) {
            state.statuses = statuses;
        }
    },
    actions: {
        touch({state, dispatch}) {
            if (state.all.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        reload(context) {
            return repo.all()
                .then(blocks => context.state.all = blocks);
        },
        one(context, id) {
            return repo.one(id);
        },
        save(context, data) {
            return repo.save(data);
        },
        remove(context, id) {
            return repo.remove(id);
        },
        createStage(context, data) {
            return repo.createStage(data.blockId, data);
        },
        updateStage(context, data) {
            return repo.updateStage(data.blockId, data.id, data);
        },
        removeStage(context, {blockId, stageId}) {
            return repo.removeStage(blockId, stageId);
        }
    }
};
