var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _c("imagine-icon-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "imagine-no-ripple imagine-scroll-top mdc-elevation--z3",
        attrs: { icon: "keyboard_arrow_up" },
        on: { click: _vm.smoothScroll },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }