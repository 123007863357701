<template>
    <imagine-layout :title="$mq === 'phone' ? 'Locataires' : 'Gestion locataires'" class="mandate-properties">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :disabled="nbRentsToCharge <= 0"
                                 @click="showChargeRentsConfirmation = true"
                                 icon="file_copy"/>
            <mandate-external-services/>
        </template>

        <div class="imagine-secondary-zone mandate-properties__filters">
            <div class="mandate-properties__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Nom propriétaire OU locataire"
                               name="actor"
                               v-model="actorFilter"/>
            </div>
            <div class="mandate-properties__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Résidence/Lotissement/Lot"
                               name="property"
                               v-model="propertyFilter"/>
            </div>
            <div class="mandate-properties__filters__filter">
                <imagine-select label="Statut"
                                name="status"
                                :implicit-required="true"
                                v-model="statusesFilter"
                                :choices="statusChoices"
                                :multiple="true"/>
            </div>
        </div>

        <div :class="{'mandate-properties__count--filtered': nbFiltered < nbTotal}"
             class="mandate-properties__count">
            <div class="mandate-properties__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">{{ formatNumber(nbFiltered) }} / </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                bien{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbTotal > 0 && nbFiltered <= 0">
                Aucun bien trouvé.
            </div>

            <div class="mandate-properties__count__sort"
                 v-if="nbTotal > 0">
                Triés du plus récemment modifié
                <br/>au plus ancien
            </div>
        </div>

        <div class="tenants">
            <div class="imagine-table-container">
                <table class="imagine-table"
                       v-if="filtered.length > 0">
                    <thead>
                    <tr class="imagine-table__row">
                        <th class="imagine-table__row__cell imagine-table__row__cell--header">&nbsp;</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header">
                            Propriétaire(s)
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                            Bien
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--furnished">
                            Meublé
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                            Défiscalisation
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                            Locataire(s)
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent">
                            Loyer charges comprises
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                            Date d'entrée
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                            Date de sortie
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :class="{'imagine-table__row--disabled': mandateProperty.isArchive}"
                        @click="redirectTo('mandateProperty', {id: mandateProperty.id})"
                        class="imagine-table__row tenants__tenant"
                        v-for="mandateProperty in filtered">
                        <td class="imagine-table__row__cell">
                            <mandate-property-status :mandate="mandateProperty"/>
                        </td>
                        <td class="imagine-table__row__cell">
                            {{ mandateProperty.owners.map(owner => owner.shortName).join(', ') }}
                            (#{{ mandateProperty.registryNumber }})
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--text">
                            {{ mandateProperty.property.infos }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--furnished">
                            {{ mandateProperty.isFurnished ? 'Oui' : 'Non' }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--text">
                            {{
                                mandateProperty.taxExemption ?
                                    references.getLabel('taxExemptions', mandateProperty.taxExemption) :
                                    null
                            }}
                            <template v-if="mandateProperty.taxExemptionEnd">
                                <br/>Fin {{ formatDate(mandateProperty.taxExemptionEnd) }}
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--text">
                            <template v-if="mandateProperty.currentLease">
                                {{ mandateProperty.currentLease.tenants.map(tenant => tenant.shortName).join(', ') }}
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--rent">
                            <template v-if="mandateProperty.currentLease">
                                <span>
                                    {{ formatMoney(mandateProperty.currentLease.rent.fullRent) }}
                                    <md-tooltip md-direction="bottom"
                                                v-if="rentDetail(mandateProperty.currentLease.rent)">
                                        {{ rentDetail(mandateProperty.currentLease.rent) }}
                                    </md-tooltip>
                                </span>
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                            <template v-if="mandateProperty.currentLease">
                                {{ formatDate(mandateProperty.currentLease.start) }}
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                            <template v-if="mandateProperty.currentLease">
                                {{ formatDate(mandateProperty.currentLease.end) }}
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="tenants__none"
                     v-else>
                    Aucun bien avec un mandat.
                </div>
            </div>
        </div>

        <imagine-modal @act="chargeRents"
                       @close="showChargeRentsConfirmation = false"
                       :button-label="'Générer ' + nbRentsToCharge + ' appel(s) de loyer'"
                       v-if="showChargeRentsConfirmation">
            <p>Etes vous sûr de vouloir générer {{ nbRentsToCharge }} appel(s) de loyer ?</p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineModal from '../components/ImagineModal.vue';
import MandatePropertyStatus from './elements/MandatePropertyStatus.vue';
import {mapState} from 'vuex';
import MandateExternalServices from "./elements/mandate/MandateExternalServices";

const _queryMatchContact = (contact, query) => {
    if (contact && contact.shortName) {
        return contact.shortName.toUpperCase().search(query.toUpperCase()) !== -1;
    }

    return false;
};

const _queryMatchProperty = (property, query) => {
    const upperQuery = query.toUpperCase();

    return (property.reference && property.reference.startsWith(upperQuery))
        || (property.infos && property.infos.toUpperCase().includes(upperQuery));
};

export default {
    components: {
        MandateExternalServices,
        ImagineModal,
        ImagineLayout,
        ImagineIconButton,
        ImagineInput,
        ImagineSelect,
        MandatePropertyStatus
    },
    computed: {
        ...mapState(['references']),
        ...mapState('mandateProperty', {mandateProperties: state => state.all}),
        ...mapState('accountEntry', {nbRentsToCharge: state => state.nbRentsToCharge}),
        actorFilter: {
            get() {
                return this.$store.state.mandateProperty.actorFilter;
            },
            set(value) {
                this.$store.commit('mandateProperty/setActorFilter', value);
            }
        },
        propertyFilter: {
            get() {
                return this.$store.state.mandateProperty.propertyFilter;
            },
            set(value) {
                this.$store.commit('mandateProperty/setPropertyFilter', value);
            }
        },
        statusesFilter: {
            get() {
                return this.$store.state.mandateProperty.statusesFilter;
            },
            set(value) {
                this.$store.commit('mandateProperty/setStatusesFilter', value);
            }
        },
        filtered() {
            return this.mandateProperties
                .filter(mandateProperty => this.propertyFilter ? this._propertyMatch(mandateProperty, this.propertyFilter) : true)
                .filter(mandateProperty => this.actorFilter ? this._actorMatch(mandateProperty, this.actorFilter) : true)
                .filter(mandateProperty => this.statusesFilter ? this._statusMatch(mandateProperty, this.statusesFilter) : true);
        },
        nbFiltered() {
            return this.filtered.length;
        },
        nbTotal() {
            return this.mandateProperties.length;
        },
        statusChoices() {
            return [
                this.makeChoice('rent_no_alert', 'OK'),
                this.makeChoice('rent_alerts', 'Alertes'),
                this.makeChoice('vacant', 'Vacants'),
                this.makeChoice('archived', 'Archivés')
            ];
        }
    },
    data() {
        return {
            showChargeRentsConfirmation: false
        }
    },
    created() {
        this.load('Chargement des biens');
        this._reloadAll()
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        chargeRents() {
            this.load();
            this.$store.dispatch('accountEntry/chargeRents')
                .then(() => {
                    this._reloadAll()
                        .then(() => {
                            this.showChargeRentsConfirmation = false;
                            this.success('Appels émis.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        _reloadAll() {
            return Promise.all([
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('mandateProperty/reload'),
                this.$store.dispatch('accountEntry/reloadRentsToCharge')
            ]);
        },
        _actorMatch(mandateProperty, query) {
            if (!query) {
                return true;
            }

            if (mandateProperty.owners.some(owner => _queryMatchContact(owner, query))) {
                return true;
            }

            if (!mandateProperty.currentLease) {
                return false;
            }

            return mandateProperty.currentLease.tenants.some(tenant => _queryMatchContact(tenant, query));
        },
        _propertyMatch(mandateProperty, query) {
            if (!query) {
                return true;
            }

            return _queryMatchProperty(mandateProperty.property, query);
        },
        _statusMatch(mandateProperty, statuses) {
            if (!statuses.includes('archived') && mandateProperty.isArchive) {
                return false;
            }

            if (!statuses.includes('vacant') && !mandateProperty.currentLease) {
                return false;
            }

            if (!statuses.includes('rent_alerts') && mandateProperty.currentLease && mandateProperty.nbCriticalAlerts > 0) {
                return false;
            }

            if (!statuses.includes('rent_no_alert') && mandateProperty.currentLease && mandateProperty.nbCriticalAlerts <= 0) {
                return false;
            }

            return true;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate-properties {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
                @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                    flex: unset;
                    width: 150px;
                }
            }

            .md-field {
                margin: 0;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.mandate-properties__count--filtered {
            .mandate-properties__count__count {
                font-weight: 500;
            }
        }
    }
}

.tenants {
    &__tenant {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
