import container from '../container';

const repo = container.repos.user;

export default {
    namespaced: true,
    state: {
        current: null
    },
    actions: {
        touch(context) {
            if (!context.state.current) {
                return context.dispatch('init');
            }

            return Promise.resolve();
        },
        authenticate(context, {email, password}) {
            return repo.authenticate(email, password)
                .then(user => context.state.current = user);
        },
        init(context) {
            return repo.current()
                .then(user => context.state.current = user);
        },
        logout(context) {
            return repo.logout()
                .then(() => context.state.current = null);
        }
    }
};
