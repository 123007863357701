<template>
    <md-field>
        <label :for="name">{{ label }}</label>
        <md-textarea :id="name"
                     :name="name"
                     v-model="innerValue"
                     :required="required"
                     @input="$emit('input',innerValue)"
                     :disabled="disabled"
                     md-autogrow/>
        <span class="md-helper-text" v-if="help">{{ help }}</span>
    </md-field>
</template>

<script>
export default {
    props: {
        label: {required: true},
        name: {required: true},
        help: null,
        value: null,
        required: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false}
    },
    data() {
        return {
            innerValue: this.value
        }
    },
    watch: {
        value(newVal) {
            this.innerValue = newVal;
        }
    }
}
</script>
