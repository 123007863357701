<template>
    <imagine-layout :inner="true" :title="title" class="block-edit">
        <template v-slot:actions>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="delete"
                                 v-if="!hasTransactions"/>
        </template>

        <imagine-tab-bar :tabs="tabs"
                         @tab="changeTab($event)"/>

        <div class="imagine-container">
            <form @submit.prevent="save"
                  class="imagine-form"
                  v-show="activeTab === 'infos'">

                <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

                <div class="imagine-form__row">
                    <imagine-input :required="true"
                                   class="imagine-form__row__field imagine-form__row__field--acronym"
                                   label="Sigle"
                                   name="acronym"
                                   :violation="errors.acronym"
                                   v-model="acronym"/>
                    <imagine-input :required="true"
                                   class="imagine-form__row__field"
                                   label="Nom complet"
                                   name="name"
                                   v-model="name"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-select :choices="references.blockStatusChoices()"
                                    class="imagine-form__row__field"
                                    :required="true"
                                    label="Statut"
                                    name="status"
                                    v-model="status"/>
                    <imagine-date-picker class="imagine-form__row__field"
                                         label="Livraison estimée"
                                         name="deliveryDate"
                                         v-model="deliveryDate"
                                         v-if="status !== 'project'"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Texte descriptif"
                                      name="description"
                                      v-model="description"/>
                </div>

                <imagine-address :show="showAddress"
                                 :with-address="false"
                                 :with-map="true"
                                 marker-icon="business"
                                 title="Localisation"
                                 v-model="location"/>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.blockOptionsChoices()"
                                        class="imagine-form__row__field"
                                        label="Prestations"
                                        name="options"
                                        v-if="false"
                                        v-model="options"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.blockPropertyOptionsChoices()"
                                        class="imagine-form__row__field"
                                        label="Caractéristiques communes à tous les lots"
                                        name="propertyOptions"
                                        v-model="propertyOptions"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   help="Sur https://3d.previsite.com/"
                                   label="Lien visite virtuelle"
                                   name="virtualTourUrl"
                                   type="url"
                                   v-model="virtualTourUrl"/>
                </div>

                <imagine-form-complex-field :show="showSalePaymentInfos"
                                            @open="showSalePaymentInfos = true"
                                            @close="showSalePaymentInfos = false"
                                            title="Appels de fonds">
                    <div class="imagine-form__row">
                        <imagine-input class="imagine-form__row__field"
                                       label="Société"
                                       name="company"
                                       v-model="company"/>
                        <imagine-input class="imagine-form__row__field"
                                       label="Email"
                                       type="email"
                                       name="email"
                                       :violation="errors.email"
                                       v-model="email"/>
                    </div>

                  <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   label="Organisme des attestations"
                                   name="approval"
                                   v-model="approval"/>
                  </div>

                    <div class="imagine-form__row">
                        <imagine-input class="imagine-form__row__field"
                                       label="IBAN"
                                       name="iban"
                                       help="Ex : FR7620800121000001234567823"
                                       :violation="errors.iban"
                                       v-model="iban"/>
                        <imagine-input class="imagine-form__row__field imagine-form__row__field--bic"
                                       label="BIC"
                                       name="bic"
                                       v-model="bic"/>
                    </div>
                </imagine-form-complex-field>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <div class="imagine-container">
            <form @submit.prevent="save"
                  class="imagine-form"
                  v-show="activeTab === 'docs'">
                <div class="imagine-form__row imagine-form__row--limited">
                    <imagine-file-input class="imagine-form__row__field"
                                        label="Visuel principal"
                                        accept="image/*"
                                        :options="{cropRatio: '16/10', watermark: true, public: true}"
                                        v-model="photoUrls[0]"/>

                    <imagine-file-input class="imagine-form__row__field"
                                        accept="application/pdf"
                                        label="Plaquette commerciale"
                                        name="booklet"
                                        v-model="bookletUrl"/>
                </div>

                <div class="imagine-form__row imagine-form__row--alone">
                  <imagine-file-input class="imagine-form__row__field"
                                      label="Pré-réservation"
                                      accept="application/pdf"
                                      name="bookingFormUrl"
                                      v-model="bookingFormUrl"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row imagine-form__row--limited">
                    <imagine-file-input class="imagine-form__row__field"
                                        accept="image/*"
                                        label="Plan situation (large)"
                                        name="largeMapUrl"
                                        v-model="largeMapUrl"/>
                    <imagine-file-input class="imagine-form__row__field"
                                        accept="image/*"
                                        label="Plan situation (zoom)"
                                        name="zoomMapUrl"
                                        v-model="zoomMapUrl"/>
                </div>

                <div class="imagine-form__row imagine-form__row--alone">
                    <imagine-file-input class="imagine-form__row__field"
                                        accept="image/*"
                                        label="Plan masse"
                                        name="groundPlanUrl"
                                        v-model="groundPlanUrl"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row imagine-form__row--limited">
                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan étage 1"
                                      name="floorPlanUrl"
                                      v-model="floorPlanUrl"/>

                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan étage 2"
                                      name="floorPlanUrl2"
                                      v-model="floorPlanUrl2"/>
                </div>

                <div class="imagine-form__row imagine-form__row--limited">
                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan étage 3"
                                      name="floorPlanUrl3"
                                      v-model="floorPlanUrl3"/>

                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan étage 4"
                                      name="floorPlanUrl4"
                                      v-model="floorPlanUrl4"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row imagine-form__row--limited">
                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan parking 1"
                                      name="floorPlanUrl"
                                      v-model="parkingPlanUrl"/>

                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan parking 2"
                                      name="floorPlanUrl2"
                                      v-model="parkingPlanUrl2"/>
                </div>

                <div class="imagine-form__row imagine-form__row--limited">
                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan parking 3"
                                      name="floorPlanUrl3"
                                      v-model="parkingPlanUrl3"/>

                  <imagine-file-input class="imagine-form__row__field"
                                      accept="image/*"
                                      label="Plan parking 4"
                                      name="floorPlanUrl4"
                                      v-model="parkingPlanUrl4"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row imagine-form__row--limited" v-for="i in photoLineIndexes()">
                    <imagine-file-input class="imagine-form__row__field"
                                        :label="'Autre visuel ' + (i+j-1)"
                                        accept="image/*"
                                        :options="{cropRatio: '16/10', watermark: true, public: true}"
                                        :key="'block-image-' + i + '-' + j"
                                        v-model="photoUrls[(i+j-1)]"
                                        v-for="j in 2"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <div class="block-edit__planning"
             v-if="block"
             v-show="activeTab === 'planning'">
            <div class="block-edit__planning__actions">
                <imagine-button :primary="true"
                                @click="currentStage = {}">
                    Ajouter une phase
                </imagine-button>
            </div>

            <div class="block-edit__planning__stages" v-if="buildingStages">
                <div v-for="(buildingStage, building) in buildingStages"
                     class="block-edit__planning__stages__stage">

                    <div class="imagine-table-container">
                        <table class="imagine-table">
                            <thead>
                            <tr class="imagine-table__row">
                                <th class="imagine-table__row__cell">
                                    <h6 class="block-edit__planning__stages__stage__title"
                                        v-if="building">
                                        Bâtiment {{ building }}
                                    </h6>
                                </th>
                                <th class="imagine-table__row__cell">Appels de fonds</th>
                                <th class="imagine-table__row__cel imagine-table__row__cell--datel">
                                    Date prévisionnelle
                                </th>
                                <th class="imagine-table__row__cell imagine-table__row__cell--date">
                                    Date réelle
                                </th>
                                <th class="imagine-table__row__cell"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="imagine-table__row"
                                v-for="stage in buildingStage">
                                <td class="imagine-table__row__cell">{{ stage.label }}</td>
                                <td class="imagine-table__row__cell">{{ stage.progress }}%</td>
                                <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                    {{ formatMonthYear(stage.estimatedDate) }}
                                </td>
                                <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                    {{ formatMonthYear(stage.actualDate) }}
                                </td>
                                <td class="imagine-table__row__cell" style="white-space: nowrap;">
                                    <imagine-icon-button class="imagine-no-ripple"
                                                         @click="currentStage = stage"
                                                         icon="edit"/>
                                    <imagine-icon-button class="imagine-no-ripple"
                                                         :disabled="!stage.canDelete"
                                                         @click="showRemoveStageConfirmation = stage"
                                                         icon="delete"/>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr class="imagine-table__row">
                                <th class="imagine-table__row__cell">TOTAL</th>
                                <th class="imagine-table__row__cell"
                                    :class="{'imagine-table__row__cell--danger': sumProgressStages(buildingStage) !== 100}">
                                    {{ sumProgressStages(buildingStage) }}%
                                </th>
                                <th class="imagine-table__row__cell"></th>
                                <th class="imagine-table__row__cell"></th>
                                <th class="imagine-table__row__cell"></th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="block-edit__planning__none"
                 v-else>
                Phases indisponibles.
            </div>
        </div>

        <div class="block-edit__divisions"
             v-if="block"
             v-show="activeTab === 'divisions'">
            <div class="block-edit__divisions__actions">
                <imagine-button :primary="true"
                                :disabled="status === 'completed'"
                                @click="addDivision()">
                    Ajouter un lot
                </imagine-button>
            </div>

            <div class="block-edit__divisions__summary"
                 v-if="summary && nbRemaining > 0">
                <div class="imagine-container">
                    <div class="mdc-data-table">
                        <table class="mdc-data-table__table">
                            <thead>
                            <tr class="mdc-data-table__header-row">
                                <th class="mdc-data-table__header-cell">Type</th>
                                <th class="mdc-data-table__header-cell block-edit__divisions__summary__status">Vendu
                                </th>
                                <th class="mdc-data-table__header-cell" style="text-align: right;">Reste</th>
                            </tr>
                            </thead>
                            <tbody class="mdc-data-table__content">
                            <tr class="mdc-data-table__row"
                                v-for="type in summary">
                                <td class="mdc-data-table__header-cell">{{ type.type }}</td>
                                <td class="mdc-data-table__cell block-edit__divisions__summary__status">
                                    <div class="block-edit__divisions__summary__status__progress">
                                        <imagine-progress :progress-rate="type.purchased/type.total"/>
                                        <div class="block-edit__divisions__summary__status__progress__count">
                                            {{ type.purchased }} / {{ type.total }}
                                        </div>
                                    </div>
                                </td>
                                <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ type.remaining }}</td>
                            </tr>
                            <tr class="mdc-data-table__row mdc-data-table__header-row">
                                <th class="mdc-data-table__cell mdc-data-table__header-cell">TOTAL</th>
                                <th class="mdc-data-table__cell mdc-data-table__header-cell block-edit__divisions__summary__status">
                                    <div class="block-edit__divisions__summary__status__progress">
                                        <imagine-progress :progress-rate="nbPurchased / nbDivisions"/>
                                        <div class="block-edit__divisions__summary__status__progress__count">
                                            {{ nbPurchased }} / {{ nbDivisions }}
                                        </div>
                                    </div>
                                </th>
                                <th class="mdc-data-table__cell mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
                                    {{ nbRemaining }}
                                </th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="mdc-list mdc-list--two-line"
                 v-if="block.divisions.length > 0">
                <template v-for="division in block.divisions">
                    <div @click.self="redirectTo('property', {id: division.property.id})"
                         class="mdc-list-item">
                        <span class="mdc-list-item__graphic">
                            <block-division-status :division="division"/>
                        </span>
                        <span @click="redirectTo('property', {id: division.property.id})"
                              class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                {{ division.number }} <span
                                v-if="division.price">- {{ formatMillionsMoney(division.price) }}</span>
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                {{ division.type }}
                                <span v-if="division.livingArea">{{ division.livingArea }} / </span>
                                <span v-if="division.totalArea">{{ division.totalArea }} m²</span>
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            <imagine-button @click="showBooking(division)"
                                            v-if="!division.buyers && canAddSaleOption()">
                                Poser option
                            </imagine-button>
                            <div class="block-edit__divisions__confirm"
                                 v-else>
                                <span>{{ division.buyers }}</span>
                            </div>
                            <option-remove v-if="division.isOption"
                                           :sale-id="division.mainSaleId"
                                           @removed="reload()"
                                           :sales-rep-id="division.salesRepId" />
                        </span>
                    </div>
                    <div class="mdc-list-divider"></div>
                </template>
            </div>
            <div class="block-edit__divisions__none"
                 v-else>
                Lots indisponibles.
            </div>
        </div>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       button-label="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer cette résidence ?</p>
        </imagine-modal>

        <imagine-modal :button-disabled="!owner"
                       :title="'Option sur lot ' + bookedDivision.number"
                       @act="book()"
                       class="imagine-modal--booking"
                       @close="showBookingModal = false"
                       button-label="Poser option"
                       v-if="bookedDivision && showBookingModal">
            <imagine-contact-autocomplete label="Contact"
                                          :redirect-after-create="{name: this.$route.name, params: {id: this.blockId}, query: {ack: 'booking'}}"
                                          v-model="owner"/>
        </imagine-modal>

        <block-stage @close="currentStage = null"
                     @completed="reloadBlock()"
                     :block="blockId"
                     :default-approval="block.approval"
                     :stage="currentStage"
                     v-if="currentStage"/>

        <imagine-modal @act="removeStage()"
                       @close="closeStageConfirm()"
                       button-label="Supprimer"
                       v-if="showRemoveStageConfirmation">
            <p>Etes vous sûr de vouloir supprimer cette phase ?</p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import ImagineAddress from '../components/ImagineAddress.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineCheckboxes from '../components/ImagineCheckboxes.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineProgress from '../components/ImagineProgress.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import BlockDivisionStatus from './elements/BlockDivisionStatus.vue';
import BlockStage from './elements/BlockStage.vue';
import {mapState} from 'vuex';
import sortBy from 'lodash-es/sortBy';
import ImagineFormComplexField from "../components/ImagineFormComplexField";
import OptionRemove from "./elements/OptionRemove";

const NB_IMAGES = 4;

export default {
    components: {
        OptionRemove,
        ImagineFormComplexField,
        BlockStage,
        BlockDivisionStatus,
        ImagineProgress,
        ImagineFileInput,
        ImagineTextarea,
        ImagineLayout,
        ImagineModal,
        ImagineButton,
        ImagineIconButton,
        ImagineInput,
        ImagineSelect,
        ImagineAlert,
        ImagineTabBar,
        ImagineAddress,
        ImagineCheckboxes,
        ImagineDatePicker,
        ImagineContactAutocomplete
    },
    computed: {
        ...mapState('block', {
            blocks: state => state.all,
            activeTab: state => state.lastTab,
            bookedDivision: state => state.bookedDivision
        }),
        ...mapState(['references']),
        title() {
            if (this.blockId) {
                return this.block ? this.block.name : '';
            }

            return 'Création résidence';
        },
        tabs() {
            return [
                {id: 'infos', title: 'Infos', active: this.activeTab === 'infos'},
                {id: 'docs', title: 'Docs', active: this.activeTab === 'docs'},
                {id: 'planning', title: 'Planning', active: this.activeTab === 'planning', disabled: !this.block},
                {
                    id: 'divisions',
                    title: 'Ventes',
                    active: this.activeTab === 'divisions',
                    disabled: !this.block || this.block.hiddenDivisions
                },
            ];
        },
        summaryDivisions() {
            if (!this.block || this.block.status !== 'ongoing' || !this.block.divisions || this.block.divisions.length <= 0) {
                return [];
            }

            return sortBy(this.block.divisions, 'appartmentType');
        },
        summary() {
            if (this.summaryDivisions.length <= 0) {
                return null;
            }

            let summary = {};

            this.summaryDivisions
                .forEach(division => {
                    const type = division.type;
                    if (summary.hasOwnProperty(type)) {
                        summary[type].total++;
                        summary[type].purchased += division.buyers ? 1 : 0;
                    } else {
                        summary[type] = {
                            type: type,
                            total: 1,
                            purchased: division.buyers ? 1 : 0,
                            remaining: 0
                        };
                    }
                });

            return Object.values(summary).map(type => {
                type.remaining = type.total - type.purchased;

                return type;
            });
        },
        nbDivisions() {
            return this.summaryDivisions.length;
        },
        nbRemaining() {
            return this.nbDivisions - this.nbPurchased;
        },
        nbPurchased() {
            return this.summaryDivisions.filter(division => division.buyers).length;
        },
        hasTransactions() {
            if (!this.block || !this.block.divisions || this.block.divisions.length <= 0) {
                return false;
            }

            return this.block.divisions.some(division => division.buyers);
        },
        buildingStages() {
            if (!this.block || !this.block.stages || this.block.stages.length <= 0) {
                return null;
            }

            let grouped = {};

            this.block.stages.forEach(stage => {
                let building = stage.building ? stage.building : '';

                if (!grouped.hasOwnProperty(building)) {
                    grouped[building] = [];
                }

                grouped[building].push(stage);
            });

            return grouped;
        }
    },
    data() {
        return {
            block: null,
            blockId: null,
            showRemoveConfirmation: false,
            name: null,
            showAddress: null,
            status: 'project',
            acronym: null,
            deliveryDate: null,
            description: null,
            location: null,
            options: null,
            propertyOptions: null,
            virtualTourUrl: null,
            bookletUrl: null,
            largeMapUrl: null,
            zoomMapUrl: null,
            floorPlanUrl: null,
            floorPlanUrl2: null,
            floorPlanUrl3: null,
            floorPlanUrl4: null,
            parkingPlanUrl: null,
            parkingPlanUrl2: null,
            parkingPlanUrl3: null,
            parkingPlanUrl4: null,
            groundPlanUrl: null,
            bookingFormUrl: null,
            photoUrls: new Array(NB_IMAGES + 1),
            company: null,
            email: null,
            approval: null,
            iban: null,
            bic: null,
            errors: {},
            cpt: 1,
            owner: null,
            showBookingModal: false,
            routeAfterSave: null,
            showRemoveStageConfirmation: false,
            currentStage: null,
            showSalePaymentInfos: false
        }
    },
    created() {
        this.blockId = this.$route.params ? parseInt(this.$route.params.id) : null;
        this.showAddress = !this.blockId;

        this.load();
        Promise.all(this._dependencies())
            .then(() => {
                if (this.$route.query && this.$route.query.ack && this.$route.query.ack === 'division') {
                    this.changeTab('divisions');
                }

                if (this.$route.query && this.$route.query.ack && this.$route.query.ack === 'booking') {
                    this.changeTab('divisions');
                    this.owner = this.$store.state.contact.lastSaved;
                    this.showBookingModal = true;
                }
            })
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        reload() {
            this.reloadBlock()
                .catch(this.serverError)
                .finally(this.unload);
        },
        changeTab(newTab) {
            this.$store.commit('block/setLastTab', newTab);
        },
        save() {
            this.errors = {};
            this.load();
            this.$store.dispatch('block/save', {
                id: this.blockId,
                name: this.name,
                status: this.status,
                acronym: this.acronym,
                deliveryDate: this.deliveryDate,
                description: this.description,
                options: this.options,
                propertyOptions: this.propertyOptions,
                location: this.location,
                virtualTourUrl: this.virtualTourUrl,
                bookletUrl: this.bookletUrl,
                groundPlanUrl: this.groundPlanUrl,
                largeMapUrl: this.largeMapUrl,
                zoomMapUrl: this.zoomMapUrl,
                floorPlanUrl: this.floorPlanUrl,
                floorPlanUrl2: this.floorPlanUrl2,
                floorPlanUrl3: this.floorPlanUrl3,
                floorPlanUrl4: this.floorPlanUrl4,
                parkingPlanUrl: this.parkingPlanUrl,
                parkingPlanUrl2: this.parkingPlanUrl2,
                parkingPlanUrl3: this.parkingPlanUrl3,
                parkingPlanUrl4: this.parkingPlanUrl4,
                bookingFormUrl: this.bookingFormUrl,
                photoUrls: this.photoUrls,
                company: this.company,
                email: this.email,
                approval: this.approval,
                iban: this.iban,
                bic: this.bic
            })
                .then(block => {
                    this.blockId = block.id;
                    this._mapBlock(block);
                    this.reload();
                    this.success('Résidence sauvegardée.');
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                })
        },
        remove() {
            this.load();
            this.$store.dispatch('block/remove', this.blockId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('blocks', {}, () => this.success('Résidence supprimée.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        closeConfirm() {
            this.showRemoveConfirmation = false;
        },
        removeStage() {
            this.load();
            this.$store.dispatch('block/removeStage', {
                blockId: this.blockId,
                stageId: this.showRemoveStageConfirmation.id
            })
                .then(() => this.reloadBlock())
                .catch(this.serverError)
                .finally(this.unload);
        },
        closeStageConfirm() {
            this.showRemoveStageConfirmation = false;
        },
        photoLineIndexes() {
            let indexes = [];
            for (let i = 1; i <= NB_IMAGES; i = i + 2) {
                indexes.push(i);
            }

            return indexes;
        },
        book() {
            this.load();
            this.$store.dispatch('sale/quickOption', {
                propertyId: this.bookedDivision.property.id,
                contactId: this.owner.id
            })
                .then(() => {
                    this.reloadBlock()
                        .then(() => {
                            this.$store.commit('block/setBookedDivision', null);
                            this.showBookingModal = false;
                            this.owner = null;
                            this.success('Option posée pour 72h.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        showBooking(division) {
            this.$store.commit('block/setBookedDivision', division);
            this.showBookingModal = true;
        },
        formatMillionsMoney(money) {
            return this.$container.types.formatMillionsMoney(money);
        },
        addDivision() {
            if (!this.blockId) {
                return;
            }

            this.$store.commit('property/setRedirectAfterSave', {
                name: this.$route.name,
                params: {id: this.blockId},
                query: {ack: 'division'}
            });

            return this.$router.push({name: 'propertyAdd', query: {'blockId': this.blockId}});
        },
        formatMonthYear(date) {
            return this.$container.types.formatMonthYear(date);
        },
        reloadBlock() {
            return this.$store.dispatch('block/one', this.blockId)
                .then(block => this._mapBlock(block));
        },
        sumProgressStages(stages) {
            return stages.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0);
        },
        _mapBlock(block) {
            this.block = block;
            this.name = block.name;
            this.status = block.status;
            this.acronym = block.acronym;
            this.deliveryDate = block.deliveryDate;
            this.description = block.description;
            this.options = block.options;
            this.propertyOptions = block.propertyOptions;
            this.virtualTourUrl = block.virtualTourUrl;
            this.bookletUrl = block.bookletUrl;
            this.largeMapUrl = block.largeMapUrl;
            this.zoomMapUrl = block.zoomMapUrl;
            this.floorPlanUrl = block.floorPlanUrl;
            this.floorPlanUrl2 = block.floorPlanUrl2;
            this.floorPlanUrl3 = block.floorPlanUrl3;
            this.floorPlanUrl4 = block.floorPlanUrl4;
            this.parkingPlanUrl = block.parkingPlanUrl;
            this.parkingPlanUrl2 = block.parkingPlanUrl2;
            this.parkingPlanUrl3 = block.parkingPlanUrl3;
            this.parkingPlanUrl4 = block.parkingPlanUrl4;
            this.groundPlanUrl = block.groundPlanUrl;
            this.bookingFormUrl = block.bookingFormUrl;
            this.photoUrls = block.photoUrls;

            this.company = block.company;
            this.email = block.email;
            this.approval = block.approval;
            this.iban = block.iban;
            this.bic = block.bic;

            this.location = block.location;

            this.showRemoveStageConfirmation = false;
            this.currentStage = null;
        },
        _dependencies() {
            let dependencies = [
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('contact/touch')
            ];

            if (this.blockId) {
                dependencies.push(this.reloadBlock());
            }

            return dependencies
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/data-table/mdc-data-table';

.block-edit {
    .imagine-form__row__field--acronym {
        flex: unset;
        width: 100px;
    }

    .imagine-form__row__field--bic {
        flex: unset;
        width: 100px;
    }

    &__divisions {
        &__actions {
            text-align: right;
            padding: 1rem;
        }

        &__summary {
            padding: 1rem;
            text-align: center;

            &__status {
                width: 70%;
                padding: 0;
                text-align: center;

                &__progress {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &__count {
                        text-align: right;
                        width: 95px;
                    }
                }
            }

            .mdc-data-table {
                @include mdc-data-table-density(-4);
                width: 100%;
            }
        }

        &__confirm {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }

    &__planning {
        &__actions {
            text-align: right;
            padding: 1rem;
        }

        &__stages {
            text-align: center;

            &__stage {
                margin: 2rem 0;

                &__title {
                    font-size: 1.2rem;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }

        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }

    .imagine-status {
        font-size: 1.2rem;
    }
}
</style>
