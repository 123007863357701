var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "property-edit",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.block
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "business" },
                    on: {
                      click: function ($event) {
                        return _vm.redirectTo("block", { id: _vm.block })
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: {
                      icon: "print",
                      disabled:
                        !_vm.property || !_vm.property.descriptionFallback,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.print()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "add_to_photos" },
                    on: {
                      click: function ($event) {
                        _vm.showDuplicateConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.property
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { disabled: _vm.hasTransactions, icon: "delete" },
                    on: {
                      click: function ($event) {
                        _vm.showRemoveConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("imagine-tab-bar", {
        attrs: { tabs: _vm.tabs },
        on: {
          tab: function ($event) {
            return _vm.changeTab($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === "infos",
              expression: "activeTab === 'infos'",
            },
          ],
          staticClass: "imagine-container property-edit__form",
        },
        [
          _c(
            "form",
            {
              staticClass: "imagine-form",
              attrs: { autocomplete: "off" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _vm.errors.global
                ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                : _vm.publishings.length > 0
                ? _c("imagine-alert", { attrs: { type: "info" } }, [
                    _vm._v(
                      "\n                Ce bien est publié sur " +
                        _vm._s(_vm.publishings.join(", ")) +
                        ".\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "imagine-form__row property-edit__form__transactions",
                },
                [
                  _c("imagine-checkboxes", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      choices: _vm.transactionChoices,
                      name: "transactions",
                    },
                    model: {
                      value: _vm.transactionTypes,
                      callback: function ($$v) {
                        _vm.transactionTypes = $$v
                      },
                      expression: "transactionTypes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-select", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      choices: _vm.propertyTypeChoices,
                      label: "Type",
                      name: "type",
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                  _vm._v(" "),
                  _vm.type === "apartment"
                    ? _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.apptTypeChoices(),
                          label: "Type appt.",
                          name: "apparmentType",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.updateApptBedrooms()
                          },
                        },
                        model: {
                          value: _vm.nbRooms,
                          callback: function ($$v) {
                            _vm.nbRooms = $$v
                          },
                          expression: "nbRooms",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasGroup
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _vm.isImagine
                        ? _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.groupedBlockChoices(
                                _vm.blocks
                              ),
                              label: "Résidence imagine",
                              name: "block",
                              violation: _vm.errors.block,
                            },
                            model: {
                              value: _vm.block,
                              callback: function ($$v) {
                                _vm.block = $$v
                              },
                              expression: "block",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.couldBeInOtherBlock
                        ? _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Résidence NON imagine",
                              name: "otherBlock",
                            },
                            model: {
                              value: _vm.otherBlock,
                              callback: function ($$v) {
                                _vm.otherBlock = $$v
                              },
                              expression: "otherBlock",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.couldBeInHousing
                        ? _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Lotissement / servitude",
                              name: "housingDev",
                            },
                            model: {
                              value: _vm.housingDev,
                              callback: function ($$v) {
                                _vm.housingDev = $$v
                              },
                              expression: "housingDev",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasBuilding && !_vm.isLand
                        ? _c("imagine-input", {
                            staticClass:
                              "imagine-form__row__field property-edit__form__building",
                            attrs: { label: "Bât.", name: "building" },
                            model: {
                              value: _vm.building,
                              callback: function ($$v) {
                                _vm.building = $$v
                              },
                              expression: "building",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasFloor && !_vm.isLand
                        ? _c("imagine-input", {
                            staticClass:
                              "imagine-form__row__field property-edit__form__floor",
                            attrs: { label: "Etage", name: "floor" },
                            model: {
                              value: _vm.floor,
                              callback: function ($$v) {
                                _vm.floor = $$v
                              },
                              expression: "floor",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasDivision
                        ? _c("imagine-input", {
                            staticClass:
                              "imagine-form__row__field property-edit__form__division",
                            attrs: {
                              label: "N° lot",
                              name: "division",
                              violation: _vm.errors.division,
                            },
                            model: {
                              value: _vm.division,
                              callback: function ($$v) {
                                _vm.division = $$v
                              },
                              expression: "division",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasGroup && !_vm.isLand
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "N° parking intérieur 1",
                          name: "insideParking1",
                          violation: _vm.errors.insideParking1,
                        },
                        model: {
                          value: _vm.insideParking1,
                          callback: function ($$v) {
                            _vm.insideParking1 = $$v
                          },
                          expression: "insideParking1",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "N° parking intérieur 2",
                          name: "insideParking2",
                          violation: _vm.errors.insideParking2,
                        },
                        model: {
                          value: _vm.insideParking2,
                          callback: function ($$v) {
                            _vm.insideParking2 = $$v
                          },
                          expression: "insideParking2",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "N° parking extérieur 1",
                          name: "outsideParking1",
                          violation: _vm.errors.outsideParking1,
                        },
                        model: {
                          value: _vm.outsideParking1,
                          callback: function ($$v) {
                            _vm.outsideParking1 = $$v
                          },
                          expression: "outsideParking1",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "N° parking extérieur 2",
                          name: "outsideParking2",
                          violation: _vm.errors.outsideParking2,
                        },
                        model: {
                          value: _vm.outsideParking2,
                          callback: function ($$v) {
                            _vm.outsideParking2 = $$v
                          },
                          expression: "outsideParking2",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _vm.hasLivingArea
                    ? _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          help: "En mètres carrés",
                          label: "Surface hab.",
                          name: "livingArea",
                        },
                        model: {
                          value: _vm.livingArea,
                          callback: function ($$v) {
                            _vm.livingArea = $$v
                          },
                          expression: "livingArea",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasLivingArea
                    ? _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          help: "En mètres carrés",
                          label: "Surface totale",
                          name: "totalArea",
                          violation: _vm.errors.totalArea,
                        },
                        model: {
                          value: _vm.totalArea,
                          callback: function ($$v) {
                            _vm.totalArea = $$v
                          },
                          expression: "totalArea",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasLandArea
                    ? _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          help: "En mètres carrés",
                          label: "Surface terrain",
                          name: "landArea",
                        },
                        model: {
                          value: _vm.landArea,
                          callback: function ($$v) {
                            _vm.landArea = $$v
                          },
                          expression: "landArea",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasEditableRooms
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Nb de pièces",
                          name: "nbRooms",
                          type: "number",
                        },
                        model: {
                          value: _vm.nbRooms,
                          callback: function ($$v) {
                            _vm.nbRooms = $$v
                          },
                          expression: "nbRooms",
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasBedrooms
                        ? _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Nb de chambres",
                              name: "nbBedrooms",
                              type: "number",
                              violation: _vm.errors.block,
                            },
                            model: {
                              value: _vm.nbBedrooms,
                              callback: function ($$v) {
                                _vm.nbBedrooms = $$v
                              },
                              expression: "nbBedrooms",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLocationUnknown
                ? _c("imagine-address", {
                    attrs: {
                      show: true,
                      "with-address": false,
                      title: "Localisation",
                      "with-map": true,
                      "marker-icon": "home_work",
                    },
                    model: {
                      value: _vm.location,
                      callback: function ($$v) {
                        _vm.location = $$v
                      },
                      expression: "location",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-checkboxes", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      choices: _vm.propertyOptionsChoices,
                      label: "Caractéristiques",
                      name: "options",
                    },
                    model: {
                      value: _vm.options,
                      callback: function ($$v) {
                        _vm.options = $$v
                      },
                      expression: "options",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "imagine-form__sep" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-textarea", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      help: _vm.publishedHelp,
                      label: _vm.descriptionLabel,
                      name: "description",
                    },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Lien visite virtuelle",
                      name: "virtualTourUrl",
                      type: "url",
                    },
                    model: {
                      value: _vm.virtualTourUrl,
                      callback: function ($$v) {
                        _vm.virtualTourUrl = $$v
                      },
                      expression: "virtualTourUrl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasRegistryNumber
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: _vm.registryNumberLabel,
                          name: "registryNumber",
                        },
                        model: {
                          value: _vm.registryNumber,
                          callback: function ($$v) {
                            _vm.registryNumber = $$v
                          },
                          expression: "registryNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPurchase
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-money-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Prix de vente proposé (XPF)",
                          name: "price",
                        },
                        model: {
                          value: _vm.price,
                          callback: function ($$v) {
                            _vm.price = $$v
                          },
                          expression: "price",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isPurchase || _vm.isRental
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.taxExemptionChoices,
                          label: "Défiscalisation",
                          name: "taxExemption",
                        },
                        model: {
                          value: _vm.taxExemption,
                          callback: function ($$v) {
                            _vm.taxExemption = $$v
                          },
                          expression: "taxExemption",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Fin défiscalisation",
                          name: "taxExemptionEnd",
                        },
                        model: {
                          value: _vm.taxExemptionEnd,
                          callback: function ($$v) {
                            _vm.taxExemptionEnd = $$v
                          },
                          expression: "taxExemptionEnd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRental
                ? _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row property-edit__form__prices",
                    },
                    [
                      _c("imagine-money-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Loyer hors charges proposé (XPF/mois)",
                          name: "rent",
                        },
                        model: {
                          value: _vm.rent,
                          callback: function ($$v) {
                            _vm.rent = $$v
                          },
                          expression: "rent",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-money-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Provision pour charges (XPF / mois)",
                          name: "charges",
                        },
                        model: {
                          value: _vm.charges,
                          callback: function ($$v) {
                            _vm.charges = $$v
                          },
                          expression: "charges",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRental
                ? _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row property-edit__form__prices",
                    },
                    [
                      _c("imagine-money-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label:
                            "Provision taxe d'ordures ménagères (XPF / mois)",
                          name: "waste",
                        },
                        model: {
                          value: _vm.waste,
                          callback: function ($$v) {
                            _vm.waste = $$v
                          },
                          expression: "waste",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-money-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Provision taxe d'eau (XPF / mois)",
                          name: "water",
                        },
                        model: {
                          value: _vm.water,
                          callback: function ($$v) {
                            _vm.water = $$v
                          },
                          expression: "water",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isRental
                ? _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row property-edit__form__furnished",
                    },
                    [
                      _c(
                        "imagine-switch",
                        {
                          model: {
                            value: _vm.isFurnished,
                            callback: function ($$v) {
                              _vm.isFurnished = $$v
                            },
                            expression: "isFurnished",
                          },
                        },
                        [_c("span", [_vm._v("Meublé")])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "imagine-form__sep" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-textarea", {
                    staticClass: "imagine-form__row__field",
                    attrs: { label: "Notes", name: "comment" },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__actions" },
                [
                  _c(
                    "imagine-button",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    { attrs: { primary: "", type: "submit" } },
                    [_vm._v("Sauvegarder")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.property
                ? _c(
                    "div",
                    { staticClass: "property-edit__timeline" },
                    [
                      _vm.timeline && _vm.timeline.length > 0
                        ? _c("timeline", { attrs: { events: _vm.timeline } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "imagine-form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.createEvent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "imagine-form__row" },
                            [
                              _c("imagine-textarea", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  label: "Nouvel évènement : visite, etc.",
                                  required: true,
                                  name: "event1",
                                },
                                model: {
                                  value: _vm.eventDescription,
                                  callback: function ($$v) {
                                    _vm.eventDescription = $$v
                                  },
                                  expression: "eventDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Ajouter évènement")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "imagine-container" }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab === "images",
                expression: "activeTab === 'images'",
              },
            ],
            staticClass: "imagine-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _vm.errors.global
              ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
              : _vm.publishings.length > 0
              ? _c("imagine-alert", { attrs: { type: "info" } }, [
                  _vm._v(
                    "\n                Ce bien est publié sur " +
                      _vm._s(_vm.publishings.join(", ")) +
                      ".\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPlan
              ? _c(
                  "div",
                  { staticClass: "imagine-form__row imagine-form__row--alone" },
                  [
                    _c("imagine-file-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        accept: "image/*",
                        options: { public: true },
                        label: "Plan principal",
                        name: "plan1",
                      },
                      model: {
                        value: _vm.plan1Url,
                        callback: function ($$v) {
                          _vm.plan1Url = $$v
                        },
                        expression: "plan1Url",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPlan
              ? _c(
                  "div",
                  {
                    staticClass: "imagine-form__row imagine-form__row--limited",
                  },
                  [
                    _c("imagine-file-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        options: { public: true },
                        accept: "image/*",
                        label: "Autre plan 1",
                        name: "plan2",
                      },
                      model: {
                        value: _vm.plan2Url,
                        callback: function ($$v) {
                          _vm.plan2Url = $$v
                        },
                        expression: "plan2Url",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-file-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        options: { public: true },
                        accept: "image/*",
                        label: "Autre plan 2",
                        name: "plan3",
                      },
                      model: {
                        value: _vm.plan3Url,
                        callback: function ($$v) {
                          _vm.plan3Url = $$v
                        },
                        expression: "plan3Url",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPlan
              ? _c("div", { staticClass: "imagine-form__sep" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--alone" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    options: {
                      cropRatio: "16/10",
                      watermark: true,
                      public: true,
                    },
                    accept: "image/*",
                    label: "Visuel principal",
                  },
                  model: {
                    value: _vm.photoUrls[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.photoUrls, 0, $$v)
                    },
                    expression: "photoUrls[0]",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.photoLineIndexes(), function (i) {
              return _c(
                "div",
                { staticClass: "imagine-form__row imagine-form__row--limited" },
                _vm._l(2, function (j) {
                  return _c("imagine-file-input", {
                    key: "property-image-" + i + "-" + j,
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Autre visuel " + (i + j - 1),
                      accept: "image/*",
                      options: {
                        cropRatio: "16/10",
                        watermark: true,
                        public: true,
                      },
                    },
                    model: {
                      value: _vm.photoUrls[i + j - 1],
                      callback: function ($$v) {
                        _vm.$set(_vm.photoUrls, i + j - 1, $$v)
                      },
                      expression: "photoUrls[(i+j-1)]",
                    },
                  })
                }),
                1
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__actions" },
              [
                _c(
                  "imagine-button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Annuler")]
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  { attrs: { primary: "", type: "submit" } },
                  [_vm._v("Sauvegarder")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === "prospects_new",
              expression: "activeTab === 'prospects_new'",
            },
          ],
          staticClass: "property-edit__prospects",
        },
        [
          _vm.isGranted(["ROLE_CEO", "ROLE_SALES_REP"])
            ? _c(
                "div",
                { staticClass: "property-edit__prospects__header" },
                [
                  _c(
                    "imagine-switch",
                    {
                      model: {
                        value: _vm.onlySalesRepProspects,
                        callback: function ($$v) {
                          _vm.onlySalesRepProspects = $$v
                        },
                        expression: "onlySalesRepProspects",
                      },
                    },
                    [_c("span", [_vm._v("Mes prospects seulement")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.matchingContacts.length > 0
            ? _c(
                "div",
                { staticClass: "mdc-list mdc-list--two-line" },
                _vm._l(_vm.matchingContacts, function (match) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mdc-list-item property-edit__prospects__prospect",
                      on: {
                        click: function ($event) {
                          return _vm.gotoContact(match.contact)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "mdc-list-item__text" }, [
                        _c(
                          "span",
                          { staticClass: "mdc-list-item__primary-text" },
                          [
                            match.contact.firstname || match.contact.lastname
                              ? [
                                  match.contact.lastname
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contacts__contact__lastname",
                                        },
                                        [_vm._v(_vm._s(match.contact.lastname))]
                                      )
                                    : _vm._e(),
                                  match.contact.firstname &&
                                  match.contact.lastname
                                    ? _c("span", [_vm._v(",")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  match.contact.firstname
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contacts__contact__firstname",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(match.contact.firstname) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  match.contact.company
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    (" +
                                            _vm._s(match.contact.company) +
                                            ")\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : match.contact.company
                              ? _c(
                                  "span",
                                  { staticClass: "contacts__contact__company" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(match.contact.company) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "mdc-list-item__secondary-text" },
                          [
                            match.contact.email
                              ? _c("span", [
                                  _vm._v(_vm._s(match.contact.email)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            match.contact.mobilePhone
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(match.contact.mobilePhone.readable)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "mdc-list-item__meta property-edit__prospects__prospect__score",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(Math.round(match.score * 100)) +
                              "%\n                        "
                          ),
                          _c("br"),
                          _vm._v(
                            _vm._s(match.salesRepName) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("p", [_vm._v("Aucun prospect.")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === "prospects_old",
              expression: "activeTab === 'prospects_old'",
            },
          ],
          staticClass: "property-edit__prospects",
        },
        [
          _vm.isGranted(["ROLE_CEO", "ROLE_SALES_REP"])
            ? _c(
                "div",
                { staticClass: "property-edit__prospects__header" },
                [
                  _c(
                    "imagine-switch",
                    {
                      model: {
                        value: _vm.onlySalesRepProspects,
                        callback: function ($$v) {
                          _vm.onlySalesRepProspects = $$v
                        },
                        expression: "onlySalesRepProspects",
                      },
                    },
                    [_c("span", [_vm._v("Mes prospects seulement")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.oldMatchingContacts.length > 0
            ? _c(
                "div",
                { staticClass: "mdc-list mdc-list--two-line" },
                _vm._l(_vm.oldMatchingContacts, function (match) {
                  return _c(
                    "div",
                    {
                      staticClass:
                        "mdc-list-item property-edit__prospects__prospect",
                      on: {
                        click: function ($event) {
                          return _vm.gotoContact(match.contact)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "mdc-list-item__text" }, [
                        _c(
                          "span",
                          { staticClass: "mdc-list-item__primary-text" },
                          [
                            match.contact.firstname || match.contact.lastname
                              ? [
                                  match.contact.lastname
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contacts__contact__lastname",
                                        },
                                        [_vm._v(_vm._s(match.contact.lastname))]
                                      )
                                    : _vm._e(),
                                  match.contact.firstname &&
                                  match.contact.lastname
                                    ? _c("span", [_vm._v(",")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  match.contact.firstname
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "contacts__contact__firstname",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(match.contact.firstname) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  match.contact.company
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                                    (" +
                                            _vm._s(match.contact.company) +
                                            ")\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              : match.contact.company
                              ? _c(
                                  "span",
                                  { staticClass: "contacts__contact__company" },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(match.contact.company) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "mdc-list-item__secondary-text" },
                          [
                            match.contact.email
                              ? _c("span", [
                                  _vm._v(_vm._s(match.contact.email)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            match.contact.mobilePhone
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(match.contact.mobilePhone.readable)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "mdc-list-item__meta property-edit__prospects__prospect__score",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(Math.round(match.score * 100)) +
                              "%\n                        "
                          ),
                          _c("br"),
                          _vm._v(
                            _vm._s(match.salesRepName) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              )
            : _c("p", [_vm._v("Aucun prospect.")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === "clients",
              expression: "activeTab === 'clients'",
            },
          ],
          staticClass: "property-edit__transactions",
        },
        [
          _vm.isNewBuild
            ? _c(
                "div",
                { staticClass: "property-edit__transactions__category" },
                [
                  _c(
                    "h6",
                    {
                      staticClass:
                        "property-edit__transactions__category__title mdc-typography--headline6",
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n                    Acheteur" +
                            _vm._s(
                              _vm.sale &&
                                _vm.sale.buyers &&
                                _vm.sale.buyers.length > 1
                                ? "s"
                                : ""
                            ) +
                            "\n                    neuf\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.property &&
                      _vm.property.canBeNewBuildPurchased &&
                      _vm.canAddSaleOption()
                        ? _c(
                            "imagine-button",
                            {
                              attrs: { primary: true },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.showBookingModal = "new_build"
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Poser option\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.sale && _vm.sale.buyers.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mdc-list" },
                        [
                          _vm._l(_vm.sale.buyers, function (buyer) {
                            return _c(
                              "div",
                              {
                                staticClass: "mdc-list-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.gotoSale(_vm.sale)
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__graphic" },
                                  [
                                    _c("sale-status", {
                                      attrs: { sale: _vm.sale },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__text" },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(buyer) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__meta" },
                                  [
                                    _vm.sale.step === "option"
                                      ? _c("option-remove", {
                                          attrs: {
                                            "sale-id": _vm.sale.id,
                                            placeholder:
                                              _vm.references.stepLabel(
                                                _vm.sale.step
                                              ),
                                            "sales-rep-id": _vm.sale.salesRepId,
                                          },
                                          on: {
                                            removed: function ($event) {
                                              return _vm.reload()
                                            },
                                          },
                                        })
                                      : [
                                          _vm._v(
                                            _vm._s(
                                              _vm.references.stepLabel(
                                                _vm.sale.step
                                              )
                                            )
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ],
                        2
                      )
                    : _c("p", [_vm._v("Pas d'acheteur.")]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "property-edit__transactions__category" }, [
            _c(
              "h6",
              {
                staticClass:
                  "property-edit__transactions__category__title mdc-typography--headline6",
              },
              [
                _c(
                  "span",
                  [
                    _vm._v("\n                    Autre"),
                    _vm.oldTransactions.length > 1 ? [_vm._v("s")] : _vm._e(),
                    _vm._v("\n                    acheteur"),
                    _vm.oldTransactions.length > 1 ? [_vm._v("s")] : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  {
                    attrs: { primary: true },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.showBookingModal = "old"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Ajouter acheteur\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.oldTransactions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mdc-list" },
                  [
                    _vm._l(_vm.oldTransactions, function (oldTransaction) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "mdc-list-item",
                            on: {
                              click: function ($event) {
                                return _vm.redirectTo("contact", {
                                  id: oldTransaction.contact.id,
                                })
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mdc-list-item__graphic" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-icons imagine-status imagine-status--sold",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                lens\n                            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(oldTransaction.contact.shortName) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "mdc-list-item__meta",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    _vm.showRemoveTransactionConfirmation =
                                      oldTransaction
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "material-icons" }, [
                                  _vm._v("delete"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mdc-list-divider" }),
                      ]
                    }),
                  ],
                  2
                )
              : _c("p", [_vm._v("Pas d'autre acheteur.")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "property-edit__transactions__category" }, [
            _c(
              "h6",
              {
                staticClass:
                  "property-edit__transactions__category__title mdc-typography--headline6",
              },
              [
                _c(
                  "span",
                  [
                    _vm._v("\n                    Mandat"),
                    _vm.property && _vm.property.mandates.length > 1
                      ? [_vm._v("s")]
                      : _vm._e(),
                    _vm._v(
                      "\n                    de gestion\n                "
                    ),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _vm.property && _vm.property.mandates.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mdc-list",
                    class: {
                      "mdc-list--non-interactive": !_vm.isMandatesGranted(),
                    },
                  },
                  [
                    _vm._l(_vm.property.mandates, function (mandateProperty) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "mdc-list-item",
                            on: {
                              click: function ($event) {
                                return _vm.gotoMandate(mandateProperty)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mdc-list-item__graphic" },
                              [
                                _c("mandate-property-status", {
                                  attrs: { mandate: mandateProperty },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _vm._v(
                                "\n                            Pour " +
                                  _vm._s(
                                    mandateProperty.owners
                                      .map((owner) => owner.shortName)
                                      .join(",")
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mdc-list-item__meta" }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mdc-list-divider" }),
                      ]
                    }),
                  ],
                  2
                )
              : _c("p", [_vm._v("Pas en gestion.")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "property-edit__transactions__category" }, [
            _c(
              "h6",
              {
                staticClass:
                  "property-edit__transactions__category__title mdc-typography--headline6",
              },
              [
                _c(
                  "span",
                  [
                    _vm._v("\n                    Locataire"),
                    _vm.rentalTransactions.length > 1
                      ? [_vm._v("s")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _vm.rentalTransactions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mdc-list mdc-list--non-interactive" },
                  [
                    _vm._l(_vm.rentalTransactions, function (rental) {
                      return [
                        _c("div", { staticClass: "mdc-list-item" }, [
                          _c(
                            "span",
                            { staticClass: "mdc-list-item__graphic" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "material-icons imagine-status",
                                  class: {
                                    "imagine-status--sold": !rental.isOngoing,
                                    "imagine-status--success": rental.isOngoing,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                lens\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "mdc-list-item__text" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  rental.contacts
                                    .map((contact) => contact.shortName)
                                    .join(", ")
                                ) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "mdc-list-item__meta" },
                            [
                              rental.hasMandate
                                ? [_vm._v("[Gestion]")]
                                : _c(
                                    "span",
                                    {
                                      staticClass: "material-icons",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.showRemoveTransactionConfirmation =
                                            rental
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                delete\n                            "
                                      ),
                                    ]
                                  ),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mdc-list-divider" }),
                      ]
                    }),
                  ],
                  2
                )
              : _c("p", [_vm._v("Aucun locataire.")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.showRemoveConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { buttonLabel: "Supprimer" },
              on: { act: _vm.remove, close: _vm.closeConfirm },
            },
            [_c("p", [_vm._v("Etes vous sûr de vouloir supprimer ce bien ?")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDuplicateConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { buttonLabel: "Dupliquer" },
              on: { act: _vm.duplicate, close: _vm.closeConfirm },
            },
            [_c("p", [_vm._v("Etes vous sûr de vouloir dupliquer ce bien ?")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRemoveTransactionConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: {
                act: _vm.removeOldTransaction,
                close: function ($event) {
                  _vm.showRemoveTransactionConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Etes vous sûr de vouloir supprimer cette transaction ?"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      this.propertyId && _vm.showBookingModal
        ? _c(
            "imagine-modal",
            {
              staticClass: "imagine-modal--booking",
              attrs: {
                "button-disabled": !_vm.owner,
                "button-label": _vm.bookingButton,
                title: _vm.bookingTitle,
              },
              on: {
                act: function ($event) {
                  _vm.showBookingModal === "new_build"
                    ? _vm.addSaleOption()
                    : _vm.book(_vm.showBookingModal)
                },
                close: function ($event) {
                  _vm.showBookingModal = false
                },
              },
            },
            [
              _c("imagine-contact-autocomplete", {
                attrs: {
                  "redirect-after-create": {
                    name: this.$route.name,
                    params: { id: this.propertyId },
                    query: { ack: _vm.showBookingModal },
                  },
                  label: "Contact",
                },
                model: {
                  value: _vm.owner,
                  callback: function ($$v) {
                    _vm.owner = $$v
                  },
                  expression: "owner",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }