<template>
    <div class="imagine-login">
        <imagine-loader v-if="isLoading"/>

        <div class="imagine-login__panel mdc-elevation--z1">
            <imagine-logo/>

            <imagine-alert v-if="!valid">Email ou mot de passe incorrect.</imagine-alert>

            <form @submit.prevent="authenticate" class="imagine-login__panel__form">
                <imagine-input :invalid="!valid"
                               autofocus
                               label="Email"
                               name="email"
                               required
                               type="email"
                               v-model="email"/>

                <imagine-input :invalid="!valid"
                               label="Mot de passe"
                               name="password"
                               required
                               type="password"
                               v-model="password"/>

                <imagine-button primary type="submit">Se connecter</imagine-button>
            </form>
        </div>
    </div>
</template>

<script>
import ImagineInput from '../components/ImagineInput.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineLogo from '../components/ImagineLogo.vue';
import ImagineLoader from '../components/ImagineLoader.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import {mapGetters} from 'vuex';

export default {
    components: {ImagineInput, ImagineLogo, ImagineButton, ImagineLoader, ImagineAlert},
    computed: {
        ...mapGetters(['isLoading']),
    },
    data() {
        return {
            email: null,
            password: null,
            valid: true
        }
    },
    methods: {
        authenticate() {
            this.valid = true;
            this.load();
            this.$store.dispatch('user/authenticate', {email: this.email, password: this.password})
                .then(() => {
                    this._everyTimesLoginSucceed()
                        .catch(this.serverError)
                        .finally(() => {
                            this.redirectToHome();
                            this.unload();
                        });
                })
                .catch(() => {
                    this.valid = false;
                    this.unload();
                });
        },
        _everyTimesLoginSucceed() {
            return this.$store.dispatch('reloadReferences');
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.imagine-login {
    display: flex;
    height: 100vh;
    background: #f5f5f5;

    .imagine-logo {
        margin-top: 0;
    }

    &__panel {
        background: #ffffff;
        margin: auto;
        padding: 2rem;
        min-width: 300px;
        max-width: 480px;

        .md-field {
            background: white;
            padding-left: 0;

            label {
                padding-left: 0;
            }
        }

        .imagine-button {
            margin-top: 1rem;
            width: 100%;
        }
    }
}
</style>
