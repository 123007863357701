<template>
    <md-field :class="{'md-invalid': invalid || violation}"
              :md-clearable="clearable"
              @md-clear="$emit('clear')"
              class="imagine-input">
        <md-icon v-if="icon">{{ icon }}</md-icon>
        <label>{{ label }}</label>
        <span class="md-prefix"
              v-if="prefix">
            {{ prefix }}
        </span>
        <md-input v-model="innerValue"
                  :type="type"
                  :id="name"
                  :name="name"
                  :required="required"
                  :autocomplete="autocomplete"
                  :autofocus="autofocus"
                  :max="max"
                  :min="min"
                  :step="step"
                  :disabled="disabled"
                  :placeholder="placeholder"
                  :tabindex="tabindex"
                  @input="$emit('input', innerValue)"
                  @focusin="$emit('focusin')"
                  @focusout="$emit('focusout')"
                  @keyup.enter="$emit('enter')"/>
        <span class="md-helper-text"
              v-if="help">
            {{ help }}
        </span>
        <span class="md-error"
              v-if="violation">
            {{ violation }}
        </span>
    </md-field>
</template>

<script>
export default {
    props: {
        label: {required: true},
        prefix: {default: null},
        name: {required: true},
        type: {default: 'text'},
        value: {default: null},
        placeholder: {default: null},
        required: {type: Boolean, default: false},
        invalid: {type: Boolean, default: false},
        help: {default: null},
        violation: {default: null},
        autocomplete: {default: true},
        autofocus: {type: Boolean, default: false},
        tabindex: {default: null},
        icon: {default: null},
        min: {default: null},
        max: {default: null},
        step: {default: null},
        clearable: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false}
    },
    data() {
        return {
            innerValue: this.value
        }
    },
    watch: {
        value(newVal) {
            this.$nextTick(() => this.innerValue = newVal);
        }
    }
}
</script>

<style lang="scss">
.imagine-input {
    .md-input {
        width: 100%;
    }

    &.md-field > .md-icon {
        margin: -4px auto;
    }

    &.md-field > .md-icon ~ .md-input {
        margin-left: 0;
    }

    &.md-field .md-input-action {
        top: 12px;
    }

    &.md-field > .md-icon:after {
        width: 24px;
    }
}
</style>
