var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "employee-edit",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.employee
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: _vm.isActive ? "lock_open" : "lock" },
                    on: { click: _vm.toggleActive },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.employee
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "delete" },
                    on: {
                      click: function ($event) {
                        _vm.showRemoveConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "imagine-container" }, [
        _c(
          "form",
          {
            staticClass: "imagine-form",
            attrs: { autocomplete: "off" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "employee-edit__avatar-row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "imagine-form__row employee-edit__avatar-row__photo",
                },
                [
                  _c("imagine-file-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      rounded: true,
                      accept: "image/*",
                      label: "Photo",
                      options: { cropRatio: "1/1" },
                    },
                    model: {
                      value: _vm.photo,
                      callback: function ($$v) {
                        _vm.photo = $$v
                      },
                      expression: "photo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "employee-edit__avatar-row__infos" }, [
                _c(
                  "div",
                  { staticClass: "imagine-form__row" },
                  [
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        required: true,
                        label: "Prénom",
                        name: "firstname",
                      },
                      model: {
                        value: _vm.firstname,
                        callback: function ($$v) {
                          _vm.firstname = $$v
                        },
                        expression: "firstname",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: { required: true, label: "Nom", name: "lastname" },
                      model: {
                        value: _vm.lastname,
                        callback: function ($$v) {
                          _vm.lastname = $$v
                        },
                        expression: "lastname",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imagine-form__row" },
                  [
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        required: true,
                        label: "Poste",
                        name: "position",
                      },
                      model: {
                        value: _vm.position,
                        callback: function ($$v) {
                          _vm.position = $$v
                        },
                        expression: "position",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    help: "Utilisé pour la connexion et les notifications",
                    required: true,
                    label: "Email",
                    name: "email",
                    type: "email",
                    violation: _vm.errors.email,
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
                _vm._v(" "),
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    help: "Sans tiret ni point, ajouter +33 si métropole ou autre",
                    required: true,
                    label: "Vini",
                    name: "mobilePhone",
                    violation: _vm.errors.phone,
                  },
                  model: {
                    value: _vm.mobilePhone,
                    callback: function ($$v) {
                      _vm.mobilePhone = $$v
                    },
                    expression: "mobilePhone",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    violation: _vm.errors.plainPassword,
                    help: "Recommandé : 16 caractères avec chiffres et lettres",
                    label: _vm.employee
                      ? "Nouveau mot de passe"
                      : "Mot de passe",
                    name: "password",
                    type: "password",
                  },
                  model: {
                    value: _vm.password,
                    callback: function ($$v) {
                      _vm.password = $$v
                    },
                    expression: "password",
                  },
                }),
                _vm._v(" "),
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    violation: _vm.errors.plainPassword,
                    help: "Recommandé : 16 caractères avec chiffres et lettres",
                    label: "Confirmer",
                    name: "passwordBis",
                    type: "password",
                  },
                  model: {
                    value: _vm.passwordBis,
                    callback: function ($$v) {
                      _vm.passwordBis = $$v
                    },
                    expression: "passwordBis",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-select", {
                  staticClass: "imagine-form__row__field",
                  attrs: { choices: _vm.groupChoices, label: "Groupe" },
                  model: {
                    value: _vm.group,
                    callback: function ($$v) {
                      _vm.group = $$v
                    },
                    expression: "group",
                  },
                }),
                _vm._v(" "),
                _vm.group === "ROLE_SALES_REP" || _vm.group === "ROLE_CEO"
                  ? _c("imagine-select", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        choices: _vm.serviceChoices,
                        label: "Service",
                        required: "",
                      },
                      model: {
                        value: _vm.service,
                        callback: function ($$v) {
                          _vm.service = $$v
                        },
                        expression: "service",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-checkboxes", {
                  attrs: {
                    choices: _vm.references.notificationChoices(),
                    label: "Recevoir emails",
                    name: "notifications",
                  },
                  model: {
                    value: _vm.notifications,
                    callback: function ($$v) {
                      _vm.notifications = $$v
                    },
                    expression: "notifications",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__actions" },
              [
                _c(
                  "imagine-button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Annuler")]
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  { attrs: { primary: "", type: "submit" } },
                  [_vm._v("Sauvegarder")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showRemoveConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { buttonLabel: "Supprimer" },
              on: { act: _vm.remove, close: _vm.closeConfirm },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer cet employé ?"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }