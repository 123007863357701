var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-modal--wide",
      attrs: { "button-label": "Envoyer", title: "Relance impayés" },
      on: {
        act: function ($event) {
          return _vm.sendReminder()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c("imagine-alert", { attrs: { type: "info" } }, [
        _vm._v(
          "\n        L'email de relance est envoyé à tous les locataires et les éventuels garants dont l'email est disponible.\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-select", {
            attrs: {
              choices: _vm.modelChoices,
              required: true,
              label: "Modèle",
              name: "models",
            },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "imagine-button",
            {
              on: {
                click: function ($event) {
                  return _vm.externalRedirectTo(_vm.previewUrl)
                },
              },
            },
            [_vm._v("Prévisualiser")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }