var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "contact-edit",
      attrs: { inner: true, title: _vm.pageTitle },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.contact
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: {
                      icon: _vm.contact.isFavorite ? "star" : "star_border",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleFavorite()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contact && _vm.contact.email
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "email" },
                    on: {
                      click: function ($event) {
                        return _vm.openMail(_vm.contact.email)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contact && _vm.contact.mobilePhone
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "smartphone" },
                    on: {
                      click: function ($event) {
                        return _vm.openTel(_vm.contact.mobilePhone.e164)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.contact
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: {
                      icon: "delete",
                      disabled:
                        !_vm.contact ||
                        _vm.contact.isClient ||
                        _vm.isLimitedView ||
                        _vm.contact.represents.length > 0,
                    },
                    on: {
                      click: function ($event) {
                        _vm.showRemoveConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("imagine-tab-bar", {
        attrs: { tabs: _vm.tabs },
        on: {
          tab: function ($event) {
            return _vm.changeTab($event)
          },
        },
      }),
      _vm._v(" "),
      _vm.activeTab === "infos"
        ? _c(
            "div",
            { staticClass: "imagine-container contact-edit__infos" },
            [
              _vm.errors.global
                ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "imagine-form",
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "contact-edit__infos__company" },
                    [
                      _c(
                        "imagine-switch",
                        {
                          attrs: {
                            disabled: !!_vm.contact || _vm.isLimitedView,
                          },
                          model: {
                            value: _vm.isCompany,
                            callback: function ($$v) {
                              _vm.isCompany = $$v
                            },
                            expression: "isCompany",
                          },
                        },
                        [_c("span", [_vm._v("Entreprise")])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isCompany &&
                  _vm.contact &&
                  _vm.contact.represents.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "contact-edit__infos__legal-represents",
                        },
                        [
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n                    Représentant légal de\n                    "
                              ),
                              _vm._l(
                                _vm.contact.represents,
                                function (represent, index) {
                                  return [
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(represent.company)
                                        ),
                                        index !==
                                        Object.keys(_vm.contact.represents)
                                          .length -
                                          1
                                          ? [_vm._v(", ")]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isCompany
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-select", {
                            staticClass:
                              "imagine-form__row__field contact-edit__infos__title",
                            attrs: {
                              choices: _vm.references.titleChoices(),
                              label: "Civ.",
                              disabled: _vm.isLimitedView,
                              name: "title",
                              violation: _vm.errors.title,
                            },
                            model: {
                              value: _vm.contactTitle,
                              callback: function ($$v) {
                                _vm.contactTitle = $$v
                              },
                              expression: "contactTitle",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Nom",
                              disabled: _vm.isLimitedView,
                              name: "lastname",
                            },
                            model: {
                              value: _vm.lastname,
                              callback: function ($$v) {
                                _vm.lastname = $$v
                              },
                              expression: "lastname",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Prénom",
                              disabled: _vm.isLimitedView,
                              name: "firstname",
                            },
                            model: {
                              value: _vm.firstname,
                              callback: function ($$v) {
                                _vm.firstname = $$v
                              },
                              expression: "firstname",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: _vm.isCompany
                            ? "Raison sociale"
                            : "Entreprise",
                          name: "company",
                          disabled: _vm.isLimitedView,
                        },
                        model: {
                          value: _vm.company,
                          callback: function ($$v) {
                            _vm.company = $$v
                          },
                          expression: "company",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.isCompany
                        ? _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Métier",
                              name: "position",
                              disabled: _vm.isLimitedView,
                            },
                            model: {
                              value: _vm.position,
                              callback: function ($$v) {
                                _vm.position = $$v
                              },
                              expression: "position",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isCompany
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.maxBirthDate,
                              violation: _vm.errors.dateOfBirth,
                              label: "Date de naissance",
                              disabled: _vm.isLimitedView,
                              name: "dateOfBirth",
                            },
                            model: {
                              value: _vm.dateOfBirth,
                              callback: function ($$v) {
                                _vm.dateOfBirth = $$v
                              },
                              expression: "dateOfBirth",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass:
                              "imagine-form__row__field contact-edit__infos__age",
                            attrs: {
                              choices: _vm.references.ageChoices(),
                              label: "Ou âge approx.",
                              disabled: _vm.isLimitedView,
                              name: "age",
                            },
                            model: {
                              value: _vm.age,
                              callback: function ($$v) {
                                _vm.age = $$v
                              },
                              expression: "age",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row imagine-form__row--limited",
                    },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Vini",
                          name: "mobilePhone",
                          disabled: _vm.isLimitedView,
                          help: "Sans tiret ni point, ajouter +33 si métropole ou autre",
                          violation: _vm.errors.mobilePhone,
                        },
                        model: {
                          value: _vm.mobilePhone,
                          callback: function ($$v) {
                            _vm.mobilePhone = $$v
                          },
                          expression: "mobilePhone",
                        },
                      }),
                      _vm._v(" "),
                      !_vm.isCompany
                        ? _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              violation: _vm.errors.homePhone,
                              help: "Sans tiret ni point, ajouter +33 si métropole ou autre",
                              label: "Téléphone domicile",
                              disabled: _vm.isLimitedView,
                              name: "homePhone",
                            },
                            model: {
                              value: _vm.homePhone,
                              callback: function ($$v) {
                                _vm.homePhone = $$v
                              },
                              expression: "homePhone",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          violation: _vm.errors.workPhone,
                          label: "Téléphone travail",
                          disabled: _vm.isLimitedView,
                          help: "Sans tiret ni point, ajouter +33 si métropole ou autre",
                          name: "workPhone",
                        },
                        model: {
                          value: _vm.workPhone,
                          callback: function ($$v) {
                            _vm.workPhone = $$v
                          },
                          expression: "workPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row imagine-form__row--limited",
                    },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          type: "email",
                          label: "Email principal",
                          name: "email",
                          disabled: _vm.isLimitedView,
                          violation: _vm.errors.email,
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          violation: _vm.errors.workEmail,
                          label: "Autre email",
                          name: "workEmail",
                          disabled: _vm.isLimitedView,
                          type: "email",
                        },
                        model: {
                          value: _vm.workEmail,
                          callback: function ($$v) {
                            _vm.workEmail = $$v
                          },
                          expression: "workEmail",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          violation: _vm.errors.facebook,
                          label: "Compte facebook",
                          name: "facebook",
                          disabled: _vm.isLimitedView,
                          type: "text",
                        },
                        model: {
                          value: _vm.facebook,
                          callback: function ($$v) {
                            _vm.facebook = $$v
                          },
                          expression: "facebook",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("imagine-address", {
                    attrs: {
                      show: _vm.showPostalAddress,
                      "with-address": true,
                      disabled: _vm.isLimitedView,
                    },
                    model: {
                      value: _vm.postalAddress,
                      callback: function ($$v) {
                        _vm.postalAddress = $$v
                      },
                      expression: "postalAddress",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "imagine-form-complex-field",
                    {
                      attrs: {
                        show: _vm.showSource,
                        disabled: _vm.isLimitedView,
                        title: _vm.sourceTitle,
                      },
                      on: {
                        close: function ($event) {
                          _vm.showSource = false
                        },
                        open: function ($event) {
                          _vm.showSource = true
                        },
                      },
                    },
                    [
                      _c("imagine-checkboxes", {
                        attrs: {
                          choices: _vm.references.sourceChoices(),
                          name: "sources",
                          disabled: _vm.isLimitedView,
                        },
                        model: {
                          value: _vm.sources,
                          callback: function ($$v) {
                            _vm.sources = $$v
                          },
                          expression: "sources",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isLimitedView
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-textarea", {
                            staticClass:
                              "imagine-form__row__field contact-edit__infos__note",
                            attrs: {
                              label: "Notes",
                              name: "comment1",
                              help: _vm.helpNote,
                            },
                            model: {
                              value: _vm.comment,
                              callback: function ($$v) {
                                _vm.comment = $$v
                              },
                              expression: "comment",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCompany
                    ? _c(
                        "div",
                        { staticClass: "contact-edit__infos__legal-rep" },
                        [
                          _c("div", { staticClass: "imagine-form__sep" }),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass:
                                "contact-edit__infos__legal-rep__title mdc-typography--headline6",
                            },
                            [
                              _vm._v(
                                "\n                    Représentants légaux\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.contact
                            ? _vm._l(
                                _vm.legalRepSlots,
                                function (legalRepSlot, slot) {
                                  return _vm.nbLegalReps >= slot
                                    ? _c(
                                        "div",
                                        { staticClass: "imagine-form__row" },
                                        [
                                          _c("imagine-contact-autocomplete", {
                                            staticClass:
                                              "imagine-form__row__field",
                                            attrs: {
                                              label:
                                                slot > 0
                                                  ? "Autre représentant légal"
                                                  : "Représentant légal",
                                              required: false,
                                              disabled: _vm.isLimitedView,
                                              name: "legalRep",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.removeLegalRepSlotHoles()
                                              },
                                            },
                                            model: {
                                              value: legalRepSlot.contact,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  legalRepSlot,
                                                  "contact",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "legalRepSlot.contact",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                }
                              )
                            : _c("imagine-alert", { attrs: { type: "info" } }, [
                                _vm._v(
                                  "\n                    Pour pouvoir ajouter des représentants légaux, il faut réaliser une première sauvegarde de\n                    l'entreprise.\n                "
                                ),
                              ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__actions" },
                    [
                      _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back()
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _vm._v(" "),
                      _c(
                        "imagine-button",
                        {
                          attrs: {
                            primary: "",
                            disabled: _vm.isLimitedView,
                            type: "submit",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Sauvegarder\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.contact
                ? _c(
                    "div",
                    { staticClass: "contact-edit__infos__timeline" },
                    [
                      _vm.timeline.length > 0
                        ? _c("timeline", { attrs: { events: _vm.timeline } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "imagine-form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.createEvent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "imagine-form__row" },
                            [
                              _c("imagine-textarea", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  label:
                                    "Nouvel évènement : contact tél, RDV, etc.",
                                  required: true,
                                  name: "event1",
                                },
                                model: {
                                  value: _vm.eventDescription,
                                  callback: function ($$v) {
                                    _vm.eventDescription = $$v
                                  },
                                  expression: "eventDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Ajouter évènement")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "prospect_new"
        ? _c(
            "div",
            { staticClass: "imagine-container" },
            [
              _vm.errors.global
                ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "imagine-form contact-edit__prospect",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.canFilterSalesRep()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row contact-edit__prospect__salesrep",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.salesRepChoices,
                              label: "Négociateur",
                              name: "salesrep",
                            },
                            model: {
                              value: _vm.salesRep,
                              callback: function ($$v) {
                                _vm.salesRep = $$v
                              },
                              expression: "salesRep",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row contact-edit__prospect__transactions",
                    },
                    [
                      _c("imagine-checkbox", {
                        attrs: {
                          label: "EN RECHERCHE",
                          name: "isProspectNewBuild",
                        },
                        model: {
                          value: _vm.isProspectNewBuild,
                          callback: function ($$v) {
                            _vm.isProspectNewBuild = $$v
                          },
                          expression: "isProspectNewBuild",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c(
                        "imagine-checkboxes",
                        {
                          staticClass: "imagine-form__row__field",
                          attrs: {
                            choices: _vm.references.areaChoices(),
                            name: "prospectareas",
                            label: "Zones",
                          },
                          model: {
                            value: _vm.prospectAreas,
                            callback: function ($$v) {
                              _vm.prospectAreas = $$v
                            },
                            expression: "prospectAreas",
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "imagine-checkareas",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addProspectAreas(
                                      "west",
                                      _vm.prospectAreas
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Cocher cote ouest\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "imagine-checkareas",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addProspectAreas(
                                      "east",
                                      _vm.prospectAreas
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Cocher cote est\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("imagine-checkboxes", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.propertyTypeChoices(),
                          name: "prospectpropertytypes",
                          label: "Types de bien",
                        },
                        model: {
                          value: _vm.prospectPropertyTypes,
                          callback: function ($$v) {
                            _vm.prospectPropertyTypes = $$v
                          },
                          expression: "prospectPropertyTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-checkboxes", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.blockChoices(
                            _vm.blocks,
                            true
                          ),
                          name: "prospectblocks",
                          label: "Résidences",
                        },
                        model: {
                          value: _vm.prospectBlocks,
                          callback: function ($$v) {
                            _vm.prospectBlocks = $$v
                          },
                          expression: "prospectBlocks",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.purchaseTypeChoices(),
                          label: "Type d'achat",
                          name: "prospectpurchasetype",
                        },
                        model: {
                          value: _vm.prospectPurchaseType,
                          callback: function ($$v) {
                            _vm.prospectPurchaseType = $$v
                          },
                          expression: "prospectPurchaseType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.newHasRooms
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Nb. de pièces min.",
                              name: "nbRooms",
                              type: "number",
                              violation: _vm.errors.prospectNbRoomsMin,
                            },
                            model: {
                              value: _vm.prospectNbRoomsMin,
                              callback: function ($$v) {
                                _vm.prospectNbRoomsMin = $$v
                              },
                              expression: "prospectNbRoomsMin",
                            },
                          }),
                          _vm._v(" "),
                          _vm.newHasBedrooms
                            ? _c("imagine-input", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  label: "Nb. de chambres min.",
                                  name: "nbBedrooms",
                                  type: "number",
                                  violation: _vm.errors.prospectNbBedroomsMin,
                                },
                                model: {
                                  value: _vm.prospectNbBedroomsMin,
                                  callback: function ($$v) {
                                    _vm.prospectNbBedroomsMin = $$v
                                  },
                                  expression: "prospectNbBedroomsMin",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.buyBudgetChoices(),
                          label: "Budget d'achat",
                          name: "prospectbuybudget",
                        },
                        model: {
                          value: _vm.prospectBuyBudget,
                          callback: function ($$v) {
                            _vm.prospectBuyBudget = $$v
                          },
                          expression: "prospectBuyBudget",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.financingChoices(),
                          label: "Financement achat",
                          name: "prospectfinancing",
                        },
                        model: {
                          value: _vm.prospectFinancing,
                          callback: function ($$v) {
                            _vm.prospectFinancing = $$v
                          },
                          expression: "prospectFinancing",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          name: "maxProspecting",
                          label: "Date butoir",
                          help: "N'apparaitra plus dans les résultats prospect promotion au delà de cette date",
                        },
                        model: {
                          value: _vm.maxProspecting,
                          callback: function ($$v) {
                            _vm.maxProspecting = $$v
                          },
                          expression: "maxProspecting",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          name: "reminder",
                          label: "Date de relance",
                          help: "Enverra un email au négociateur promotion à cette date",
                        },
                        model: {
                          value: _vm.reminder,
                          callback: function ($$v) {
                            _vm.reminder = $$v
                          },
                          expression: "reminder",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-textarea", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Notes",
                          help: _vm.helpNote,
                          name: "comment2",
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__actions" },
                    [
                      _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back()
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _vm._v(" "),
                      _c(
                        "imagine-button",
                        { attrs: { primary: "", type: "submit" } },
                        [_vm._v("Sauvegarder")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.contact
                ? _c(
                    "div",
                    { staticClass: "contact-edit__infos__timeline" },
                    [
                      _vm.timeline.length > 0
                        ? _c("timeline", { attrs: { events: _vm.timeline } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "imagine-form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.createEvent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "imagine-form__row" },
                            [
                              _c("imagine-textarea", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  required: true,
                                  label:
                                    "Nouvel évènement : contact tél, RDV, etc.",
                                  name: "event2",
                                },
                                model: {
                                  value: _vm.eventDescription,
                                  callback: function ($$v) {
                                    _vm.eventDescription = $$v
                                  },
                                  expression: "eventDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Ajouter évènement")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contact && _vm.contact.matchingProperties.length > 0
                ? [
                    _c("h3", { staticStyle: { margin: "1rem" } }, [
                      _vm._v("Biens correspondants promotion"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mdc-list mdc-list--two-line" },
                      _vm._l(_vm.contact.matchingProperties, function (match) {
                        return _c(
                          "div",
                          {
                            staticClass: "mdc-list-item",
                            on: {
                              click: function ($event) {
                                return _vm.gotoProperty(match.property)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdc-list-item__text" }, [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__primary-text" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(match.property.infos) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-list-item__secondary-text",
                                },
                                [
                                  _vm._v(
                                    "\n                            Référence " +
                                      _vm._s(match.property.reference) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mdc-list-item__meta" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(Math.round(match.score * 100)) +
                                  "%\n                    "
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "prospect_old"
        ? _c(
            "div",
            { staticClass: "imagine-container" },
            [
              _vm.errors.global
                ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "imagine-form contact-edit__prospect",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.canFilterSalesRep()
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row contact-edit__prospect__salesrep",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.salesRepChoices,
                              label: "Négociateur",
                              name: "salesrepother",
                            },
                            model: {
                              value: _vm.salesRepOther,
                              callback: function ($$v) {
                                _vm.salesRepOther = $$v
                              },
                              expression: "salesRepOther",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row contact-edit__prospect__transactions",
                    },
                    [
                      _c("imagine-checkboxes", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.oldProspectTransactionChoices,
                          name: "prospecttransactions",
                        },
                        model: {
                          value: _vm.prospectTransactionTypes,
                          callback: function ($$v) {
                            _vm.prospectTransactionTypes = $$v
                          },
                          expression: "prospectTransactionTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c(
                        "imagine-checkboxes",
                        {
                          staticClass: "imagine-form__row__field",
                          attrs: {
                            choices: _vm.references.areaChoices(),
                            name: "prospectoldareas",
                            label: "Zones",
                          },
                          model: {
                            value: _vm.prospectOldAreas,
                            callback: function ($$v) {
                              _vm.prospectOldAreas = $$v
                            },
                            expression: "prospectOldAreas",
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "a",
                              {
                                staticClass: "imagine-checkareas",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addProspectAreas(
                                      "west",
                                      _vm.prospectOldAreas
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Cocher cote ouest\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "imagine-checkareas",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addProspectAreas(
                                      "east",
                                      _vm.prospectOldAreas
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Cocher cote est\n                        "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("imagine-checkboxes", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.references.propertyTypeChoices(),
                          name: "prospectoldpropertytypes",
                          label: "Types de bien",
                        },
                        model: {
                          value: _vm.prospectOldPropertyTypes,
                          callback: function ($$v) {
                            _vm.prospectOldPropertyTypes = $$v
                          },
                          expression: "prospectOldPropertyTypes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.oldHasRooms
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Nb. de pièces min.",
                              name: "oldnbRooms",
                              type: "number",
                              violation: _vm.errors.prospectOldNbRoomsMin,
                            },
                            model: {
                              value: _vm.prospectOldNbRoomsMin,
                              callback: function ($$v) {
                                _vm.prospectOldNbRoomsMin = $$v
                              },
                              expression: "prospectOldNbRoomsMin",
                            },
                          }),
                          _vm._v(" "),
                          _vm.oldHasBedrooms
                            ? _c("imagine-input", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  label: "Nb. de chambres min.",
                                  name: "oldnbBedrooms",
                                  type: "number",
                                  violation:
                                    _vm.errors.prospectOldNbBedroomsMin,
                                },
                                model: {
                                  value: _vm.prospectOldNbBedroomsMin,
                                  callback: function ($$v) {
                                    _vm.prospectOldNbBedroomsMin = $$v
                                  },
                                  expression: "prospectOldNbBedroomsMin",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isBuying
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.purchaseTypeChoices(),
                              label: "Type d'achat",
                              name: "oldsprospectpurchasetype",
                            },
                            model: {
                              value: _vm.prospectOldPurchaseType,
                              callback: function ($$v) {
                                _vm.prospectOldPurchaseType = $$v
                              },
                              expression: "prospectOldPurchaseType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isBuying
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.buyBudgetChoices(),
                              label: "Budget d'achat",
                              name: "oldprospectbuybudget",
                            },
                            model: {
                              value: _vm.prospectOldBuyBudget,
                              callback: function ($$v) {
                                _vm.prospectOldBuyBudget = $$v
                              },
                              expression: "prospectOldBuyBudget",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.financingChoices(),
                              label: "Financement achat",
                              name: "oldprospectfinancing",
                            },
                            model: {
                              value: _vm.prospectOldFinancing,
                              callback: function ($$v) {
                                _vm.prospectOldFinancing = $$v
                              },
                              expression: "prospectOldFinancing",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRenting
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.rentBudgetChoices(),
                              label: "Budget de location",
                              name: "prospectrentbudget",
                            },
                            model: {
                              value: _vm.prospectRentBudget,
                              callback: function ($$v) {
                                _vm.prospectRentBudget = $$v
                              },
                              expression: "prospectRentBudget",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.references.furnishedChoices(),
                              label: "Ameublement",
                              name: "prospectrentfurnished",
                            },
                            model: {
                              value: _vm.prospectRentFurnished,
                              callback: function ($$v) {
                                _vm.prospectRentFurnished = $$v
                              },
                              expression: "prospectRentFurnished",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRenting
                    ? _c(
                        "div",
                        { staticClass: "imagine-form__row" },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices:
                                _vm.references.prospectRentTaxExemptionChoices(),
                              label: "Défiscalisation",
                              name: "prospectrenttaxexemption",
                            },
                            model: {
                              value: _vm.prospectRentTaxExemption,
                              callback: function ($$v) {
                                _vm.prospectRentTaxExemption = $$v
                              },
                              expression: "prospectRentTaxExemption",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          name: "oldMaxProspecting",
                          label: "Date butoir",
                          help: "N'apparaitra plus dans les résultats prospect agence au delà de cette date",
                        },
                        model: {
                          value: _vm.oldMaxProspecting,
                          callback: function ($$v) {
                            _vm.oldMaxProspecting = $$v
                          },
                          expression: "oldMaxProspecting",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          name: "oldReminder",
                          label: "Date de relance",
                          help: "Enverra un email au négociateur agence à cette date",
                        },
                        model: {
                          value: _vm.oldReminder,
                          callback: function ($$v) {
                            _vm.oldReminder = $$v
                          },
                          expression: "oldReminder",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-textarea", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Notes",
                          name: "comment3",
                          help: _vm.helpNote,
                        },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__actions" },
                    [
                      _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back()
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _vm._v(" "),
                      _c(
                        "imagine-button",
                        { attrs: { primary: "", type: "submit" } },
                        [_vm._v("Sauvegarder")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.contact
                ? _c(
                    "div",
                    { staticClass: "contact-edit__infos__timeline" },
                    [
                      _vm.timeline.length > 0
                        ? _c("timeline", { attrs: { events: _vm.timeline } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "imagine-form",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.createEvent.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "imagine-form__row" },
                            [
                              _c("imagine-textarea", {
                                staticClass: "imagine-form__row__field",
                                attrs: {
                                  required: true,
                                  label:
                                    "Nouvel évènement : contact tél, RDV, etc.",
                                  name: "event2",
                                },
                                model: {
                                  value: _vm.eventDescription,
                                  callback: function ($$v) {
                                    _vm.eventDescription = $$v
                                  },
                                  expression: "eventDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Ajouter évènement")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contact && _vm.contact.matchingOldProperties.length > 0
                ? [
                    _c("h3", { staticStyle: { margin: "1rem" } }, [
                      _vm._v("Biens correspondants agence"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mdc-list mdc-list--two-line" },
                      _vm._l(
                        _vm.contact.matchingOldProperties,
                        function (match) {
                          return _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.gotoProperty(match.property)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__primary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(match.property.infos) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__secondary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Référence " +
                                          _vm._s(match.property.reference) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__meta" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(Math.round(match.score * 100)) +
                                      "%\n                    "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.contact && _vm.activeTab === "client"
        ? _c("div", { staticClass: "contact-edit__client" }, [
            _c("div", { staticClass: "contact-edit__client__category" }, [
              _c(
                "h6",
                {
                  staticClass:
                    "contact-edit__client__category__title mdc-typography--headline6",
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v("\n                    Achat"),
                      _vm.newBuildTransactions.length > 1
                        ? [_vm._v("s")]
                        : _vm._e(),
                      _vm._v("\n                    neuf"),
                      _vm.newBuildTransactions.length > 1
                        ? [_vm._v("s")]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.canAddSaleOption()
                    ? _c(
                        "imagine-button",
                        {
                          attrs: { primary: true },
                          on: {
                            click: function ($event) {
                              _vm.showBookingModal = "new_build"
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Poser option\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.contact && _vm.contact.sales.length > 0
                ? _c(
                    "div",
                    { staticClass: "mdc-list" },
                    [
                      _vm._l(_vm.contact.sales, function (sale) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.gotoSale(sale)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__graphic" },
                                [_c("sale-status", { attrs: { sale: sale } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(sale.property) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__meta" },
                                [
                                  sale.step === "option"
                                    ? _c("option-remove", {
                                        attrs: {
                                          "sale-id": sale.id,
                                          placeholder: _vm.references.stepLabel(
                                            sale.step
                                          ),
                                          "sales-rep-id": sale.salesRepId,
                                        },
                                        on: {
                                          removed: function ($event) {
                                            return _vm.reloadContact()
                                          },
                                        },
                                      })
                                    : [
                                        _vm._v(
                                          _vm._s(
                                            _vm.references.stepLabel(sale.step)
                                          )
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("p", [_vm._v("Aucun achat neuf")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "contact-edit__client__category" }, [
              _c(
                "h6",
                {
                  staticClass:
                    "contact-edit__client__category__title mdc-typography--headline6",
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v("\n                    Autre"),
                      _vm.oldTransactions.length > 1 ? [_vm._v("s")] : _vm._e(),
                      _vm._v("\n                    achat"),
                      _vm.oldTransactions.length > 1 ? [_vm._v("s")] : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    {
                      attrs: { primary: true },
                      on: {
                        click: function ($event) {
                          _vm.showBookingModal = "old"
                        },
                      },
                    },
                    [
                      _vm._v("\n                    Ajout bien "),
                      _c("small", [_vm._v("(hors neuf)")]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.oldTransactions.length > 0
                ? _c(
                    "div",
                    { staticClass: "mdc-list mdc-list--two-line" },
                    [
                      _vm._l(_vm.oldTransactions, function (transaction) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("property", {
                                    id: transaction.property.id,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__graphic" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "material-icons imagine-status imagine-status--sold",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                lens\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__primary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(transaction.property.infos) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__secondary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Référence " +
                                          _vm._s(
                                            transaction.property.reference
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-list-item__meta",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.showRemoveTransactionConfirmation =
                                        transaction
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [_vm._v("delete")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("p", [_vm._v("Pas d'autres achats.")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "contact-edit__client__category" }, [
              _c(
                "h6",
                {
                  staticClass:
                    "contact-edit__client__category__title mdc-typography--headline6",
                },
                [
                  _c("span", [_vm._v("Gestion")]),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    {
                      attrs: {
                        primary: true,
                        disabled: !_vm.isMandatesGranted(),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addMandate()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Ajouter mandat\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.contact.mandates.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "mdc-list",
                      class: {
                        "mdc-list--non-interactive": !_vm.isMandatesGranted(),
                      },
                    },
                    [
                      _vm._l(_vm.contact.mandates, function (mandate) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.gotoMandate(mandate)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _vm._v(
                                    "\n                            Mandat #" +
                                      _vm._s(mandate.registryNumber) +
                                      ",\n                            "
                                  ),
                                  mandate.properties.length === 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(mandate.properties[0].infos)
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(mandate.properties.length) +
                                            " biens"
                                        ),
                                      ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__meta" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatDate(mandate.startDate)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("p", [_vm._v("Aucun bien en gestion.")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "contact-edit__client__category" }, [
              _c(
                "h6",
                {
                  staticClass:
                    "contact-edit__client__category__title mdc-typography--headline6",
                },
                [
                  _c(
                    "span",
                    [
                      _vm._v("\n                    Location"),
                      _vm.rentalTransactions.length > 1
                        ? [_vm._v("s")]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    {
                      attrs: { primary: true },
                      on: {
                        click: function ($event) {
                          _vm.showBookingModal = "rental"
                        },
                      },
                    },
                    [
                      _vm._v("\n                    Ajout location "),
                      _c("small", [_vm._v("(hors gestion)")]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.rentalTransactions.length > 0
                ? _c(
                    "div",
                    { staticClass: "mdc-list mdc-list--two-line" },
                    [
                      _vm._l(_vm.rentalTransactions, function (transaction) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("property", {
                                    id: transaction.property.id,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__graphic" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "material-icons imagine-status",
                                      class: {
                                        "imagine-status--sold":
                                          !transaction.isOngoing,
                                        "imagine-status--success":
                                          transaction.isOngoing,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                lens\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__primary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(transaction.property.infos) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__secondary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Référence " +
                                          _vm._s(
                                            transaction.property.reference
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-list-item__meta",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.showRemoveTransactionConfirmation =
                                        transaction
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "material-icons" },
                                    [_vm._v("delete")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("p", [_vm._v("Aucune location.")]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeTab === "docs",
              expression: "activeTab === 'docs'",
            },
          ],
          staticClass: "imagine-container",
        },
        [
          _c(
            "form",
            {
              staticClass: "imagine-form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "imagine-form__row imagine-form__row--limited" },
                [
                  _c("imagine-file-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Pièce d'identité",
                      accept: "application/pdf",
                      name: "identityUrl",
                    },
                    model: {
                      value: _vm.identityUrl,
                      callback: function ($$v) {
                        _vm.identityUrl = $$v
                      },
                      expression: "identityUrl",
                    },
                  }),
                  _vm._v(" "),
                  _c("imagine-file-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      accept: "application/pdf",
                      label: "Justificatif de ressources",
                    },
                    model: {
                      value: _vm.earningsUrl,
                      callback: function ($$v) {
                        _vm.earningsUrl = $$v
                      },
                      expression: "earningsUrl",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Numéro de compte bancaire",
                      name: "iban",
                      help: "Format IBAN : FR7620800121000001234567823",
                      violation: _vm.errors.bankAccount,
                    },
                    model: {
                      value: _vm.bankAccount,
                      callback: function ($$v) {
                        _vm.bankAccount = $$v
                      },
                      expression: "bankAccount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__actions" },
                [
                  _c(
                    "imagine-button",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.back()
                        },
                      },
                    },
                    [_vm._v("Annuler")]
                  ),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    { attrs: { primary: "", type: "submit" } },
                    [_vm._v("Sauvegarder")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showRemoveConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: { act: _vm.remove, close: _vm.closeConfirm },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer ce contact ?"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRemoveTransactionConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: {
                act: _vm.removeOldTransaction,
                close: function ($event) {
                  _vm.showRemoveTransactionConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Etes vous sûr de vouloir supprimer cette transaction ?"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      this.contactId && _vm.showBookingModal
        ? _c(
            "imagine-modal",
            {
              staticClass: "imagine-modal--booking",
              attrs: {
                "button-disabled": !_vm.property,
                title:
                  _vm.showBookingModal === "new_build"
                    ? "Poser une option"
                    : "Ajouter bien",
                "button-label":
                  _vm.showBookingModal === "new_build"
                    ? "Poser une option"
                    : "Ajouter bien",
              },
              on: {
                act: function ($event) {
                  _vm.showBookingModal === "new_build"
                    ? _vm.addSaleOption()
                    : _vm.addTransaction(_vm.showBookingModal)
                },
                close: function ($event) {
                  _vm.showBookingModal = false
                },
              },
            },
            [
              _c("imagine-property-autocomplete", {
                attrs: {
                  "redirect-after-create": {
                    name: this.$route.name,
                    params: { id: this.contactId },
                    query: { ack: _vm.showBookingModal },
                  },
                  filter:
                    _vm.showBookingModal === "new_build"
                      ? _vm.isPropertyNewBuild
                      : _vm.isPropertyNotNewBuild,
                },
                model: {
                  value: _vm.property,
                  callback: function ($$v) {
                    _vm.property = $$v
                  },
                  expression: "property",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }