<template>
    <div :class="{'imagine-file--rounded': rounded}"
         class="imagine-file">
        <imagine-modal @close="cancelCropper"
                       @act="process"
                       button-label="Redimensionner"
                       title="Redimensionnement image"
                       v-if="showCropper">
            <cropper :src="url"
                     :stencil-props="{aspectRatio: aspectRatio}"
                     :stencilComponent="rounded ? $options.components.CircleStencil : $options.components.RectangleStencil"
                     @change="coordinates = $event.coordinates"/>
        </imagine-modal>

        <div class="imagine-file__preview"
             v-if="(previewUrl || previewIcon) && !showCropper">
            <img :src="previewUrl"
                 @click="download()"
                 v-if="previewUrl">
            <template v-else-if="previewIcon">
                <span @click="download()"
                      class="material-icons-outlined">
                    {{ previewIcon }}
                </span>
                <br><span @click="download()">{{ label }}.{{ valueExtension }}</span>
            </template>
            <imagine-icon-button @click="removeImage()"
                                 class="imagine-no-ripple"
                                 icon="delete"
                                 v-if="!disabled"/>
        </div>

        <md-field :class="{'imagine-file__input--error': errors && errors.global || violation}"
                  class="imagine-file__input">
            <label>{{ label }}</label>
            <md-file :accept="accept"
                     :disabled="disabled"
                     :required="required"
                     v-model="inner"
                     @md-change="upload"/>
            <span class="md-helper-text"
                  v-if="help">
                {{ help }} {{ violation }}
            </span>
        </md-field>
    </div>

</template>

<script>
import ImagineModal from './ImagineModal.vue';
import ImagineIconButton from './ImagineIconButton.vue';
import {CircleStencil, Cropper} from 'vue-advanced-cropper';

const M = 1024 * 1024;

const DEFAULT_MAX_SIZES = {
    'default': 10,
    'application/pdf': 20
};

export default {
    components: {Cropper, CircleStencil, ImagineModal, ImagineIconButton},
    props: ['label', 'value', 'required', 'options', 'accept', 'maxSizeM', 'rounded', 'disabled', 'violation'],
    computed: {
        aspectRatio() {
            if (!this.options || !this.options.cropRatio) {
                return 0;
            }

            const parts = this.options.cropRatio.split('/');

            if (parts.length === 2) {
                return parseInt(parts[0]) / parseInt(parts[1]);
            }

            return 1;
        },
        help() {
            let help = (this.errors && this.errors.global) ? this.errors.global + ' : ' : '';

            let type = this.humanReadableFileType;

            if (type) {
                help += 'Fichier ' + type + ', ';
            }

            help += this.humanReadableMaxSize + ' max.';

            if (this.humanReadableOptions) {
                help += ' - ' + this.humanReadableOptions;
            }

            return help;
        },
        isImage() {
            return this.accept && this.accept.startsWith('image/');
        },
        humanReadableMaxSize() {
            return this.finalMaxSizeM + ' Mo';
        },
        finalMaxSizeM() {
            if (this.maxSizeM) {
                return this.maxSizeM;
            }

            return DEFAULT_MAX_SIZES.hasOwnProperty(this.accept) ? DEFAULT_MAX_SIZES[this.accept] : DEFAULT_MAX_SIZES['default'];
        },
        humanReadableFileType() {
            if (this.accept === 'image/*') {
                return 'JPG, PNG';
            }

            if (this.accept === 'image/jpg') {
                return 'JPG';
            }

            if (this.accept === 'image/png') {
                return 'PNG';
            }

            if (this.accept === 'application/pdf') {
                return 'PDF';
            }

            return null;
        },
        humanReadableRatio() {
            if (!this.options || !this.options.cropRatio) {
                return '';
            }

            const parts = this.options.cropRatio.split('/');

            if (parts.length === 2 && parseInt(parts[0]) === parseInt(parts[1])) {
                return 'carré';
            }

            return this.options.cropRatio.replace('/', 'x');
        },
        valueExtension() {
            return this.value.substr(this.value.lastIndexOf('.') + 1);
        },
        _uploadOptions() {
            let options = {};

            if (this.options && this.options.watermark) {
                options.watermark = !this.options.cropRatio;
            }

            if (this.options && this.options.public) {
                options.public = true;
            }

            return options;
        },
        _processOptions() {
            let options = {};

            if (this.coordinates) {
                options.cropCoordinates = this.coordinates;
            }

            if (this.options && this.options.watermark) {
                options.watermark = true;
            }

            if (this.options && this.options.public) {
                options.public = true;
            }

            return options;
        },
        humanReadableOptions() {
            if (!this.isImage || !this.options) {
                return null;
            }

            let options = [];

            if (this.options.cropRatio) {
                options.push('ratio ' + this.humanReadableRatio);
            }

            if (this.options.watermark) {
                options.push('ajout filigrane');
            }

            if (this.options.public) {
                options.push('accès public');
            }

            return options.join(', ');
        },
        previewUrl() {
            if (this.url && this.isImage) {
                return this.url;
            }

            return null;
        },
        previewIcon() {
            if (this.url && this.accept === 'application/pdf') {
                return 'insert_drive_file';
            }
        }
    },
    watch: {
        value(newVal) {
            this.url = newVal;
        }
    },
    data() {
        return {
            url: this.value,
            coordinates: null,
            showCropper: false,
            inner: null,
            errors: {}
        }
    },
    methods: {
        upload(fileList) {
            let file = fileList && fileList.length > 0 ? fileList[0] : null;

            if (this._validate(file) && file) {
                //this.load(this._getFileSize(file) > 1 ? 'Transmission fichier' : '');
                this.load();
                this.$container.fileUploader.createUrl(file, this._uploadOptions)
                    .then(({url}) => {
                        this.url = url;
                        this.showCropper = this.isImage && this.options && this.options.cropRatio;
                        if (!this.showCropper) {
                            this.$emit('input', this.url);
                        }
                    })
                    .catch(response => this.errors = this.serverError(response))
                    .finally(this.unload);
            }
        },
        process() {
            this.load();
            this.$container.fileUploader.processUrl(this.url, this._processOptions)
                .then(({url}) => {
                    this.url = url;
                    this.showCropper = false;
                    this.$emit('input', this.url);
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelCropper() {
            this.showCropper = false;

        },
        download() {
            if (this.url) {
                this.externalRedirectTo(this.url);
            }
        },
        removeImage() {
            if (this.url) {
                this.url = null;
                this.inner = null;
                this.$emit('input', this.url);
            }
        },
        _validate(file) {
            this.errors = {};

            if (!file || typeof file.name === 'undefined') {
                if (this.required) {
                    this.errors.global = 'Fichier invalide';
                    this.$emit('invalid', 'Fichier invalide');
                    this.error(this.errors.global);

                    return false;
                }

                this.$emit('valid');

                return true;
            }

            if (this._getFileSize(file) > this.finalMaxSizeM) {
                this.errors.global = 'Fichier trop gros';
                this.$emit('invalid', 'Fichier trop gros');
                this.error(this.errors.global + ' (' + this.humanReadableMaxSize + ' max.)');

                return false;
            }

            this.$emit('valid');

            return true;
        },
        _getFileSize(file) {
            if (!file || typeof file.size === 'undefined') {
                return null;
            }

            return (file.size / M).toFixed(4);
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.imagine-file {

    &__preview {
        text-align: center;
        cursor: pointer;

        img {
            max-width: 350px;
        }

        .material-icons-outlined {
            font-size: 72px;
        }
    }

    &--rounded {
        .imagine-file__preview {
            img {
                border-radius: 160px;
            }
        }
    }

    &__input {
        &--error {
            &.md-field.md-theme-default .md-helper-text, &.md-field.md-theme-default .md-count, &.md-field.md-theme-default label {
                color: $imagine-error-color;
            }
        }

        input {
            width: 100%;
        }

        &.md-field .md-icon {
            margin: -4px auto;
        }
    }
}
</style>
