<template>
    <imagine-layout :inner="true"
                    :title="title"
                    class="mandate-property">
        <template v-slot:actions>
            <template v-if="mandateProperty && !mandateProperty.isArchived">
                <md-badge :class="{'mandate-property__badge--alert': hasCriticalAlerts}"
                          :md-content="nbAlerts"
                          class="mandate-property__badge"
                          v-if="nbAlerts > 0">
                    <imagine-icon-button @click="showAlertsModal = true"
                                         class="mdc-top-app-bar__action-item"
                                         icon="notifications"/>
                </md-badge>
                <imagine-icon-button :disabled="true"
                                     class="mdc-top-app-bar__action-item"
                                     icon="notifications_none"
                                     v-else/>

                <imagine-icon-button
                    :disabled="mandateProperty && !mandateProperty.possibleActions.includes('desactivate')"
                    @click="executeAction('desactivate')"
                    class="mdc-top-app-bar__action-item"
                    icon="cancel"/>
            </template>
        </template>

        <div class="imagine-secondary-zone">
            <div class="mandate-property__infos"
                 v-if="mandateProperty">
                <div class="mandate-property__infos__info">
                    <span>
                        Propriétaire{{ mandateProperty.owners.length > 1 ? 's' : '' }}
                        <template v-for="(owner, index) in mandateProperty.owners">
                            <a @click.prevent="redirectTo('contact', {id: owner.id})"
                               class="mandate-property__infos__info__link"
                               href="#">
                                {{ owner.shortName }}
                            </a>
                            <small v-if="owner.legalRepresentatives.length > 0">
                                <br/>représenté par
                                <template v-for="(represented, indexR) in owner.legalRepresentatives">
                                    <a @click.prevent="redirectTo('contact', {id: represented.id})"
                                       class="mandate-property__infos__info__link"
                                       href="#">
                                        {{ represented.shortName }}
                                    </a>
                                    <span
                                        v-if="indexR !== Object.keys(owner.legalRepresentatives).length - 1"> , </span>
                                </template>
                            </small>
                            <span v-if="index !== Object.keys(mandateProperty.owners).length - 1"> <br/> </span>
                        </template>
                        <small v-if="mandateProperty.unpaidGuaranteePercent">
                            <br/>GLI {{ mandateProperty.unpaidGuaranteePercent }}%
                        </small>
                    </span>
                </div>
                <div @click.prevent="redirectTo('property', {id: mandateProperty.property.id})"
                     class="mandate-property__infos__info">
                    <span>
                        Bien
                        <a class="mandate-property__infos__info__link"
                           href="#">
                            {{ mandateProperty.property.infos }}
                        </a>
                    </span>
                </div>
                <div
                    class="mandate-property__infos__info">
                    <template v-if="!mandateProperty"></template>
                    <template v-else-if="currentLease">
                        <template v-if="currentLease.end">
                            <span>
                                {{ _formatFromDate('Départ', currentLease.end) }} de
                                <template v-for="(tenant, index) in currentLease.tenants">
                                    <a @click.prevent="redirectTo('contact', {id: tenant.id})"
                                       class="mandate-property__infos__info__link"
                                       href="#">
                                        {{ tenant.shortName }}
                                    </a>
                                    <span v-if="index !== Object.keys(currentLease.tenants).length - 1"> , </span>
                                </template>
                            </span>
                        </template>
                        <template v-else>
                            <span>
                                {{ _formatFromDate('Loué', currentLease.start) }} à
                                <template v-for="(tenant, index) in currentLease.tenants">
                                    <a @click.prevent="redirectTo('contact', {id: tenant.id})"
                                       class="mandate-property__infos__info__link"
                                       href="#">
                                        {{ tenant.shortName }}
                                    </a>
                                    <span v-if="index !== Object.keys(currentLease.tenants).length - 1"> , </span>
                                </template>
                            </span>
                        </template>
                    </template>
                    <template v-else>Vacant</template>
                </div>
                <div class="mandate-property__infos__info"
                     v-if="currentLease">
                    <span>
                        Loyer charges comprises {{ formatMoney(currentLease.rent.fullRent) }}
                        <md-tooltip md-direction="bottom" v-if="rentDetail(currentLease.rent)">
                            {{ rentDetail(currentLease.rent) }}
                        </md-tooltip>
                    </span>
                </div>
                <div class="mandate-property__infos__info">
                    {{ ongoingLabel }}
                </div>
            </div>

            <div class="mandate-property__fields">
                <imagine-textarea class="imagine-form__row__field"
                                  label="Notes"
                                  name="comment"
                                  v-model="comment"/>

                <imagine-button :primary="true"
                                @click="saveComment()"
                                type="submit">
                    Sauvegarder
                </imagine-button>
            </div>
        </div>

        <imagine-tab-bar :tabs="tabs"
                         @tab="changeTab($event)"/>

        <div v-if="activeTabId === 'log'">
            <div class="mandate-property__actions" v-if="mandateProperty && mandateProperty.possibleActions.length > 0">
                <imagine-select label="Voir"
                                :implicit-required="true"
                                name="logFilter"
                                v-model="logFilter"
                                :choices="logFilterChoices"/>
            </div>

            <div class="mandate-property__logs">
                <div class="mandate-property__logs__actors"
                     v-if="mandateProperty">
                    <div class="mandate-property__logs__actors__actor"
                         :class="{'mandate-property__logs__actors__actor--inactive': lease.end && today.isAfter(lease.end)}"
                         v-for="(lease,index) in mandateProperty.leases"
                         v-if="showLeaseId === lease.id">
                        <h3>
                            <imagine-icon-button class="imagine-no-ripple"
                                                 icon="arrow_left"
                                                 @click="showLeaseId = mandateProperty.leases[index-1].id"
                                                 v-if="mandateProperty.leases.hasOwnProperty(index-1)"/>
                            <div class="mandate-property__logs__actors__actor__nonav"
                                 v-else></div>

                            <a href="#"
                               @click.prevent="redirectTo('lease', {mandatePropertyId: mandateProperty.id, id: lease.id})">
                                Locataire{{ lease.tenants.length > 1 ? 's' : '' }}
                                {{ lease.tenants.map(tenant => tenant.shortName).join(', ') }}
                                <small>
                                    <br/>
                                    {{ formatDate(lease.start) }}
                                    -
                                    {{ lease.end ? formatDate(lease.end) : 'aujourd\'hui' }}
                                </small>
                            </a>

                            <md-menu md-size="medium"
                                     md-align-trigger
                                     v-if="lease.possibleActions.length > 1">
                                <imagine-button :primary="true"
                                                md-menu-trigger>
                                    Nouvelle action
                                </imagine-button>
                                <md-menu-content>
                                    <md-menu-item @click="executeAction(action)"
                                                  v-for="action in lease.possibleActions"
                                                  :key="'mandate-action-' + action">
                                        {{ references.mandateActionLabel(action) }}
                                    </md-menu-item>
                                </md-menu-content>
                            </md-menu>
                            <imagine-button :primary="true"
                                            @click="executeAction(lease.possibleActions[0])"
                                            v-else>
                                {{ references.mandateActionLabel(lease.possibleActions[0]) }}
                            </imagine-button>

                            <imagine-icon-button class="imagine-no-ripple"
                                                 icon="arrow_right"
                                                 @click="showLeaseId = mandateProperty.leases[index+1].id"
                                                 v-if="mandateProperty.leases.hasOwnProperty(index+1)"/>
                            <div class="mandate-property__logs__actors__actor__nonav"
                                 v-else></div>
                        </h3>

                        <p class="mandate-property__logs__actors__actor__filter"
                           v-if="filterLogs(lease.logs).length < lease.logs.length">
                            <span v-if="filterLogs(lease.logs).length <= 0">Aucun évènement dans {{
                                    logFilterLabel
                                }}.</span>
                            <span v-else>... d'autres évènements sont filtrés.</span>
                        </p>

                        <div class="mdc-list mdc-list--non-interactive">
                            <template v-for="log in filterLogs(lease.logs)">
                                <div class="mdc-list-item mandate-property__logs__actors__actor__log">
                                    <span class="mdc-list-item__graphic">
                                        {{ formatDate(log.date) }}
                                    </span>
                                    <span class="mdc-list-item__text">
                                        {{ log.description }}
                                    </span>
                                    <span class="mdc-list-item__meta">
                                        <span v-if="log.preview">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="externalRedirectTo(log.preview.url)"
                                                                 icon="zoom_in"/>
                                            <md-tooltip md-direction="right">{{ log.preview.title }}</md-tooltip>
                                        </span>
                                        <span v-if="log.email">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="showSendMailConfirmation = log.email"
                                                                 icon="send"/>
                                            <md-tooltip md-direction="right">{{ log.email.subject }}</md-tooltip>
                                        </span>
                                        <span v-if="log.doc">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="download(log.doc)"
                                                                 icon="insert_drive_file"/>
                                            <md-tooltip md-direction="right">{{ log.doc.title }}</md-tooltip>
                                        </span>
                                    </span>
                                </div>
                                <div class="mdc-list-divider"></div>
                            </template>
                        </div>
                    </div>

                    <div class="mandate-property__logs__actors__actor">
                        <h3>
                            <div class="mandate-property__logs__actors__actor__nonav"></div>

                            <a href="#"
                               @click.prevent="redirectTo('mandate', {id: mandateProperty.mandateId})">
                                Propriétaire{{ mandateProperty.owners.length > 1 ? 's' : '' }}
                                {{ mandateProperty.owners.map(owner => owner.shortName).join(', ') }}
                            </a>

                            <md-menu md-size="medium"
                                     md-align-trigger
                                     v-if="mandateProperty.possibleActions.length > 1">
                                <imagine-button :primary="true"
                                                md-menu-trigger>
                                    Nouvelle action
                                </imagine-button>
                                <md-menu-content>
                                    <md-menu-item @click="executeAction(action)"
                                                  v-for="action in mandateProperty.possibleActions"
                                                  :key="'mandate-action-' + action">
                                        {{ references.mandateActionLabel(action) }}
                                    </md-menu-item>
                                </md-menu-content>
                            </md-menu>
                            <imagine-button :primary="true"
                                            @click="executeAction(mandateProperty.possibleActions[0])"
                                            v-else>
                                {{ references.mandateActionLabel(mandateProperty.possibleActions[0]) }}
                            </imagine-button>

                            <div class="mandate-property__logs__actors__actor__nonav"></div>
                        </h3>

                        <p class="mandate-property__logs__actors__actor__filter"
                           v-if="filterLogs(mandateProperty.logs).length < mandateProperty.logs.length">
                            <span v-if="filterLogs(mandateProperty.logs).length <= 0">Aucun évènement dans {{
                                    logFilterLabel
                                }}.</span>
                            <span v-else>... d'autres évènements sont filtrés.</span>
                        </p>

                        <div class="mdc-list mdc-list--non-interactive">
                            <template v-for="log in filterLogs(mandateProperty.logs)">
                                <div class="mdc-list-item mandate-property__logs__actors__actor__log">
                                    <span class="mdc-list-item__graphic">
                                        {{ formatDate(log.date) }}
                                    </span>
                                    <span class="mdc-list-item__text">
                                        {{ log.description }}
                                    </span>
                                    <span class="mdc-list-item__meta">
                                        <span v-if="log.preview">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="externalRedirectTo(log.preview.url)"
                                                                 icon="zoom_in"/>
                                            <md-tooltip md-direction="right">{{ log.preview.title }}</md-tooltip>
                                        </span>
                                        <span v-if="log.email">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="showSendMailConfirmation = log.email"
                                                                 icon="send"/>
                                            <md-tooltip md-direction="right">{{ log.email.subject }}</md-tooltip>
                                        </span>
                                        <span v-if="log.doc">
                                            <imagine-icon-button class="imagine-no-ripple"
                                                                 @click="download(log.doc)"
                                                                 icon="insert_drive_file"/>
                                            <md-tooltip md-direction="right">{{ log.doc.title }}</md-tooltip>
                                        </span>
                                    </span>
                                </div>
                                <div class="mdc-list-divider"></div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="mandate-property__logs__empty"
                     v-else>
                    Aucune activité.
                </div>
            </div>
        </div>

        <div class="mandate-property__accounts"
             v-if="activeTabId === 'accounts'">
            <div style="margin: .5rem auto;"
                 v-for="(lease,index) in mandateProperty.leases"
                 v-if="showLeaseId === lease.id">
                <account :account="lease.account"
                         :lettering="true"
                         :prev="mandateProperty.leases.hasOwnProperty(index-1)"
                         @prev="showLeaseId = mandateProperty.leases[index-1].id"
                         :next="mandateProperty.leases.hasOwnProperty(index+1)"
                         @next="showLeaseId = mandateProperty.leases[index+1].id"
                         :lease="lease"
                         @refresh="refresh()"/>
            </div>

            <account :account="mandateProperty.ownerAccount"
                     :mandate="{id: mandateProperty.mandateId}"
                     @refresh="refresh()"/>
        </div>

        <imagine-modal :title="alertsModalTitle"
                       @close="showAlertsModal = false"
                       v-if="mandateProperty && showAlertsModal">
            <div :class="{'mandate-property-alert--is-critical': alert.isCritical}"
                 class="mandate-property-alert"
                 v-for="alert in mandateProperty.alerts">
                <span class="material-icons" v-if="alert.isCritical">warning</span>
                <span class="material-icons" v-else>info</span>
                <p>{{ alert.label }}</p>
            </div>
        </imagine-modal>

        <imagine-modal @close="showSendMailConfirmation = false"
                       @act="sendMail(showSendMailConfirmation.sendUrl)"
                       button-label="Envoyer"
                       :title="'Confirmation email ' + showSendMailConfirmation.subject"
                       v-if="showSendMailConfirmation">
            <p>
                Etes vous sûr de vouloir envoyer un mail
                {{ showSendMailConfirmation.recipientType === 'owner' ? 'au propriétaire' : 'au(x) locataire(s)' }} ?
            </p>
        </imagine-modal>

        <desactivate :mandate-property="mandateProperty"
                     @canceled="actionCanceled()"
                     @completed="actionCompleted()"
                     v-if="showAction === 'desactivate'"/>

        <save-inventory :lease="shownLease"
                        :type="showAction === 'save_inventory_in' ? 'in' : 'out'"
                        @canceled="actionCanceled()"
                        @completed="actionCompleted()"
                        v-if="showAction === 'save_inventory_in' || showAction === 'save_inventory_out'"/>

        <lease-end-notice :lease="shownLease"
                          @canceled="actionCanceled()"
                          @completed="actionCompleted()"
                          v-if="showAction === 'lease_end_notice'"/>

        <charge-rent :lease="shownLease"
                     @canceled="actionCanceled()"
                     @completed="actionCompleted()"
                     v-if="showAction === 'charge_rent'"/>

        <send-reminder :lease="shownLease"
                       @canceled="actionCanceled()"
                       @completed="actionCompleted()"
                       v-if="showAction === 'send_reminder'"/>

        <send-receipt :lease="shownLease"
                      @canceled="actionCanceled()"
                      @completed="actionCompleted()"
                      v-if="showAction === 'send_receipt'"/>

        <save-insurance :lease="shownLease"
                        @canceled="actionCanceled()"
                        @completed="actionCompleted()"
                        v-if="showAction === 'save_insurance'"/>

        <save-insurance :mandate-property="mandateProperty"
                        @canceled="actionCanceled()"
                        @completed="actionCompleted()"
                        v-if="showAction === 'edit_mandate_property_insurance'"/>

        <custom-event :lease="shownLease"
                      @canceled="actionCanceled()"
                      @completed="actionCompleted()"
                      v-if="showAction === 'custom_lease'"/>

        <custom-event :mandate-property="mandateProperty"
                      @canceled="actionCanceled()"
                      @completed="actionCompleted()"
                      v-if="showAction === 'custom_mandate_property'"/>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import ImagineMoneyInput from '../components/ImagineMoneyInput.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import Account from './elements/mandate/Account.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import LeaseEndNotice from './elements/mandate/LeaseEndNotice';
import SaveInventory from './elements/mandate/SaveInventory.vue';
import Desactivate from './elements/mandate/Desactivate.vue';
import SendReminder from './elements/mandate/SendReminder.vue';
import ChargeRent from './elements/mandate/ChargeRent.vue';
import SaveInsurance from './elements/mandate/SaveInsurance.vue';
import CustomEvent from './elements/mandate/CustomEvent.vue';
import SendReceipt from './elements/mandate/SendReceipt.vue';
import {mapState} from 'vuex';

export default {
    components: {
        SendReceipt,
        CustomEvent,
        ChargeRent,
        SendReminder,
        SaveInsurance,
        SaveInventory,
        LeaseEndNotice,
        Desactivate,
        ImagineMoneyInput,
        ImagineContactAutocomplete,
        ImagineLayout,
        ImagineIconButton,
        ImagineModal,
        ImagineDatePicker,
        ImagineButton,
        ImagineInput,
        ImagineTextarea,
        ImagineTabBar,
        Account,
        ImagineFileInput,
        ImagineSelect
    },
    computed: {
        ...mapState(['references']),
        ...mapState('mandateProperty', {mandateProperty: state => state.current}),
        title() {
            if (!this.mandateProperty) {
                return 'Gestion bien';
            }

            return 'Gestion ' + this.mandateProperty.property.infos;
        },
        currentLease() {
            if (!this.mandateProperty) {
                return null;
            }

            return this.mandateProperty.leases.find(lease => lease.isCurrent);
        },
        logFilterChoices() {
            return [
                this.makeChoice('last_months', 'Les 3 derniers mois'),
                this.makeChoice('last_years', 'La dernière année'),
                this.makeChoice('all', 'Tout')
            ];
        },
        logFilterLabel() {
            if (!this.logFilter || this.logFilter === 'all') {
                return '';
            }

            return (this.logFilter === 'last_months') ? 'les 3 derniers mois' : 'la dernière année';
        },
        ongoingLabel() {
            if (!this.mandateProperty) {
                return '';
            }

            if (this.mandateProperty.endDate) {
                return this._formatFromDate('Archivé', this.mandateProperty.endDate);
            }

            return this._formatFromDate('En gestion', this.mandateProperty.startDate);
        },
        hasCriticalAlerts() {
            if (!this.mandateProperty) {
                return false;
            }

            return this.mandateProperty.alerts.some(alert => alert.isCritical);
        },
        nbAlerts() {
            if (!this.mandateProperty) {
                return false;
            }

            return this.mandateProperty.alerts.length;
        },
        alertsModalTitle() {
            if (!this.mandateProperty) {
                return '';
            }

            const nbAlerts = this.mandateProperty.alerts.length;

            return `${nbAlerts} alerte${nbAlerts > 1 ? 's' : ''} en cours`;
        },
        tabs() {
            return [
                {id: 'log', title: 'Activité', active: this.activeTabId === 'log'},
                {
                    id: 'accounts',
                    title: 'Comptabilité',
                    active: this.activeTabId === 'accounts',
                    disabled: !this.mandateProperty
                        || (this.mandateProperty.ownerAccount.entries.length <= 0 && !this.currentLease)
                }
            ];
        },
        today() {
            return this.$container.types.today();
        },
        shownLease() {
            if (!this.mandateProperty) {
                return null;
            }

            return this.mandateProperty.leases.find(lease => lease.id === this.showLeaseId);
        }
    },
    data() {
        return {
            mandatePropertyId: null,
            showAction: null,
            showAlertsModal: false,
            showSendMailConfirmation: false,
            comment: null,
            activeTabId: 'log',
            errors: {},
            showLeaseId: null,
            logFilter: 'last_months'
        }
    },
    created() {
        this.load();
        this.mandatePropertyId = this.$route.params ? parseInt(this.$route.params.id) : null;
        Promise.all([this._reloadMandateProperty(), this.$store.dispatch('touchReferences')])
            .then(() => {
                if (this.currentLease) {
                    this.showLeaseId = this.currentLease.id;
                } else if (this.mandateProperty.leases.length > 0) {
                    this.showLeaseId = this.mandateProperty.leases[0].id;
                }
            })
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        changeTab(newTabId) {
            this.activeTabId = newTabId;
        },
        saveComment() {
            this.load();
            this.$store.dispatch('mandateProperty/save', {
                id: this.mandatePropertyId,
                data: {comment: this.comment}
            })
                .then(mandateProperty => {
                    this._remapMandateProperty(mandateProperty);
                    this.success('Note sauvegardée.');
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        executeAction(action) {
            if (action === 'add_lease') {
                return this.redirectTo('leaseAdd', {mandatePropertyId: this.mandatePropertyId});
            }

            if (action === 'charge_guarantee') {
                this.load();
                this.$store.dispatch('accountEntry/save', {
                    lease: this.currentLease.id,
                    type: 'charge_guarantee'
                })
                    .then(() => this.actionCompleted())
                    .catch(this.serverError)
                    .finally(this.unload);

                return;
            }

            if (action === 'charge_rental_fees') {
                this.load();
                this.$store.dispatch('accountEntry/save', {
                    lease: this.currentLease.id,
                    type: 'charge_rental_fees'
                })
                    .then(() => this.actionCompleted())
                    .catch(this.serverError)
                    .finally(this.unload);

                return;
            }

            if (action === 'send_email_lease') {
                location.href = `mailto:${this._emails(this.currentLease.tenants).join(',')}?cc=${this._emails(this.currentLease.protectors).join(',')}`;
                return;
            }

            if (action === 'send_email_mandate') {
                location.href = `mailto:${this._emails(this.mandateProperty.ownersEmails).join(',')}`;
                return;
            }

            this.showAction = action;
        },
        formatDate(date) {
            return this.$container.types.formatDate(date);
        },
        actionCompleted() {
            this.load();
            this._reloadMandateProperty()
                .then(() => this.showAction = null)
                .catch(this.serverError)
                .finally(this.unload);
        },
        actionCanceled() {
            this.showAction = null;
        },
        download(doc) {
            if (doc.url) {
                this.externalRedirectTo(doc.url);
            }
        },
        filterLogs(logs) {
            if (this.logFilter === 'last_years') {
                return logs.filter(log => log.date > this.$container.types.daysAgo(365));
            }

            if (this.logFilter === 'last_months') {
                return logs.filter(log => log.date > this.$container.types.daysAgo(90));
            }

            return logs;
        },
        sendMail(url) {
            this.load();
            this.$store.dispatch('mandateProperty/sendMail', url)
                .then(() => {
                    this.showSendMailConfirmation = false;
                    this.success('Email envoyé.');
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        refresh() {
            this.load();
            this._reloadMandateProperty()
                .catch(this.serverError)
                .finally(this.unload);
        },
        _emails(actors) {
            return actors.filter(actor => actor && actor.email).map(actor => actor.email);
        },
        _reloadMandateProperty() {
            return this.$store.dispatch('mandateProperty/one', this.mandatePropertyId)
                .then(this._remapMandateProperty);
        },
        _formatFromDate(prefix, date) {
            return prefix + (this.$container.types.today().isBefore(date) ? ' au ' : ' depuis le ') + this.formatDate(date);
        },
        _remapMandateProperty(mandateProperty) {
            this.comment = mandateProperty.comment;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate-property {
    &__badge {
        color: #000000;

        &.mandate-property__badge--alert {
            .md-badge.md-theme-default {
                background-color: $imagine-orange;
            }
        }
    }

    .mandate-property-alert {
        display: flex;
        align-items: center;
        color: $mdc-theme-primary;

        .material-icons {
            margin-right: .5rem;
        }

        &--is-critical {
            color: $imagine-orange;
        }
    }

    &__infos {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__info {
            text-align: center;
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 16px;
            line-height: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            /*height: 56px;*/

            a.mandate-property__infos__info__link,
            a.mandate-property__infos__info__link:hover {
                color: #ffffff;
                text-decoration: underline;
            }
        }
    }

    &__fields {
        display: flex;
        align-items: center;
        padding: .5rem;
        flex-direction: column;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            flex-direction: row;
        }

        .md-field {
            margin: 0 .5rem .5rem;
        }

        .imagine-button {
            max-width: 150px;
        }
    }

    &__actions {
        padding: 1rem;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .imagine-select {
            width: 180px;
            margin: 0;
        }
    }

    &__accounts {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        padding: .5rem;
        overflow: auto;
        flex-direction: column;

        @media (min-width: 1440px) {
            flex-direction: row;
        }
    }

    &__logs {
        &__actors {
            display: flex;
            flex-direction: column;

            @media (min-width: 1440px) {
                flex-direction: row;
            }

            &__actor {
                &--inactive {
                    opacity: .85;
                }

                padding: 0 1rem;
                flex: 1;

                h3 {
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: normal;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 48px;
                }

                &__nonav {
                    width: 48px;
                }

                &__filter {
                    padding: .5rem;
                    text-align: center;
                    font-size: 1.1rem;
                }

                &__log {
                    .mdc-list-item__graphic {
                        width: 72px;
                    }

                    .mdc-list-item__meta {
                        display: flex;
                    }
                }
            }
        }

        &__empty {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }
}
</style>
