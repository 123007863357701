<template>
    <imagine-layout class="employees" title="Employés">
        <template v-slot:actions>
            <imagine-icon-button @click="redirectTo('employeeAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <div class="employees__list mdc-list mdc-list--two-line">
            <template v-for="employee in displayedEmployees">
                <div :class="{'mdc-list-item--disabled': !employee.isActive}"
                     class="mdc-list-item employees__list__employee"
                     @click="redirectTo('employee', {id: employee.id})">
                    <span class="mdc-list-item__graphic imagine-rounded-shape"
                          v-if="employee.image">
                        <img :src="employee.image"/>
                    </span>
                    <span class="mdc-list-item__graphic imagine-rounded-shape material-icons"
                          v-else>person</span>
                    <span class="mdc-list-item__text">
                        <span class="mdc-list-item__primary-text">{{ employee.firstname }} {{
                                employee.lastname
                            }}</span>
                        <span class="mdc-list-item__secondary-text employees__employee__position">{{
                                employee.position
                            }}</span>
                    </span>
                    <span class="mdc-list-item__meta" v-if="!employee.isActive">
                        <span class="material-icons">lock</span>
                    </span>
                    <span class="mdc-list-item__meta" v-else-if="employee.mainRoleLabel">
                        {{ employee.mainRoleLabel }}<small v-if="employee.service">, {{ serviceLabel(employee.service) }}</small>
                    </span>
                </div>
                <div class="mdc-list-divider"></div>
            </template>
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import {mapState} from 'vuex';
import sortBy from 'lodash-es/sortBy';

export default {
    components: {ImagineLayout, ImagineIconButton},
    computed: {
        ...mapState('employee', {employees: state => state.all}),
        ...mapState(['references']),
        displayedEmployees() {
            return sortBy(this.employees.filter(employee => !employee.isDeleted), 'firstname');
        }
    },
    created() {
        this.load();
        this.$store.dispatch('employee/reload')
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        serviceLabel(service) {
            if (!service) {
                return null;
            }

            return this.references.serviceLabel(service);
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import "~@material/list/mdc-list";

.employees {
    &__list {
        padding: 0;

        &__employee {
            &.mdc-list-item--disabled {
                cursor: pointer;

                .mdc-list-item__meta {
                    span {
                        color: $imagine-error-color;
                    }
                }
            }
        }
    }
}
</style>
