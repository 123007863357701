class SalePaymentsRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all(params) {
        return this._http.get('/api/sale-payment', params);
    }

    one(saleId) {
        return this._http.get('/api/sale/' + saleId + '/payment')
            .then(salePayments => this._makeSalePayments(salePayments));
    }

    savePayment(payment) {
        return this._http.post('/api/sale/' + payment.saleId + '/payment', payment);
    }

    toggleRegrouped(salePaymentId) {
        return this._http.post('/api/sale/' + salePaymentId + '/regroup');
    }

    _makeSalePayments(salePayments) {
        salePayments.saleDate = this._types.stringToDate(salePayments.saleDate);
        salePayments.price = this._types.stringToMoney(salePayments.price);
        salePayments.parkingPrice = this._types.stringToMoney(salePayments.parkingPrice);
        salePayments.discount = this._types.stringToMoney(salePayments.discount);
        salePayments.lawyerFees = this._types.stringToMoney(salePayments.lawyerFees);
        salePayments.expected = this._types.stringToMoney(salePayments.expected);
        salePayments.paid = this._types.stringToMoney(salePayments.paid);
        salePayments.due = this._types.stringToMoney(salePayments.due);
        salePayments.milestones = salePayments.milestones.map(milestone => this._makeSaleMilestone(milestone));

        return salePayments;
    }

    _makeSaleMilestone(milestone) {
        milestone.expectedDate = this._types.stringToDate(milestone.expectedDate);
        milestone.expectedAmount = this._types.stringToMoney(milestone.expectedAmount);
        milestone.paid = this._types.stringToDate(milestone.paid);
        milestone.due = this._types.stringToMoney(milestone.due);
        milestone.payments = milestone.payments.map(payment => this._makeSaleMilestonePayment(payment))

        return milestone;
    }

    _makeSaleMilestonePayment(payment) {
        payment.amount = this._types.stringToMoney(payment.amount);
        payment.date = this._types.stringToDate(payment.date);

        return payment;
    }
}

export default SalePaymentsRepository;
