<template>
    <imagine-layout :title="publishingTitle" class="publishing">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :active="mode === 'search'"
                                 @click="changeMode('search')"
                                 icon="search"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('list')"
                                 :active="mode === 'list'"
                                 icon="filter_list"/>
            <div class="publishing__actions-sep"></div>
            <imagine-icon-button :active="tab.id === activeTabId"
                                 :disabled="tab.disabled"
                                 :icon="tab.icon"
                                 :svg="tab.svg"
                                 :key="'publishing-to-' + tab.id"
                                 @click="changeTab(tab.id)"
                                 class="mdc-top-app-bar__action-item"
                                 v-for="tab in tabs"/>
        </template>

        <properties-list :mode="mode">
            <template v-slot:header>
                <p class="publishing__disclaimer"
                   v-if="publishingDisclaimer">
                    {{ publishingDisclaimer }}
                </p>

                <div class="publishing__header">
                    <div class="publishing__header__select">
                        <md-menu md-align-trigger md-size="medium">
                            <md-button md-menu-trigger>
                                <div class="publishing__header__select__menu">
                                    <imagine-checkbox :disabled="true"
                                                      :indeterminate-support="1"
                                                      :indeterminate="nbChecked > 0 && !allSelected"
                                                      :value="allSelected"/>
                                    <div>
                                        <svg height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10l5 5 5-5z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </div>
                                </div>
                            </md-button>

                            <md-menu-content>
                                <md-menu-item @click="checkAll">Tous</md-menu-item>
                                <md-menu-item @click="checkFiltered"
                                              v-if="isFiltering">
                                    Résultats de recherche
                                </md-menu-item>
                                <md-menu-item @click="checkPublished">Ceux déjà publiés</md-menu-item>
                                <md-menu-item @click="checkNone">Aucun</md-menu-item>
                            </md-menu-content>
                        </md-menu>

                        <div class="publishing__header__select__count">
                            <span v-if="nbChecked <= 0">Aucun bien sélectionné</span>
                            <span v-else-if="nbChecked === 1">1 bien sélectionné</span>
                            <span v-else>{{ nbChecked }} biens sélectionnés</span>
                        </div>
                    </div>

                    <div>
                        <imagine-button :primary="true"
                                        @click="showConfirmationModal = true">
                            Publier
                        </imagine-button>
                    </div>
                </div>
            </template>

            <template v-slot:default="{property}">
                <publishing-property :property="property"
                                     :publishing="activeTabId"
                                     :exportable-desc="publishingDisclaimer"
                                     v-if="isCheckedInitOnTab"/>
            </template>
        </properties-list>

        <imagine-modal :buttonLabel="confirmationButtonLabel"
                       @act="publish"
                       @close="showPreview = false;showConfirmationModal = false"
                       v-if="showConfirmationModal">
            <p>{{ confirmationText }}</p>

            <p v-if="nbChecked">
                {{ nbChecked }} biens sélectionnés
                <template v-if="nbChecked < maxPreviewable">
                    -
                    <a @click.prevent="togglePreviews()"
                       href="#">
                        <span v-if="showPreview">Masquer</span>
                        <span v-else>Voir</span>
                    </a>
                </template>
            </p>

            <transition name="slideY">
                <ul v-if="showPreview && checkedProperties.length">
                    <li v-for="checkedProperty in checkedProperties">
                        {{ checkedProperty }}
                    </li>
                </ul>
            </transition>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import PropertiesList from './elements/PropertiesList.vue';
import ImagineCheckbox from '../components/ImagineCheckbox.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import PublishingProperty from './elements/PublishingProperty.vue';
import {mapState} from 'vuex';

const MAX_PREVIEWABLE = 300;

export default {
    components: {
        PublishingProperty,
        ImagineButton,
        ImagineCheckbox,
        ImagineLayout,
        PropertiesList,
        ImagineModal,
        ImagineIconButton
    },
    computed: {
        ...mapState('property', {
            nbTotal: state => state.nbTotal,
            nbFiltered: state => state.nbFiltered,
            activeTabId: state => state.publishingTab,
            checked: state => state.checked,
            published: state => state.published,
            mode: state => state.mode
        }),
        ...mapState(['references']),
        checked: {
            get() {
                return this.$store.state.property.checked;
            },
            set(value) {
                this.$store.commit('property/setChecked', value);
            }
        },
        publishingTitle() {
            if (this._activeTab) {
                return this._activeTab.title;
            }

            return null;
        },
        publishingDisclaimer() {
            if (this._activeTab) {
                return this._activeTab.disclaimer;
            }

            return null;
        },
        confirmationButtonLabel() {
            if (this.nbChecked <= 0) {
                return `Tout débublier de "${this.publishingTitle}"`;
            }

            return `Publier sur "${this.publishingTitle}"`;
        },
        confirmationText() {
            if (this.nbChecked <= 0) {
                return `Etes vous sûr de vouloir tout dépublier de "${this.publishingTitle}" ?`;
            }

            return `Etes vous sûr de vouloir publier
                        le${this.nbChecked > 1 ? 's ' + this.nbChecked : ''}
                        bien${this.nbChecked > 1 ? 's' : ''}
                        sélectionné${this.nbChecked > 1 ? 's' : ''}
                        sur "${this.publishingTitle}" ?`;
        },
        isCheckedInitOnTab() {
            return this.checked.hasOwnProperty(this.activeTabId);
        },
        checkedOnTab() {
            return this.isCheckedInitOnTab ? this.checked[this.activeTabId] : [];
        },
        isPublishedInitOnTab() {
            return this.published.hasOwnProperty(this.activeTabId);
        },
        publishedOnTab() {
            return this.isPublishedInitOnTab ? this.published[this.activeTabId] : [];
        },
        nbChecked() {
            return this.checkedOnTab.length;
        },
        nbPublished() {
            return this.publishedOnTab.length;
        },
        allSelected() {
            if (!this.nbTotal) {
                return false;
            }

            return this.nbChecked >= this.nbTotal;
        },
        tabs() {
            if (!this.references || !this.references.publishings()) {
                return [];
            }

            const publishings = this.references.publishings();
            const icons = {'website': 'public'};
            const svgs = {'immopf': 'immopf', 'showcase': 'vmlive'};

            let tabs = [];

            Object.keys(publishings).forEach(id => {
                let icon = icons.hasOwnProperty(id) ? icons[id] : null;
                if (!icon && !svgs.hasOwnProperty(id)) {
                    icon = 'public';
                }

                if (id !== 'showcase') {
                    tabs.push(
                        {
                            id: id,
                            title: publishings[id]['name'],
                            disclaimer: publishings[id]['disclaimer'],
                            icon: icon,
                            svg: svgs.hasOwnProperty(id) ? svgs[id] : null,
                            disabled: false
                        }
                    );
                }
            });

            return tabs;
        },
        isFiltering() {
            return this.nbFiltered > 0 && this.nbFiltered < this.nbTotal;
        },
        _activeTab() {
            if (this.tabs) {
                return this.tabs.find(tab => tab.id === this.activeTabId);
            }

            return null;
        }
    },
    data() {
        return {
            checkedProperties: [],
            showConfirmationModal: false,
            showPreview: false,
            maxPreviewable: MAX_PREVIEWABLE
        }
    },
    created() {
        this.load('Chargement des biens');
        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        publish() {
            this.showConfirmationModal = false;
            this.load(`Publication en cours sur "${this.publishingTitle}"`);
            this.$store.dispatch('property/publish', {to: this.activeTabId, ids: this.checkedOnTab})
                .then(() => this.success('Publication OK.'))
                .catch(this.serverError)
                .finally(this.unload);
        },
        checkAll() {
            this.$store.dispatch('property/checkAll');
        },
        checkFiltered() {
            this.$store.dispatch('property/checkFiltered');
        },
        checkPublished() {
            this.$store.dispatch('property/checkPublished');
        },
        checkNone() {
            this.$store.dispatch('property/checkNone');
        },
        changeMode(newMode) {
            this.$store.commit('property/changeMode', newMode);
        },
        changeTab(newTab) {
            this.$store.commit('property/changePublishingTab', newTab);

            this.load('Chargement des biens');
            Promise.all(this._getDependencies())
                .catch(this.serverError)
                .finally(this.unload);
        },
        togglePreviews() {
            if (this.showPreview) {
                this.showPreview = !this.showPreview;

                return;
            }

            this.load();
            this.$store.dispatch('property/checkedProperties')
                .then(properties => {
                    this.checkedProperties = properties;
                    this.showPreview = true;
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('property/touch'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];

            if (!this.isPublishedInitOnTab) {
                dependencies.push(this.$store.dispatch('property/reloadPublished'));
            }

            return dependencies;
        }
    }
}
</script>

<style lang="scss">
.publishing {
    .imagine-layout__header {
        .publishing__actions-sep {
            border-right: 1px solid white;
            height: 24px;
            margin: 0 .5rem;
        }

        .mdc-icon-button:disabled {
          opacity: .45;
        }
    }

    &__disclaimer {
        padding: 1rem;
        font-style: italic;
        margin: 0;
    }

    &__header {
        padding: .5rem 16px .5rem 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__select {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .md-menu {
                margin-left: -10px;
            }

            &__menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__count {
                padding: 0 .5rem;
            }
        }

    }
}
</style>
