<template>
    <imagine-layout class="sales" title="Statistiques ventes">
        <template v-slot:actions>
            <imagine-icon-button @click="redirectTo('sales')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="filter_list"/>
            <imagine-icon-button active
                                 class="mdc-top-app-bar__action-item"
                                 icon="insights"/>
        </template>

        <div class="imagine-secondary-zone sales__filters">
            <div class="sales__filters__filter">
                <imagine-select :choices="references.saleMetricChoices()"
                                label="Métrique"
                                name="metric"
                                v-model="metric"/>
            </div>

            <div class="sales__filters__filter">
                <imagine-select :choices="references.saleDimensionChoices()"
                                implicit-required
                                multiple
                                no-select-all
                                label="Dimension(s)"
                                name="dimensions"
                                v-model="dimensions"/>
            </div>

            <div class="sales__filters__filter">
                <imagine-select :choices="references.groupedBlockChoices(blocks, ['ongoing', 'completed'])"
                                label="Filtre résidence"
                                name="block"
                                v-model="block"/>
            </div>
        </div>

        <div class="imagine-table-container"
             v-if="headers.length && displayedRows.length">
            <table class="imagine-table">
                <thead>
                <tr class="imagine-table__row">
                    <th class="imagine-table__row__cell imagine-table__row__cell--header"
                        v-for="header in headers">
                        {{ header }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr class="imagine-table__row"
                    v-for="row in displayedRows">
                    <td class="imagine-table__row__cell"
                        :class="{'imagine-table__row__cell--header imagine-table__row__cell--text': cell.format === 'string', 'imagine-table__row__cell--stat': cell.format !== 'string'}"
                        v-for="cell in row">
                        {{ formatCell(cell) }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="sales__stats__none"
             v-else-if="!displayedRows.length && rows.length">
            Aucune statistique disponible pour cette résidence.
        </div>
        <div class="sales__stats__none"
             v-else>
            Choisir une métrique pour afficher des statistiques.
        </div>
    </imagine-layout>
</template>

<script>
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import {mapState} from 'vuex';

export default {
    components: {ImagineAlert, ImagineLayout, ImagineSelect, ImagineIconButton},
    computed: {
        ...mapState(['references']),
        ...mapState('block', {blocks: state => state.all}),
        block: {
            get() {
                return this.$store.state.sale.block;
            },
            set(value) {
                this.$store.commit('sale/setBlock', value);
            }
        },
        displayedRows() {
            return this.rows.filter(row => row.filter(cell => cell.format !== 'string').some(cell => cell.value > 0));
        }
    },
    watch: {
        block() {
            this._reload();
        },
        dimensions() {
            this._reload();
        },
        metric() {
            this._reload();
        }
    },
    data() {
        return {
            dimensions: null,
            metric: null,
            headers: [],
            rows: []
        }
    },
    created() {
        this.load('Chargement des statistiques');

        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        formatCell(cell) {
            if (cell.format === 'string') {
                return cell.value;
            }

            return this.formatNumber(cell.value);
        },
        _reload() {
            if (!this.metric) {
                this.headers = [];
                this.rows = [];

                return;
            }

            this.load('Chargement des statistiques');
            this.$store.dispatch('sale/stats', {block: this.block, metric: this.metric, dimensions: this.dimensions})
                .then(stats => {
                    this.headers = stats.headers;
                    this.rows = stats.rows;
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('sale/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];

            dependencies.push(
                this.$store.dispatch('user/touch')
                    .then(() => {
                        if (this.canFilterSalesRep()) {
                            this.$store.dispatch('employee/touch');
                        }
                    })
            );

            return dependencies;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.sales {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }

            &--statuses {
                .md-menu.md-select {
                    flex: unset;
                    width: 90%;

                    .md-input {
                        font-size: .9rem;
                    }
                }
            }
        }
    }

    &__stats {
        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }
}
</style>
