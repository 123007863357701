class EventRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all(since) {
        const qp = since ? {since: this._types.queryDate(since)} : {};

        return this._http.get('/api/event', qp)
            .then(events => events.map(event => this._makeEvent(event)));
    }

    _makeEvent(event) {
        event.date = this._types.stringToDate(event.date);

        return event;
    }
}

export default EventRepository;
