import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './pages/Login.vue';
import Contact from './pages/Contact.vue';
import Employee from './pages/Employee.vue';
import Error from './pages/Error.vue';
import Properties from './pages/Properties.vue';
import Home from './pages/Home.vue';
import Contacts from './pages/Contacts.vue';
import Employees from './pages/Employees.vue';
import Activities from './pages/Activities.vue';
import Property from './pages/Property.vue';
import Block from './pages/Block.vue';
import Blocks from './pages/Blocks.vue';
import Publishing from './pages/Publishing.vue';
import Mailing from './pages/Mailing.vue';
import Mailings from './pages/Mailings.vue';
import Mandates from './pages/Mandates.vue';
import Mandate from './pages/Mandate.vue';
import MandatesProperties from './pages/MandatesProperties.vue';
import MandateProperty from './pages/MandateProperty.vue';
import Lease from './pages/Lease.vue';
import Contractors from './pages/Contractors.vue';
import Contractor from './pages/Contractor.vue';
import MandatesReports from './pages/MandatesReports.vue';
import Sales from './pages/Sales.vue';
import Sale from './pages/Sale.vue';
import SalesPayments from './pages/SalesPayments.vue';
import SalePayments from './pages/SalePayments.vue';
import SalesStats from './pages/SalesStats.vue';

Vue.use(VueRouter);

let router = new VueRouter({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {public: true}
        },
        {
            path: '/activities',
            name: 'activities',
            component: Activities,
            meta: {menu: 'activities', asyncScroll: true}
        },
        {
            path: '/contacts/edit/:id',
            name: 'contact',
            component: Contact,
            meta: {menu: 'contacts'}
        },
        {
            path: '/contacts/add',
            name: 'contactAdd',
            component: Contact,
            meta: {menu: 'contacts'}
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: Contacts,
            meta: {menu: 'contacts', asyncScroll: true}
        },
        {
            path: '/contractors/edit/:id',
            name: 'contractor',
            component: Contractor,
            meta: {menu: 'contractors'}
        },
        {
            path: '/contractors/add',
            name: 'contractorAdd',
            component: Contractor,
            meta: {menu: 'contractors'}
        },
        {
            path: '/contractors',
            name: 'contractors',
            component: Contractors,
            meta: {menu: 'contractors', asyncScroll: true}
        },
        {
            path: '/employees/edit/:id',
            name: 'employee',
            component: Employee,
            meta: {menu: 'employees'}
        },
        {
            path: '/employees/add',
            name: 'employeeAdd',
            component: Employee,
            meta: {menu: 'employees'}
        },
        {
            path: '/employees',
            name: 'employees',
            component: Employees,
            meta: {menu: 'employees', asyncScroll: true}
        },
        {
            path: '/properties/edit/:id',
            name: 'property',
            component: Property,
            meta: {menu: 'properties'}
        },
        {
            path: '/properties/add',
            name: 'propertyAdd',
            component: Property,
            meta: {menu: 'properties'}
        },
        {
            path: '/properties',
            name: 'properties',
            component: Properties,
            meta: {menu: 'properties', asyncScroll: true}
        },
        {
            path: '/blocks/edit/:id',
            name: 'block',
            component: Block,
            meta: {menu: 'blocks'}
        },
        {
            path: '/blocks/add',
            name: 'blockAdd',
            component: Block,
            meta: {menu: 'blocks'}
        },
        {
            path: '/blocks',
            name: 'blocks',
            component: Blocks,
            meta: {menu: 'blocks', asyncScroll: true}
        },
        {
            path: '/publishing',
            name: 'publishing',
            component: Publishing,
            meta: {menu: 'publishing', asyncScroll: true}
        },
        {
            path: '/mailings',
            name: 'mailings',
            component: Mailings,
            meta: {menu: 'mailings', asyncScroll: true}
        },
        {
            path: '/mailings/add',
            name: 'mailing',
            component: Mailing,
            meta: {menu: 'mailings', asyncScroll: true}
        },
        {
            path: '/mandates',
            name: 'mandates',
            component: Mandates,
            meta: {menu: 'mandates', asyncScroll: true}
        },
        {
            path: '/mandates/add',
            name: 'mandateAdd',
            component: Mandate,
            meta: {menu: 'mandates'}
        },
        {
            path: '/mandates/edit/:id',
            name: 'mandate',
            component: Mandate,
            meta: {menu: 'mandates'}
        },
        {
            path: '/mandates/properties',
            name: 'mandatesProperties',
            component: MandatesProperties,
            meta: {menu: 'mandatesProperties', asyncScroll: true}
        },
        {
            path: '/mandates/property/:id',
            name: 'mandateProperty',
            component: MandateProperty,
            meta: {menu: 'mandatesProperties'}
        },
        {
            path: '/mandates/property/:mandatePropertyId/lease/:id',
            name: 'lease',
            component: Lease,
            meta: {menu: 'mandatesProperties'}
        },
        {
            path: '/mandates/property/:mandatePropertyId/lease',
            name: 'leaseAdd',
            component: Lease,
            meta: {menu: 'mandatesProperties'}
        },
        {
            path: '/mandates/reports',
            name: 'mandatesReports',
            component: MandatesReports,
            meta: {menu: 'mandatesReports'}
        },
        {
            path: '/sales',
            name: 'sales',
            component: Sales,
            meta: {menu: 'sales'}
        },
        {
            path: '/sales-stats',
            name: 'salesStats',
            component: SalesStats,
            meta: {menu: 'sales'}
        },
        {
            path: '/sales-payments',
            name: 'salesPayments',
            component: SalesPayments,
            meta: {menu: 'salesPayments'}
        },
        {
            path: '/sales-payments/:id',
            name: 'salePayments',
            component: SalePayments,
            meta: {menu: 'salesPayments'}
        },
        {
            path: '/sales/:id',
            name: 'sale',
            component: Sale,
            meta: {menu: 'sales'}
        },
        {
            path: '*',
            name: 'error',
            component: Error,
            meta: {public: true}
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta && to.meta.asyncScroll) {
            // return new Promise(resolve => setTimeout(() => resolve(savedPosition), 200));
            return new Promise(resolve => this.app.$root.$once('loaded', () => resolve(savedPosition)));
        }

        return savedPosition || {x: 0, y: 0};
    }
});

export default router;
