var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contacts__filters__filters__group" },
    [
      _c(
        "div",
        {
          staticClass: "contacts__filters__filters__group__title",
          on: { click: _vm.toggle },
        },
        [
          _c("h6", { staticClass: "mdc-typography--headline6" }, [
            _c("span", [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _vm.used
              ? _c("span", { staticClass: "material-icons" }, [_vm._v("done")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "material-icons" }, [
            _vm._v(
              _vm._s(_vm.display ? "keyboard_arrow_up" : "keyboard_arrow_down")
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.display,
                expression: "display",
              },
            ],
            staticClass: "contacts__filters__filters__group__body",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }