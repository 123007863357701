<template>
    <imagine-layout :title="$mq === 'phone' ? 'Mandats' : 'Registre mandats'" class="mandates">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :disabled="!firstNonEmptyPeriodInfo"
                                 @click="showPeriodsModal = true"
                                 icon="file_copy"/>
            <imagine-icon-button @click="redirectTo('mandateAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
            <mandate-external-services/>
        </template>

        <div class="imagine-secondary-zone mandates__filters">
            <div class="mandates__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Nom propriétaire"
                               name="actor"
                               v-model="actorFilter"/>
            </div>
            <div class="mandates__filters__filter">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Résidence/Lotissement"
                               name="property"
                               v-model="propertyFilter"/>
            </div>
            <div class="mandates__filters__filter mandates__filters__filter--bordered">
                <span>Voir les archives</span>
                <imagine-switch v-model="withArchivesFilter"/>
            </div>
        </div>

        <div :class="{'mandates__count--filtered': nbFiltered < nbTotal}"
             class="mandates__count">
            <div class="mandates__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">{{ formatNumber(nbFiltered) }} / </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                mandat{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbTotal > 0 && nbFiltered <= 0">
                Aucun mandat trouvé.
            </div>

            <div class="mandates__count__sort"
                 v-if="mandates.length > 0">
                Triés par numéro croissant
            </div>
        </div>

        <div class="imagine-table-container"
             v-if="filtered.length > 0">
            <table class="imagine-table">
                <thead>
                <tr class="imagine-table__row">
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--number">
                        N°
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                        Date
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header">Propriétaire(s)</th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                        Bien(s)
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                        Date fin
                    </th>
                    <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                        Observations
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'imagine-table__row--disabled': mandate.endDate}"
                    @click="redirectTo('mandate', {id: mandate.id})"
                    class="imagine-table__row mandates__mandate"
                    v-for="mandate in filtered">
                    <td class="imagine-table__row__cell imagine-table__row__cell--number">
                        #{{ mandate.registryNumber }}
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--date">
                        {{ formatDate(mandate.startDate) }}
                    </td>
                    <td class="imagine-table__row__cell">
                        {{ mandate.owners.map(owner => owner.shortName).join(', ') }}
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--text">
                        <span v-if="mandate.properties.length === 1">
                            {{ mandate.properties[0].infos }}
                        </span>
                        <ul class="mandates__mandate__properties"
                            v-else-if="mandate.properties.length > 1">
                            <li v-for="property in mandate.properties">
                                {{ property.infos }}
                            </li>
                        </ul>
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--date">
                        <span v-if="mandate.endDate">{{ formatDate(mandate.endDate) }}</span>
                        <span v-else>({{ formatDate(mandate.legalEndDate) }})</span>
                    </td>
                    <td class="imagine-table__row__cell imagine-table__row__cell--text">{{ mandate.observation }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mandates__none"
             v-else>
            Aucun mandat.
        </div>

        <imagine-modal @close="showPeriodsModal = false"
                       v-if="showPeriodsModal">
            <p>
                <imagine-select-period :periods="periodInfos.map(periodInfo => periodInfo.period)"
                                       class="imagine-form__row__field"
                                       v-model="period"/>
            </p>

            <p style="text-align: center;">
                <imagine-button @click.prevent="printSummaries()"
                                v-if="nbSummaries > 0">
                    Editer {{ nbSummaries }} relevé{{ nbSummaries > 1 ? 's' : '' }}
                </imagine-button>
                <imagine-button :disabled="true"
                                v-else>
                    Editer relevés
                </imagine-button>
            </p>
            <p style="text-align: center;">
                <imagine-button @click.prevent="showSendFeesEntriesConfirmation = true"
                                v-if="mandateIdsForFeesEntries.length > 0">
                    Lancer {{ mandateIdsForFeesEntries.length }}
                    écriture{{ mandateIdsForFeesEntries.length > 1 ? 's' : '' }} honoraire
                </imagine-button>
                <imagine-button :disabled="true"
                                v-else>
                    Lancer les écritures honoraires
                </imagine-button>
            </p>
            <p style="text-align: center;">
                <imagine-button :disabled="nbSummaries <= 0"
                                @click.prevent="downloadBankFile(1)">
                    Générer fichier bancaire EXCEL
                </imagine-button>
                <imagine-button @click.prevent="downloadBankFile(0)"
                                :disabled="nbSummaries <= 0">
                    Générer fichier bancaire SOCREDO
                </imagine-button>
            </p>
            <p style="text-align: center;">
                <imagine-button @click.prevent="showSendPaymentEntriesConfirmation = true"
                                v-if="mandateIdsForPaymentEntries.length > 0">
                    Lancer {{ mandateIdsForPaymentEntries.length }}
                    écriture{{ mandateIdsForPaymentEntries.length > 1 ? 's' : '' }} versement
                </imagine-button>
                <imagine-button :disabled="true"
                                v-else>
                    Lancer les écritures versement
                </imagine-button>
            </p>
            <p style="text-align: center;">
                <imagine-button @click.prevent="showSendSummariesConfirmation = true"
                                v-if="mandateIdsForSendSummaries.length > 0">
                    Envoyer {{ mandateIdsForSendSummaries.length }}
                    relevé{{ mandateIdsForSendSummaries.length > 1 ? 's' : '' }}
                </imagine-button>
                <imagine-button :disabled="true"
                                v-else>
                    Envoyer les relevés
                </imagine-button>
            </p>
        </imagine-modal>

        <imagine-modal @act="sendFeesEntries()"
                       @close="showSendFeesEntriesConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSendFeesEntriesConfirmation">
            <p>
                Etes vous sûr de vouloir réaliser toutes les écritures comptables d'honoraires ?
            </p>
        </imagine-modal>

        <imagine-modal @act="sendPaymentEntries()"
                       @close="showSendPaymentEntriesConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSendPaymentEntriesConfirmation">
            <p>
                Etes vous sûr de vouloir réaliser toutes les écritures comptables de versement propriétaire ?
            </p>
        </imagine-modal>

        <imagine-modal @act="sendSummaries()"
                       @close="showSendSummariesConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSendSummariesConfirmation">
            <p>
                Etes vous sûr de vouloir envoyer tous les relevés ?
            </p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineSelectPeriod from '../components/ImagineSelectPeriod.vue';
import ImagineButton from '../components/ImagineButton.vue';
import MandateExternalServices from './elements/mandate/MandateExternalServices.vue';
import {mapState} from 'vuex';

const _queryMatchContact = (contact, query) => {
    if (contact) {
        return (contact.lastname && contact.lastname.toUpperCase().search(query.toUpperCase()) !== -1)
            || (contact.company && contact.company.toUpperCase().search(query.toUpperCase()) !== -1);
    }

    return false;
};

const _queryMatchProperty = (property, query) => {
    const upperQuery = query.toUpperCase();

    return (property.reference && property.reference.startsWith(upperQuery))
        || (property.infos && property.infos.toUpperCase().includes(upperQuery));
};

export default {
    components: {
        MandateExternalServices,
        ImagineButton,
        ImagineSelectPeriod,
        ImagineLayout,
        ImagineIconButton,
        ImagineInput,
        ImagineSwitch,
        ImagineModal
    },
    computed: {
        ...mapState('mandate', {mandates: state => state.all, periodInfos: state => state.periodInfos}),
        mandateIdsForFeesEntries() {
            if (!this._periodInfo) {
                return [];
            }

            return this._periodInfo.mandateIdsForFeesEntries;
        },
        mandateIdsForPaymentEntries() {
            if (!this._periodInfo) {
                return [];
            }

            return this._periodInfo.mandateIdsForPaymentEntries;
        },
        nbSummaries() {
            if (!this._periodInfo) {
                return true;
            }

            return this._periodInfo.nbSummaries;
        },
        mandateIdsForSendSummaries() {
            if (!this._periodInfo) {
                return [];
            }

            return this._periodInfo.mandateIdsForSendSummaries;
        },
        firstNonEmptyPeriodInfo() {
            return this.periodInfos.find(periodInfo => periodInfo.nbSummaries > 0);
        },
        actorFilter: {
            get() {
                return this.$store.state.mandate.actorFilter;
            },
            set(value) {
                this.$store.commit('mandate/setActorFilter', value);
            }
        },
        propertyFilter: {
            get() {
                return this.$store.state.mandate.propertyFilter;
            },
            set(value) {
                this.$store.commit('mandate/setPropertyFilter', value);
            }
        },
        withArchivesFilter: {
            get() {
                return this.$store.state.mandate.withArchivesFilter;
            },
            set(value) {
                this.$store.commit('mandate/setWithArchivesFilter', value);
            }
        },
        filtered() {
            return this.mandates
                .filter(mandate => this.propertyFilter ? this._propertiesMatch(mandate, this.propertyFilter) : true)
                .filter(mandate => this.actorFilter ? this._actorsMatch(mandate, this.actorFilter) : true)
                .filter(mandate => !this.withArchivesFilter ? !mandate.endDate : true);
        },
        nbFiltered() {
            return this.filtered.length;
        },
        nbTotal() {
            return this.mandates.length;
        },
        _periodInfo() {
            if (!this.period) {
                return null;
            }

            return this.periodInfos.find(periodInfo => periodInfo.period.id === this.period.id);
        }
    },
    data() {
        return {
            showPeriodsModal: false,
            period: null,
            showSendFeesEntriesConfirmation: false,
            showSendPaymentEntriesConfirmation: false,
            showSendSummariesConfirmation: false
        }
    },
    created() {
        this.load('Chargement des mandats');
        this.$store.dispatch('mandate/reload')
            .then(() => this.period = this._defaultPeriod())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        formatDate(date) {
            return this.$container.types.formatDate(date);
        },
        printSummaries() {
            this._download('/api/mandate/summary');
        },
        downloadBankFile(readable) {
            this._download('/api/mandate/bank?readable=' + readable);
        },
        sendFeesEntries() {
            if (!this.period || this.mandateIdsForFeesEntries.length < 0) {
                return null;
            }

            this.load('Ecritures comptables d\'honoraire', this.mandateIdsForFeesEntries.length);
            this.showPeriodsModal = false;
            this.showSendFeesEntriesConfirmation = false;
            this._sendFeesEntryFor(this.mandateIdsForFeesEntries.shift());
        },
        sendPaymentEntries() {
            if (!this.period || this.mandateIdsForPaymentEntries.length < 0) {
                return null;
            }

            this.load('Ecritures comptables de versement propriétaire', this.mandateIdsForPaymentEntries.length);
            this.showPeriodsModal = false;
            this.showSendPaymentEntriesConfirmation = false;
            this._sendPaymentEntryFor(this.mandateIdsForPaymentEntries.shift());
        },
        sendSummaries() {
            if (!this.period || this.mandateIdsForSendSummaries.length < 0) {
                return null;
            }

            this.load('Envoi des relevés', this.mandateIdsForSendSummaries.length);
            this.showPeriodsModal = false;
            this.showSendSummariesConfirmation = false;
            this._sendSummaryFor(this.mandateIdsForSendSummaries.shift());
        },
        _sendFeesEntryFor(mandateId) {
            if (mandateId) {
                this.$store.dispatch('mandate/sendFeesEntries', {
                    mandate: mandateId,
                    month: this.period.month,
                    year: this.period.year
                })
                    .then(() => {
                        this.progress();
                        this._sendFeesEntryFor(this.mandateIdsForFeesEntries.shift());
                    })
                    .catch(error => {
                        this.serverError(error);
                        this.unload();
                    });
            } else {
                this._sendCompleted('Ecritures effectuées.');
            }
        },
        _sendPaymentEntryFor(mandateId) {
            if (mandateId) {
                this.$store.dispatch('mandate/sendPaymentEntries', {
                    mandate: mandateId,
                    month: this.period.month,
                    year: this.period.year
                })
                    .then(() => {
                        this.progress();
                        this._sendPaymentEntryFor(this.mandateIdsForPaymentEntries.shift());
                    })
                    .catch(error => {
                        this.serverError(error);
                        this.unload();
                    });
            } else {
                this._sendCompleted('Ecritures effectuées.');
            }
        },
        _sendSummaryFor(mandateId) {
            if (mandateId) {
                this.$store.dispatch('mandate/sendSummary', {
                    mandate: mandateId,
                    month: this.period.month,
                    year: this.period.year
                })
                    .then(() => {
                        this.progress();
                        this._sendSummaryFor(this.mandateIdsForSendSummaries.shift());
                    })
                    .catch(error => {
                        this.serverError(error);
                        this.unload();
                    });
            } else {
                this._sendCompleted('Relevés envoyés.');
            }
        },
        _sendCompleted(label) {
            this.$store.dispatch('mandate/reload')
                .then(() => {
                    this.unload();
                    this.success(label);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        _download(baseUrl) {
            this.showPeriodsModal = false;

            if (!this.period) {
                return;
            }

            this.externalRedirectTo(baseUrl + (baseUrl.includes('?') ? '&' : '?') + 'month=' + this.period.month + '&year=' + this.period.year);
        },
        _defaultPeriod() {
            if (this.firstNonEmptyPeriodInfo) {
                return this.firstNonEmptyPeriodInfo.period;
            }

            return null;
        },
        _actorsMatch(mandate, query) {
            if (!query) {
                return true;
            }

            return mandate.owners.some(owner => _queryMatchContact(owner, query));
        },
        _propertiesMatch(mandate, query) {
            if (!query) {
                return true;
            }

            return mandate.properties.some(property => _queryMatchProperty(property, query));
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandates {
    &__mass-modal {
        .imagine-button {
            width: 100%;
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.mandates__count--filtered {
            .mandates__count__count {
                font-weight: 500;
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }

    &__mandate {
        cursor: pointer;

        &__properties {
            padding: 0;
            margin: 0;
            list-style-position: inside;
        }
    }

    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: -8px;
            margin-left: -8px;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
                @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
                    flex: unset;
                    width: 150px;
                }
            }

            .md-field {
                margin: 0;
            }
        }
    }
}
</style>
