import container from '../container';

const repo = container.repos.event;

export default {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        reload(context, since) {
            return repo.all(since)
                .then(events => context.state.all = events);
        },
        pull(context, since) {
            return repo.all(since)
                .then(events => context.state.all = events.concat(context.state.all));
        }
    }
};
