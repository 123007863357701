<template>
    <div :class="{'imagine-loader--with-message': loadingMessage}" class="imagine-loader">
        <div class="imagine-loader__message" v-if="loadingMessage">
            {{ loadingMessage }}
            <span v-if="progressTotal"><br/> {{ progressInc }} / {{ progressTotal }}</span>
        </div>
        <div class="imagine-loader__progress mdc-linear-progress"
             :class="{'mdc-linear-progress--indeterminate': !progressTotal}">
            <div class="mdc-linear-progress__buffering-dots"></div>
            <div class="mdc-linear-progress__buffer"></div>
            <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar" :style="barStyle">
                <span class="mdc-linear-progress__bar-inner"></span>
            </div>
            <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                <span class="mdc-linear-progress__bar-inner"></span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

export default {
    computed: {
        ...mapGetters(['loadingMessage']),
        ...mapState(['progressInc', 'progressTotal']),
        barStyle() {
            if (!this.progressTotal) {
                return {};
            }

            const progress = this.progressInc / this.progressTotal;

            return {transform: 'scaleX(' + progress + ')'}
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/theme/variables';
@import '~@material/linear-progress/mdc-linear-progress';

.imagine-loader {
    position: fixed;
    z-index: 10;
    width: 100%;

    &.imagine-loader--with-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .imagine-loader__message {
            margin: 2rem;
            color: #666666;
            font-size: 1.5rem;
            text-transform: uppercase;
            text-align: center;
            line-height: 2rem;
        }

        .imagine-loader__progress {
            width: 80%;
        }
    }
}
</style>
