class FileUploader {
    constructor(http) {
        this._http = http;
    }

    createUrl(file, options) {
        let filePayload = new FormData();
        filePayload.append('file', file);
        filePayload.append('options', JSON.stringify(options));

        return this._http.post('/api/file', filePayload);
    }

    processUrl(url, options) {
        return this._http.put('/api/file', {url, options});
    }
}

export default FileUploader;
