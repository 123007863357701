var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandates",
      attrs: { title: _vm.$mq === "phone" ? "Mandats" : "Registre mandats" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: {
                  disabled: !_vm.firstNonEmptyPeriodInfo,
                  icon: "file_copy",
                },
                on: {
                  click: function ($event) {
                    _vm.showPeriodsModal = true
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("mandateAdd")
                  },
                },
              }),
              _vm._v(" "),
              _c("mandate-external-services"),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "imagine-secondary-zone mandates__filters" }, [
        _c(
          "div",
          { staticClass: "mandates__filters__filter" },
          [
            _c("imagine-input", {
              attrs: {
                autocomplete: false,
                clearable: true,
                required: false,
                icon: "search",
                label: "Nom propriétaire",
                name: "actor",
              },
              model: {
                value: _vm.actorFilter,
                callback: function ($$v) {
                  _vm.actorFilter = $$v
                },
                expression: "actorFilter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mandates__filters__filter" },
          [
            _c("imagine-input", {
              attrs: {
                autocomplete: false,
                clearable: true,
                required: false,
                icon: "search",
                label: "Résidence/Lotissement",
                name: "property",
              },
              model: {
                value: _vm.propertyFilter,
                callback: function ($$v) {
                  _vm.propertyFilter = $$v
                },
                expression: "propertyFilter",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mandates__filters__filter mandates__filters__filter--bordered",
          },
          [
            _c("span", [_vm._v("Voir les archives")]),
            _vm._v(" "),
            _c("imagine-switch", {
              model: {
                value: _vm.withArchivesFilter,
                callback: function ($$v) {
                  _vm.withArchivesFilter = $$v
                },
                expression: "withArchivesFilter",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mandates__count",
          class: { "mandates__count--filtered": _vm.nbFiltered < _vm.nbTotal },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 0,
                  expression: "nbFiltered > 0",
                },
              ],
              staticClass: "mandates__count__count",
            },
            [
              _vm.nbFiltered < _vm.nbTotal
                ? _c("span", [
                    _vm._v(_vm._s(_vm.formatNumber(_vm.nbFiltered)) + " / "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.formatNumber(_vm.nbTotal)))]),
              _vm._v(
                "\n            mandat" +
                  _vm._s(_vm.nbFiltered > 1 ? "s" : "") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbTotal > 0 && _vm.nbFiltered <= 0,
                  expression: "nbTotal > 0 && nbFiltered <= 0",
                },
              ],
            },
            [_vm._v("\n            Aucun mandat trouvé.\n        ")]
          ),
          _vm._v(" "),
          _vm.mandates.length > 0
            ? _c("div", { staticClass: "mandates__count__sort" }, [
                _vm._v("\n            Triés par numéro croissant\n        "),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.filtered.length > 0
        ? _c("div", { staticClass: "imagine-table-container" }, [
            _c("table", { staticClass: "imagine-table" }, [
              _c("thead", [
                _c("tr", { staticClass: "imagine-table__row" }, [
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--number",
                    },
                    [_vm._v("\n                    N°\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                    },
                    [_vm._v("\n                    Date\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header",
                    },
                    [_vm._v("Propriétaire(s)")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                    },
                    [_vm._v("\n                    Bien(s)\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                    },
                    [_vm._v("\n                    Date fin\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticClass:
                        "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                    },
                    [
                      _vm._v(
                        "\n                    Observations\n                "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.filtered, function (mandate) {
                  return _c(
                    "tr",
                    {
                      staticClass: "imagine-table__row mandates__mandate",
                      class: {
                        "imagine-table__row--disabled": mandate.endDate,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.redirectTo("mandate", { id: mandate.id })
                        },
                      },
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass:
                            "imagine-table__row__cell imagine-table__row__cell--number",
                        },
                        [
                          _vm._v(
                            "\n                    #" +
                              _vm._s(mandate.registryNumber) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "imagine-table__row__cell imagine-table__row__cell--date",
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.formatDate(mandate.startDate)) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "imagine-table__row__cell" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              mandate.owners
                                .map((owner) => owner.shortName)
                                .join(", ")
                            ) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "imagine-table__row__cell imagine-table__row__cell--text",
                        },
                        [
                          mandate.properties.length === 1
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(mandate.properties[0].infos) +
                                    "\n                    "
                                ),
                              ])
                            : mandate.properties.length > 1
                            ? _c(
                                "ul",
                                {
                                  staticClass: "mandates__mandate__properties",
                                },
                                _vm._l(mandate.properties, function (property) {
                                  return _c("li", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(property.infos) +
                                        "\n                        "
                                    ),
                                  ])
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "imagine-table__row__cell imagine-table__row__cell--date",
                        },
                        [
                          mandate.endDate
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.formatDate(mandate.endDate))),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.formatDate(mandate.legalEndDate)
                                    ) +
                                    ")"
                                ),
                              ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass:
                            "imagine-table__row__cell imagine-table__row__cell--text",
                        },
                        [_vm._v(_vm._s(mandate.observation))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _c("div", { staticClass: "mandates__none" }, [
            _vm._v("\n        Aucun mandat.\n    "),
          ]),
      _vm._v(" "),
      _vm.showPeriodsModal
        ? _c(
            "imagine-modal",
            {
              on: {
                close: function ($event) {
                  _vm.showPeriodsModal = false
                },
              },
            },
            [
              _c(
                "p",
                [
                  _c("imagine-select-period", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      periods: _vm.periodInfos.map(
                        (periodInfo) => periodInfo.period
                      ),
                    },
                    model: {
                      value: _vm.period,
                      callback: function ($$v) {
                        _vm.period = $$v
                      },
                      expression: "period",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.nbSummaries > 0
                    ? _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.printSummaries()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Editer " +
                              _vm._s(_vm.nbSummaries) +
                              " relevé" +
                              _vm._s(_vm.nbSummaries > 1 ? "s" : "") +
                              "\n            "
                          ),
                        ]
                      )
                    : _c("imagine-button", { attrs: { disabled: true } }, [
                        _vm._v(
                          "\n                Editer relevés\n            "
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.mandateIdsForFeesEntries.length > 0
                    ? _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showSendFeesEntriesConfirmation = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Lancer " +
                              _vm._s(_vm.mandateIdsForFeesEntries.length) +
                              "\n                écriture" +
                              _vm._s(
                                _vm.mandateIdsForFeesEntries.length > 1
                                  ? "s"
                                  : ""
                              ) +
                              " honoraire\n            "
                          ),
                        ]
                      )
                    : _c("imagine-button", { attrs: { disabled: true } }, [
                        _vm._v(
                          "\n                Lancer les écritures honoraires\n            "
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "imagine-button",
                    {
                      attrs: { disabled: _vm.nbSummaries <= 0 },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.downloadBankFile(1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Générer fichier bancaire EXCEL\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "imagine-button",
                    {
                      attrs: { disabled: _vm.nbSummaries <= 0 },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.downloadBankFile(0)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Générer fichier bancaire SOCREDO\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.mandateIdsForPaymentEntries.length > 0
                    ? _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showSendPaymentEntriesConfirmation = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Lancer " +
                              _vm._s(_vm.mandateIdsForPaymentEntries.length) +
                              "\n                écriture" +
                              _vm._s(
                                _vm.mandateIdsForPaymentEntries.length > 1
                                  ? "s"
                                  : ""
                              ) +
                              " versement\n            "
                          ),
                        ]
                      )
                    : _c("imagine-button", { attrs: { disabled: true } }, [
                        _vm._v(
                          "\n                Lancer les écritures versement\n            "
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm.mandateIdsForSendSummaries.length > 0
                    ? _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showSendSummariesConfirmation = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Envoyer " +
                              _vm._s(_vm.mandateIdsForSendSummaries.length) +
                              "\n                relevé" +
                              _vm._s(
                                _vm.mandateIdsForSendSummaries.length > 1
                                  ? "s"
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _c("imagine-button", { attrs: { disabled: true } }, [
                        _vm._v(
                          "\n                Envoyer les relevés\n            "
                        ),
                      ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendFeesEntriesConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendFeesEntries()
                },
                close: function ($event) {
                  _vm.showSendFeesEntriesConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir réaliser toutes les écritures comptables d'honoraires ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendPaymentEntriesConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendPaymentEntries()
                },
                close: function ($event) {
                  _vm.showSendPaymentEntriesConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir réaliser toutes les écritures comptables de versement propriétaire ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendSummariesConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendSummaries()
                },
                close: function ($event) {
                  _vm.showSendSummariesConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir envoyer tous les relevés ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }