<template>
    <div>
        <div class="imagine-message imagine-message--success mdc-snackbar"
             ref="success">
            <div class="mdc-snackbar__surface">
                <div class="mdc-snackbar__label">{{ label }}</div>
                <div class="mdc-snackbar__actions">
                    <button class="mdc-button mdc-snackbar__action"
                            type="button">Fermer
                    </button>
                </div>
            </div>
        </div>
        <div class="imagine-message imagine-message--error mdc-snackbar"
             ref="error">
            <div class="mdc-snackbar__surface">
                <div class="mdc-snackbar__label">{{ label }}</div>
                <div class="mdc-snackbar__actions">
                    <button class="mdc-button mdc-snackbar__action"
                            type="button">Fermer
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {MDCSnackbar} from '@material/snackbar/index';

export default {
    data() {
        return {
            label: null,
            _snackbarSuccess: null,
            _snackbarError: null,
            _subscriber: null
        }
    },
    mounted() {
        this._snackbarSuccess = new MDCSnackbar(this.$refs.success);
        this._snackbarError = new MDCSnackbar(this.$refs.error);

        this._subscriber = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'success') {
                this._open(this._snackbarSuccess, state.messages.success);
            }

            if (mutation.type === 'error') {
                this._open(this._snackbarError, state.messages.error);
            }
        });
    },
    beforeDestroy() {
        if (this._subscriber) {
            this._subscriber();
        }
        this._snackbarError.destroy();
        this._snackbarSuccess.destroy();
    },
    methods: {
        _open(snackbar, message) {
            this.label = message;
            snackbar.open();
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/snackbar/mdc-snackbar';

.mdc-snackbar {
    z-index: 100;
}

.imagine-message.imagine-message--success .mdc-snackbar__surface {
    background: $mdc-theme-secondary;
    background: var(--mdc-theme-secondary, $mdc-theme-secondary);
    color: white;
    border-color: white;
}

.imagine-message.imagine-message--error .mdc-snackbar__surface {
    background: $imagine-error-color;
    color: white;
    border-color: white;
}

.mdc-snackbar__surface .mdc-snackbar__action {
    color: white;
}
</style>
