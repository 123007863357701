const FRENCH_DATEPICKER_LOCALE = {
    startYear: 1900,
    endYear: 2099,
    dateFormat: 'dd/MM/yyyy',
    days: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi'
    ],
    shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    shorterDays: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    months: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
    ],
    shortMonths: [
        'Janv',
        'Févr',
        'Mars',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Août',
        'Sept',
        'Oct',
        'Nov',
        'Déc'
    ],
    shorterMonths: [
        'J',
        'F',
        'M',
        'A',
        'M',
        'Ju',
        'Ju',
        'A',
        'Se',
        'O',
        'N',
        'D'
    ],
    firstDayOfAWeek: 1
};

export default FRENCH_DATEPICKER_LOCALE;
