<template>
    <imagine-autocomplete :label="label ? label : 'Bien'"
                          :propositions="propertyPropositions"
                          :required="required"
                          :create="!!redirectAfterCreate"
                          @create="gotoCreate()"
                          @first="proposeAllProperties"
                          @input="$emit('input', innerValue)"
                          @search="reloadPropositions($event)"
                          entity="bien"
                          :name="name"
                          :violation="violation"
                          help="Saisir mot clé et cliquer sur résultat"
                          v-model="innerValue"/>
</template>

<script>
import ImagineAutocomplete from './ImagineAutocomplete.vue';
import {mapState} from 'vuex';
import debounce from 'lodash-es/debounce';

export default {
    props: ['value', 'label', 'name', 'required', 'redirectAfterCreate', 'violation', 'filter'],
    components: {ImagineAutocomplete},
    computed: {
        ...mapState('property', {all: state => state.displayed ? state.displayed : []})
    },
    data() {
        return {
            term: null,
            search: null,
            propertyPropositions: [],
            innerValue: this._propertyToProposition(this.value)
        }
    },
    created() {
        this.refreshPropertyPropositions = debounce(this._refreshPropertyPropositions, 200);
    },
    watch: {
        value(newVal) {
            this.innerValue = this._propertyToProposition(newVal);
        }
    },
    methods: {
        reloadPropositions(term) {
            this.term = term;
            this.refreshPropertyPropositions();
        },
        _refreshPropertyPropositions() {
            this.$store.dispatch('property/query', this.term)
                .then(properties => this.propertyPropositions = this._propertiesToPropositions(this._restrict(properties)));
        },
        proposeAllProperties() {
            this.propertyPropositions = this._propertiesToPropositions(this.all);
        },
        gotoCreate() {
            this.$store.commit('property/setRedirectAfterSave', this.redirectAfterCreate);
            this.redirectTo('propertyAdd');
        },
        _restrict(properties) {
            if (this.filter) {
                return properties.filter(this.filter);
            }

            return properties;
        },
        _propertiesToPropositions(properties) {
            if (!properties) {
                return [];
            }

            return properties.map(property => this._propertyToProposition(property));
        },
        _propertyToProposition(property) {
            if (!property) {
                return null;
            }

            const getPropertyLabel = (property) => {
                if (property.label) {
                    return property.label;
                }

                return property.infos + ' (' + property.reference + ')';
            };

            const label = getPropertyLabel(property);

            if (!label) {
                return null;
            }

            return {id: property.id, label: label};
        }
    }
}
</script>
