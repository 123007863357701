<template>
    <imagine-layout :inner="true" :title="pageTitle" class="contact-edit">
        <template v-slot:actions>
            <imagine-icon-button @click="toggleFavorite()"
                                 class="mdc-top-app-bar__action-item"
                                 :icon="contact.isFavorite ? 'star' : 'star_border'"
                                 v-if="contact"/>
            <imagine-icon-button @click="openMail(contact.email)"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="email"
                                 v-if="contact && contact.email"/>
            <imagine-icon-button @click="openTel(contact.mobilePhone.e164)"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="smartphone"
                                 v-if="contact && contact.mobilePhone"/>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="delete"
                                 :disabled="!contact || (contact.isClient || isLimitedView || contact.represents.length > 0)"
                                 v-if="contact"/>
        </template>

        <imagine-tab-bar :tabs="tabs"
                         @tab="changeTab($event)"/>

        <div class="imagine-container contact-edit__infos"
             v-if="activeTab === 'infos'">

            <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

            <form @submit.prevent="save"
                  autocomplete="off"
                  class="imagine-form">
                <div class="contact-edit__infos__company">
                    <imagine-switch :disabled="!!contact || isLimitedView"
                                    v-model="isCompany">
                        <span>Entreprise</span>
                    </imagine-switch>
                </div>

                <div class="contact-edit__infos__legal-represents"
                     v-if="!isCompany && contact && contact.represents.length > 0">
                    <p>
                        Représentant légal de
                        <template v-for="(represent,index) in contact.represents">
                            <span>
                                {{ represent.company }}<template
                                v-if="index !== Object.keys(contact.represents).length - 1">, </template>
                            </span>
                        </template>
                    </p>
                </div>

                <div class="imagine-form__row"
                     v-if="!isCompany">
                    <imagine-select :choices="references.titleChoices()"
                                    class="imagine-form__row__field contact-edit__infos__title"
                                    label="Civ."
                                    :disabled="isLimitedView"
                                    name="title"
                                    :violation="errors.title"
                                    v-model="contactTitle"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Nom"
                                   :disabled="isLimitedView"
                                   name="lastname"
                                   v-model="lastname"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Prénom"
                                   :disabled="isLimitedView"
                                   name="firstname"
                                   v-model="firstname"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   :label="isCompany ? 'Raison sociale' : 'Entreprise'"
                                   name="company"
                                   :disabled="isLimitedView"
                                   v-model="company"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Métier"
                                   name="position"
                                   :disabled="isLimitedView"
                                   v-if="!isCompany"
                                   v-model="position"/>
                </div>

                <div class="imagine-form__row"
                     v-if="!isCompany">
                    <imagine-date-picker :max-date="maxBirthDate"
                                         :violation="errors.dateOfBirth"
                                         class="imagine-form__row__field"
                                         label="Date de naissance"
                                         :disabled="isLimitedView"
                                         name="dateOfBirth"
                                         v-model="dateOfBirth"/>

                    <imagine-select :choices="references.ageChoices()"
                                    class="imagine-form__row__field contact-edit__infos__age"
                                    label="Ou âge approx."
                                    :disabled="isLimitedView"
                                    name="age"
                                    v-model="age"/>
                </div>

                <div class="imagine-form__row  imagine-form__row--limited">
                    <imagine-input class="imagine-form__row__field"
                                   label="Vini"
                                   name="mobilePhone"
                                   :disabled="isLimitedView"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   v-model="mobilePhone"
                                   :violation="errors.mobilePhone"/>

                    <imagine-input class="imagine-form__row__field"
                                   :violation="errors.homePhone"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   label="Téléphone domicile"
                                   :disabled="isLimitedView"
                                   name="homePhone"
                                   v-if="!isCompany"
                                   v-model="homePhone"/>

                    <imagine-input :violation="errors.workPhone"
                                   class="imagine-form__row__field"
                                   label="Téléphone travail"
                                   :disabled="isLimitedView"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   name="workPhone"
                                   v-model="workPhone"/>
                </div>

                <div class="imagine-form__row imagine-form__row--limited">
                    <imagine-input class="imagine-form__row__field"
                                   type="email"
                                   label="Email principal"
                                   name="email"
                                   :disabled="isLimitedView"
                                   v-model="email"
                                   :violation="errors.email"/>

                    <imagine-input :violation="errors.workEmail"
                                   class="imagine-form__row__field"
                                   label="Autre email"
                                   name="workEmail"
                                   :disabled="isLimitedView"
                                   type="email"
                                   v-model="workEmail"/>

                    <imagine-input :violation="errors.facebook"
                                   class="imagine-form__row__field"
                                   label="Compte facebook"
                                   name="facebook"
                                   :disabled="isLimitedView"
                                   type="text"
                                   v-model="facebook"/>
                </div>

                <imagine-address :show="showPostalAddress"
                                 :with-address="true"
                                 :disabled="isLimitedView"
                                 v-model="postalAddress"/>

                <imagine-form-complex-field :show="showSource"
                                            @close="showSource = false"
                                            @open="showSource = true"
                                            :disabled="isLimitedView"
                                            :title="sourceTitle">
                    <imagine-checkboxes :choices="references.sourceChoices()"
                                        name="sources"
                                        :disabled="isLimitedView"
                                        v-model="sources"/>
                </imagine-form-complex-field>

                <div class="imagine-form__row"
                     v-if="!isLimitedView">
                    <imagine-textarea class="imagine-form__row__field contact-edit__infos__note"
                                      label="Notes"
                                      name="comment1"
                                      :help="helpNote"
                                      v-model="comment"/>
                </div>

                <div class="contact-edit__infos__legal-rep"
                     v-if="isCompany">
                    <div class="imagine-form__sep"></div>

                    <h6 class="contact-edit__infos__legal-rep__title mdc-typography--headline6">
                        Représentants légaux
                    </h6>

                    <template v-if="contact">
                        <div class="imagine-form__row"
                             v-for="(legalRepSlot, slot) in legalRepSlots"
                             v-if="nbLegalReps >= slot">
                            <imagine-contact-autocomplete
                                :label="slot > 0 ? 'Autre représentant légal' : 'Représentant légal'"
                                :required="false"
                                :disabled="isLimitedView"
                                @input="removeLegalRepSlotHoles()"
                                class="imagine-form__row__field"
                                name="legalRep"
                                v-model="legalRepSlot.contact"/>
                        </div>
                    </template>
                    <imagine-alert type="info"
                                   v-else>
                        Pour pouvoir ajouter des représentants légaux, il faut réaliser une première sauvegarde de
                        l'entreprise.
                    </imagine-alert>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary
                                    :disabled="isLimitedView"
                                    type="submit">
                        Sauvegarder
                    </imagine-button>
                </div>
            </form>

            <div class="contact-edit__infos__timeline"
                 v-if="contact">
                <timeline :events="timeline"
                          v-if="timeline.length > 0"/>

                <form @submit.prevent="createEvent"
                      class="imagine-form">
                    <div class="imagine-form__row">
                        <imagine-textarea class="imagine-form__row__field"
                                          label="Nouvel évènement : contact tél, RDV, etc."
                                          :required="true"
                                          name="event1"
                                          v-model="eventDescription"/>
                    </div>

                    <div class="imagine-form__actions">
                        <imagine-button primary type="submit">Ajouter évènement</imagine-button>
                    </div>
                </form>
            </div>
        </div>

        <div class="imagine-container"
             v-if="activeTab === 'prospect_new'">

            <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

            <form @submit.prevent="save"
                  class="imagine-form contact-edit__prospect">
                <div class="imagine-form__row contact-edit__prospect__salesrep"
                     v-if="canFilterSalesRep()">
                    <imagine-select :choices="salesRepChoices"
                                    class="imagine-form__row__field"
                                    label="Négociateur"
                                    name="salesrep"
                                    v-model="salesRep"/>
                </div>

                <div class="imagine-form__row contact-edit__prospect__transactions">
                    <imagine-checkbox label="EN RECHERCHE"
                                      name="isProspectNewBuild"
                                      v-model="isProspectNewBuild"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.areaChoices()"
                                        name="prospectareas"
                                        class="imagine-form__row__field"
                                        label="Zones"
                                        v-model="prospectAreas">
                        <div>
                            <a class="imagine-checkareas"
                               href="#"
                               @click.prevent="addProspectAreas('west', prospectAreas)">
                                Cocher cote ouest
                            </a>
                            <a class="imagine-checkareas"
                               href="#"
                               @click.prevent="addProspectAreas('east', prospectAreas)">
                                Cocher cote est
                            </a>
                        </div>
                    </imagine-checkboxes>

                    <imagine-checkboxes :choices="references.propertyTypeChoices()"
                                        name="prospectpropertytypes"
                                        class="imagine-form__row__field"
                                        label="Types de bien"
                                        v-model="prospectPropertyTypes"/>
                </div>


                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.blockChoices(blocks, true)"
                                        name="prospectblocks"
                                        class="imagine-form__row__field"
                                        label="Résidences"
                                        v-model="prospectBlocks"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-select :choices="references.purchaseTypeChoices()"
                                    class="imagine-form__row__field"
                                    label="Type d'achat"
                                    name="prospectpurchasetype"
                                    v-model="prospectPurchaseType"/>
                </div>

                <div class="imagine-form__row"
                     v-if="newHasRooms">
                    <imagine-input class="imagine-form__row__field"
                                   label="Nb. de pièces min."
                                   name="nbRooms"
                                   type="number"
                                   :violation="errors.prospectNbRoomsMin"
                                   v-model="prospectNbRoomsMin"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Nb. de chambres min."
                                   name="nbBedrooms"
                                   type="number"
                                   v-if="newHasBedrooms"
                                   :violation="errors.prospectNbBedroomsMin"
                                   v-model="prospectNbBedroomsMin"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-select :choices="references.buyBudgetChoices()"
                                    class="imagine-form__row__field"
                                    label="Budget d'achat"
                                    name="prospectbuybudget"
                                    v-model="prospectBuyBudget"/>

                    <imagine-select :choices="references.financingChoices()"
                                    class="imagine-form__row__field"
                                    label="Financement achat"
                                    name="prospectfinancing"
                                    v-model="prospectFinancing"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-date-picker name="maxProspecting"
                                         class="imagine-form__row__field"
                                         label="Date butoir"
                                         help="N'apparaitra plus dans les résultats prospect promotion au delà de cette date"
                                         v-model="maxProspecting" />

                    <imagine-date-picker name="reminder"
                                         class="imagine-form__row__field"
                                         label="Date de relance"
                                         help="Enverra un email au négociateur promotion à cette date"
                                         v-model="reminder" />
                </div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Notes"
                                      :help="helpNote"
                                      name="comment2"
                                      v-model="comment"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>

            <div class="contact-edit__infos__timeline" v-if="contact">
                <timeline :events="timeline"
                          v-if="timeline.length > 0"/>

                <form @submit.prevent="createEvent"
                      class="imagine-form">
                    <div class="imagine-form__row">
                        <imagine-textarea :required="true"
                                          class="imagine-form__row__field"
                                          label="Nouvel évènement : contact tél, RDV, etc."
                                          name="event2"
                                          v-model="eventDescription"/>
                    </div>

                    <div class="imagine-form__actions">
                        <imagine-button primary type="submit">Ajouter évènement</imagine-button>
                    </div>
                </form>
            </div>

            <template v-if="contact && contact.matchingProperties.length > 0">
                <h3 style="margin: 1rem;">Biens correspondants promotion</h3>

                <div class="mdc-list mdc-list--two-line">
                    <div @click="gotoProperty(match.property)"
                         class="mdc-list-item"
                         v-for="match in contact.matchingProperties">
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                {{ match.property.infos }}
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                Référence {{ match.property.reference }}
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            {{ Math.round(match.score * 100) }}%
                        </span>
                    </div>
                </div>
            </template>
        </div>

        <div class="imagine-container"
             v-if="activeTab === 'prospect_old'">

            <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

            <form @submit.prevent="save"
                  class="imagine-form contact-edit__prospect">
                <div class="imagine-form__row contact-edit__prospect__salesrep"
                     v-if="canFilterSalesRep()">
                    <imagine-select :choices="salesRepChoices"
                                    class="imagine-form__row__field"
                                    label="Négociateur"
                                    name="salesrepother"
                                    v-model="salesRepOther"/>
                </div>

                <div class="imagine-form__row contact-edit__prospect__transactions">
                    <imagine-checkboxes :choices="oldProspectTransactionChoices"
                                        class="imagine-form__row__field"
                                        name="prospecttransactions"
                                        v-model="prospectTransactionTypes"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.areaChoices()"
                                        name="prospectoldareas"
                                        class="imagine-form__row__field"
                                        label="Zones"
                                        v-model="prospectOldAreas">
                        <div>
                            <a class="imagine-checkareas"
                               href="#"
                               @click.prevent="addProspectAreas('west', prospectOldAreas)">
                                Cocher cote ouest
                            </a>
                            <a class="imagine-checkareas"
                               href="#"
                               @click.prevent="addProspectAreas('east', prospectOldAreas)">
                                Cocher cote est
                            </a>
                        </div>
                    </imagine-checkboxes>

                    <imagine-checkboxes :choices="references.propertyTypeChoices()"
                                        name="prospectoldpropertytypes"
                                        class="imagine-form__row__field"
                                        label="Types de bien"
                                        v-model="prospectOldPropertyTypes"/>
                </div>


                <div class="imagine-form__row"
                     v-if="oldHasRooms">
                    <imagine-input class="imagine-form__row__field"
                                   label="Nb. de pièces min."
                                   name="oldnbRooms"
                                   type="number"
                                   :violation="errors.prospectOldNbRoomsMin"
                                   v-model="prospectOldNbRoomsMin"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Nb. de chambres min."
                                   name="oldnbBedrooms"
                                   type="number"
                                   v-if="oldHasBedrooms"
                                   :violation="errors.prospectOldNbBedroomsMin"
                                   v-model="prospectOldNbBedroomsMin"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isBuying">
                    <imagine-select :choices="references.purchaseTypeChoices()"
                                    class="imagine-form__row__field"
                                    label="Type d'achat"
                                    name="oldsprospectpurchasetype"
                                    v-model="prospectOldPurchaseType"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isBuying">
                    <imagine-select :choices="references.buyBudgetChoices()"
                                    class="imagine-form__row__field"
                                    label="Budget d'achat"
                                    name="oldprospectbuybudget"
                                    v-model="prospectOldBuyBudget"/>

                    <imagine-select :choices="references.financingChoices()"
                                    class="imagine-form__row__field"
                                    label="Financement achat"
                                    name="oldprospectfinancing"
                                    v-model="prospectOldFinancing"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isRenting">
                    <imagine-select :choices="references.rentBudgetChoices()"
                                    class="imagine-form__row__field"
                                    label="Budget de location"
                                    name="prospectrentbudget"
                                    v-model="prospectRentBudget"/>

                    <imagine-select :choices="references.furnishedChoices()"
                                    class="imagine-form__row__field"
                                    label="Ameublement"
                                    name="prospectrentfurnished"
                                    v-model="prospectRentFurnished"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isRenting">
                    <imagine-select :choices="references.prospectRentTaxExemptionChoices()"
                                    class="imagine-form__row__field"
                                    label="Défiscalisation"
                                    name="prospectrenttaxexemption"
                                    v-model="prospectRentTaxExemption"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-date-picker name="oldMaxProspecting"
                                         class="imagine-form__row__field"
                                         label="Date butoir"
                                         help="N'apparaitra plus dans les résultats prospect agence au delà de cette date"
                                         v-model="oldMaxProspecting" />

                    <imagine-date-picker name="oldReminder"
                                         class="imagine-form__row__field"
                                         label="Date de relance"
                                         help="Enverra un email au négociateur agence à cette date"
                                         v-model="oldReminder" />
                </div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Notes"
                                      name="comment3"
                                      :help="helpNote"
                                      v-model="comment"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>

            <div class="contact-edit__infos__timeline" v-if="contact">
                <timeline :events="timeline"
                          v-if="timeline.length > 0"/>

                <form @submit.prevent="createEvent"
                      class="imagine-form">
                    <div class="imagine-form__row">
                        <imagine-textarea :required="true"
                                          class="imagine-form__row__field"
                                          label="Nouvel évènement : contact tél, RDV, etc."
                                          name="event2"
                                          v-model="eventDescription"/>
                    </div>

                    <div class="imagine-form__actions">
                        <imagine-button primary type="submit">Ajouter évènement</imagine-button>
                    </div>
                </form>
            </div>

            <template v-if="contact && contact.matchingOldProperties.length > 0">
                <h3 style="margin: 1rem;">Biens correspondants agence</h3>

                <div class="mdc-list mdc-list--two-line">
                    <div @click="gotoProperty(match.property)"
                         class="mdc-list-item"
                         v-for="match in contact.matchingOldProperties">
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                {{ match.property.infos }}
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                Référence {{ match.property.reference }}
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            {{ Math.round(match.score * 100) }}%
                        </span>
                    </div>
                </div>
            </template>
        </div>

        <div class="contact-edit__client"
             v-if="contact && activeTab === 'client'">
            <div class="contact-edit__client__category">
                <h6 class="contact-edit__client__category__title mdc-typography--headline6">
                    <span>
                        Achat<template v-if="newBuildTransactions.length > 1">s</template>
                        neuf<template v-if="newBuildTransactions.length > 1">s</template>
                    </span>
                    <imagine-button :primary="true"
                                    v-if="canAddSaleOption()"
                                    @click="showBookingModal = 'new_build'">
                        Poser option
                    </imagine-button>
                </h6>

                <div class="mdc-list"
                     v-if="contact && contact.sales.length > 0">
                    <template v-for="sale in contact.sales">
                        <div @click="gotoSale(sale)"
                             class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <sale-status :sale="sale"/>
                            </span>
                            <span class="mdc-list-item__text">
                                {{ sale.property }}
                            </span>
                            <span class="mdc-list-item__meta">
                                <option-remove v-if="sale.step === 'option'"
                                               :sale-id="sale.id"
                                               @removed="reloadContact()"
                                               :placeholder="references.stepLabel(sale.step)"
                                               :sales-rep-id="sale.salesRepId" />
                                <template v-else>{{ references.stepLabel(sale.step) }}</template>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>

                <p v-else>Aucun achat neuf</p>
            </div>

            <div class="contact-edit__client__category">
                <h6 class="contact-edit__client__category__title mdc-typography--headline6">
                    <span>
                        Autre<template v-if="oldTransactions.length > 1">s</template>
                        achat<template v-if="oldTransactions.length > 1">s</template>
                    </span>
                    <imagine-button :primary="true"
                                    @click="showBookingModal = 'old'">
                        Ajout bien <small>(hors neuf)</small>
                    </imagine-button>
                </h6>

                <div class="mdc-list mdc-list--two-line"
                     v-if="oldTransactions.length > 0">
                    <template v-for="transaction in oldTransactions">
                        <div @click="redirectTo('property', {id: transaction.property.id})"
                             class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <span class="material-icons imagine-status imagine-status--sold">
                                    lens
                                </span>
                            </span>
                            <span class="mdc-list-item__text">
                                <span class="mdc-list-item__primary-text">
                                    {{ transaction.property.infos }}
                                </span>
                                <span class="mdc-list-item__secondary-text">
                                    Référence {{ transaction.property.reference }}
                                </span>
                            </span>
                            <span class="mdc-list-item__meta"
                                  @click.stop="showRemoveTransactionConfirmation = transaction">
                                <span class="material-icons">delete</span>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>

                <p v-else>Pas d'autres achats.</p>
            </div>

            <div class="contact-edit__client__category">
                <h6 class="contact-edit__client__category__title mdc-typography--headline6">
                    <span>Gestion</span>
                    <imagine-button :primary="true"
                                    :disabled="!isMandatesGranted()"
                                    @click="addMandate()">
                        Ajouter mandat
                    </imagine-button>
                </h6>

                <div class="mdc-list"
                     :class="{'mdc-list--non-interactive': !isMandatesGranted()}"
                     v-if="contact.mandates.length > 0">
                    <template v-for="mandate in contact.mandates">
                        <div class="mdc-list-item"
                             @click="gotoMandate(mandate)">
                            <span class="mdc-list-item__text">
                                Mandat #{{ mandate.registryNumber }},
                                <span v-if="mandate.properties.length === 1">{{ mandate.properties[0].infos }}</span>
                                <span v-else>{{ mandate.properties.length }} biens</span>
                            </span>
                            <span class="mdc-list-item__meta">
                                {{ formatDate(mandate.startDate) }}
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
                <p v-else>Aucun bien en gestion.</p>
            </div>

            <div class="contact-edit__client__category">
                <h6 class="contact-edit__client__category__title mdc-typography--headline6">
                    <span>
                        Location<template v-if="rentalTransactions.length > 1">s</template>
                    </span>
                    <imagine-button :primary="true"
                                    @click="showBookingModal = 'rental'">
                        Ajout location <small>(hors gestion)</small>
                    </imagine-button>
                </h6>

                <div class="mdc-list mdc-list--two-line"
                     v-if="rentalTransactions.length > 0">
                    <template v-for="transaction in rentalTransactions">
                        <div @click="redirectTo('property', {id: transaction.property.id})"
                             class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <span
                                    :class="{'imagine-status--sold': !transaction.isOngoing, 'imagine-status--success':  transaction.isOngoing}"
                                    class="material-icons imagine-status">
                                    lens
                                </span>
                            </span>
                            <span class="mdc-list-item__text">
                                <span class="mdc-list-item__primary-text">
                                    {{ transaction.property.infos }}
                                </span>
                                <span class="mdc-list-item__secondary-text">
                                    Référence {{ transaction.property.reference }}
                                </span>
                            </span>
                            <span class="mdc-list-item__meta"
                                  @click.stop="showRemoveTransactionConfirmation = transaction">
                                <span class="material-icons">delete</span>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
                <p v-else>Aucune location.</p>
            </div>
        </div>

        <div class="imagine-container"
             v-show="activeTab === 'docs'">
            <form @submit.prevent="save"
                  class="imagine-form">
                <div class="imagine-form__row imagine-form__row--limited">
                    <imagine-file-input class="imagine-form__row__field"
                                        label="Pièce d'identité"
                                        accept="application/pdf"
                                        name="identityUrl"
                                        v-model="identityUrl"/>

                    <imagine-file-input accept="application/pdf"
                                        class="imagine-form__row__field"
                                        label="Justificatif de ressources"
                                        v-model="earningsUrl"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   label="Numéro de compte bancaire"
                                   name="iban"
                                   help="Format IBAN : FR7620800121000001234567823"
                                   :violation="errors.bankAccount"
                                   v-model="bankAccount"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       button-label="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer ce contact ?</p>
        </imagine-modal>

        <imagine-modal @act="removeOldTransaction"
                       @close="showRemoveTransactionConfirmation = false"
                       button-label="Supprimer"
                       v-if="showRemoveTransactionConfirmation">
            <p>Etes vous sûr de vouloir supprimer cette transaction ?</p>
        </imagine-modal>

        <imagine-modal :button-disabled="!property"
                       :title="showBookingModal === 'new_build' ? 'Poser une option' : 'Ajouter bien'"
                       @act="showBookingModal === 'new_build' ? addSaleOption() : addTransaction(showBookingModal)"
                       @close="showBookingModal = false"
                       :button-label="showBookingModal === 'new_build' ? 'Poser une option' : 'Ajouter bien'"
                       class="imagine-modal--booking"
                       v-if="this.contactId && showBookingModal">
            <imagine-property-autocomplete
                :redirect-after-create="{name: this.$route.name, params: {id: this.contactId}, query: {ack: showBookingModal}}"
                :filter="showBookingModal === 'new_build' ? isPropertyNewBuild : isPropertyNotNewBuild"
                v-model="property"/>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineCheckboxes from '../components/ImagineCheckboxes.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import ImagineFormComplexField from '../components/ImagineFormComplexField.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import Timeline from './elements/Timeline.vue';
import ImagineAddress from '../components/ImagineAddress.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImaginePropertyAutocomplete from '../components/ImaginePropertyAutocomplete.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import SaleStatus from './elements/SaleStatus.vue';
import OptionRemove from "./elements/OptionRemove";
import ImagineCheckbox from '../components/ImagineCheckbox.vue';
import {mapState} from 'vuex';

const WITH_ROOMS = ['apartment', 'penthouse', 'villa', 'premises_pro', 'premises_business'];
const WITH_BEDROOMS = ['apartment', 'penthouse', 'villa'];

const reserveSlots = (nbSlots) => {
    let slots = [];

    for (let i = 0; i < nbSlots; i++) {
        slots.push({contact: null});
    }

    return slots;
};

const MAX_LEGAL_REPS = 100;

export default {
    components: {
        ImagineCheckbox,
        OptionRemove,
        SaleStatus,
        ImagineContactAutocomplete,
        ImagineDatePicker,
        ImagineSwitch,
        ImagineAddress,
        ImagineFormComplexField,
        ImagineLayout,
        ImagineModal,
        ImagineButton,
        ImagineIconButton,
        ImagineInput,
        ImagineTextarea,
        ImagineSelect,
        ImagineCheckboxes,
        ImagineTabBar,
        ImagineAlert,
        Timeline,
        ImaginePropertyAutocomplete,
        ImagineFileInput
    },
    computed: {
        ...mapState('user', {user: state => state.current}),
        ...mapState('contact', {activeTab: state => state.lastTab}),
        ...mapState('employee', {employees: state => state.all}),
        ...mapState('block', {blocks: state => state.all}),
        ...mapState(['references']),
        pageTitle() {
            if (!this.contactId) {
                return 'Création contact';
            }

            if (!this.contact) {
                return null;
            }

            let label = '';

            if (this.contact.isCompany) {
                return this.contact.company;
            }

            if (this.contact.firstname) {
                label += this.contact.firstname + ' ';
            }

            if (this.contact.lastname) {
                label += this.contact.lastname;
            }

            if (!label && this.contact.email) {
                label += this.contact.email;
            }

            if (!label) {
                return 'Edition contact';
            }

            return label;
        },
        sourceTitle() {
            let title = 'Provenance';

            if (!this.contact || !this.contact.sources || this.contact.sources.length <= 0 || !this.references) {
                return title;
            }

            return title + ' : ' + this.contact.sources.map(source => this.references.getLabel('sources', source)).join(', ');
        },
        oldProspectTransactionChoices() {
            return this.references.prospectTransactionChoices().filter(choice => choice.value !== 'new_build');
        },
        salesRepChoices() {
            if (this.contactId) {
                return this.references.salesRepChoices(this.employees);
            }

            return this.references.activeSalesRepChoices(this.employees);
        },
        isBuying() {
            return this.prospectTransactionTypes
                && this.prospectTransactionTypes.some(transaction => transaction === 'old' || transaction === 'intermediate');
        },
        isRenting() {
            return this.prospectTransactionTypes
                && this.prospectTransactionTypes.some(transaction => transaction === 'rental');
        },
        newHasRooms() {
            return this.hasPropertyTypes(this.prospectPropertyTypes, WITH_ROOMS);
        },
        oldHasRooms() {
            return this.hasPropertyTypes(this.prospectOldPropertyTypes, WITH_ROOMS);
        },
        newHasBedrooms() {
            return this.hasPropertyTypes(this.prospectPropertyTypes, WITH_BEDROOMS);
        },
        oldHasBedrooms() {
            return this.hasPropertyTypes(this.prospectOldPropertyTypes, WITH_BEDROOMS);
        },
        isLimitedView() {
            if (!this.contactId) {
                return false;
            }

            return !this.user
                || (!this.canFilterSalesRep()
                    && this.salesRep !== this.user.id
                    && this.salesRepOther !== this.user.id);
        },
        tabs() {
            const salesRep = this.references.employee(this.employees, this.salesRep);
            const salesRepOther = this.references.employee(this.employees, this.salesRepOther);

            let tabs = [
                {id: 'infos', title: 'Infos', active: this.activeTab === 'infos'},
                {
                    id: 'prospect_new',
                    title: 'Prospect promotion',
                    subtitle: !salesRep ? 'non assigné' : salesRep.firstname,
                    active: this.activeTab === 'prospect_new',
                    disabled: this.isLimitedView
                },
                {
                    id: 'prospect_old',
                    title: 'Prospect agence',
                    subtitle: !salesRepOther ? 'non assigné' : salesRepOther.firstname,
                    active: this.activeTab === 'prospect_old',
                    disabled: this.isLimitedView
                },
                {id: 'client', title: 'Client', active: this.activeTab === 'client', disabled: !this.contact}
            ];

            if (this.isMandatesGranted() || (this.user && this.user.lastname === 'Hiongue')) {
                tabs.push({id: 'docs', title: 'Docs', active: this.activeTab === 'docs'});
            }

            return tabs;
        },
        newBuildTransactions() {
            return this._contactTransactions('new_build');
        },
        oldTransactions() {
            return this._contactTransactions('old');
        },
        rentalTransactions() {
            if (!this.contact) {
                return [];
            }

            return this._contactTransactions('rental').concat(this.contact.leases);
        },
        maxBirthDate() {
            return this.$container.types.today().subtract(18, 'years');
        },
        legalRepIds() {
            return this.legalRepSlots.filter(legalRepSlot => legalRepSlot.contact).map(legalRepSlot => legalRepSlot.contact.id);
        },
        nbLegalReps() {
            return this.legalRepIds.length;
        },
        helpNote() {
            return 'Préférable de compléter plutôt que de remplacer complètement, partagé par les négociateurs promotion et agence, ajouter un évènement (plus bas) permet de mieux retracer l\'historique';
        }
    },
    watch: {
        isProspectNewBuild() {
            if (this.isProspectNewBuild) {
                if (!this.prospectTransactionTypes) {
                    this.prospectTransactionTypes = ['new_build'];
                } else if (!this.prospectTransactionTypes.includes('new_build')) {
                    this.prospectTransactionTypes.push('new_build');
                }
            } else {
                if (this.prospectTransactionTypes && this.prospectTransactionTypes.includes('new_build')) {
                    this.prospectTransactionTypes = this.prospectTransactionTypes.filter(type => type !== 'new_build');
                }
            }
        }
    },
    data() {
        return {
            contact: null,
            showPostalAddress: false,
            showSource: false,
            showRemoveConfirmation: false,
            contactId: null,
            contactTitle: null,
            lastname: null,
            firstname: null,
            mobilePhone: null,
            homePhone: null,
            workPhone: null,
            email: null,
            workEmail: null,
            facebook: null,
            comment: null,
            maxProspecting: null,
            reminder: null,
            oldMaxProspecting: null,
            oldReminder: null,
            position: null,
            company: null,
            isCompany: false,
            age: null,
            dateOfBirth: null,
            sources: null,
            identityUrl: null,
            earningsUrl: null,
            bankAccount: null,
            postalAddress: null,
            salesRep: null,
            salesRepOther: null,
            isProspectNewBuild: false,
            prospectPurchaseType: null,
            prospectRentBudget: null,
            prospectRentFurnished: null,
            prospectRentTaxExemption: null,
            prospectBuyBudget: null,
            prospectAreas: [],
            prospectBlocks: [],
            prospectFinancing: null,
            prospectPropertyTypes: [],
            prospectTransactionTypes: [],
            prospectNbRoomsMin: null,
            prospectNbBedroomsMin: null,
            prospectOldBuyBudget: null,
            prospectOldAreas: [],
            prospectOldPurchaseType: null,
            prospectOldNbRoomsMin: null,
            prospectOldNbBedroomsMin: null,
            prospectOldFinancing: null,
            prospectOldPropertyTypes: [],
            eventDescription: null,
            timeline: [],
            cityPF: null,
            errors: {},
            fromRoute: null,
            fromId: null,
            property: null,
            showBookingModal: false,
            routeAfterSave: null,
            legalRepSlots: reserveSlots(MAX_LEGAL_REPS),
            showRemoveTransactionConfirmation: false
        }
    },
    created() {
        this.routeAfterSave = this.$store.state.contact.redirectAfterSave;
        this.$store.commit('contact/clearRedirectAfterSave');

        this.load();
        this.contactId = this.$route.params ? parseInt(this.$route.params.id) : null;

        if (!this.contactId) {
            this.$store.commit('contact/resetLastTab');
            this.showSource = true;
        }

        Promise.all(this._getDependencies())
            .then(() => {
                if (this.$route.query && this.$route.query.ack) {
                    this.changeTab('client');
                    this.property = this.$store.state.property.lastSaved;
                    this.showBookingModal = this.$route.query.ack;
                }
            })
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        hasPropertyTypes(among, types) {
            return among && among.some(type => types.includes(type));
        },
        changeTab(newTab) {
            this.$store.commit('contact/setLastTab', newTab);
        },
        gotoMandate(mandate) {
            if (this.isMandatesGranted()) {
                this.redirectTo('mandate', {id: mandate.id});
            }

            return null;
        },
        addSaleOption() {
            this.load();
            this.$store.dispatch('sale/quickOption', {
                propertyId: this.property.id,
                contactId: this.contactId
            })
                .then(() => {
                    this._afterNewTransaction()
                        .then(() => {
                            this.showBookingModal = false;
                            this.property = null;
                            this.success('Option posée pour 72h.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        addProspectAreas(coast, areas) {
            areas = this._merge(
                areas,
                coast === 'east' ? ['mahina', 'arue', 'pirae'] : ['punaauia', 'faaa', 'paea', 'papara']
            );
        },
        _merge(array1, array2) {
            let merged = array1;

            array2.forEach(elem => !merged.includes(elem) ? merged.push(elem) : null);

            return merged;
        },
        gotoSale(sale) {
            if (this.isSalesGranted()) {
                this.redirectTo('sale', {id: sale.id});

                return;
            }

            this.redirectTo('property', {id: sale.propertyId});
        },
        save() {
            this.errors = {};

            this.load();

            this.$store.dispatch('contact/save', {
                id: this.contactId,
                title: this.contactTitle,
                lastname: this.lastname,
                firstname: this.firstname,
                email: this.email,
                workEmail: this.workEmail,
                facebook: this.facebook,
                mobilePhone: this.mobilePhone,
                homePhone: this.homePhone,
                workPhone: this.workPhone,
                comment: this.comment,
                maxProspecting: this.maxProspecting,
                reminder: this.reminder,
                oldMaxProspecting: this.oldMaxProspecting,
                oldReminder: this.oldReminder,
                position: this.position,
                company: this.company,
                isCompany: this.isCompany,
                age: this.age,
                dateOfBirth: this.dateOfBirth,
                sources: this.sources,
                identityUrl: this.identityUrl,
                earningsUrl: this.earningsUrl,
                bankAccount: this.bankAccount,
                postalAddressAddress: this.postalAddress ? this.postalAddress.address : null,
                postalAddressZipCode: this.postalAddress ? this.postalAddress.zipCode : null,
                postalAddressCity: this.postalAddress ? this.postalAddress.city : null,
                postalAddressArea: this.postalAddress ? this.postalAddress.area : null,
                postalAddressCountry: this.postalAddress ? this.postalAddress.country : null,
                salesRep: this.salesRep,
                salesRepOther: this.salesRepOther,
                prospectPurchaseType: this.prospectPurchaseType,
                prospectRentBudget: this.prospectRentBudget,
                prospectRentFurnished: this.prospectRentFurnished,
                prospectRentTaxExemption: this.prospectRentTaxExemption,
                prospectBuyBudget: this.prospectBuyBudget,
                prospectAreas: this.prospectAreas,
                prospectBlocks: this.prospectBlocks,
                prospectFinancing: this.prospectFinancing,
                prospectPropertyTypes: this.prospectPropertyTypes,
                prospectTransactionTypes: this.prospectTransactionTypes,
                prospectNbBedroomsMin: this.prospectNbBedroomsMin,
                prospectNbRoomsMin: this.prospectNbRoomsMin,
                prospectOldBuyBudget: this.prospectOldBuyBudget,
                prospectOldAreas: this.prospectOldAreas,
                prospectOldPurchaseType: this.prospectOldPurchaseType,
                prospectOldNbRoomsMin: this.prospectOldNbRoomsMin,
                prospectOldNbBedroomsMin: this.prospectOldNbBedroomsMin,
                prospectOldFinancing: this.prospectOldFinancing,
                prospectOldPropertyTypes: this.prospectOldPropertyTypes,
                legalRepIds: this.legalRepIds
            })
                .then((contact) => {
                    this.redirectAfterSave(contact)
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        formatDate(date) {
            return this.$container.types.formatDate(date);
        },
        gotoProperty(property) {
            this.$store.commit('property/resetLastTab');
            this.redirectTo('property', {id: property.id})
        },
        createEvent() {
            this.load();
            this.$store.dispatch('contact/addEvent', {
                contactId: this.contactId,
                description: this.eventDescription
            })
                .then(event => {
                    this.timeline.push(event);
                    this.eventDescription = ' ';
                    this.success('Nouvel évènement sauvegardé.');
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        remove() {
            this.load();
            this.$store.dispatch('contact/remove', this.contactId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('contacts', {}, () => this.success('Contact supprimé.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        openTel(tel) {
            window.location.href = "tel:" + tel;
        },
        openMail(email) {
            window.location.href = "mailto:" + email;
        },
        closeConfirm() {
            this.showRemoveConfirmation = false;
        },
        redirectAfterSave(contact) {
            this.$store.commit('contact/setLastSaved', contact);
            return this.$store.dispatch('contact/reload')
                .then(() => {
                    if (!this.routeAfterSave) {
                        this.contactId = contact.id;
                        this._remapContact(contact);
                        this.success('Contact sauvegardé.');

                        return;
                    }

                    this.$router.push(this.routeAfterSave);
                });
        },
        addTransaction(type) {
            this.load();
            this.$store.dispatch('property/book', {
                id: this.property.id,
                ownerId: this.contactId,
                type: type
            })
                .then(() => {
                    this._afterNewTransaction()
                        .then(() => {
                            this.showBookingModal = false;
                            this.property = null;
                            this.success('Transaction sauvegardée.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        addMandate() {
            if (this.contactId) {
                return this.$router.push({name: 'mandateAdd', query: {contactId: this.contactId}});
            }

            return this.redirectTo('mandateAdd');
        },
        removeOldTransaction() {
            this.load();
            this.$store.dispatch('contact/removeTransaction', {
                contactId: this.showRemoveTransactionConfirmation.contact.id,
                transactionId: this.showRemoveTransactionConfirmation.id
            })
                .then(() => {
                    this._afterNewTransaction()
                        .then(() => {
                            this.showRemoveTransactionConfirmation = false;
                            this.success('Transaction supprimée.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        toggleFavorite() {
            this.load();
            this.$store.dispatch(this.contact.isFavorite ? 'contact/removeFromFavorites' : 'contact/addToFavorites', this.contactId)
                .then(() => {
                    this.success(this.contact.isFavorite ? 'Contact non VIP.' : 'Contact VIP.');
                    this.contact.isFavorite = !this.contact.isFavorite;
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch'),
                this.$store.dispatch('property/touch'),
                this.$store.dispatch('employee/touch')
            ];

            if (this.contactId) {
                dependencies.push(this.reloadContact());
            }

            return dependencies;
        },
        isPropertyNewBuild(property) {
            return property.canBeNewBuildPurchased;
        },
        isPropertyNotNewBuild(property) {
            return !property.canBeNewBuildPurchased;
        },
        removeLegalRepSlotHoles() {
            setTimeout(() => {
                const nbSlots = this.legalRepSlots.length;
                const nonEmptySlots = this.legalRepSlots.filter(legalRepSlot => legalRepSlot.contact);
                this.legalRepSlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        _afterNewTransaction() {
            return Promise.all([this.reloadContact(), this.$store.dispatch('property/reload')]);
        },
        reloadContact() {
            return this.$store.dispatch('contact/one', this.contactId)
                .then(contact => this._remapContact(contact))
                .catch(this.serverError)
                .finally(this.unload);
        },
        _remapContact(contact) {
            this.contact = contact;
            this.contactTitle = contact.title;
            this.lastname = contact.lastname;
            this.firstname = contact.firstname;
            this.mobilePhone = contact.mobilePhone ? contact.mobilePhone.readable : '';
            this.homePhone = contact.homePhone ? contact.homePhone.readable : '';
            this.workPhone = contact.workPhone ? contact.workPhone.readable : '';
            this.email = contact.email;
            this.workEmail = contact.workEmail;
            this.facebook = contact.facebook;
            this.comment = contact.comment;
            this.maxProspecting = contact.maxProspecting;
            this.reminder = contact.reminder;
            this.oldMaxProspecting = contact.oldMaxProspecting;
            this.oldReminder = contact.oldReminder;
            this.company = contact.company;
            this.isCompany = contact.isCompany;
            this.age = contact.age;
            this.dateOfBirth = contact.dateOfBirth;
            this.position = contact.position;
            this.postalAddress = contact.postalAddress;
            this.identityUrl = contact.identityUrl;
            this.earningsUrl = contact.earningsUrl;
            this.bankAccount = contact.bankAccount;
            this.sources = contact.sources;
            this.salesRep = contact.salesRepId;
            this.salesRepOther = contact.salesRepOtherId;
            this.prospectPurchaseType = contact.prospectPurchaseType;
            this.prospectRentBudget = contact.prospectRentBudget;
            this.prospectRentFurnished = contact.prospectRentFurnished;
            this.prospectRentTaxExemption = contact.prospectRentTaxExemption;
            this.prospectBuyBudget = contact.prospectBuyBudget;
            this.prospectAreas = contact.prospectAreas;
            this.prospectBlocks = contact.prospectBlocks ? contact.prospectBlocks.map(block => block.id) : [];
            this.prospectPropertyTypes = contact.prospectPropertyTypes;
            this.prospectFinancing = contact.prospectFinancing;
            this.isProspectNewBuild = contact.prospectTransactionTypes && contact.prospectTransactionTypes.includes('new_build');
            this.prospectTransactionTypes = contact.prospectTransactionTypes;
            this.timeline = contact.timeline;
            this.prospectNbBedroomsMin = contact.prospectNbBedroomsMin;
            this.prospectNbRoomsMin = contact.prospectNbRoomsMin;
            this.prospectOldBuyBudget = contact.prospectOldBuyBudget;
            this.prospectOldAreas = contact.prospectOldAreas;
            this.prospectOldPurchaseType = contact.prospectOldPurchaseType;
            this.prospectOldNbRoomsMin = contact.prospectOldNbRoomsMin;
            this.prospectOldNbBedroomsMin = contact.prospectOldNbBedroomsMin;
            this.prospectOldFinancing = contact.prospectOldFinancing;
            this.prospectOldPropertyTypes = contact.prospectOldPropertyTypes;

            contact.legalReps.forEach((contact, index) => {
                this.legalRepSlots[index].contact = contact;
            });
        },
        _contactTransactions(type) {
            if (!this.contact || this.contact.transactions.length <= 0) {
                return [];
            }

            return this.contact.transactions.filter(transaction => transaction.type === type);
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.contact-edit {
    &__infos {
        &__company {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__title {
            flex: unset;
            width: 80px;
        }

        &__age {
            flex: unset;
            width: 150px;
        }

        &__timeline {
            margin-top: 1rem;
        }

        &__ads {
            display: flex;
            align-items: center;
        }

        &__note {
            &.md-field {
                margin-bottom: 0;
            }
        }

        &__legal-represents {
            p {
                margin: 0 0 1rem;
                text-align: center;
                font-size: 1rem;
            }
        }

        &__legal-rep {
            &__title {
                margin: .5rem 0 1rem;
                text-align: center;
                font-size: 1.2rem;
            }
        }

        .imagine-form__actions {
            margin-top: 3rem;
        }
    }

    &__prospect {
        &__salesrep {
            .imagine-form__row__field {
                margin-bottom: 0;
            }
        }

        &__transactions {
            display: flex;
            align-items: center;
            justify-content: center;

            .imagine-checkboxes__container {
                margin: 1rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .imagine-form__actions {
            margin-top: 1rem;
        }
    }

    &__client {
        &__category {
            margin: 1rem 0;

            &__title {
                margin: 0 0 .5rem;
                padding: 0 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            p {
                padding: 0.5rem 1rem;
            }
        }

        .imagine-status {
            font-size: 1.2rem;
        }
    }

    .imagine-checkareas {
        display: inline-block;
        margin-right: 1rem;
    }
}
</style>
