var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-field",
    {
      staticClass: "imagine-input",
      class: { "md-invalid": _vm.invalid || _vm.violation },
      attrs: { "md-clearable": _vm.clearable },
      on: {
        "md-clear": function ($event) {
          return _vm.$emit("clear")
        },
      },
    },
    [
      _vm.icon ? _c("md-icon", [_vm._v(_vm._s(_vm.icon))]) : _vm._e(),
      _vm._v(" "),
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _vm.prefix
        ? _c("span", { staticClass: "md-prefix" }, [
            _vm._v("\n        " + _vm._s(_vm.prefix) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("md-input", {
        attrs: {
          type: _vm.type,
          id: _vm.name,
          name: _vm.name,
          required: _vm.required,
          autocomplete: _vm.autocomplete,
          autofocus: _vm.autofocus,
          max: _vm.max,
          min: _vm.min,
          step: _vm.step,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", _vm.innerValue)
          },
          focusin: function ($event) {
            return _vm.$emit("focusin")
          },
          focusout: function ($event) {
            return _vm.$emit("focusout")
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("enter")
          },
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      }),
      _vm._v(" "),
      _vm.help
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v("\n        " + _vm._s(_vm.help) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.violation
        ? _c("span", { staticClass: "md-error" }, [
            _vm._v("\n        " + _vm._s(_vm.violation) + "\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }