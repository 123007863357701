<template>
    <imagine-modal @act="sendReminder()"
                   @close="$emit('canceled')"
                   button-label="Envoyer"
                   class="imagine-modal--wide"
                   title="Relance impayés">
        <imagine-alert type="info">
            L'email de relance est envoyé à tous les locataires et les éventuels garants dont l'email est disponible.
        </imagine-alert>

        <p>
            <imagine-select :choices="modelChoices"
                            :required="true"
                            label="Modèle"
                            name="models"
                            v-model="model"/>
        </p>

        <p style="text-align: center;">
            <imagine-button @click="externalRedirectTo(previewUrl)">Prévisualiser</imagine-button>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineButton from '../../../components/ImagineButton.vue';
import ImagineAlert from '../../../components/ImagineAlert.vue';
import ImagineSelect from '../../../components/ImagineSelect.vue';

export default {
    props: ['lease'],
    components: {ImagineAlert, ImagineButton, ImagineModal, ImagineSelect},
    computed: {
        modelChoices() {
            let choices = [
                this.makeChoice('rent_R1', '1ère relance'),
                this.makeChoice('rent_R2', '2ème relance')
            ];

            if (this.lease.protectors.length > 0) {
                choices.push(this.makeChoice('rent_RP', 'Relance garants'));
            }

            choices.push(this.makeChoice('rent_RL', 'Mise en demeure'));

            return choices;
        },
        previewUrl() {
            return '/api/lease/' + this.lease.id + '/reminder.html?model=' + this.model;
        }
    },
    data() {
        return {
            model: 'rent_R1'
        }
    },
    methods: {
        sendReminder() {
            this.load();
            this.$store.dispatch('lease/sendReminder', {id: this.lease.id, model: this.model})
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
