var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      attrs: {
        "button-label": "Arrêter la gestion",
        title: "Arrêt de la gestion du bien",
      },
      on: {
        act: function ($event) {
          return _vm.desactivate()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: {
              "min-date": _vm.mandateProperty.lastLeaseEnd
                ? _vm.mandateProperty.lastLeaseEnd
                : _vm.mandateProperty.startDate,
              required: true,
              label: "Date d'arrêt de gestion",
              name: "endDate",
            },
            model: {
              value: _vm.endDate,
              callback: function ($$v) {
                _vm.endDate = $$v
              },
              expression: "endDate",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }