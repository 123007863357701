<template>
    <imagine-modal @act="desactivate()"
                   @close="$emit('canceled')"
                   button-label="Arrêter la gestion"
                   title="Arrêt de la gestion du bien">
        <p>
            <imagine-date-picker
                :min-date="mandateProperty.lastLeaseEnd ? mandateProperty.lastLeaseEnd : mandateProperty.startDate"
                :required="true"
                class="imagine-form__row__field"
                label="Date d'arrêt de gestion"
                name="endDate"
                v-model="endDate"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';

export default {
    props: ['mandateProperty'],
    components: {ImagineModal, ImagineDatePicker},
    data() {
        return {
            endDate: this.$container.types.today()
        }
    },
    methods: {
        desactivate() {
            this.load();
            this.$store.dispatch('mandateProperty/desactivate', {id: this.mandateProperty.id, date: this.endDate})
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
