<template>
    <div class="imagine-alert"
         :class="{'imagine-alert--warning': type === 'warning', 'imagine-alert--info': type === 'info'}">
        <span class="material-icons">{{ type }}</span>
        <p class="imagine-alert__message">
            <slot/>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        type: {type: String, default: 'warning'}
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.imagine-alert {
    padding: 1rem;
    margin: .5rem 0;
    font-size: 1rem;

    display: flex;
    align-items: center;

    &__message {
        margin: 0 0 0 1rem;
    }

    &--warning {
        background: $imagine-error-color;
        color: #ffffff;
    }

    &--info {
        background: $mdc-theme-secondary;
        color: #ffffff;
    }

    a {
        color: #ffffff !important;
        text-decoration: underline !important;
    }
}
</style>
