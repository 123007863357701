<template>
    <div class="mailing-detail">
        <div @click.self="toggleStats()"
             class="mdc-list-item">
            <span @click="toggleStats()"
                  class="mdc-list-item__text">
                <span class="mdc-list-item__primary-text">
                    {{ mailing.subject }},
                    {{ mailing.recipients.length }} destinataire{{ mailing.recipients.length > 1 ? 's' : '' }} -
                    <small>"{{ mailing.body }}"</small>
                </span>
                <span class="mdc-list-item__secondary-text">
                    {{ by }}
                </span>
            </span>
            <span class="mdc-list-item__meta">
                <span v-if="mailing.sent">Envoyé, {{ formatMailingDate(mailing.sent) }}</span>
                <span v-else style="display: flex; align-items: center;">
                    <imagine-button @click="sendTest()"
                                    v-if="user.canTestMailings">
                        Me l'envoyer
                    </imagine-button>
                    <imagine-icon-button icon="delete"
                                         @click="showDeleteConfirmation = true"
                                         class="imagine-no-ripple"/>
                    <imagine-icon-button icon="send"
                                         class="imagine-no-ripple"
                                         @click="showSendConfirmation = true"/>
                </span>
            </span>
        </div>
        <transition name="slideY">
            <div v-show="showDetail">
                <div class="mailing-detail__preview">
                    <div class="mailing-detail__preview__section">
                        <strong>A :</strong>
                        <span v-if="mailing.recipients.length === 1">
                            {{ mailing.recipients[0].name }} <{{ mailing.recipients[0].email }}>
                        </span>
                        <template v-else>
                            {{ mailing.recipients.length }} destinataires -
                            <a @click.prevent="showRecipients = !showRecipients" href="#">
                                <span v-if="showRecipients">Masquer</span>
                                <span v-else>Voir</span>
                            </a>
                        </template>

                        <transition name="slideY">
                            <ul v-if="showRecipients && mailing.recipients.length > 1">
                                <li v-for="recipient in mailing.recipients">
                                    {{ recipient.name }} <{{ recipient.email }}>
                                </li>
                            </ul>
                        </transition>
                    </div>

                    <div class="mailing-detail__preview__section">
                        <strong>Objet:</strong> {{ mailing.subject }}
                    </div>

                    <div class="mailing-detail__preview__section" v-if="mailing.template">
                        <em>Template brevo</em>
                    </div>
                    <div class="mailing-detail__preview__section"
                         v-else
                         v-html="mailing.body.replace(/(?:\r\n|\r|\n)/g, '<br>')"></div>

                    <div class="mailing-detail__preview__section"
                         v-if="mailing.attachmentUrl">
                        <span class="material-icons">attach_file</span>
                        <a href="#" @click.prevent="download(mailing.attachmentUrl)">{{ mailing.attachmentName }}</a>
                    </div>
                </div>

                <div class="mailing-detail__stats" v-if="hasStats">
                    <div class="mailing-detail__stats__stat">
                        <span>{{ stats.uniqueViews ? stats.uniqueViews : 0 }}</span>
                        <strong>VUE{{ stats.uniqueViews > 1 ? 'S' : '' }}</strong>
                    </div>

                    <div class="mailing-detail__stats__stat">
                        <span>{{ stats.clickers ? stats.clickers : 0 }}</span>
                        <strong>CLIC{{ stats.clickers > 1 ? 'S' : '' }}</strong>
                    </div>

                    <div class="mailing-detail__stats__stat">
                        <span>{{ stats.hardBounces ? stats.hardBounces : 0 }}</span>
                        <strong>REBOND{{ stats.hardBounces > 1 ? 'S' : '' }}</strong>
                    </div>

                    <div class="mailing-detail__stats__stat">
                        <span>{{ stats.unsubscriptions ? stats.unsubscriptions : 0 }}</span>
                        <strong>DESINSCR.</strong>
                    </div>
                </div>
                <div class="mailing-detail__nostats" v-else>
                    Statistiques indisponibles.
                </div>
            </div>
        </transition>

        <imagine-modal @act="remove()"
                       @close="showDeleteConfirmation = false"
                       button-label="Supprimer"
                       v-if="showDeleteConfirmation">
            <p>Etes vous sûr de vouloir supprimer ce publipostage ?</p>
        </imagine-modal>

        <imagine-modal @act="send()"
                       @close="showSendConfirmation = false"
                       :button-label="'Envoyer à ' + mailing.recipients.length + ' contacts'"
                       v-if="showSendConfirmation">
            <p>Etes vous sûr de vouloir envoyer ce publipostage ?</p>
        </imagine-modal>
    </div>
</template>

<script>
import ImagineButton from '../../components/ImagineButton.vue';
import ImagineModal from '../../components/ImagineModal.vue';
import ImagineIconButton from '../../components/ImagineIconButton.vue';
import {mapState} from 'vuex';

export default {
    props: ['mailing'],
    components: {ImagineButton, ImagineModal, ImagineIconButton},
    computed: {
        ...mapState('user', {user: state => state.current}),
        by() {
            if (this.mailing.from) {
                let employee = this.mailing.from.shortname;

                return `Par ${employee}`;
            }

            return '';
        },
        canHaveStats() {
            return this.mailing.sendingblueId;
        },
        hasStats() {
            return this.stats && Object.keys(this.stats).length > 0;
        }
    },
    data() {
        return {
            showSendConfirmation: false,
            showDeleteConfirmation: false,
            showDetail: false,
            showRecipients: false,
            stats: null
        }
    },
    methods: {
        toggleStats() {
            this.showDetail = !this.showDetail;
            if (this.canHaveStats && this.showDetail && this.stats === null) {
                this.load();
                this.$store.dispatch('mailing/stats', {id: this.mailing.id})
                    .then(stats => this.stats = stats)
                    .catch(this.serverError)
                    .finally(this.unload);
            }
        },
        send() {
            this.load('Envoi email en cours');
            this.$store.dispatch('mailing/send', {id: this.mailing.id})
                .then(() => {
                    this.$store.dispatch('mailing/reloadAll')
                        .then(() => setTimeout(() => this.success('Envoi OK.'), 500))
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch((error) => {
                    this.serverError(error);
                    this.unload();
                });
        },
        sendTest() {
            this.load();
            this.$store.dispatch('mailing/sendTest', {id: this.mailing.id})
                .then(() => {
                    this.$store.dispatch('mailing/reloadAll')
                        .then(() => {
                            this.showDeleteConfirmation = false;
                            setTimeout(() => this.success(`Test envoyé sur ${this.user.email}.`), 500);
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch((error) => {
                    this.serverError(error);
                    this.unload();
                });
        },
        remove() {
            this.load();
            this.$store.dispatch('mailing/delete', {id: this.mailing.id})
                .then(() => {
                    this.$store.dispatch('mailing/reloadAll')
                        .then(() => {
                            this.showDeleteConfirmation = false;
                            setTimeout(() => this.success('Suppression OK.'), 500);
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch((error) => {
                    this.serverError(error);
                    this.unload();
                });
        },
        formatMailingDate(date) {
            const localDate = this.$container.types.toTAHT(date);

            if (this.$container.types.isToday(localDate)) {
                return this.$container.types.formatTime(localDate);
            }

            return this.$container.types.formatDateTime(localDate);
        },
        download(url) {
            if (url) {
                this.externalRedirectTo(url);
            }
        }
    }
}
</script>

<style lang="scss">
.mailing-detail {
    &__break {
        flex-basis: 100%;
        height: 0;
    }

    &__preview {
        background: #efefef;
        padding: .5rem;

        &__section {
            border-bottom: 1px dotted;
            padding: .5rem;
        }
    }

    &__stats {
        background: #efefef;
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;;

        &__stat {
            font-size: .85rem;
            border: 1px dotted;
            padding: 0 .5rem;
            margin: 0 .5rem;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    &__nostats {
        background: #efefef;
        text-align: center;
        font-size: 1.2rem;
        padding: 1rem;
    }
}
</style>
