class MandatePropertyRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all() {
        return this._http.get('/api/mandate-property')
            .then(mandateProperties => mandateProperties.map(mandatePropertyOverview => this._makeMandatePropertyOverview(mandatePropertyOverview)));
    }

    one(id) {
        return this._http.get('/api/mandate-property/' + id)
            .then(mandateProperty => this._makeMandatePropertyDetail(mandateProperty));
    }

    save(id, data) {
        return this._http.post('/api/mandate-property/' + id, data)
            .then(mandateProperty => this._makeMandatePropertyDetail(mandateProperty));
    }

    saveInsurance(id, data) {
        return this._http.post('/api/mandate-property/' + id + '/insurance', data);
    }

    desactivate(id, date) {
        return this._http.delete('/api/mandate-property/' + id, {date})
            .then(mandateProperty => this._makeMandatePropertyDetail(mandateProperty));
    }

    sendMail(url) {
        return this._http.post(url);
    }

    saveCustomEvent(data) {
        return this._http.post('/api/mandate-property/event', data);
    }

    _makeMandatePropertyOverview(mandatePropertyOverview) {
        mandatePropertyOverview.currentLease = this._makeLeaseOverview(mandatePropertyOverview.currentLease);
        mandatePropertyOverview.ongoingLease = this._makeLeaseOverview(mandatePropertyOverview.ongoingLease);
        mandatePropertyOverview.taxExemptionEnd = this._types.stringToDate(mandatePropertyOverview.taxExemptionEnd);

        return mandatePropertyOverview;
    }

    _makeMandatePropertyDetail(mandatePropertyDetail) {
        mandatePropertyDetail.startDate = this._types.stringToDate(mandatePropertyDetail.startDate);
        mandatePropertyDetail.endDate = this._types.stringToDate(mandatePropertyDetail.endDate);
        mandatePropertyDetail.lastLeaseEnd = this._types.stringToDate(mandatePropertyDetail.lastLeaseEnd);
        mandatePropertyDetail.logs = mandatePropertyDetail.logs.map(log => this._makeLog(log));
        mandatePropertyDetail.leases = mandatePropertyDetail.leases.map(lease => this._makeLeaseDetail(lease));
        mandatePropertyDetail.ownerAccount = this._makeAccount(mandatePropertyDetail.ownerAccount);

        return mandatePropertyDetail;
    }

    _makeLeaseOverview(lease) {
        if (!lease) {
            return null;
        }

        lease.rent = this._makeRent(lease.rent);
        lease.start = this._types.stringToDate(lease.start);
        lease.end = this._types.stringToDate(lease.end);

        return lease;
    }

    _makeLeaseDetail(lease) {
        lease = this._makeLeaseOverview(lease);
        lease.logs = lease.logs.map(log => this._makeLog(log));
        lease.date = this._types.stringToDate(lease.date);
        lease.guarantee = this._types.stringToMoney(lease.guarantee);
        lease.feesBase = this._types.stringToMoney(lease.feesBase);
        lease.tenantRentalFees = this._types.stringToMoney(lease.tenantRentalFees);
        lease.insuranceEndDate = this._types.stringToDate(lease.insuranceEndDate);
        lease.inventoryInDate = this._types.stringToDate(lease.inventoryInDate);
        lease.inventoryOutDate = this._types.stringToDate(lease.inventoryOutDate);
        lease.endNoticeDate = this._types.stringToDate(lease.endNoticeDate);
        lease.account = this._makeAccount(lease.account);

        return lease;
    }

    _makeLog(log) {
        log.date = this._types.stringToDate(log.date);

        return log;
    }

    _makeAccount(account) {
        account.balance = this._types.stringToMoney(account.balance);
        account.entries = account.entries.map(entry => this._makeEntry(entry));

        return account;
    }

    _makeEntry(entry) {
        entry.date = this._types.stringToDate(entry.date);
        entry.debit = this._types.stringToMoney(entry.debit);
        entry.credit = this._types.stringToMoney(entry.credit);
        entry.balance = this._types.stringToMoney(entry.balance);

        return entry;
    }

    _makeRent(rent) {
        rent.rent = this._types.stringToMoney(rent.rent);
        rent.charges = this._types.stringToMoney(rent.charges);
        rent.water = this._types.stringToMoney(rent.water);
        rent.waste = this._types.stringToMoney(rent.waste);
        rent.fullRent = this._types.stringToMoney(rent.fullRent);

        return rent;
    }
}

export default MandatePropertyRepository;
