import container from '../container';

const repo = container.repos.mandate;

export default {
    namespaced: true,
    state: {
        all: [],
        periodInfos: [],
        periods: [],
        bankEntries: [],
        nbSendRentAll: null,
        nbRepayOwnerAll: null,
        actorFilter: null,
        propertyFilter: null,
        withArchivesFilter: false,
        registry: null,
        actors: [],
        periodReports: []
    },
    mutations: {
        setActorFilter(state, actorFilter) {
            state.actorFilter = actorFilter;
        },
        setPropertyFilter(state, propertyFilter) {
            state.propertyFilter = propertyFilter;
        },
        setWithArchivesFilter(state, withArchives) {
            state.withArchivesFilter = withArchives;
        }
    },
    actions: {
        reloadRegistry(context) {
            return repo.registry()
                .then(registry => context.state.registry = registry);
        },
        reload(context) {
            return Promise.all([
                repo.all()
                    .then(mandates => context.state.all = mandates),
                repo.periodInfos()
                    .then(periodInfos => context.state.periodInfos = periodInfos)
            ]);
        },
        reloadPeriods(context) {
            return repo.periods()
                .then(periods => context.state.periods = periods);
        },
        touchAll({state, dispatch}) {
            if (!state.all || state.all.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        save(context, data) {
            return repo.save(data);
        },
        sendSummary(context, data) {
            return repo.sendSummary(data.mandate, data.month, data.year);
        },
        sendFeesEntries(context, data) {
            return repo.sendFeesEntries(data.mandate, data.month, data.year);
        },
        sendPaymentEntries(context, data) {
            return repo.sendPaymentEntries(data.mandate, data.month, data.year);
        },
        one(context, id) {
            return repo.one(id);
        },
        periodReports(context, data) {
            return repo.reports(data.month, data.year)
                .then(reports => context.state.periodReports = reports);
        }
    }
};
