<template>
</template>

<script>
export default {
    created() {
        this.redirectToHome();
    }
}
</script>
