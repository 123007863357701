var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "success",
        staticClass: "imagine-message imagine-message--success mdc-snackbar",
      },
      [
        _c("div", { staticClass: "mdc-snackbar__surface" }, [
          _c("div", { staticClass: "mdc-snackbar__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "error",
        staticClass: "imagine-message imagine-message--error mdc-snackbar",
      },
      [
        _c("div", { staticClass: "mdc-snackbar__surface" }, [
          _c("div", { staticClass: "mdc-snackbar__label" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mdc-snackbar__actions" }, [
      _c(
        "button",
        {
          staticClass: "mdc-button mdc-snackbar__action",
          attrs: { type: "button" },
        },
        [_vm._v("Fermer\n                ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mdc-snackbar__actions" }, [
      _c(
        "button",
        {
          staticClass: "mdc-button mdc-snackbar__action",
          attrs: { type: "button" },
        },
        [_vm._v("Fermer\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }