var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "sales",
      attrs: { title: "Administration des ventes" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "get_app" },
                on: {
                  click: function ($event) {
                    return _vm.exportSales()
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "search", icon: "search" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("search")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "list", icon: "filter_list" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("list")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "insights" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("salesStats")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _vm.mode === "search"
          ? _c(
              "div",
              { staticClass: "imagine-secondary-zone search__filters" },
              [
                _c(
                  "div",
                  { staticClass: "search__filters__search" },
                  [
                    _c("imagine-input", {
                      attrs: {
                        autocomplete: false,
                        clearable: true,
                        required: false,
                        icon: "search",
                        label: "Référence / mot clé",
                        name: "query",
                      },
                      model: {
                        value: _vm.query,
                        callback: function ($$v) {
                          _vm.query = $$v
                        },
                        expression: "query",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _vm.mode !== "search"
          ? _c(
              "div",
              { staticClass: "imagine-secondary-zone sales__filters" },
              [
                _c(
                  "div",
                  { staticClass: "sales__filters__filter" },
                  [
                    _c("imagine-select", {
                      attrs: {
                        choices: _vm.references.groupedBlockChoices(
                          _vm.blocks,
                          ["ongoing", "completed"]
                        ),
                        label: "Résidence",
                        name: "block",
                      },
                      model: {
                        value: _vm.block,
                        callback: function ($$v) {
                          _vm.block = $$v
                        },
                        expression: "block",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "sales__filters__filter" },
                  [
                    _c("imagine-select", {
                      attrs: {
                        choices: _vm.saleStatusChoices,
                        "implicit-required": true,
                        multiple: true,
                        label: "Statuts",
                        name: "statuses",
                      },
                      model: {
                        value: _vm.statuses,
                        callback: function ($$v) {
                          _vm.statuses = $$v
                        },
                        expression: "statuses",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "sales__filters__filter sales__filters__filter--statuses",
                  },
                  [
                    _c("imagine-select", {
                      attrs: {
                        choices: _vm.saleStepsChoices,
                        "implicit-required": true,
                        multiple: true,
                        label: "Etapes",
                        name: "steps",
                      },
                      model: {
                        value: _vm.steps,
                        callback: function ($$v) {
                          _vm.steps = $$v
                        },
                        expression: "steps",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.canFilterSalesRep()
                  ? _c(
                      "div",
                      { staticClass: "sales__filters__filter" },
                      [
                        _c("imagine-select", {
                          attrs: {
                            choices: _vm.lastUpdateChoices,
                            label: "Dernière modification",
                          },
                          model: {
                            value: _vm.lastUpdate,
                            callback: function ($$v) {
                              _vm.lastUpdate = $$v
                            },
                            expression: "lastUpdate",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canFilterSalesRep()
                  ? _c(
                      "div",
                      { staticClass: "sales__filters__filter" },
                      [
                        _c("imagine-select", {
                          attrs: {
                            choices: _vm.references.salesRepChoices(
                              _vm.employees
                            ),
                            label: "Négociateur",
                          },
                          model: {
                            value: _vm.salesRep,
                            callback: function ($$v) {
                              _vm.salesRep = $$v
                            },
                            expression: "salesRep",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sales__count",
          class: { "sales__count--filtered": _vm.nbFiltered < _vm.nbTotal },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 0,
                  expression: "nbFiltered > 0",
                },
              ],
              staticClass: "sales__count__count",
            },
            [
              _vm.nbFiltered < _vm.nbTotal
                ? _c("span", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatNumber(_vm.nbFiltered)) +
                        " /\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.formatNumber(_vm.nbTotal)))]),
              _vm._v(
                "\n            vente" +
                  _vm._s(_vm.nbFiltered > 1 ? "s" : "") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered <= 0,
                  expression: "nbFiltered <= 0",
                },
              ],
            },
            [_vm._v("\n            Aucune vente trouvé.\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 1,
                  expression: "nbFiltered > 1",
                },
              ],
              staticClass: "sales__count__sort",
            },
            [_vm._v("\n            Trié par résidence/lot\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.displayed.length > 0
        ? [
            _c(
              "div",
              { staticClass: "mdc-list mdc-list--two-line sales__list" },
              [
                _vm._l(_vm.displayed, function (sale) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "mdc-list-item sales__list__item",
                        class: {
                          "sales__list__item--canceled": sale.isCanceled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.redirectTo("sale", { id: sale.id })
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "mdc-list-item__graphic" },
                          [_c("sale-status", { attrs: { sale: sale } })],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "mdc-list-item__text" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "mdc-list-item__primary-text sales__list__item__title",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(sale.property) +
                                  "\n                            " +
                                  _vm._s(sale.taxExemption ? "[défisc]" : "") +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "mdc-list-item__secondary-text sales__list__item__owner",
                            },
                            [
                              sale.buyers.length > 0
                                ? [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(sale.buyers.join(", ")) +
                                        "\n                                "
                                    ),
                                    sale.salesRep
                                      ? [
                                          _vm._v(
                                            "\n                                    (" +
                                              _vm._s(sale.salesRep) +
                                              " " +
                                              _vm._s(sale.otherSalesRep) +
                                              ")\n                                "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : [_vm._v("Pas d'acquéreur(s)")],
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mdc-list-item__meta" }, [
                          _vm._v(_vm._s(_vm.stepLabel(sale.step))),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mdc-list-divider" }),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDisplayLimited,
                    expression: "isDisplayLimited",
                  },
                ],
                staticClass: "sales__see-more",
                on: {
                  click: function ($event) {
                    return _vm.displayMore()
                  },
                },
              },
              [_c("imagine-button", [_vm._v("Voir plus de biens")])],
              1
            ),
          ]
        : _c("div", { staticClass: "sales__none" }, [
            _vm._v("\n        Aucune vente avec ces critères.\n    "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }