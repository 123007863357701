var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "md-datepicker",
            {
              staticClass: "imagine-datepicker",
              class: {
                "imagine-datepicker--dense": _vm.dense,
                "md-invalid": !!_vm.violation,
              },
              attrs: {
                "md-disabled-dates": _vm.disabledDates,
                required: _vm.required,
                "md-clearable": !_vm.required,
                "md-immediately": "",
              },
              on: {
                input: function ($event) {
                  _vm.$emit("input", _vm._useDate(_vm.innerValue))
                },
              },
              model: {
                value: _vm.innerValue,
                callback: function ($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue",
              },
            },
            [
              _c("label", [
                _vm._v(_vm._s(_vm.label) + " "),
                _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.violation
                ? _c("span", { staticClass: "md-error" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.violation) + "\n        "
                    ),
                  ])
                : _vm.help
                ? _c("span", { staticClass: "md-helper-text" }, [
                    _vm._v("\n            " + _vm._s(_vm.help) + "\n        "),
                  ])
                : _vm._e(),
            ]
          )
        : _c("imagine-input", {
            attrs: {
              disabled: true,
              label: _vm.label,
              name: _vm.name,
              required: _vm.required,
              help: _vm.help,
            },
            model: {
              value: _vm.innerValue,
              callback: function ($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }