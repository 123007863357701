<template>
    <md-switch @change="$emit('input', innerValue)"
               :class="{'imagine-switch--disabled': disabled}"
               :disabled="disabled"
               class="imagine-switch md-primary"
               v-model="innerValue">
        <slot/>
    </md-switch>
</template>

<script>
export default {
    props: ['value', 'disabled'],
    data() {
        return {
            innerValue: this.value
        }
    },
    watch: {
        value(newVal) {
            this.innerValue = newVal;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.imagine-switch {
    &--disabled {
        color: grey;
    }
}
</style>
