class MandateRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    registry() {
        return this._http.get('/api/mandate/registry')
            .then(registry => this._makeRegistry(registry));
    }

    all() {
        return this._http.get('/api/mandate')
            .then(mandates => mandates.map((mandate) => this._makeMandateOverview(mandate)));
    }

    periodInfos() {
        return this._http.get('/api/mandate/period-info');
    }

    periods() {
        return this._http.get('/api/mandate/period');
    }

    one(id) {
        return this._http.get('/api/mandate/' + id)
            .then(mandateDetail => this._makeMandateDetail(mandateDetail));
    }

    save(data) {
        return this._http.post('/api/mandate' + (data.id ? '/' + data.id : ''), data)
            .then(mandateDetail => this._makeMandateDetail(mandateDetail));
    }

    sendSummary(mandateId, month, year) {
        return this._http.post(this._addPeriod('/api/mandate/' + mandateId + '/summary/send', month, year));
    }

    sendFeesEntries(mandateId, month, year) {
        return this._http.post(this._addPeriod('/api/mandate/' + mandateId + '/summary/fees', month, year));
    }

    sendPaymentEntries(mandateId, month, year) {
        return this._http.post(this._addPeriod('/api/mandate/' + mandateId + '/summary/payment', month, year));
    }

    reports(month, year) {
        return this._http.get(this._addPeriod('/api/mandate/report', month, year))
            .then(reports => this._makeReports(reports));
    }

    _addPeriod(url, month, year) {
        return url + (url.includes('?') ? '&' : '?') + 'month=' + month + '&year=' + year;
    }

    _makeRegistry(registry) {
        registry.lastDate = this._types.stringToDate(registry.lastDate);

        return registry;
    }

    _makeMandateOverview(mandate) {
        mandate.startDate = this._types.stringToDate(mandate.startDate);
        mandate.endDate = this._types.stringToDate(mandate.endDate);
        mandate.legalEndDate = this._types.stringToDate(mandate.legalEndDate);

        return mandate;
    }

    _makeMandateDetail(mandate) {
        mandate.date = this._types.stringToDate(mandate.date);
        mandate.startDate = this._types.stringToDate(mandate.startDate);
        mandate.endDate = this._types.stringToDate(mandate.endDate);
        mandate.legalEndDate = this._types.stringToDate(mandate.legalEndDate);
        mandate.feesStartDate = this._types.stringToDate(mandate.feesStartDate);
        mandate.account = this._makeAccount(mandate.account);
        mandate.mandateProperties = mandate.mandateProperties.map(mandateProperty => this._makeMandateProperty(mandateProperty));
        mandate.summaries = mandate.summaries.map(summary => this._makeSummary(summary));

        return mandate;
    }

    _makeAccount(account) {
        account.balance = this._types.stringToMoney(account.balance);
        account.entries = account.entries.map(entry => this._makeEntry(entry));

        return account;
    }

    _makeSummary(summary) {
        summary.lastSent = this._types.stringToDate(summary.lastSent);

        return summary;
    }

    _makeEntry(entry) {
        entry.date = this._types.stringToDate(entry.date);
        entry.debit = this._types.stringToMoney(entry.debit);
        entry.credit = this._types.stringToMoney(entry.credit);

        if (entry.balance) {
            entry.balance = this._types.stringToMoney(entry.balance);
        }

        return entry;
    }

    _makeMandateProperty(mandateProperty) {
        mandateProperty.insuranceEndDate = this._types.stringToDate(mandateProperty.insuranceEndDate);
        mandateProperty.ongoingLease = this._makeLease(mandateProperty.ongoingLease);
        mandateProperty.currentLease = this._makeLease(mandateProperty.currentLease);
        mandateProperty.leases = mandateProperty.leases.map(lease => this._makeLease(lease));

        return mandateProperty;
    }

    _makeLease(lease) {
        if (!lease) {
            return null;
        }

        lease.start = this._types.stringToDate(lease.start);
        lease.end = this._types.stringToDate(lease.end);

        lease.rent = this._makeRent(lease.rent);

        return lease;
    }

    _makeReports(reports) {
        reports.rents = reports.rents.map(report => this._makeRentReport(report));
        reports.guarantees = reports.guarantees.map(report => this._makeGuaranteeReport(report));
        reports.rentalFees = reports.rentalFees.map(report => this._makeRentalFeesReport(report));
        reports.repays = reports.repays.map(report => this._makeRepaysReport(report));
        reports.unpaid = reports.unpaid.map(report => this._makeUnpaidReport(report));

        return reports;
    }

    _makeRentReport(report) {
        report.rent = this._makeRent(report.rent);

        return report;
    }

    _makeRent(rent) {
        rent.rent = this._types.stringToMoney(rent.rent);
        rent.charges = this._types.stringToMoney(rent.charges);
        rent.water = this._types.stringToMoney(rent.water);
        rent.waste = this._types.stringToMoney(rent.waste);
        rent.fullRent = this._types.stringToMoney(rent.fullRent);

        return rent;
    }

    _makeGuaranteeReport(report) {
        report.guarantee = this._types.stringToMoney(report.guarantee);

        return report;
    }

    _makeRentalFeesReport(report) {
        report.rentalFees = this._types.stringToMoney(report.rentalFees);

        return report;
    }

    _makeRepaysReport(report) {
        report.repayAmount = this._types.stringToMoney(report.repayAmount);
        report.repayDate = this._types.stringToDate(report.repayDate);

        return report;
    }

    _makeUnpaidReport(report) {
        report.lastReminderEvents = report.lastReminderEvents.map(event => {
            event.date = this._types.stringToDate(event.date);

            return event;
        });
        report.balance = this._types.stringToMoney(report.balance);
        report.entries = report.entries.map(entry => this._makeEntry(entry));

        return report;
    }
}

export default MandateRepository;
