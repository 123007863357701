var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-modal--wide",
      attrs: {
        "button-disabled": !_vm.endDate,
        "button-label": "Enregistrer",
        title: "Pré-avis de résiliation du bail",
      },
      on: {
        act: function ($event) {
          return _vm.leaseEndNotice()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: {
              "min-date": _vm.lease.start,
              label: "Date de réception du pré-avis",
              name: "noticeDate",
            },
            model: {
              value: _vm.noticeDate,
              callback: function ($$v) {
                _vm.noticeDate = $$v
              },
              expression: "noticeDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: {
              "min-date": _vm.lease.start,
              required: true,
              label: "Date de sortie",
              name: "feesDate",
            },
            model: {
              value: _vm.endDate,
              callback: function ($$v) {
                _vm.endDate = $$v
              },
              expression: "endDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-file-input", {
            staticClass: "imagine-form__row__field",
            attrs: {
              label: "Courrier pré-avis (doc)",
              name: "url",
              required: true,
              accept: "application/pdf",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }