var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mdc-list-item contacts__contact",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.gotoContact()
        },
      },
    },
    [
      _c(
        "span",
        { staticClass: "mdc-list-item__graphic" },
        [
          _c("imagine-checkbox", {
            attrs: {
              disabled: !_vm.canBeExported,
              value: _vm.isContactChecked,
              title: !_vm.canBeExported ? _vm.exportableDesc : null,
            },
            on: {
              input: function ($event) {
                $event ? _vm.checkContact() : _vm.uncheckContact()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "mdc-list-item__text",
          on: {
            click: function ($event) {
              return _vm.gotoContact()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "mdc-list-item__primary-text" },
            [
              _vm.contact.firstname || _vm.contact.lastname
                ? [
                    _vm.contact.lastname
                      ? _c(
                          "span",
                          { staticClass: "contacts__contact__lastname" },
                          [_vm._v(_vm._s(_vm.contact.lastname))]
                        )
                      : _vm._e(),
                    _vm.contact.firstname && _vm.contact.lastname
                      ? _c("span", [_vm._v(",")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contact.firstname
                      ? _c(
                          "span",
                          { staticClass: "contacts__contact__firstname" },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.contact.firstname) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contact.company
                      ? _c("span", [
                          _vm._v(
                            "\n                    (" +
                              _vm._s(_vm.contact.company) +
                              ")\n                "
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm.contact.company
                ? _c("span", { staticClass: "contacts__contact__company" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.contact.company) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("span", { staticClass: "mdc-list-item__secondary-text" }, [
            _vm.contact.email
              ? _c("span", [_vm._v(_vm._s(_vm.contact.email))])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "mdc-list-item__meta",
          on: {
            click: function ($event) {
              return _vm.gotoContact()
            },
          },
        },
        [_vm.contact.isClient ? _c("span", [_vm._v("Client")]) : _vm._e()]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }