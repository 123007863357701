<template>
    <div class="imagine-map" ref="mapContainer"></div>
</template>

<script>
import {Control, CRS, DivIcon, LayerGroup, Map, Marker, TileLayer} from 'leaflet';

export default {
    props: ['isShown', 'zoom', 'center', 'editablePlace', 'places'],
    data() {
        return {
            _map: null,
            _editableMarker: null,
            _placesLayer: null
        }
    },
    watch: {
        isShown(newVal) {
            if (newVal) {
                this._map.invalidateSize();
            }
        },
        center(newVal) {
            this._map.setView(this.isPosValid(newVal) ? newVal : this.defaultCenter(), this.zoom ? this.zoom : this.defaultZoom());
        },
        markerPos(newVal) {
            this._editableMarker.setLatLng(this.isPosValid(newVal) ? newVal : this.defaultCenter());
        },
        editablePlace(newEditablePlace) {
            if (this.isPosValid(newEditablePlace.position)) {
                this._editableMarker.setLatLng(newEditablePlace.position);
            }
        },
        places(newPlaces) {
            this._refreshPlaces(newPlaces);
        }
    },
    mounted() {
        const center = this.isPosValid(this.center) ? this.center : this.defaultCenter();

        const teFenua = new TileLayer(
            `https://www.tefenua.gov.pf/api/wmts?layer=TEFENUA%3AFOND&style=&tilematrixset=EPSG%3A4326&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix=EPSG%3A4326%3A{z}&TileCol={x}&TileRow={y}`,
            {
                minZoom: 0,
                maxZoom: 18,
                attribution: '&copy; <a href="https://www.tefenua.gov.pf">Te Fenua</a>',
                tileSize: 256
            }
        );

        const cadastre = new TileLayer(
            `https://www.tefenua.gov.pf/api/wmts?layer=TEFENUA%3ACADASTRE&style=&tilematrixset=EPSG%3A4326&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A4326%3A{z}&TileCol={x}&TileRow={y}`,
            {
                minZoom: 0,
                maxZoom: 18,
                attribution: '&copy; <a href="https://www.tefenua.gov.pf">Te Fenua</a>',
                tileSize: 256
            }
        );

        const satellite = new TileLayer(
            `https://www.tefenua.gov.pf/api/wmts?layer=TEFENUA%3AIMAGE_PUBLIQUE&style=&tilematrixset=EPSG%3A4326&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng8&TileMatrix=EPSG%3A4326%3A{z}&TileCol={x}&TileRow={y}`,
            {
                minZoom: 0,
                maxZoom: 18,
                attribution: '&copy; <a href="https://www.tefenua.gov.pf">Te Fenua</a>',
                tileSize: 256
            }
        );

        this._map = new Map(this.$refs.mapContainer, {
            crs: CRS.EPSG4326,
            center: center,
            zoom: this.zoom ? this.zoom : this.defaultZoom(),
            layers: [teFenua]
        });

        new Control.Layers({}, {'Cadastre': cadastre, 'Satellite': satellite}).addTo(this._map);

        this._map.on('zoomend', () => this.$emit('zoom', this._map.getZoom()));
        this._map.on('moveend', () => this.$emit('centerMove', this._map.getCenter()));

        if (this.editablePlace) {
            this._editableMarker = this._getDraggableMarker(this.editablePlace, center)
                .addTo(this._map);
            this._editableMarker.on('moveend', () => this.$emit('editablePlaceMove', this._editableMarker.getLatLng()));
        }

        this._placesLayer = new LayerGroup().addTo(this._map);

        if (this.places) {
            this._refreshPlaces(this.places);
        }
    },
    methods: {
        isPosValid(center) {
            return center && center[0];
        },
        defaultCenter() {
            return this._papeeteCenter();
        },
        defaultZoom() {
            return 12;
        },
        _icon(name) {
            return new DivIcon({
                className: 'imagine-map-icon',
                html: `<div class="imagine-map-icon__marker"></div><i class="material-icons">${name}</i>`,
                iconSize: [30, 42],
                iconAnchor: [15, 42]
            });
        },
        _papeeteCenter() {
            return [-17.5516251, -149.5584758];
        },
        _refreshPlaces(places) {
            this._placesLayer.clearLayers();

            if (places.length <= 0) {
                this._map.setView(this.defaultCenter(), this.defaultZoom());

                return;
            }

            let positions = [];

            places.filter(place => this.isPosValid(place.position))
                .map(place => {
                    positions.push(place.position);

                    return this._getClickableMarker(place)
                })
                .forEach(marker => marker.addTo(this._placesLayer));

            this._map.fitBounds(positions);
        },
        _getClickableMarker(place, defaultPosition = null) {
            return this._getMarker(place, defaultPosition)
                .bindTooltip(place.description)
                .on('click', place.redirectHandler);
        },
        _getDraggableMarker(place, defaultPosition = null) {
            let marker = this._getMarker(place, defaultPosition);

            if (!marker) {
                return null;
            }

            marker.options.draggable = true;

            return marker;
        },
        _getMarker(place, defaultPosition = null) {
            let markerPos = this.isPosValid(place.position) ? place.position : null;

            if (!markerPos) {
                markerPos = this.isPosValid(defaultPosition) ? defaultPosition : null;
            }

            if (markerPos) {
                return new Marker(markerPos, {icon: this._icon(place.icon)});
            }

            return null;
        }
    }
}
</script>

<style lang="scss">
@import '~leaflet';
@import '../scss/config';

.imagine-map {
    height: 400px;
    width: 100%;
    z-index: 0;
}

.imagine-map-icon__marker {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: $mdc-theme-primary;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
}

.imagine-map-icon__marker::after {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #ffffff;
    position: absolute;
    border-radius: 50%;
}

.imagine-map-icon i {
    position: absolute;
    width: 22px;
    font-size: 22px;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
}
</style>
