<template>
    <div :class="{'imagine-form__complex-field--show': show, 'imagine-form__complex-field--disabled': disabled}"
         class="imagine-form__complex-field">
        <div @click="show ? $emit('close') : $emit('open')"
             class="imagine-form__complex-field__header">
            <h6 class="imagine-form__complex-field__header__title">{{ title }}</h6>
            <span class="material-icons">{{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</span>
        </div>

        <transition name="slideY">
            <div class="imagine-form__complex-field__body" v-show="show">
                <slot/>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        show: Boolean,
        disabled: Boolean
    }
}
</script>

<style lang="scss">
.imagine-form__complex-field {
    padding: .5rem;

    &--show {
        .imagine-form__complex-field__header {
            border: 1px dotted rgba(0, 0, 0, .42);
        }
    }

    &__header {
        color: rgba(0, 0, 0, 0.6);
        background-color: whitesmoke;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px dotted rgba(0, 0, 0, .42);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &__title {
            margin: 0;
            font-size: 1rem;
            font-weight: normal;
        }
    }

    &--disabled {
        .imagine-form__complex-field__header {
            color: rgba(0, 0, 0, 0.3);
        }
    }

    &__body {
        border-radius: 0 0 4px 4px;
        border: 1px dotted rgba(0, 0, 0, .42);
        border-top: 0;
        padding: .5rem;
    }
}
</style>
