var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mdc-form-field imagine-checkbox",
      class: { "imagine-checkbox--disabled": _vm.disabled },
    },
    [
      _c(
        "div",
        {
          staticClass: "mdc-checkbox",
          class: { "mdc-checkbox--disabled": _vm.disabled },
        },
        [
          _c("input", {
            staticClass: "mdc-checkbox__native-control",
            attrs: {
              id: _vm.name,
              name: _vm.name,
              type: "checkbox",
              disabled: _vm.disabled,
              value: "1",
            },
            domProps: { checked: _vm.value },
            on: {
              change: function ($event) {
                return _vm.$emit("input", !_vm.value)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-checkbox__background" }, [
            _c(
              "svg",
              {
                staticClass: "mdc-checkbox__checkmark",
                attrs: { viewBox: "0 0 24 24" },
              },
              [
                _c("path", {
                  staticClass: "mdc-checkbox__checkmark-path",
                  attrs: {
                    d: "M1.73,12.91 8.1,19.28 22.79,4.59",
                    fill: "none",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mdc-checkbox__mixedmark" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-checkbox__ripple" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "imagine-checkbox__label",
          class: { "imagine-checkbox__label--disabled": _vm.disabled },
          attrs: { for: _vm.name },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }