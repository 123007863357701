class MailingRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all() {
        return this._http.get('/api/mailing')
            .then(mailings => mailings.map(mailing => this._makeMailing(mailing)));
    }

    templates() {
        return this._http.get('/api/mailing/template');
    }

    quota() {
        return this._http.get('/api/mailing/quota');
    }

    create(mailing) {
        return this._http.post('/api/mailing', mailing);
    }

    send(mailingId) {
        return this._http.post('/api/mailing/' + mailingId + '/send');
    }

    sendTest(mailingId) {
        return this._http.post('/api/mailing/' + mailingId + '/test');
    }

    delete(mailingId) {
        return this._http.delete('/api/mailing/' + mailingId);
    }

    stats(mailingId) {
        return this._http.get('/api/mailing/' + mailingId + '/stat');
    }

    _makeMailing(mailing) {
        mailing.sent = this._types.stringToDate(mailing.sent);

        return mailing;
    }
}

export default MailingRepository;
