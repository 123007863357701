var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "activities",
      attrs: { title: "Activité" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.filter, icon: "filter_list" },
                on: {
                  click: function ($event) {
                    _vm.filter = !_vm.filter
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "refresh" },
                on: { click: _vm.pull },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "slideY" } },
        [
          _vm.filter
            ? [
                _c(
                  "div",
                  { staticClass: "imagine-secondary-zone activities__filters" },
                  [
                    _c(
                      "div",
                      { staticClass: "activities__filters__filter" },
                      [
                        _c("imagine-select", {
                          attrs: {
                            choices: _vm.periodChoices,
                            label: "Période",
                            name: "periodfilter",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.changePeriod()
                            },
                          },
                          model: {
                            value: _vm.period,
                            callback: function ($$v) {
                              _vm.period = $$v
                            },
                            expression: "period",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "activities__filters__filter" },
                      [
                        _c("imagine-select", {
                          attrs: {
                            choices: _vm.employeeChoices,
                            label: "Employé",
                            name: "employeefilter",
                          },
                          model: {
                            value: _vm.employee,
                            callback: function ($$v) {
                              _vm.employee = $$v
                            },
                            expression: "employee",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.displayedEvents.length > 0
        ? _c("timeline", { attrs: { events: _vm.displayedEvents } })
        : _c("div", { staticClass: "activities__none" }, [
            _vm._v(
              "\n        Aucune activité depuis " +
                _vm._s(_vm.periodLabel) +
                " " +
                _vm._s(_vm.employee ? "pour cet employé" : "") +
                ".\n    "
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }