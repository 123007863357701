var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "imagine-file",
      class: { "imagine-file--rounded": _vm.rounded },
    },
    [
      _vm.showCropper
        ? _c(
            "imagine-modal",
            {
              attrs: {
                "button-label": "Redimensionner",
                title: "Redimensionnement image",
              },
              on: { close: _vm.cancelCropper, act: _vm.process },
            },
            [
              _c("cropper", {
                attrs: {
                  src: _vm.url,
                  "stencil-props": { aspectRatio: _vm.aspectRatio },
                  stencilComponent: _vm.rounded
                    ? _vm.$options.components.CircleStencil
                    : _vm.$options.components.RectangleStencil,
                },
                on: {
                  change: function ($event) {
                    _vm.coordinates = $event.coordinates
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.previewUrl || _vm.previewIcon) && !_vm.showCropper
        ? _c(
            "div",
            { staticClass: "imagine-file__preview" },
            [
              _vm.previewUrl
                ? _c("img", {
                    attrs: { src: _vm.previewUrl },
                    on: {
                      click: function ($event) {
                        return _vm.download()
                      },
                    },
                  })
                : _vm.previewIcon
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "material-icons-outlined",
                        on: {
                          click: function ($event) {
                            return _vm.download()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.previewIcon) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.download()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.label) + "." + _vm._s(_vm.valueExtension)
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.disabled
                ? _c("imagine-icon-button", {
                    staticClass: "imagine-no-ripple",
                    attrs: { icon: "delete" },
                    on: {
                      click: function ($event) {
                        return _vm.removeImage()
                      },
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "md-field",
        {
          staticClass: "imagine-file__input",
          class: {
            "imagine-file__input--error":
              (_vm.errors && _vm.errors.global) || _vm.violation,
          },
        },
        [
          _c("label", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _c("md-file", {
            attrs: {
              accept: _vm.accept,
              disabled: _vm.disabled,
              required: _vm.required,
            },
            on: { "md-change": _vm.upload },
            model: {
              value: _vm.inner,
              callback: function ($$v) {
                _vm.inner = $$v
              },
              expression: "inner",
            },
          }),
          _vm._v(" "),
          _vm.help
            ? _c("span", { staticClass: "md-helper-text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.help) +
                    " " +
                    _vm._s(_vm.violation) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }