<template>
    <imagine-layout :inner="true" :title="title" class="sale-payments">
        <template v-slot:actions>
            <template v-if="$mq !== 'phone'">
                <imagine-icon-button class="mdc-top-app-bar__action-item"
                                     @click="redirectTo('property', {id: salePayments.sale.propertyId})"
                                     title="Fiche bien"
                                     icon="home_work"
                                     v-if="salePayments"/>
                <imagine-icon-button class="mdc-top-app-bar__action-item"
                                     @click="redirectTo('block', {id: salePayments.sale.blockId})"
                                     title="Fiche résidence"
                                     icon="business"
                                     v-if="salePayments"/>
                <imagine-icon-button @click="externalRedirectTo('https://www.socredo.pf/connexion/auth/login')"
                                     class="mdc-top-app-bar__action-item"
                                     v-if="false"
                                     svg="socredo"/>
            </template>
            <imagine-icon-button :disabled="!canSendMail"
                                 @click="openMail()"
                                 class="mdc-top-app-bar__action-item"
                                 icon="email"/>
        </template>

        <div class="imagine-container" v-if="salePayments">
            <div class="sale-payments__header">
                <div class="sale-payments__header__info">
                    <b>Acheteur{{ salePayments.sale.buyers.length > 1 ? 's' : '' }} :</b>
                    <span>{{ salePayments.sale.buyers.length > 0 ? salePayments.sale.buyers.join(', ') : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info">
                    <b>Défisc :</b>
                    <span>{{ salePayments.sale.taxExemption ? references.getLabel('taxExemptions', salePayments.sale.taxExemption) : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info">
                    <b>Négociateur{{ salePayments.salesReps.length > 1 ? 's' : '' }} :</b>
                    <span>{{ salePayments.salesReps.length > 0 ? salePayments.salesReps.join(', ') : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info">
                    <b>Signature acte vente :</b>
                    <span>{{ salePayments.saleDate ? formatDate(salePayments.saleDate) : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info">
                    <hr/>
                </div>
                <div class="sale-payments__header__info sale-payments__header__info--price">
                    <b>Prix d'achat :</b>
                    <span>{{ salePayments.price ? formatMoney(salePayments.price) : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info sale-payments__header__info--price">
                    <b>Prix parking :</b>
                    <span>{{ salePayments.parkingPrice ? formatMoney(salePayments.parkingPrice) : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info sale-payments__header__info--price">
                    <b>Remise :</b>
                    <span>{{ salePayments.discount ? formatMoney(salePayments.discount.multiply(-1)) : 'N/A' }}</span>
                </div>
                <div class="sale-payments__header__info sale-payments__header__info--price"
                     v-if="salePayments.withLawyerFees">
                    <b>Frais notaire (défisc.) :</b>
                    <span>{{ salePayments.lawyerFees ? formatMoney(salePayments.lawyerFees) : 'N/A' }}</span>
                </div>
                <div
                    class="sale-payments__header__info sale-payments__header__info--price sale-payments__header__info--total">
                    <b>Total à payer :</b>
                    <span>{{ salePayments.expected ? formatMoney(salePayments.expected) : 'N/A' }}</span>
                </div>
            </div>

            <div class="imagine-table-container"
                 v-if="salePayments.milestones.length > 0">
                <table class="imagine-table">
                    <thead>
                    <tr class="imagine-table__row">
                        <th class="imagine-table__row__cell"></th>
                        <th class="imagine-table__row__cell">
                            <imagine-button v-if="!hasOnePaymentOnMilestone"
                                            @click="toggleRegroup()">
                                {{ (salePayments && salePayments.areMilestonesRegrouped) ? 'Dégrouper' : 'Grouper' }}
                            </imagine-button>
                        </th>
                        <th class="imagine-table__row__cell">Part</th>
                        <th class="imagine-table__row__cell">A payer</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--date">Date d'échéance</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--date">Date de paiement</th>
                        <th class="imagine-table__row__cell">Payé</th>
                        <th class="imagine-table__row__cell">Reste à payer (ou frais de notaire)</th>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="imagine-table__row"
                        v-for="milestone in salePayments.milestones">
                        <td class="imagine-table__row__cell">
                            <span :class="{
                                'imagine-status--sold': milestone.isDone,
                                'imagine-status--error': milestone.isLate,
                                'imagine-status--danger': milestone.isClose,
                                'imagine-status--success': !milestone.isDone && !milestone.isLate && !milestone.isClose}"
                                  class="material-icons imagine-status">
                                lens
                            </span>
                        </td>
                        <td class="imagine-table__row__cell">{{ milestone.label }}</td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount">
                            {{ milestone.progress }}%
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount">
                            {{ formatMoney(milestone.expectedAmount) }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                            {{ formatDate(milestone.expectedDate) }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                            <template v-for="(payment, index) in milestone.payments">
                                <br v-if="index > 0">
                                <span>
                                    <a href="#" @click.prevent="updatePayment(payment)">
                                        {{ formatDate(payment.date) }}
                                    </a>
                                    <md-tooltip md-direction="right" v-if="payment.note">{{ payment.note }}</md-tooltip>
                                </span>
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount">
                            <template v-for="(payment, index) in milestone.payments">
                                <br v-if="index > 0">
                                <span>
                                    <a href="#" @click.prevent="updatePayment(payment)">
                                        {{ formatMoney(payment.amount) }}
                                    </a>
                                    <md-tooltip md-direction="right" v-if="payment.note">{{ payment.note }}</md-tooltip>
                                </span>
                            </template>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount">
                            {{ formatMoney(milestone.due) }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--actions">
                            <imagine-icon-button class="imagine-no-ripple"
                                                 @click="addPayment(milestone)"
                                                 icon="add_task"/>
                            <imagine-icon-button class="imagine-no-ripple"
                                                 @click="editDoc(milestone)"
                                                 svg="word"/>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="imagine-table__row">
                        <th class="imagine-table__row__cell"></th>
                        <th class="imagine-table__row__cell">TOTAL</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--amount">
                            {{ salePayments.totalProgress }}%
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--amount">
                            {{ formatMoney(salePayments.expected) }}
                        </th>
                        <th class="imagine-table__row__cell"></th>
                        <th class="imagine-table__row__cell"></th>
                        <th class="imagine-table__row__cell">
                            {{ formatMoney(salePayments.paid) }}
                        </th>
                        <th class="imagine-table__row__cell">
                            {{ formatMoney(salePayments.due) }}
                        </th>
                        <th></th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="imagine-container"
                 v-else>
                <div class="sale-payments__none">Planning introuvable pour la résidence ou le bâtiment.</div>
            </div>
        </div>

        <imagine-modal v-if="showPaymentModal"
                       :title="paymentId ? 'Modification encaissement' : 'Ajout encaissement'"
                       @act="saveSalePayment()"
                       @close="showPaymentModal = false"
                       button-label="Sauvegarder">
            <p>
                <imagine-money-input class="imagine-form__row__field"
                                     label="Montant"
                                     name="amount"
                                     v-model="amount"/>
            </p>

            <p>
                <imagine-date-picker :min-date="salePayments.saleDate"
                                     class="imagine-form__row__field"
                                     label="Date"
                                     name="date"
                                     v-model="date"/>
            </p>

            <p>
                <imagine-textarea name="note"
                                  v-model="note"
                                  label="Commentaire"
                                  class="imagine-form__row__field"/>
            </p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineMoneyInput from '../components/ImagineMoneyInput.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import {mapState} from 'vuex';
import ImagineButton from "../components/ImagineButton";

export default {
    components: {
        ImagineButton,
        ImagineTextarea, ImagineMoneyInput, ImagineDatePicker, ImagineModal, ImagineIconButton, ImagineLayout},
    computed: {
        ...mapState(['references']),
        ...mapState('employee', {employees: state => state.all}),
        ...mapState('contractor', {contractors: state => state.all}),
        title() {
            let title = 'Paiements ';

            if (this.salePayments) {
                title += this.salePayments.sale.property;

                if (this.salePayments.sale.taxExemption) {
                    title += ' [défisc]';
                }
            }

            return title;
        },
        canSendMail() {
            if (!this.salePayments) {
                return false;
            }

            return this.salePayments.sale.buyers.some(buyer => buyer.email);
        },
        hasOnePaymentOnMilestone() {
            if (!this.salePayments) {
                return false;
            }

            return this.salePayments.milestones.some(milestone => milestone.payments.length > 0);
        }
    },
    created() {
        this.load();
        this.saleId = this.$route.params ? parseInt(this.$route.params.id) : null;
        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    data() {
        return {
            salePayments: null,
            saleId: null,
            paymentId: null,
            stageId: null,
            amount: null,
            date: null,
            note: null,
            showPaymentModal: false
        }
    },
    methods: {
        addPayment(milestone) {
            this.paymentId = null;
            this.amount = milestone.due;
            this.date = this.$container.types.today();
            this.note = null;
            this.stageId = milestone.stageId;
            this.showPaymentModal = true;
        },
        updatePayment(payment) {
            this.paymentId = payment.id;
            this.amount = payment.amount;
            this.date = payment.date;
            this.note = payment.note;
            this.stageId = payment.stageId;
            this.showPaymentModal = true;
        },
        saveSalePayment() {
            this.load();
            return this.$store.dispatch('salePayments/savePayment', {
                paymentId: this.paymentId,
                saleId: this.salePayments.sale.id,
                stageId: this.stageId,
                amount: this.amount,
                date: this.date,
                note: this.note
            })
                .then(() => {
                    this._reload()
                        .then(() => this.showPaymentModal = false)
                        .catch(error => this.serverError(error))
                        .finally(() => this.unload());
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                })
        },
        editDoc(milestone) {
            window.location.href = '/api/sale/' + this.salePayments.sale.id + '/stage/' + milestone.stageId + '/doc';
        },
        openMail() {
            if (!this.canSendMail) {
                return;
            }

            const buyersEmails = [];
            this.salePayments.sale.buyers.forEach(buyer => {
                if (buyer.email) {
                    buyersEmails.push(buyer.email);
                }
                if (buyer.otherEmail) {
                    buyersEmails.push(buyer.otherEmail);
                }
            })

            location.href = `mailto:${buyersEmails.join(',')}?cc=${this.salePayments.sale.salesRep.email}`;
        },
        toggleRegroup() {
            this.load();
            return this.$store.dispatch('salePayments/toggleRegrouped', this.salePayments.sale.id)
                .then(() => {
                    this._reload()
                        .catch(error => this.serverError(error))
                        .finally(() => this.unload());
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                })
        },
        _getDependencies() {
            return [
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('employee/touch'),
                this.$store.dispatch('contractor/touchAll'),
                this.$store.dispatch('contact/touch'),
                this._reload()
            ];
        },
        _reload() {
            return this.$store.dispatch('salePayments/one', this.saleId)
                .then(salePayments => this._remapSalePayments(salePayments));
        },
        _remapSalePayments(salePayments) {
            this.salePayments = salePayments;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.sale-payments {
    &__header {
        padding: 1rem;

        &__info {
            font-size: 1rem;
            display: flex;
            align-items: center;
            margin: .5rem 0;

            &--price {
                span {
                    width: 140px;
                    text-align: right;
                }
            }

            &--total {
                span {
                    border-top: 1px dashed #000000;
                    font-weight: bold;
                    padding-top: 2px;
                }
            }

            b {
                width: 180px;
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
