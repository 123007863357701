var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "sales",
      attrs: { title: "Statistiques ventes" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "filter_list" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("sales")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: "", icon: "insights" },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "imagine-secondary-zone sales__filters" }, [
        _c(
          "div",
          { staticClass: "sales__filters__filter" },
          [
            _c("imagine-select", {
              attrs: {
                choices: _vm.references.saleMetricChoices(),
                label: "Métrique",
                name: "metric",
              },
              model: {
                value: _vm.metric,
                callback: function ($$v) {
                  _vm.metric = $$v
                },
                expression: "metric",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sales__filters__filter" },
          [
            _c("imagine-select", {
              attrs: {
                choices: _vm.references.saleDimensionChoices(),
                "implicit-required": "",
                multiple: "",
                "no-select-all": "",
                label: "Dimension(s)",
                name: "dimensions",
              },
              model: {
                value: _vm.dimensions,
                callback: function ($$v) {
                  _vm.dimensions = $$v
                },
                expression: "dimensions",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sales__filters__filter" },
          [
            _c("imagine-select", {
              attrs: {
                choices: _vm.references.groupedBlockChoices(_vm.blocks, [
                  "ongoing",
                  "completed",
                ]),
                label: "Filtre résidence",
                name: "block",
              },
              model: {
                value: _vm.block,
                callback: function ($$v) {
                  _vm.block = $$v
                },
                expression: "block",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.headers.length && _vm.displayedRows.length
        ? _c("div", { staticClass: "imagine-table-container" }, [
            _c("table", { staticClass: "imagine-table" }, [
              _c("thead", [
                _c(
                  "tr",
                  { staticClass: "imagine-table__row" },
                  _vm._l(_vm.headers, function (header) {
                    return _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header",
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(header) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.displayedRows, function (row) {
                  return _c(
                    "tr",
                    { staticClass: "imagine-table__row" },
                    _vm._l(row, function (cell) {
                      return _c(
                        "td",
                        {
                          staticClass: "imagine-table__row__cell",
                          class: {
                            "imagine-table__row__cell--header imagine-table__row__cell--text":
                              cell.format === "string",
                            "imagine-table__row__cell--stat":
                              cell.format !== "string",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.formatCell(cell)) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
          ])
        : !_vm.displayedRows.length && _vm.rows.length
        ? _c("div", { staticClass: "sales__stats__none" }, [
            _vm._v(
              "\n        Aucune statistique disponible pour cette résidence.\n    "
            ),
          ])
        : _c("div", { staticClass: "sales__stats__none" }, [
            _vm._v(
              "\n        Choisir une métrique pour afficher des statistiques.\n    "
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }