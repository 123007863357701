var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "blocks",
      attrs: { title: "Résidences" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("blockAdd")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _c(
          "div",
          { staticClass: "imagine-secondary-zone blocks__filter" },
          [
            _c("imagine-select", {
              attrs: {
                choices: _vm.blockStatusChoices,
                "implicit-required": true,
                multiple: true,
                label: "Statuts",
                name: "statuses",
              },
              model: {
                value: _vm.statuses,
                callback: function ($$v) {
                  _vm.statuses = $$v
                },
                expression: "statuses",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mdc-list mdc-list--two-line" },
        [
          _vm._l(_vm.filteredBlocks, function (block) {
            return [
              _c(
                "div",
                {
                  staticClass: "mdc-list-item",
                  on: {
                    click: function ($event) {
                      return _vm.gotoBlock(block)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "mdc-list-item__graphic imagine-rounded-shape",
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(block.acronym) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__text" }, [
                    _c("span", { staticClass: "mdc-list-item__primary-text" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(block.name) +
                          "\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "mdc-list-item__secondary-text" },
                      [
                        block.nbDivisions
                          ? _c("span", [
                              _vm._v(_vm._s(block.nbDivisions) + " lots"),
                            ])
                          : _c("span", [_vm._v("Lots indisponibles")]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__meta" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.blockStatus(block.status)) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mdc-list-divider" }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }