var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mdc-linear-progress" }, [
    _c("div", { staticClass: "mdc-linear-progress__buffer" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "mdc-linear-progress__bar mdc-linear-progress__primary-bar",
        style: "transform: scaleX(" + _vm.progressRate + ")",
      },
      [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }