<template>
    <imagine-select :choices="periodChoices"
                    label="Période"
                    v-model="periodId"
                    :required="true"
                    :disabled="!hasMultiplePeriods"
                    @input="$emit('input', period)"
                    name="period"/>
</template>

<script>
import ImagineSelect from './ImagineSelect.vue';

export default {
    props: ['periods', 'value'],
    components: {ImagineSelect},
    computed: {
        periodChoices() {
            if (!this.periods || this.periods.length <= 0) {
                return [];
            }

            return this.periods.map(period => this.makeChoice(period.id, period.ucfLabel));
        },
        hasMultiplePeriods() {
            return this.periods && this.periods.length > 1;
        },
        period() {
            if (!this.periods || this.periods.length <= 0) {
                return null;
            }

            return this.periods.find(period => period.id === this.periodId);
        }
    },
    data() {
        return {
            periodId: this.value ? this.value.id : null
        }
    },
    watch: {
        value(newVal) {
            this.periodId = newVal ? newVal.id : null;
        }
    }
}
</script>
