class ContractorRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all() {
        return this._http.get('/api/contractor');
    }

    save(data) {
        return this._http.post('/api/contractor' + (data.id ? '/' + data.id : ''), data);
    }

    remove(id) {
        return this._http.delete('/api/contractor/' + id);
    }
}

export default ContractorRepository;
