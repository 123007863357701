<template>
    <imagine-modal @close="$emit('close')"
                   @act="isEditing ? updateStage() : createStage()"
                   :button-label="isEditing ? 'Enregistrer' : 'Ajouter'">
        <p>
            <imagine-input name="building"
                           label="Bâtiment (si pertinent)"
                           class="imagine-form__row__field"
                           v-model="building"/>
        </p>

        <p>
            <imagine-input name="label"
                           label="Libellé"
                           :required="true"
                           class="imagine-form__row__field"
                           v-model="label"/>
        </p>

        <p>
            <imagine-input name="progress"
                           label="Appels de fonds (%)"
                           :required="true"
                           type="number"
                           :min="0"
                           :max="99"
                           class="imagine-form__row__field"
                           v-model="stageProgress"/>
        </p>

        <p>
            <imagine-date-picker name="estimatedDate"
                                 label="Date estimée"
                                 :required="true"
                                 class="imagine-form__row__field"
                                 v-model="estimatedDate"/>
        </p>

        <p>
            <imagine-date-picker name="actualDate"
                                 label="Date réelle"
                                 class="imagine-form__row__field"
                                 v-model="actualDate"/>
        </p>

        <p style="margin-bottom: .5rem;">
            <imagine-input class="imagine-form__row__field"
                           label="Organisme des attestations"
                           name="approval"
                           :help="'Si différent de ' + defaultApproval"
                           v-model="approval"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineDatePicker from '../../components/ImagineDatePicker.vue';
import ImagineInput from '../../components/ImagineInput.vue';
import ImagineModal from '../../components/ImagineModal.vue';

export default {
    components: {ImagineModal, ImagineInput, ImagineDatePicker},
    props: ['stage', 'block', 'defaultApproval'],
    computed: {
        isEditing() {
            return this.stage && this.stage.id;
        }
    },
    data() {
        return {
            building: this.stage.building,
            label: this.stage.label,
            stageProgress: this.stage.progress,
            estimatedDate: this.stage.estimatedDate,
            actualDate: this.stage.actualDate,
            approval: this.stage.approval
        }
    },
    methods: {
        createStage() {
            this.load();
            this.$store.dispatch('block/createStage', {
                blockId: this.block,
                building: this.building,
                label: this.label,
                progress: this.stageProgress,
                estimatedDate: this.estimatedDate,
                actualDate: this.actualDate,
                approval: this.approval
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        },
        updateStage() {
            this.load();
            this.$store.dispatch('block/updateStage', {
                id: this.stage.id,
                blockId: this.block,
                building: this.building,
                label: this.label,
                progress: this.stageProgress,
                estimatedDate: this.estimatedDate,
                actualDate: this.actualDate,
                approval: this.approval
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
