import { render, staticRenderFns } from "./Mandates.vue?vue&type=template&id=484ec4f2&"
import script from "./Mandates.vue?vue&type=script&lang=js&"
export * from "./Mandates.vue?vue&type=script&lang=js&"
import style0 from "./Mandates.vue?vue&type=style&index=0&id=484ec4f2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports