<template>
    <imagine-layout :inner="true" :title="pageTitle" class="contractor-edit">
        <template v-slot:actions v-if="contractor">
            <imagine-icon-button @click="openMail(contractor.email)"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="email"
                                 v-if="contractor.email"/>
            <imagine-icon-button @click="openTel(contractor.mobile.e164)"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="smartphone"
                                 v-if="contractor.mobile"/>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 :disabled="!contractor.canBeDeleted"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="delete"/>
        </template>

        <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

        <div class="imagine-container">
            <form @submit.prevent="save"
                  autocomplete="off"
                  class="imagine-form">
                <div class="imagine-form__row">
                    <imagine-select class="imagine-form__row__field"
                                    label="Catégorie"
                                    name="category"
                                    :choices="references.contractorCategoryChoices()"
                                    v-model="category"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   label="Nom"
                                   name="name"
                                   :required="true"
                                   v-model="name"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Entreprise"
                                   name="company"
                                   v-model="company"/>

                    <imagine-input class="imagine-form__row__field"
                                   label="Poste"
                                   name="position"
                                   v-model="position"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input :violation="errors.mobile"
                                   class="imagine-form__row__field"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   label="Vini"
                                   name="mobile"
                                   v-model="mobile"/>

                    <imagine-input :violation="errors.phone"
                                   class="imagine-form__row__field"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   label="Téléphone"
                                   name="phone"
                                   v-model="phone"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input :violation="errors.email"
                                   class="imagine-form__row__field"
                                   label="Email"
                                   name="email"
                                   type="email"
                                   v-model="email"/>

                    <imagine-input :violation="errors.otherEmail"
                                   class="imagine-form__row__field"
                                   label="Autre email"
                                   name="otherEmail"
                                   type="email"
                                   v-model="otherEmail"/>
                </div>

                <imagine-address :show="showPostalAddress"
                                 :with-address="true"
                                 title="Adresse postale"
                                 v-model="postalAddress"/>

                <div class="imagine-form__row">
                    <imagine-input :violation="errors.bankAccount"
                                   class="imagine-form__row__field"
                                   help="Format IBAN : FR7620800121000001234567823"
                                   label="Numéro de compte bancaire"
                                   name="iban"
                                   v-model="bankAccount"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Notes"
                                      name="comment"
                                      v-model="comment"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       button-label="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer ce prestataire ?</p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineAddress from '../components/ImagineAddress.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import {mapState} from 'vuex';

export default {
    components: {
        ImagineAddress,
        ImagineLayout,
        ImagineModal,
        ImagineButton,
        ImagineIconButton,
        ImagineInput,
        ImagineAlert,
        ImagineTextarea,
        ImagineSelect
    },
    computed: {
        ...mapState('contractor', {contractors: state => state.all}),
        ...mapState(['references']),
        pageTitle() {
            if (!this.contractorId) {
                return 'Création prestataire';
            }

            if (!this.contractor) {
                return null;
            }

            let label = this.contractor.shortName;

            if (!label) {
                return 'Edition prestataire';
            }

            return label;
        }
    },
    data() {
        return {
            contractor: null,
            showPostalAddress: false,
            showRemoveConfirmation: false,
            contractorId: null,
            name: null,
            company: null,
            position: null,
            phone: null,
            email: null,
            bankAccount: null,
            postalAddress: null,
            category: null,
            comment: null,
            mobile: null,
            otherEmail: null,
            errors: {}
        }
    },
    created() {
        this.load();
        this.contractorId = this.$route.params ? parseInt(this.$route.params.id) : null;

        Promise.all([this.$store.dispatch('contractor/touchAll'), this.$store.dispatch('touchReferences')])
            .then(this._reloadContractor)
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        save() {
            this.errors = {};
            this.load();
            this.$store.dispatch('contractor/save', {
                id: this.contractorId,
                name: this.name,
                company: this.company,
                position: this.position,
                email: this.email,
                phone: this.phone,
                postalAddressAddress: this.postalAddress ? this.postalAddress.address : null,
                postalAddressZipCode: this.postalAddress ? this.postalAddress.zipCode : null,
                postalAddressCity: this.postalAddress ? this.postalAddress.city : null,
                postalAddressArea: this.postalAddress ? this.postalAddress.area : null,
                postalAddressCountry: this.postalAddress ? this.postalAddress.country : null,
                bankAccount: this.bankAccount,
                category: this.category,
                comment: this.comment,
                otherEmail: this.otherEmail,
                mobile: this.mobile
            })
                .then(contractor => this.redirectAfterSave(contractor))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        remove() {
            this.load();
            this.$store.dispatch('contractor/remove', this.contractorId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('contractors', {}, () => this.success('Prestataire supprimé.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        openTel(tel) {
            window.location.href = "tel:" + tel;
        },
        openMail(email) {
            window.location.href = "mailto:" + email;
        },
        closeConfirm() {
            this.showRemoveConfirmation = false;
        },
        redirectAfterSave(contractor) {
            this.contractorId = contractor.id;
            this.$store.dispatch('contractor/reloadAll')
                .then(() => {
                    this._reloadContractor();
                    this.success('Prestataire sauvegardé.');
                });
        },
        _reloadContractor() {
            if (!this.contractorId) {
                return null;
            }

            const contractor = this.contractors.find(contractor => contractor.id === parseInt(this.contractorId));

            this.contractor = contractor;
            this.name = contractor.name;
            this.company = contractor.company;
            this.position = contractor.position;
            this.phone = contractor.phone ? contractor.phone.readable : '';
            this.email = contractor.email;
            this.postalAddress = contractor.postalAddress;
            this.bankAccount = contractor.bankAccount;
            this.category = contractor.category;
            this.comment = contractor.comment;
            this.mobile = contractor.mobile ? contractor.mobile.readable : '';
            this.otherEmail = contractor.otherEmail;
        }
    }
}
</script>

<style lang="scss">
</style>
