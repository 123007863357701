<template>
    <div>
        <imagine-button @click.stop="removeOption()"
                        v-if="isCurrentSalesRep">
            Retirer option
        </imagine-button>
        <span v-else>{{ placeholder }}</span>
    </div>
</template>

<script>
import ImagineButton from '../../components/ImagineButton.vue';
import {mapState} from 'vuex';

export default {
    props: {
        salesRepId: {type: Number, required: true},
        saleId: {type: Number, required: true},
        placeholder: {type: String, default: null}
    },
    components: {ImagineButton},
    computed: {
        ...mapState('user', {user: state => state.current}),
        isCurrentSalesRep() {
            return this.user && this.salesRepId === this.user.id;
        },
    },
    methods: {
        removeOption() {
            this.load();
            this.$store.dispatch('sale/cancelSalesRepOption', this.saleId)
                .then(() => {
                    this.$emit('removed');
                    this.success('Option supprimée.');
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        }
    }
};
</script>
