class UserRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    authenticate(email, password) {
        return this._http.post('/api/user/session', {email, password});
    }

    logout() {
        return this._http.delete('/api/user/session');
    }

    current() {
        return this._http.get('/api/user');
    }
}

export default UserRepository;
