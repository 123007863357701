var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      attrs: { title: "Contacts" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.mode === "filters"
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: { icon: "get_app" },
                    on: { click: _vm.exportCurrent },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "query", icon: "search" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("query")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "vip", icon: "star" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("vip")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.mode === "filters", icon: "filter_list" },
                on: {
                  click: function ($event) {
                    return _vm.changeMode("filters")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("contactAdd")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("contacts-list", {
        attrs: { mode: _vm.mode },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ contact }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "mdc-list-item contacts__contact",
                    on: {
                      click: function ($event) {
                        return _vm.gotoContact(contact)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "mdc-list-item__text" }, [
                      _c(
                        "span",
                        { staticClass: "mdc-list-item__primary-text" },
                        [
                          contact.firstname || contact.lastname
                            ? [
                                contact.lastname
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "contacts__contact__lastname",
                                      },
                                      [_vm._v(_vm._s(contact.lastname))]
                                    )
                                  : _vm._e(),
                                contact.firstname && contact.lastname
                                  ? _c("span", [_vm._v(",")])
                                  : _vm._e(),
                                _vm._v(" "),
                                contact.firstname
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "contacts__contact__firstname",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(contact.firstname) +
                                            "\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                contact.company
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                                (" +
                                          _vm._s(contact.company) +
                                          ")\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            : contact.company
                            ? _c(
                                "span",
                                { staticClass: "contacts__contact__company" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(contact.company) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mdc-list-item__secondary-text" },
                        [
                          contact.email
                            ? _c("span", [_vm._v(_vm._s(contact.email))])
                            : _vm._e(),
                          _vm._v(" "),
                          contact.mobilePhone
                            ? _c("span", [
                                _vm._v(_vm._s(contact.mobilePhone.readable)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mdc-list-item__meta" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.metas(contact).join(", ")) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }