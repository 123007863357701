var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isCurrentSalesRep
        ? _c(
            "imagine-button",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.removeOption()
                },
              },
            },
            [_vm._v("\n        Retirer option\n    ")]
          )
        : _c("span", [_vm._v(_vm._s(_vm.placeholder))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }