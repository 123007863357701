<template>
    <button :type="type"
            @click="$emit('click', $event)"
            :class="{'mdc-button--raised': primary}"
            :disabled="disabled"
            class="imagine-button mdc-button">
        <div class="mdc-button__ripple"></div>
        <span class="mdc-button__label"><slot/></span>
    </button>
</template>

<script>
import {MDCRipple} from '@material/ripple/index';

export default {
    props: {
        type: {default: 'button'},
        disabled: {type: Boolean, default: false},
        primary: {type: Boolean, defaut: false}
    },
    data() {
        return {
            _ripple: null
        }
    },
    mounted() {
        this._ripple = new MDCRipple(this.$el);
    },
    beforeDestroy: function () {
        this._ripple.destroy();
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/button/mdc-button';
</style>
