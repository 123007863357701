<template>
    <imagine-layout class="mailings" title="Publipostages">
        <template v-slot:actions>
            <imagine-icon-button :active="true"
                                 class="mdc-top-app-bar__action-item"
                                 icon="email"/>
            <imagine-icon-button @click="redirectTo('mailing')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
            <imagine-icon-button :active="false"
                                 @click="externalRedirectTo('https://account-app.brevo.com/account/login')"
                                 class="mdc-top-app-bar__action-item"
                                 svg="brevo"/>
        </template>

        <div class="mdc-list mdc-list--two-line" v-if="all.length > 0">
            <template v-for="mailing in all">
                <mailing :mailing="mailing"/>
                <div class="mdc-list-divider"></div>
            </template>
        </div>
        <p class="mailings__none" v-else>Aucun publipostage.</p>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import Mailing from './elements/Mailing.vue';
import {mapState} from 'vuex';

export default {
    components: {
        ImagineLayout,
        ImagineIconButton,
        Mailing
    },
    computed: {
        ...mapState('mailing', {all: state => state.all}),
    },
    created() {
        this.load('Chargement des publipostages');
        Promise.all(this._dependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        _dependencies() {
            return [
                this.$store.dispatch('mailing/reloadAll')
            ];
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mailings {
    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}

</style>
