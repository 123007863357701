<template>
    <div class="mdc-form-field imagine-checkbox"
         :class="{'imagine-checkbox--disabled': disabled}">
        <div :class="{'mdc-checkbox--disabled': disabled}" class="mdc-checkbox">
            <input :checked="value"
                   :id="name"
                   :name="name"
                   class="mdc-checkbox__native-control"
                   @change="$emit('input', !value)"
                   type="checkbox"
                   :disabled="disabled"
                   value="1"/>
            <div class="mdc-checkbox__background">
                <svg class="mdc-checkbox__checkmark"
                     viewBox="0 0 24 24">
                    <path class="mdc-checkbox__checkmark-path"
                          d="M1.73,12.91 8.1,19.28 22.79,4.59"
                          fill="none"/>
                </svg>
                <div class="mdc-checkbox__mixedmark"></div>
            </div>
            <div class="mdc-checkbox__ripple"></div>
        </div>
        <label class="imagine-checkbox__label"
               :class="{'imagine-checkbox__label--disabled': disabled}"
               :for="name">{{ label }}</label>
    </div>
</template>

<script>
import {MDCFormField} from '@material/form-field/index';
import {MDCCheckbox} from '@material/checkbox/index';

export default {
    props: ['name', 'label', 'value', 'disabled', 'indeterminate', 'indeterminateSupport'],
    data() {
        return {
            _checkbox: null,
            _formField: null
        }
    },
    watch: {
        indeterminate(newVal) {
            if (this.indeterminateSupport) {
                this._checkbox.indeterminate = newVal;
            }
        }
    },
    mounted() {
        if (this.indeterminateSupport) {
            //Doing this does not really bring much benefit apart from the indeterminate support
            //But it comes with a lot of performance penalties (probably because "boundingRect" triggers many rerenders)
            this._checkbox = new MDCCheckbox(this.$el.querySelector('.mdc-checkbox'));
            this._checkbox.indeterminate = this.indeterminate;
            this._formField = new MDCFormField(this.$el);
            this._formField.input = this._checkbox;
        }
    },
    beforeDestroy() {
        if (this.indeterminateSupport) {
            this._formField.destroy();
            this._checkbox.destroy();
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/form-field/mdc-form-field';
@import '~@material/checkbox/mdc-checkbox';

.imagine-checkbox {
    &__label {
        cursor: pointer;

        &.imagine-checkbox__label--disabled {
            color: rgba(0, 0, 0, 0.37);
            cursor: default;
        }
    }
}
</style>
