<template>
    <imagine-layout class="mailing" title="Nouveau publipostage">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 icon="email"
                                 @click="redirectTo('mailings')"/>
            <imagine-icon-button :active="true"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
            <imagine-icon-button :active="false"
                                 @click="externalRedirectTo('https://account-app.brevo.com/account/login')"
                                 class="mdc-top-app-bar__action-item"
                                 svg="brevo"/>
        </template>

        <contacts-list mode="filters">
            <template v-slot:header>
                <p class="mailing__disclaimer">
                    {{ disclaimer }}
                    <span v-if="quota && quota.credits !== -1">Quota en cours : {{ quota.credits }} {{ quota.type }}.</span>
                </p>

                <div class="mailing__header">
                    <div class="mailing__header__select">
                        <md-menu md-align-trigger md-size="medium">
                            <md-button md-menu-trigger>
                                <div class="mailing__header__select__menu">
                                    <imagine-checkbox :disabled="true"
                                                      :indeterminate-support="1"
                                                      :indeterminate="nbChecked > 0 && !allSelected"
                                                      :value="allSelected"/>
                                    <div>
                                        <svg height="24" viewBox="0 0 24 24" width="24"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 10l5 5 5-5z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </div>
                                </div>
                            </md-button>

                            <md-menu-content>
                                <md-menu-item @click="checkAll">Tous</md-menu-item>
                                <md-menu-item @click="checkFiltered"
                                              v-if="isFiltering">
                                    Résultats de recherche
                                </md-menu-item>
                                <md-menu-item @click="checkNone">Aucun</md-menu-item>
                            </md-menu-content>
                        </md-menu>

                        <div class="publishing__header__select__count">
                            <span v-if="nbChecked <= 0">Aucun contact sélectionné</span>
                            <span v-else-if="nbChecked === 1">1 contact sélectionné</span>
                            <span v-else>{{ nbChecked }} contacts sélectionnés</span>
                        </div>
                    </div>

                    <div>
                        <imagine-button :disabled="nbChecked === 0"
                                        :primary="true"
                                        @click="showConfirmationModal = true">
                            Paramétrer publipostage
                        </imagine-button>
                    </div>
                </div>
            </template>

            <template v-slot:default="{contact}">
                <mailing-contact :contact="contact"
                                 :exportable-desc="disclaimer"/>
            </template>
        </contacts-list>

        <imagine-modal :button-disabled="!isEmailSendable"
                       @act="create()"
                       @close="showRecipients = false;showConfirmationModal = false"
                       button-label="Créer publipostage (SANS envoi)"
                       class="mailing__email"
                       v-if="showConfirmationModal">
            <p>
                A :
                {{ nbChecked }} destinataires
                <template v-if="nbChecked < maxPreviewable">
                    -
                    <a @click.prevent="toggleRecipients()"
                       href="#">
                        <span v-if="showRecipients">Masquer</span>
                        <span v-else>Voir</span>
                    </a>
                </template>
            </p>

            <transition name="slideY">
                <ul v-if="nbChecked < 200 && showRecipients && checkedContacts.length">
                    <li v-for="checkedContact in checkedContacts">
                        {{ checkedContact }}
                    </li>
                </ul>
            </transition>

            <p>
                <imagine-input :required="true"
                               label="Objet"
                               name="subject"
                               v-model="subject"/>
            </p>

            <p v-if="emailTemplateChoices.length > 1">
                <imagine-select :choices="emailTemplateChoices"
                                :required="true"
                                label="Choisir un template"
                                name="emailTemplate"
                                v-model="template"/>
            </p>

            <p v-if="template === 'custom'"
               class="mailing__email__body">
                <imagine-textarea :required="true"
                                  label="Message"
                                  name="message"
                                  v-model="body"/>
            </p>

            <p v-if="template === 'custom'">
                <imagine-file-input label="Pièce jointe"
                                    accept="application/pdf"
                                    :maxSizeM="2"
                                    :options="{public: true}"
                                    name="attachmentUrl"
                                    :violation="errors.attachmentUrl"
                                    @invalid="fileWanted = true; fileValid = false"
                                    @valid="fileValid = true"
                                    v-model="attachmentUrl"/>
            </p>

            <p v-if="template === 'custom'">
                <imagine-input label="Nom pièce jointe"
                               name="attachmentName"
                               :violation="errors.attachmentName"
                               v-model="attachmentName"/>
            </p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ContactsList from './elements/ContactsList.vue';
import ImagineCheckbox from '../components/ImagineCheckbox.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import MailingContact from './elements/MailingContact.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import {mapState} from 'vuex';

const MAX_PREVIEWABLE = 300;

export default {
    components: {
        ImagineFileInput,
        ImagineTextarea,
        ImagineInput,
        ImagineLayout,
        ContactsList,
        ImagineCheckbox,
        ImagineButton,
        ImagineModal,
        ImagineIconButton,
        ImagineSelect,
        MailingContact
    },
    computed: {
        ...mapState('contact', {
            nbTotal: state => state.nbTotal,
            nbFiltered: state => state.nbFiltered,
            checked: state => state.checked
        }),
        ...mapState('mailing', {templates: state => state.templates, quota: state => state.quota}),
        nbChecked() {
            return this.checked.length;
        },
        allSelected() {
            if (!this.nbTotal) {
                return false;
            }

            return this.nbChecked >= this.nbTotal;
        },
        isFiltering() {
            return this.nbFiltered > 0 && this.nbFiltered < this.nbTotal;
        },
        disclaimer() {
            return `Seuls les contacts possédant un email peuvent être sélectionnés. Selon le RGPD, il est obligatoire d'obtenir au préalable le consentement des destinataires pour toute sollicitation commerciale. Les destinataires ayant déjà demandé à ne plus recevoir de communications électroniques ne recevront pas de message.`;
        },
        emailTemplateChoices() {
            const customs = [this.makeChoice('custom', 'Personnalisé')];

            if (this.templates.length <= 0) {
                return customs;
            }

            let choices = [];
            this.templates.map(template => choices.push(this.makeChoice(template.id, template.name)));

            return choices.concat(customs);
        },
        isEmailSendable() {
            return this.subject
                && (this.body || (this.template && this.template !== 'custom'))
                && (!this.fileWanted || (this.fileWanted && this.fileValid));
        }
    },
    created() {
        this.$store.commit('contact/changeMode', 'filters');
        this.load('Chargement des contacts');
        Promise.all(this._dependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    data() {
        return {
            showConfirmationModal: false,
            showRecipients: false,
            subject: null,
            body: null,
            template: 'custom',
            attachmentUrl: null,
            attachmentName: null,
            errors: {},
            checkedContacts: [],
            maxPreviewable: MAX_PREVIEWABLE,
            fileValid: false,
            fileWanted: false
        }
    },
    methods: {
        checkAll() {
            this.$store.dispatch('contact/checkAll');
        },
        checkFiltered() {
            this.$store.dispatch('contact/checkFiltered');
        },
        checkNone() {
            this.$store.dispatch('contact/checkNone');
        },
        create() {
            this.errors = {};

            if ((this.attachmentUrl && !this.attachmentName)
                || (!this.attachmentUrl && this.attachmentName)) {
                const errorMsg = 'Nom et document obligatoire lors de l\'ajout d\'une pièce jointe';
                this.errors['attachmentName'] = errorMsg;
                this.error(errorMsg);
                return;
            }

            this.load('Création publipostage en cours');
            this.$store.dispatch('mailing/create', {
                recipientIds: this.checked,
                templateId: this.template,
                subject: this.subject,
                body: this.body,
                attachmentUrl: this.attachmentUrl,
                attachmentName: this.attachmentName
            })
                .then(() => {
                    this._reset();
                    this.redirectTo('mailings', null, () => this.success('Création OK, reste à envoyer.'));
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        toggleRecipients() {
            if (this.showRecipients) {
                this.showRecipients = !this.showRecipients;

                return;
            }

            this.load();
            this.$store.dispatch('contact/checkedContacts')
                .then(recipients => {
                    this.checkedContacts = recipients;
                    this.showRecipients = true;
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        _dependencies() {
            let dependencies = [
                this.$store.dispatch('contact/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch'),
                this.$store.dispatch('mailing/reloadTemplates'),
                this.$store.dispatch('mailing/reloadQuota')
            ];

            dependencies.push(
                this.$store.dispatch('user/touch')
                    .then(() => {
                        if (this.canFilterSalesRep()) {
                            this.$store.dispatch('employee/touch');
                        }
                    })
            );

            return dependencies;
        },
        _reset() {
            this.showConfirmationModal = false;
            this.checkNone();
            this.template = 'custom';
            this.subject = null;
            this.body = null;
        }
    }
}
</script>

<style lang="scss">
.mailing {
    &__disclaimer {
        padding: 1rem;
        font-style: italic;
        margin: 0;
    }

    &__header {
        padding: .5rem 16px .5rem 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__select {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .md-menu {
                margin-left: -10px;
            }

            &__menu {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__count {
                padding: 0 .5rem;
            }
        }
    }

    &__email {
        .mdc-dialog__container, .mdc-dialog__surface {
            width: 100%;
        }

        &__body {
            .md-field .md-textarea {
                min-height: 100px;
            }
        }
    }
}
</style>
