<template>
    <imagine-modal :button-disabled="!date"
                   :title="title"
                   @act="saveInventory()"
                   @close="$emit('canceled')"
                   button-label="Enregistrer"
                   class="imagine-modal--wide">
        <p>
            <imagine-date-picker :min-date="lease.start"
                                 :required="true"
                                 class="imagine-form__row__field"
                                 label="Date"
                                 name="inventoryDate"
                                 v-model="date"/>
        </p>

        <p>
            <imagine-file-input class="imagine-form__row__field"
                                label="Document"
                                name="leaseUrl"
                                :required="true"
                                accept="application/pdf"
                                v-model="url"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineFileInput from '../../../components/ImagineFileInput.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';

export default {
    props: ['lease', 'type'],
    components: {ImagineModal, ImagineFileInput, ImagineDatePicker},
    computed: {
        title() {
            return 'Etat des lieux ' + (this.type === 'in' ? 'd\'entrée' : 'de sortie');
        }
    },
    data() {
        return {
            date: this.lease.start,
            url: null
        }
    },
    methods: {
        saveInventory() {
            this.load();
            this.$store.dispatch('lease/saveInventory', {
                id: this.lease.id,
                data: {
                    type: this.type,
                    date: this.date,
                    url: this.url
                }
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
