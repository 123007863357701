<template>
    <div @click.self="gotoProperty()"
         class="mdc-list-item">
        <span class="mdc-list-item__graphic">
            <imagine-checkbox :value="isChecked"
                              :disabled="!canBeExported"
                              :title="!canBeExported ? exportableDesc : null"
                              @input="$event ? checkProperty() : uncheckProperty()"/>
        </span>
        <span @click="gotoProperty()"
              class="mdc-list-item__text">
            <span class="mdc-list-item__primary-text">
                {{ property.infos }}
            </span>
            <span class="mdc-list-item__secondary-text">
                Référence {{ property.reference }}
            </span>
        </span>
        <span @click="gotoProperty()"
              class="mdc-list-item__meta">
            <span
                :class="{'imagine-status--danger': publishingChange && willBeDeleted, 'imagine-status--success': publishingChange && willBePublished}"
                class="imagine-status">
                {{ publishingStatus }}
            </span>
        </span>
    </div>
</template>

<script>
import ImagineCheckbox from '../../components/ImagineCheckbox.vue';
import {mapState} from 'vuex';

export default {
    props: ['property', 'publishing', 'exportableDesc'],
    components: {ImagineCheckbox},
    computed: {
        ...mapState('property', {published: state => state.published, checked: state => state.checked}),
        publishingStatus() {
            if (!this.publishingChange) {
                return this.willBePublished ? 'Sera mis à jour' : 'Non publié';
            }

            if (this.willBeDeleted) {
                return 'Sera dépublié';
            }

            return 'Sera publié';
        },
        publishingChange() {
            return this.isPublished !== this.willBePublished;
        },
        willBePublished() {
            return this.isChecked;
        },
        willBeDeleted() {
            return !this.isChecked;
        },
        canBeExported() {
            return this.property.publishable && this.property.publishable.includes(this.publishing);
        },
        isPublished() {
            return this.published
                && this.published[this.publishing]
                && this.published[this.publishing].includes(this.property.id);
        },
        isChecked() {
            return this.checked[this.publishing].includes(this.property.id);
        }
    },
    methods: {
        gotoProperty() {
            this.$store.commit('property/resetLastTab');
            this.redirectTo('property', {id: this.property.id})
        },
        checkProperty() {
            this.$store.commit('property/addToChecked', this.property.id);
        },
        uncheckProperty() {
            this.$store.commit('property/removeFromChecked', this.property.id);
        }
    }
}
</script>

<style lang="scss">
.publishing {
    .mdc-list-item {
        .imagine-checkbox.imagine-checkbox--disabled {
            cursor: not-allowed;
        }
    }
}
</style>
