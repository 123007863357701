<template>
    <imagine-layout class="activities" title="Activité">
        <template v-slot:actions>
            <imagine-icon-button :active="filter"
                                 @click="filter = !filter"
                                 class="mdc-top-app-bar__action-item"
                                 icon="filter_list"/>
            <imagine-icon-button @click="pull"
                                 class="mdc-top-app-bar__action-item"
                                 icon="refresh"/>
        </template>

        <transition name="slideY">
            <template v-if="filter">
                <div class="imagine-secondary-zone activities__filters">
                    <div class="activities__filters__filter">
                        <imagine-select :choices="periodChoices"
                                        label="Période"
                                        name="periodfilter"
                                        v-model="period"
                                        @input="changePeriod()"/>
                    </div>
                    <div class="activities__filters__filter">
                        <imagine-select :choices="employeeChoices"
                                        label="Employé"
                                        name="employeefilter"
                                        v-model="employee"/>
                    </div>
                </div>
            </template>
        </transition>

        <timeline :events="displayedEvents" v-if="displayedEvents.length > 0"/>
        <div class="activities__none"
             v-else>
            Aucune activité depuis {{ periodLabel }} {{ employee ? 'pour cet employé' : '' }}.
        </div>
    </imagine-layout>
</template>

<script>
import Timeline from './elements/Timeline.vue';
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSelect from '../components/ImagineSelect';
import {mapState} from 'vuex';
import orderBy from 'lodash-es/orderBy';

const POLL_EVERY_SEC = 60;

export default {
    components: {ImagineLayout, ImagineIconButton, Timeline, ImagineSelect},
    computed: {
        ...mapState('event', {events: state => state.all}),
        periodChoices() {
            return [
                this.makeChoice(7, 'une semaine'),
                this.makeChoice(5 * 30, '5 mois')
            ];
        },
        employeeChoices() {
            let busyEmployeeIds = [];
            let choices = [];

            this.events
                .filter(event => event.by)
                .forEach(event => {
                    const employee = event.by;
                    const employeeId = employee.id;

                    if (!busyEmployeeIds.includes(employeeId)) {
                        busyEmployeeIds.push(employeeId);
                        choices.push(this.makeChoice(employeeId, employee.shortname));
                    }
                });

            return orderBy(choices, [choice => choice.label], ['asc']);
        },
        displayedEvents() {
            if (!this.employee) {
                return this.events;
            }

            return this.events.filter(event => event.by && event.by.id === parseInt(this.employee));
        },
        periodLabel() {
            return this.periodChoices.find(choice => choice.value === this.period).label;
        }
    },
    data() {
        const DEFAULT_PERIOD = 7;

        return {
            employee: null,
            period: DEFAULT_PERIOD,
            since: this.$container.types.daysAgo(DEFAULT_PERIOD),
            filter: false,
            _polling: null
        }
    },
    created() {
        this.refresh();
        this._poll();
    },
    beforeDestroy() {
        clearInterval(this._polling);
    },
    methods: {
        changePeriod() {
            this.since = this.$container.types.daysAgo(this.period);
            this.refresh();
        },
        refresh() {
            this.load();
            this.$store.dispatch('event/reload', this.since)
                .then(this._traceSince)
                .catch(this.serverError)
                .finally(this.unload);
        },
        pull() {
            this.load();
            this.$store.dispatch('event/pull', this.since)
                .then(this._traceSince)
                .catch(this.serverError)
                .finally(this.unload);
        },
        _poll() {
            this._polling = setInterval(this.pull, POLL_EVERY_SEC * 1000);
        },
        _traceSince() {
            if (this.events.length > 0) {
                this.since = this.events[0].date;
            }
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.activities {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            .md-field {
                margin-bottom: 8px;
            }
        }
    }

    &__list {
        &__activity {
            cursor: default;
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
