import container from '../container';

const repo = container.repos.contractor;

export default {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        reloadAll({state}) {
            return repo.all()
                .then(contractors => state.all = contractors);
        },
        touchAll({state, dispatch}) {
            if (!state.all || state.all.length <= 0) {
                return dispatch('reloadAll');
            }

            return Promise.resolve();
        },
        save(context, data) {
            return repo.save(data);
        },
        remove(context, id) {
            return repo.remove(id);
        }
    }
};
