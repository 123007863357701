import container from '../container';

const repo = container.repos.employee;

export default {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        touch({state, dispatch}) {
            if (state.all.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        reload(context) {
            return repo.all()
                .then(employees => context.state.all = employees);
        },
        save(context, data) {
            return repo.save(data);
        },
        remove(context, id) {
            return repo.delete(id);
        },
        state(context, {id, state}) {
            return repo.state(id, state);
        }
    }
};
