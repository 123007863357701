<template>
    <imagine-layout :inner="true" :title="title" class="lease">
        <template v-slot:actions>
            <imagine-icon-button :disabled="true"
                                 class="mdc-top-app-bar__action-item"
                                 icon="post_add"
                                 title="Faire un avenant"
                                 v-if="lease && !lease.canBeModifiedWithoutAmendment"/>

            <imagine-icon-button :disabled="lease && !lease.canBeDeleted"
                                 class="mdc-top-app-bar__action-item"
                                 icon="delete"
                                 @click="deleteLease()"
                                 v-if="lease"/>
        </template>

        <div class="imagine-container">
            <form @submit.prevent="save"
                  autocomplete="off"
                  class="imagine-form">

                <imagine-alert type="info"
                               v-if="mandateProperty && mandateProperty.canBeModifiedWithoutAmendment">
                    La création du premier bail rendra impossible la modification de la date, du propriétaire ou
                    des biens du mandat qui s'y rattache.
                </imagine-alert>

                <imagine-alert type="info"
                               v-if="!leaseId">
                    Avant de commencer à saisir un bail, il est nécessaire de créer le(s) locataires et le(s)
                    éventuel(s) garant(s) <a href="#" @click.prevent="redirectTo('contactAdd')">dans les contacts</a>.
                </imagine-alert>

                <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

                <div class="imagine-form__row">
                    <imagine-date-picker :required="true"
                                         class="imagine-form__row__field"
                                         label="Date de signature"
                                         :disabled="isEditingDisabled"
                                         name="date"
                                         v-model="date"/>
                </div>

                <div class="imagine-form__row"
                     v-for="(tenantSlot, slot) in tenantSlots"
                     v-if="isEditingDisabled ? nbTenants > slot : nbTenants >= slot">
                    <imagine-contact-autocomplete :filter="canContactBeTenant"
                                                  :label="slot > 0 ? 'Autre locataire' : 'Locataire'"
                                                  :required="slot <= 0"
                                                  :disabled="isEditingDisabled"
                                                  @input="removeSlotHoles()"
                                                  class="imagine-form__row__field"
                                                  name="tenant"
                                                  v-model="tenantSlot.tenant"/>

                    <imagine-contact-autocomplete :disabled="!tenantSlot.tenant || isEditingDisabled"
                                                  :filter="canContactBeProtector"
                                                  class="imagine-form__row__field"
                                                  label="Garant"
                                                  name="protector"
                                                  v-if="mandateProperty && mandateProperty.unpaidGuaranteePercent <= 0"
                                                  v-model="tenantSlot.protector"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-date-picker :min-date="minLeaseDate"
                                         :required="true"
                                         :disabled="isEditingDisabled"
                                         class="imagine-form__row__field"
                                         label="Date entrée"
                                         name="startDate"
                                         v-model="startDate"/>

                    <imagine-input :required="true"
                                   :disabled="isEditingDisabled"
                                   class="imagine-form__row__field"
                                   label="Durée (années)"
                                   min="1"
                                   name="duration"
                                   step="1"
                                   type="number"
                                   v-model="duration"/>

                    <imagine-input :required="true"
                                   :disabled="isEditingDisabled"
                                   class="imagine-form__row__field"
                                   label="Délai pré-avis (mois)"
                                   min="1"
                                   name="endDelay"
                                   step="1"
                                   type="number"
                                   v-model="endDelay"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-money-input :required="true"
                                         class="imagine-form__row__field"
                                         label="Loyer hors charges (XPF / mois)"
                                         :disabled="isEditingDisabled"
                                         name="rent"
                                         v-model="rent"/>

                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision pour charges (XPF / mois)"
                                         :disabled="isEditingDisabled"
                                         name="charges"
                                         v-model="charges"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision taxe d'ordures ménagères (XPF / mois)"
                                         :disabled="isEditingDisabled"
                                         name="waste"
                                         v-model="waste"/>

                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision taxe d'eau (XPF / mois)"
                                         :disabled="isEditingDisabled"
                                         name="water"
                                         v-model="water"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Dépôt de garantie (XPF)"
                                         help="Par défaut = 1.5x loyer hors charges"
                                         :disabled="isEditingDisabled"
                                         name="guarantee"
                                         v-model="guarantee"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input :required="true"
                                   class="imagine-form__row__field"
                                   label="Taux H.T. des H.M.L. (%)"
                                   help="Par défaut = 60%"
                                   :disabled="isEditingDisabled"
                                   min="0"
                                   name="feesPercent"
                                   step="any"
                                   type="number"
                                   v-model="feesPercent"/>

                    <imagine-money-input class="imagine-form__row__field"
                                         label="Base des H.M.L. (XPF)"
                                         :help="feesHelp"
                                         :disabled="isEditingDisabled"
                                         name="feesBase"
                                         v-model="feesBase"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-file-input class="imagine-form__row__field"
                                        label="Bail (doc)"
                                        name="leaseUrl"
                                        accept="application/pdf"
                                        v-model="leaseUrl"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-date-picker :min-date="startDate"
                                         class="imagine-form__row__field"
                                         label="Date état des lieux d'entrée"
                                         name="inventoryInDate"
                                         v-model="inventoryInDate"/>

                    <imagine-file-input class="imagine-form__row__field"
                                        label="Etat des lieux d'entrée (doc)"
                                        name="inventoryInUrl"
                                        accept="application/pdf"
                                        v-model="inventoryInUrl"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-date-picker :min-date="startDate"
                                         class="imagine-form__row__field"
                                         label="Date de fin assurance habitation"
                                         name="insuranceEndDate"
                                         v-model="insuranceEndDate"/>

                    <imagine-file-input accept="application/pdf"
                                        class="imagine-form__row__field"
                                        label="Assurance habitation (doc)"
                                        name="insuranceUrl"
                                        v-model="insuranceUrl"/>
                </div>

                <template v-if="leaseId">
                    <div class="imagine-form__sep"></div>

                    <div class="imagine-form__row">
                        <imagine-date-picker :min-date="startDate"
                                             class="imagine-form__row__field"
                                             label="Date de réception du pré-avis de départ"
                                             name="endNoticeDate"
                                             v-model="endNoticeDate"/>

                        <imagine-file-input class="imagine-form__row__field"
                                            label="Courrier pré-avis (doc)"
                                            name="endNoticeUrl"
                                            accept="application/pdf"
                                            v-model="endNoticeUrl"/>
                    </div>

                    <div class="imagine-form__sep"></div>

                    <div class="imagine-form__row">
                        <imagine-date-picker :min-date="startDate"
                                             class="imagine-form__row__field"
                                             label="Date état des lieux de sortie"
                                             name="inventoryOutDate"
                                             v-model="inventoryOutDate"/>

                        <imagine-file-input class="imagine-form__row__field"
                                            label="Etat des lieux de sortie (doc)"
                                            name="inventoryOutUrl"
                                            accept="application/pdf"
                                            v-model="inventoryOutUrl"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-date-picker :min-date="startDate"
                                             class="imagine-form__row__field"
                                             label="Date de sortie"
                                             name="endDate"
                                             v-model="endDate"/>
                    </div>
                </template>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button :primary="true"
                                    type="submit">
                        Sauvegarder
                    </imagine-button>
                </div>
            </form>
        </div>
    </imagine-layout>
</template>

<script>
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineMoneyInput from '../components/ImagineMoneyInput.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';

const MAX_TENANT_PER_LEASE = 10;

const reserveSlots = (nbSlots) => {
    let slots = [];

    for (let i = 0; i < nbSlots; i++) {
        slots.push({tenant: null, protector: null});
    }

    return slots;
};

export default {
    components: {
        ImagineAlert,
        ImagineLayout,
        ImagineContactAutocomplete,
        ImagineDatePicker,
        ImagineInput,
        ImagineMoneyInput,
        ImagineFileInput,
        ImagineButton,
        ImagineIconButton
    },
    computed: {
        title() {
            if (!this.mandateProperty) {
                return 'Bail';
            }

            if (this.lease) {
                return 'Edition bail';
            }

            return 'Ajout bail';
        },
        tenantIds() {
            return this.tenantSlots.filter(tenantSlot => tenantSlot.tenant).map(tenantSlot => tenantSlot.tenant.id);
        },
        protectorIds() {
            return this.tenantSlots.filter(tenantSlot => tenantSlot.tenant).map(tenantSlot => tenantSlot.protector ? tenantSlot.protector.id : null);
        },
        nbTenants() {
            return this.tenantIds.length;
        },
        minLeaseDate() {
            if (!this.mandateProperty) {
                return null;
            }

            return this.mandateProperty.lastLeaseEnd ? this.mandateProperty.lastLeaseEnd : this.mandateProperty.startDate;
        },
        fullRent() {
            let fullRent = this.$container.types.makeMoney(0);

            if (this.rent) {
                fullRent = fullRent.add(this.rent);
            }

            if (this.water) {
                fullRent = fullRent.add(this.water);
            }

            if (this.waste) {
                fullRent = fullRent.add(this.waste);
            }

            if (this.charges) {
                fullRent = fullRent.add(this.charges);
            }

            return fullRent;
        },
        feesHelp() {
            if (this.feesBase && this.feesPercent) {
                return `Taux de ${this.feesPercent}% x base de ${this.formatMoney(this.feesBase)} = HML de ${this.formatMoney(this.feesBase.multiply(this.feesPercent / 100))}`;
            }

            return 'Par défaut = Loyer CC';
        }
    },
    data() {
        return {
            leaseId: null,
            lease: null,
            mandatePropertyId: null,
            mandateProperty: null,
            tenantSlots: reserveSlots(MAX_TENANT_PER_LEASE),
            date: null,
            startDate: null,
            duration: 3,
            endDelay: 3,
            rent: null,
            charges: null,
            waste: null,
            water: null,
            guarantee: null,
            leaseUrl: null,
            feesPercent: 60,
            feesBase: null,
            inventoryInDate: null,
            inventoryInUrl: null,
            insuranceEndDate: null,
            insuranceUrl: null,
            endNoticeDate: null,
            endNoticeUrl: null,
            inventoryOutDate: null,
            inventoryOutUrl: null,
            endDate: null,
            errors: {},
            isEditingDisabled: false,
            isLoadingValues: false
        }
    },
    created() {
        this.mandatePropertyId = this.$route.params['mandatePropertyId'];
        this.leaseId = this.$route.params.hasOwnProperty('id') ? parseInt(this.$route.params['id']) : null;

        this.load();
        Promise.all([
            this.$store.dispatch('contact/touch'),
            this.$store.dispatch('mandateProperty/one', this.mandatePropertyId)
                .then(mandateProperty => {
                    this.mandateProperty = mandateProperty;

                    if (this.leaseId) {
                        this.isLoadingValues = true;
                        this.lease = this.mandateProperty.leases.find(lease => lease.id === this.leaseId);
                        this.isEditingDisabled = !this.lease.canBeModifiedWithoutAmendment;
                        this.startDate = this.lease.start;
                        this.endDate = this.lease.end;
                        this.duration = this.lease.duration;
                        this.endDelay = this.lease.endDelay;
                        this.rent = this.lease.rent.rent;
                        this.charges = this.lease.rent.charges;
                        this.waste = this.lease.rent.waste;
                        this.water = this.lease.rent.water;
                        this.lease.tenants.forEach((tenant, index) => {
                            this.tenantSlots[index].tenant = tenant;
                            if (this.lease.protectors.hasOwnProperty(index)) {
                                this.tenantSlots[index].protector = this.lease.protectors[index];
                            }
                        });
                        this.leaseUrl = this.lease.url;
                        this.date = this.lease.date;
                        this.feesPercent = this.lease.feesPercent;
                        this.guarantee = this.lease.guarantee;
                        this.feesBase = this.lease.feesBase;
                        this.inventoryInDate = this.lease.inventoryInDate;
                        this.inventoryInUrl = this.lease.inventoryInUrl;
                        this.insuranceEndDate = this.lease.insuranceEndDate;
                        this.insuranceUrl = this.lease.insuranceUrl;
                        this.endNoticeDate = this.lease.endNoticeDate;
                        this.endNoticeUrl = this.lease.endNoticeUrl;
                        this.inventoryOutDate = this.lease.inventoryOutDate;
                        this.inventoryOutUrl = this.lease.inventoryOutUrl;
                        this.$nextTick(() => this.isLoadingValues = false);
                    } else {
                        const today = this.$container.types.today();
                        this.date = today;
                        this.startDate = today.isAfter(this.minLeaseDate) ? today : this.minLeaseDate;
                    }
                })
        ])
            .catch(this.serverError)
            .finally(this.unload);
    },
    watch: {
        rent(newVal) {
            if (!this.isLoadingValues) {
                this.guarantee = newVal.multiply(1.5);
                this.feesBase = this.fullRent;
            }
        },
        charges() {
            if (!this.isLoadingValues) {
                this.feesBase = this.fullRent;
            }
        },
        water() {
            if (!this.isLoadingValues) {
                this.feesBase = this.fullRent;
            }
        },
        waste() {
            if (!this.isLoadingValues) {
                this.feesBase = this.fullRent;
            }
        }
    },
    methods: {
        save() {
            this.load();
            this.$store.dispatch('lease/save', {
                data: {
                    id: this.leaseId,
                    mandatePropertyId: this.mandatePropertyId,
                    date: this.date,
                    startDate: this.startDate,
                    rent: this.rent,
                    charges: this.charges,
                    waste: this.waste,
                    water: this.water,
                    duration: this.duration,
                    endDelay: this.endDelay,
                    tenantIds: this.tenantIds,
                    protectorIds: this.protectorIds,
                    guarantee: this.guarantee,
                    leaseUrl: this.leaseUrl,
                    feesPercent: this.feesPercent,
                    feesBase: this.feesBase,
                    inventoryInDate: this.inventoryInDate,
                    inventoryInUrl: this.inventoryInUrl,
                    insuranceEndDate: this.insuranceEndDate,
                    insuranceUrl: this.insuranceUrl,
                    endNoticeDate: this.endNoticeDate,
                    endNoticeUrl: this.endNoticeUrl,
                    inventoryOutDate: this.inventoryOutDate,
                    inventoryOutUrl: this.inventoryOutUrl,
                    endDate: this.endDate
                }
            })
                .then(() => this.redirectTo('mandateProperty', {id: this.mandatePropertyId}))
                .catch(this.serverError)
                .finally(this.unload);
        },
        removeSlotHoles() {
            setTimeout(() => {
                const nbSlots = this.tenantSlots.length;
                const nonEmptySlots = this.tenantSlots.filter(tenantSlot => tenantSlot.tenant);
                this.tenantSlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        canContactBeTenant(contact) {
            return !this.tenantIds.includes(contact.id);
        },
        canContactBeProtector(contact) {
            return !this.tenantIds.includes(contact.id);
        },
        deleteLease() {
            if (!this.lease) {
                return;
            }

            this.load();
            this.$store.dispatch('lease/delete', this.lease.id)
                .then(() => {
                    this.redirectTo('mandateProperty', {id: this.mandatePropertyId});
                })
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
