import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-advanced-cropper/dist/style.css';
import './scss/layout.scss';

import Vue from 'vue';
import router from './routes';
import store from './store';
import App from './App.vue';
import ImagineLoader from './components/ImagineLoader.vue';
import container from './container';
import {
    MdAutocomplete,
    MdBadge,
    MdButton,
    MdCheckbox,
    MdContent,
    MdDatepicker,
    MdDialog,
    MdField,
    MdHighlightText,
    MdList,
    MdMenu,
    MdSteppers,
    MdSubheader,
    MdSwitch,
    MdTooltip
} from 'vue-material/dist/components';
import VueMq from 'vue-mq';

Vue.config.productionTip = false;

Vue.use(MdField);
Vue.use(MdMenu);
Vue.use(MdContent);
Vue.use(MdList);
Vue.use(MdSubheader);
Vue.use(MdButton);
Vue.use(MdDialog);
Vue.use(MdDatepicker);
Vue.use(MdCheckbox);
Vue.use(MdSwitch);
Vue.use(MdAutocomplete);
Vue.use(MdHighlightText);
Vue.use(MdTooltip);
Vue.use(MdBadge);
Vue.use(MdSteppers);

Vue.use(VueMq, {
    breakpoints: {
        phone: 500,
        desktop: 1000,
        wide: Infinity,
    },
    defaultBreakpoint: 'desktop'
});

Vue.mixin({
    beforeCreate() {
        const options = this.$options;
        if (options.container) {
            this.$container = options.container;
        } else if (options.parent && options.parent.$container) {
            this.$container = options.parent.$container;
        }
    },
    methods: {
        isMandatesGranted() {
            return this.isGranted(['ROLE_CEO', 'ROLE_RENTALS']);
        },
        isSalesGranted() {
            return this.isGranted(['ROLE_CEO', 'ROLE_SALES']);
        },
        canAddSaleOption() {
            return this.isGranted(['ROLE_CEO', 'ROLE_SALES_REP']);
        },
        canFilterSalesRep() {
            return this.isGranted(['ROLE_CEO', 'ROLE_SALES', 'ROLE_RENTALS']);
        },
        load(message, count) {
            if (count) {
                this.$store.commit('prepareProgress', count);
            }
            this.$store.commit('load', message);
        },
        getLoanDefaultDurationYears() {
            return 20;
        },
        getLoanDefaultInterestRate() {
            return 3.5;
        },
        progress() {
            this.$store.commit('progress');
        },
        unload() {
            this.$store.commit('unload');
            this.$root.$emit('loaded');
        },
        success(message) {
            this.$store.commit('success', message);
        },
        error(message) {
            this.$store.commit('error', message);
        },
        isGranted(roles) {
            let user = this.$store.state.user.current;

            if (!user) {
                return false;
            }

            if (typeof roles === 'string') {
                roles = [roles];
            }

            let userRoles = [...user.roles];

            if (user.email === 'vaea@imagine-lagence.com') {
                userRoles.push('ROLE_RENTALS');
            }

            return roles.filter(role => userRoles.includes(role)).length > 0;
        },
        redirectTo(name, params, callback) {
            this.$router.push({name, params})
                .catch(err => {
                });

            if (callback) {
                setTimeout(callback, 800);
            }
        },
        externalRedirectTo(url) {
            window.open(url, '_blank');
        },
        rentDetail(lease) {
            if (!lease) {
                return null;
            }

            const parts = [
                {label: 'Loyer', amount: lease.rent},
                {label: 'Charges', amount: lease.charges},
                {label: 'Eau', amount: lease.water},
                {label: 'Ordures ménagères', amount: lease.waste}].filter(part => part.amount);

            if (parts.length === 1) {
                return null;
            }

            return parts.map(part => part.label + ' ' + this.formatMoney(part.amount)).join(' + ');
        },
        formatMoney(money) {
            return this.$container.types.formatMoney(money);
        },
        money0() {
            return this.$container.types.formatMoney(this.$container.types.makeMoney(0));
        },
        formatDate(date) {
            return this.$container.types.formatDate(date);
        },
        serverError(error) {
            if (!navigator.onLine) {
                this.error('Serveur de l\'extranet injoignable, vérifier votre connexion internet.');

                return {};
            }

            let response = null;
            if (error.response) {
                response = error.response;
            } else if (error.status) {
                response = error;
            }

            if (response) {
                const responseStatus = response.status;
                const responseData = response.data;

                if (responseStatus === 401
                    && this.$route.name !== 'login'
                    && (!this.$route.meta || !this.$route.meta.public)) {
                    this.redirectTo('login');

                    return {};
                }

                if (responseStatus === 404) {
                    this.error('Page ou référence introuvable.');

                    return {};
                }

                if (responseStatus === 413) {
                    this.error('Données envoyées trop volumineuses.');

                    return {};
                }

                if (responseStatus === 422) {
                    if (responseData && responseData.errors && Object.keys(responseData.errors).length > 0) {
                        let moreInfos = Object.keys(responseData.errors).join(', ');
                        if (responseData.errors.hasOwnProperty('global')) {
                            moreInfos = responseData.errors['global'];
                        }

                        this.error(`Informations saisies invalides (${moreInfos}).`);

                        return responseData.errors;
                    }

                    return {global: 'Informations saisies invalides'};
                }
            }

            console.error(error);
            setTimeout(() => this.error('Problème lors de la communication avec le serveur, veuillez réessayer.'), 500);

            return {};
        },
        redirectToHome() {
            if (this.isGranted(['ROLE_CEO'])) {
                return this.redirectTo('activities');
            }

            if (this.isGranted(['ROLE_SALES'])) {
                return this.redirectTo('sales');
            }

            if (this.isGranted(['ROLE_RENTALS'])) {
                return this.redirectTo('mandates');
            }

            this.redirectTo('contacts');
        },
        formatNumber(number) {
            return this.$container.types.formatNumber(number);
        },
        makeChoice(value, label) {
            label = label || value;

            if (value && label) {
                return {value, label, disabled: false};
            }

            return null;
        },
        randomId() {
            return '_' + Math.random().toString(36).substr(2, 9);
        },
        back() {
            this.$router.go(-1);
        },
        toQueryString(params) {
            if (!params) {
                return '';
            }

            return Object.keys(params).filter(key => params[key]).map(key => key + '=' + params[key]).join('&');
        }
    }
});

Vue.config.errorHandler = (err, vm, info) => {
    if (err.message !== "Cannot read property 'badInput' of undefined") {
        console.error(err);
    }
};

new Vue({
    components: {App, ImagineLoader},
    render: h => h(App),
    router,
    store,
    container,
    created() {
        if (this.$route.meta && this.$route.meta.public) {
            return;
        }

        this.load();
        this.$store.dispatch('user/init')
            .catch(this.serverError)
            .finally(this.unload);
    }
}).$mount('#app');
