<template>
    <div>
        <imagine-icon-button @click="externalRedirectTo('https://applis.itool.net')"
                             class="mdc-top-app-bar__action-item"
                             svg="itool"/>
        <imagine-icon-button @click="externalRedirectTo('https://www.socredo.pf/connexion/auth/login')"
                             class="mdc-top-app-bar__action-item"
                             svg="socredo"/>
    </div>
</template>

<script>
import ImagineIconButton from '../../../components/ImagineIconButton.vue';

export default {
    components: {ImagineIconButton}
}
</script>
