var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mailing-detail" },
    [
      _c(
        "div",
        {
          staticClass: "mdc-list-item",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.toggleStats()
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "mdc-list-item__text",
              on: {
                click: function ($event) {
                  return _vm.toggleStats()
                },
              },
            },
            [
              _c("span", { staticClass: "mdc-list-item__primary-text" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.mailing.subject) +
                    ",\n                " +
                    _vm._s(_vm.mailing.recipients.length) +
                    " destinataire" +
                    _vm._s(_vm.mailing.recipients.length > 1 ? "s" : "") +
                    " -\n                "
                ),
                _c("small", [_vm._v('"' + _vm._s(_vm.mailing.body) + '"')]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mdc-list-item__secondary-text" }, [
                _vm._v(
                  "\n                " + _vm._s(_vm.by) + "\n            "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "mdc-list-item__meta" }, [
            _vm.mailing.sent
              ? _c("span", [
                  _vm._v(
                    "Envoyé, " + _vm._s(_vm.formatMailingDate(_vm.mailing.sent))
                  ),
                ])
              : _c(
                  "span",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm.user.canTestMailings
                      ? _c(
                          "imagine-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.sendTest()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    Me l'envoyer\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("imagine-icon-button", {
                      staticClass: "imagine-no-ripple",
                      attrs: { icon: "delete" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteConfirmation = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-icon-button", {
                      staticClass: "imagine-no-ripple",
                      attrs: { icon: "send" },
                      on: {
                        click: function ($event) {
                          _vm.showSendConfirmation = true
                        },
                      },
                    }),
                  ],
                  1
                ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDetail,
                expression: "showDetail",
              },
            ],
          },
          [
            _c("div", { staticClass: "mailing-detail__preview" }, [
              _c(
                "div",
                { staticClass: "mailing-detail__preview__section" },
                [
                  _c("strong", [_vm._v("A :")]),
                  _vm._v(" "),
                  _vm.mailing.recipients.length === 1
                    ? _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.mailing.recipients[0].name) +
                            " <" +
                            _vm._s(_vm.mailing.recipients[0].email) +
                            ">\n                    "
                        ),
                      ])
                    : [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.mailing.recipients.length) +
                            " destinataires -\n                        "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.showRecipients = !_vm.showRecipients
                              },
                            },
                          },
                          [
                            _vm.showRecipients
                              ? _c("span", [_vm._v("Masquer")])
                              : _c("span", [_vm._v("Voir")]),
                          ]
                        ),
                      ],
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "slideY" } }, [
                    _vm.showRecipients && _vm.mailing.recipients.length > 1
                      ? _c(
                          "ul",
                          _vm._l(_vm.mailing.recipients, function (recipient) {
                            return _c("li", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(recipient.name) +
                                  " <" +
                                  _vm._s(recipient.email) +
                                  ">\n                            "
                              ),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mailing-detail__preview__section" }, [
                _c("strong", [_vm._v("Objet:")]),
                _vm._v(
                  " " + _vm._s(_vm.mailing.subject) + "\n                "
                ),
              ]),
              _vm._v(" "),
              _vm.mailing.template
                ? _c(
                    "div",
                    { staticClass: "mailing-detail__preview__section" },
                    [_c("em", [_vm._v("Template brevo")])]
                  )
                : _c("div", {
                    staticClass: "mailing-detail__preview__section",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.mailing.body.replace(/(?:\r\n|\r|\n)/g, "<br>")
                      ),
                    },
                  }),
              _vm._v(" "),
              _vm.mailing.attachmentUrl
                ? _c(
                    "div",
                    { staticClass: "mailing-detail__preview__section" },
                    [
                      _c("span", { staticClass: "material-icons" }, [
                        _vm._v("attach_file"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.download(_vm.mailing.attachmentUrl)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.mailing.attachmentName))]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.hasStats
              ? _c("div", { staticClass: "mailing-detail__stats" }, [
                  _c("div", { staticClass: "mailing-detail__stats__stat" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.uniqueViews ? _vm.stats.uniqueViews : 0
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "VUE" + _vm._s(_vm.stats.uniqueViews > 1 ? "S" : "")
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mailing-detail__stats__stat" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.stats.clickers ? _vm.stats.clickers : 0)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "CLIC" + _vm._s(_vm.stats.clickers > 1 ? "S" : "")
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mailing-detail__stats__stat" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.hardBounces ? _vm.stats.hardBounces : 0
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "REBOND" + _vm._s(_vm.stats.hardBounces > 1 ? "S" : "")
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mailing-detail__stats__stat" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.stats.unsubscriptions
                            ? _vm.stats.unsubscriptions
                            : 0
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v("DESINSCR.")]),
                  ]),
                ])
              : _c("div", { staticClass: "mailing-detail__nostats" }, [
                  _vm._v(
                    "\n                Statistiques indisponibles.\n            "
                  ),
                ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showDeleteConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: {
                act: function ($event) {
                  return _vm.remove()
                },
                close: function ($event) {
                  _vm.showDeleteConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer ce publipostage ?"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: {
                "button-label":
                  "Envoyer à " + _vm.mailing.recipients.length + " contacts",
              },
              on: {
                act: function ($event) {
                  return _vm.send()
                },
                close: function ($event) {
                  _vm.showSendConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir envoyer ce publipostage ?"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }