<template>
    <div>
        <md-datepicker :md-disabled-dates="disabledDates"
                       :required="required"
                       @input="$emit('input', _useDate(innerValue))"
                       class="imagine-datepicker"
                       :class="{'imagine-datepicker--dense': dense, 'md-invalid': !!violation}"
                       :md-clearable="!required"
                       md-immediately
                       v-if="!disabled"
                       v-model="innerValue">
            <label>{{ label }} <span v-if="required">*</span></label>
            <span class="md-error"
                  v-if="violation">
                {{ violation }}
            </span>
            <span class="md-helper-text"
                  v-else-if="help">
                {{ help }}
            </span>
        </md-datepicker>
        <imagine-input :disabled="true"
                       :label="label"
                       :name="name"
                       :required="required"
                       :help="help"
                       v-else
                       v-model="innerValue"/>
    </div>
</template>

<script>
import FRENCH_DATEPICKER_LOCALE from '../config/datepicker-fr';
import ImagineInput from "./ImagineInput.vue";

export default {
    components: {ImagineInput},
    props: {
        help: {default: null},
        label: {required: true},
        name: {required: true},
        value: {default: null},
        required: {type: Boolean, default: false},
        minDate: {default: null},
        maxDate: {default: null},
        disabled: {type: Boolean, default: false},
        dense: {type: Boolean, default: false},
        violation: {default: null}
    },
    data() {
        return {
            innerValue: this._useString(this.value),
            disabledDates: jsDate => {
                if (!this.minDate && !this.maxDate) {
                    return false;
                }

                const date = this.$container.types.jsToDate(jsDate);

                return (this.minDate && date.isBefore(this.minDate, 'day'))
                    || (this.maxDate && date.isAfter(this.maxDate, 'day'));
            }
        }
    },
    watch: {
        value(newValue) {
            this.innerValue = this._useString(newValue);
        }
    },
    beforeMount() {
        this.$material.locale = FRENCH_DATEPICKER_LOCALE;
    },
    methods: {
        _useString(date) {
            return date ? date.format('DD/MM/YYYY') : null;
        },
        _useDate(string) {
            return this.$container.types.stringToDate(string);
        }
    }
}
</script>

<style lang="scss">
.imagine-datepicker {
    &--disabled {
        color: rgba(0, 0, 0, 0.3);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.3);

        label, input {
            color: rgba(0, 0, 0, 0.3);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.3) !important;
        }
    }

    &--dense {
        height: 36px;
        min-height: 36px;
        padding-top: 10px;
        margin-top: 0;

        &.md-has-value.md-field label {
            top: 0;
        }

        &.md-field label {
            top: 10px;
        }
    }

    input {
        width: 100%;
    }

    &.md-field > .md-icon {
        margin: -4px auto;
    }

    &.md-field .md-input-action {
        top: 8px;
    }
}

.md-datepicker-dialog {
    z-index: 101;
}
</style>
