var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "mandate-status" },
    [
      _c(
        "span",
        {
          staticClass: "material-icons imagine-status",
          class: {
            "imagine-status--success":
              !_vm.mandate.isArchive &&
              _vm.mandate.currentLease &&
              _vm.mandate.nbCriticalAlerts <= 0,
            "imagine-status--danger":
              !_vm.mandate.isArchive &&
              _vm.mandate.currentLease &&
              _vm.mandate.nbCriticalAlerts > 0,
            "imagine-status--error":
              !_vm.mandate.isArchive && !_vm.mandate.currentLease,
            "imagine-status--sold": _vm.mandate.isArchive,
          },
        },
        [_vm._v("\n        lens\n    ")]
      ),
      _vm._v(" "),
      _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
        _vm._v(_vm._s(_vm.statusLabel)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }