<template>
    <div class="imagine-checkboxes">
        <p :class="{'imagine-checkboxes__label--disabled': disabled}"
           class="imagine-checkboxes__label"
           v-if="label">
            <span>{{ label }}</span>
            <slot></slot>
        </p>

        <div class="imagine-checkboxes__container">
            <imagine-checkbox :label="choice.label"
                              :name="name + '-' + index"
                              :disabled="disabled || choice.disabled"
                              :key="name + '-' + index"
                              @input="$emit('input', emitedValues)"
                              v-for="(choice,index) in choices"
                              v-model="indexToCheckedMap[index]"/>
        </div>
    </div>
</template>

<script>
import ImagineCheckbox from './ImagineCheckbox.vue';

export default {
    props: {
        'name': {type: String},
        'label': {type: String},
        'disabled': {type: Boolean},
        'choices': {type: Array},
        'value': {type: Array},
    },
    components: {ImagineCheckbox},
    computed: {
        emitedValues() {
            return this.choices
                .filter((choice, index) => this.indexToCheckedMap[index])
                .map(choice => choice.value);
        }
    },
    data() {
        return {
            indexToCheckedMap: this._getIndexToCheckedMapFrom(this.value)
        }
    },
    watch: {
        choices() {
            this.indexToCheckedMap = this._getIndexToCheckedMapFrom(this.value);
        },
        value(newVal) {
            this.indexToCheckedMap = this._getIndexToCheckedMapFrom(newVal);
        }
    },
    methods: {
        _getIndexToCheckedMapFrom(value) {
            if (!this.choices.length) {
                return [];
            }

            let map = [];

            this.choices.forEach((choice, index) => map[index] = (value && value.includes(choice.value)));

            return map;
        }
    }
}
</script>

<style lang="scss">
.imagine-checkboxes {
    display: flex;
    flex-direction: column;

    &__label {
        margin: 0 0 .5rem;

        &.imagine-checkboxes__label--disabled {
            color: rgba(0, 0, 0, 0.37);
            cursor: default;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .imagine-checkbox {
            margin-right: 1rem;
        }
    }
}
</style>
