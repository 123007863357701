import container from '../container';

const repo = container.repos.accountEntry;

export default {
    namespaced: true,
    state: {
        nbRentsToCharge: null,
        transfersOut: [],
        transfersIn: []
    },
    mutations: {},
    actions: {
        chargeRents() {
            return repo.chargeRents();
        },
        reloadRentsToCharge(context) {
            return repo.rentsToCharge()
                .then(rents => context.state.nbRentsToCharge = rents.count);
        },
        save(context, data) {
            return repo.save(data);
        },
        saveLetters(context, data) {
            return repo.saveLetters(data);
        },
        remove(context, id) {
            return repo.delete(id);
        }
    }
};
