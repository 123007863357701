var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("imagine-input", {
    attrs: {
      label: _vm.label,
      name: _vm.name,
      required: _vm.required,
      help: _vm.help,
      disabled: _vm.disabled,
    },
    on: {
      input: function ($event) {
        _vm.$emit("input", _vm._fromFormatedStringToMoney(_vm.innerValue))
      },
    },
    model: {
      value: _vm.innerValue,
      callback: function ($$v) {
        _vm.innerValue = $$v
      },
      expression: "innerValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }