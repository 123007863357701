var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-modal--wide",
      attrs: { "button-label": "Envoyer", title: "Quittance de loyer" },
      on: {
        act: function ($event) {
          return _vm.sendReceipt()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-select-period", {
            attrs: { periods: _vm.lease.receiptPeriods },
            model: {
              value: _vm.period,
              callback: function ($$v) {
                _vm.period = $$v
              },
              expression: "period",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "imagine-button",
            {
              on: {
                click: function ($event) {
                  return _vm.externalRedirectTo(_vm.previewUrl)
                },
              },
            },
            [_vm._v("Prévisualiser")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }