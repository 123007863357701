<template>
    <span class="block-division-status">
        <span :class="{
            'imagine-status--available': division && !division.buyers,
            'imagine-status--option': division && division.buyers && division.isOption,
            'imagine-status--booked': division && division.buyers && !division.isOption}"
              class="material-icons imagine-status">
            lens
        </span>
        <md-tooltip md-direction="right">{{ statusLabel }}</md-tooltip>
    </span>
</template>

<script>
export default {
    props: ['division'],
    computed: {
        statusLabel() {
            if (!this.division.buyers) {
                return 'Disponible à la vente';
            }

            if (this.division.isOption) {
                return 'En option (reste ' + this.division.optionRemainingHours + 'h)';
            }

            return 'Vente en cours';
        }
    }
}
</script>

<style lang="scss">
.block-division-status {
    .imagine-status {
        font-size: 1.2rem;
    }
}
</style>
