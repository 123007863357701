var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      attrs: { "button-label": _vm.isEditing ? "Enregistrer" : "Ajouter" },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
        act: function ($event) {
          _vm.isEditing ? _vm.updateStage() : _vm.createStage()
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-input", {
            staticClass: "imagine-form__row__field",
            attrs: { name: "building", label: "Bâtiment (si pertinent)" },
            model: {
              value: _vm.building,
              callback: function ($$v) {
                _vm.building = $$v
              },
              expression: "building",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-input", {
            staticClass: "imagine-form__row__field",
            attrs: { name: "label", label: "Libellé", required: true },
            model: {
              value: _vm.label,
              callback: function ($$v) {
                _vm.label = $$v
              },
              expression: "label",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-input", {
            staticClass: "imagine-form__row__field",
            attrs: {
              name: "progress",
              label: "Appels de fonds (%)",
              required: true,
              type: "number",
              min: 0,
              max: 99,
            },
            model: {
              value: _vm.stageProgress,
              callback: function ($$v) {
                _vm.stageProgress = $$v
              },
              expression: "stageProgress",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: {
              name: "estimatedDate",
              label: "Date estimée",
              required: true,
            },
            model: {
              value: _vm.estimatedDate,
              callback: function ($$v) {
                _vm.estimatedDate = $$v
              },
              expression: "estimatedDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: { name: "actualDate", label: "Date réelle" },
            model: {
              value: _vm.actualDate,
              callback: function ($$v) {
                _vm.actualDate = $$v
              },
              expression: "actualDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticStyle: { "margin-bottom": ".5rem" } },
        [
          _c("imagine-input", {
            staticClass: "imagine-form__row__field",
            attrs: {
              label: "Organisme des attestations",
              name: "approval",
              help: "Si différent de " + _vm.defaultApproval,
            },
            model: {
              value: _vm.approval,
              callback: function ($$v) {
                _vm.approval = $$v
              },
              expression: "approval",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }