<template>
    <div class="contacts">
        <contact-search-engine :mode="mode"/>

        <div :class="{'contacts__count--filtered': nbFiltered < nbTotal}"
             class="contacts__count">
            <div class="contacts__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">{{ formatNumber(nbFiltered) }} / </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                contact{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbFiltered <= 0">
                Aucun contact trouvé.
            </div>

            <div class="contacts__count__sort"
                 v-show="nbFiltered > 1">
                Triés par nom
            </div>
        </div>

        <slot name="header"/>

        <div class="mdc-list mdc-list--two-line" v-show="displayed.length > 0">
            <template v-for="contact in displayed">
                <slot v-bind:contact="contact"/>
                <div class="mdc-list-divider"></div>
            </template>

            <div @click="displayMore()"
                 class="contacts__see-more"
                 v-show="isDisplayLimited">
                <imagine-button>Voir plus de contacts</imagine-button>
            </div>
        </div>
    </div>
</template>

<script>
import ContactSearchEngine from '../elements/ContactSearchEngine.vue';
import ImagineButton from '../../components/ImagineButton.vue';
import {mapState} from 'vuex';

export default {
    props: ['mode'],
    components: {
        ContactSearchEngine,
        ImagineButton
    },
    computed: {
        ...mapState('contact', {
            query: state => state.query,
            displayed: state => state.displayed,
            nbTotal: state => state.nbTotal,
            nbFiltered: state => state.nbFiltered,
            maxDisplayed: state => state.maxDisplayed
        }),
        isDisplayLimited() {
            if (this.displayed.length <= 0) {
                return false;
            }

            return this.displayed.length < this.nbFiltered;
        }
    },
    methods: {
        displayMore() {
            this.load();
            this.$store.dispatch('contact/displayMore')
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/config';
@import '~@material/list/mdc-list';

.contacts {
    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.contacts__count--filtered {
            .contacts__count__count {
                font-weight: 500;
            }
        }
    }

    &__contact {
        &__lastname, &__company {
            font-weight: 500;
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }
}
</style>
