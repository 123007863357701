<template>
    <transition name="fade">
        <imagine-icon-button @click="smoothScroll"
                             class="imagine-no-ripple imagine-scroll-top mdc-elevation--z3"
                             icon="keyboard_arrow_up"
                             v-show="visible"/>
    </transition>
</template>

<script>
import ImagineIconButton from './ImagineIconButton.vue';
import throttle from 'lodash-es/throttle';

export default {
    components: {ImagineIconButton},
    data() {
        return {
            visible: false
        }
    },
    methods: {
        smoothScroll() {
            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(this.smoothScroll);
                window.scrollTo(0, Math.floor(currentScroll - (currentScroll / 5)))
            }
        },
        displayScrollTop: throttle(function () {
            this.visible = this.shouldDisplayScrollTop();
        }, 300),
        shouldDisplayScrollTop() {
            return window.pageYOffset > 260;
        }
    },
    created() {
        window.addEventListener('scroll', this.displayScrollTop, {passive: true});
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.displayScrollTop);
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.imagine-scroll-top.mdc-icon-button {
    border: 2px solid #000000;
    border-color: $mdc-theme-primary;
    border-color: var(--mdc-theme-primary, $mdc-theme-primary);
    border-radius: 3rem;
    color: $mdc-theme-primary;
    color: var(--mdc-theme-primary, $mdc-theme-primary);
    position: fixed;
    bottom: 1rem;
    padding: 10px;
    z-index: 10;
    right: 1rem;
    background: white;
}
</style>
