<template>
    <imagine-modal :closable="true"
                   @act="calculate()"
                   @close="closeCalculator()"
                   buttonLabel="Simuler"
                   class="imagine-calculator"
                   title="Simulateur emprunt">

        <form class="imagine-form" ref="calculatorForm">
            <div class="imagine-form__row">
                <imagine-money-input class="imagine-form__row__field"
                                     label="Prix (XPF)"
                                     name="purchasePrice"
                                     required
                                     v-model="purchasePrice"/>

                <imagine-money-input class="imagine-form__row__field"
                                     label="Apport (XPF)"
                                     name="provision"
                                     v-model="provision"/>
            </div>

            <div class="imagine-form__row">
                <imagine-input class="imagine-form__row__field"
                               label="Durée (années)"
                               min="0"
                               name="duration"
                               required
                               step="1"
                               type="number"
                               v-model="duration"/>

                <imagine-input class="imagine-form__row__field"
                               label="Taux d'intérêt (%)"
                               max="100"
                               min="0"
                               name="interestRate"
                               required
                               step="any"
                               type="number"
                               v-model="interestRate"/>
            </div>
        </form>

        <transition name="slideY">
            <div class="imagine-calculator__result" v-show="showResult">
                <p>Le montant des mensualités s'élève à <br/><strong>{{ loanMonthlyCost }}</strong>.</p>
                <p>Le coût du crédit sera de {{ loanTotalCost }}.</p>
            </div>
        </transition>
    </imagine-modal>
</template>

<script>
import ImagineModal from './ImagineModal.vue';
import ImagineInput from './ImagineInput.vue';
import ImagineMoneyInput from './ImagineMoneyInput.vue';

export default {
    components: {ImagineModal, ImagineInput, ImagineMoneyInput},
    data() {
        return {
            purchasePrice: null,
            provision: null,
            duration: this.getLoanDefaultDurationYears(),
            showResult: false,
            interestRate: this.getLoanDefaultInterestRate(),
            loanMonthlyCost: null,
            loanTotalCost: null
        }
    },
    methods: {
        calculate() {
            if (!this.$refs.calculatorForm.reportValidity()) {
                return;
            }

            const insuranceRate = 0;
            const interestRate = parseFloat(this.interestRate) / 100;
            const durationYear = parseInt(this.duration);

            const borrowed = this.purchasePrice.subtract(this.provision ? this.provision : this.$container.types.makeMoney(0));
            const insuranceCost = borrowed.multiply(insuranceRate).multiply(durationYear);
            const loanCost = borrowed.multiply((durationYear * interestRate) / (1 - Math.pow(1 + interestRate / 12, -(12 * durationYear))) - 1);
            const loanTotalCost = loanCost.add(insuranceCost);

            this.loanTotalCost = this.formatMoney(loanTotalCost);
            this.loanMonthlyCost = this.formatMoney(borrowed.add(loanTotalCost).divide(durationYear).divide(12));
            this.showResult = true;
        },
        closeCalculator() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss">
.imagine-calculator {
    &__result {
        padding: 1rem;
        text-align: center;
    }
}
</style>
