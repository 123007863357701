var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "sale",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.$mq !== "phone"
                ? [
                    _vm.hasPreBooking
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: {
                            title: _vm.displayPastErrors
                              ? "Masquer les retards passés"
                              : "Voir les retards passés",
                            icon: _vm.displayPastErrors
                              ? "report"
                              : "report_off",
                          },
                          on: {
                            click: function ($event) {
                              _vm.displayPastErrors = !_vm.displayPastErrors
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sale
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { title: "Fiche bien", icon: "home_work" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("property", {
                                id: _vm.sale.property.id,
                              })
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.sale
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { title: "Fiche résidence", icon: "business" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("block", {
                                id: _vm.sale.property.blockId,
                              })
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("imagine-icon-button", {
                      staticClass: "mdc-top-app-bar__action-item",
                      attrs: {
                        disabled: !_vm.canSendMail,
                        title: "Envoyer email acheteur(s)",
                        icon: "email",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openMail()
                        },
                      },
                    }),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.sale && _vm.canCancel
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: { title: "Annuler la vente", icon: "cancel" },
                    on: {
                      click: function ($event) {
                        _vm.showCancelModal = true
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.sale && _vm.canDelete
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: { title: "Supprimer la vente", icon: "delete" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "imagine-container" },
        [
          _c(
            "form",
            {
              staticClass: "imagine-form sale__option",
              attrs: { autocomplete: "off" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  !_vm.hasStarted ? _vm.saveOption() : _vm.saveHeader()
                },
              },
            },
            [
              _vm.isOptionOngoing
                ? _c(
                    "imagine-alert",
                    {
                      staticClass: "sale__option__alert",
                      attrs: { type: "info" },
                    },
                    [
                      _vm._v(
                        "\n                Une option est en cours sur ce bien.\n                "
                      ),
                      _c("strong", [
                        _vm._v(
                          "\n                    Il reste " +
                            _vm._s(_vm.optionDurationHours) +
                            "h pour la validation du contrat de pré-réservation.\n                "
                        ),
                      ]),
                    ]
                  )
                : !_vm.hasStarted && _vm.maxOptionDurationHours
                ? _c(
                    "imagine-alert",
                    {
                      staticClass: "sale__option__alert",
                      attrs: { type: "info" },
                    },
                    [
                      _vm._v(
                        "\n                Une option permet de bloquer un bien pendant " +
                          _vm._s(_vm.maxOptionDurationHours) +
                          "h maximum. Au delà,\n                l'option est automatiquement supprimée.\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.buyerSlots, function (buyerSlot, slot) {
                return (
                  _vm.isCanceled ? _vm.nbBuyers > slot : _vm.nbBuyers >= slot
                )
                  ? _c(
                      "div",
                      { staticClass: "imagine-form__row sale__buyer" },
                      [
                        _c("imagine-contact-autocomplete", {
                          staticClass: "imagine-form__row__field",
                          attrs: {
                            label: "Acquéreur " + (slot + 1),
                            "name-only": "",
                            required: slot === 0,
                            disabled: _vm.isCanceled,
                            name: "buyer",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.selectBuyer()
                            },
                          },
                          model: {
                            value: buyerSlot.contact,
                            callback: function ($$v) {
                              _vm.$set(buyerSlot, "contact", $$v)
                            },
                            expression: "buyerSlot.contact",
                          },
                        }),
                        _vm._v(" "),
                        buyerSlot.contact
                          ? _c("imagine-icon-button", {
                              staticClass:
                                "sale__buyer__goto imagine-no-ripple",
                              attrs: { icon: "contacts", active: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("contact", {
                                    id: buyerSlot.contact.id,
                                  })
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.canFilterSalesRep()
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row sale__meta" },
                    [
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.salesRepChoices,
                          required: true,
                          disabled: _vm.isCanceled,
                          label: "Négociateur",
                          name: "salesrep",
                        },
                        model: {
                          value: _vm.salesRep,
                          callback: function ($$v) {
                            _vm.salesRep = $$v
                          },
                          expression: "salesRep",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.otherSalesRepChoices,
                          disabled:
                            _vm.otherSalesRepChoices.length <= 0 ||
                            _vm.isCanceled,
                          label: "Autre négociateur",
                          name: "othersalesrep",
                        },
                        model: {
                          value: _vm.otherSalesRep,
                          callback: function ($$v) {
                            _vm.otherSalesRep = $$v
                          },
                          expression: "otherSalesRep",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__row" },
                [
                  _c("imagine-textarea", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Notes",
                      name: "comment",
                      disabled: _vm.isCanceled,
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "imagine-form__actions" },
                [
                  !_vm.isCanceled
                    ? _c(
                        "imagine-button",
                        {
                          attrs: {
                            primary: "",
                            disabled: !_vm.salesRep,
                            type: "submit",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                !_vm.hasStarted
                                  ? "Poser option"
                                  : "Enregistrer entête"
                              ) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "md-steppers",
            {
              attrs: {
                "md-active-step": _vm.activeStep,
                "md-linear": "",
                "md-vertical": "",
              },
              on: {
                "update:mdActiveStep": function ($event) {
                  _vm.activeStep = $event
                },
                "update:md-active-step": function ($event) {
                  _vm.activeStep = $event
                },
              },
            },
            [
              _c(
                "md-step",
                {
                  attrs: {
                    "md-editable":
                      _vm.activeStep === "prebooking" || _vm.hasPreBooking,
                    "md-label": _vm.prebookingStepTitle,
                    "md-error":
                      _vm.activeStep === "prebooking" && !_vm.isPriceConsistent
                        ? "Prix incohérent"
                        : null,
                    "md-done": _vm.hasPreBooking,
                    id: "prebooking",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "imagine-form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.savePrebooking.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Date",
                              "max-date": _vm.today(),
                              required: true,
                              violation: _vm.errors.prebookingDate,
                              disabled: _vm.isCanceled,
                              name: "prebookingDate",
                            },
                            model: {
                              value: _vm.prebookingDate,
                              callback: function ($$v) {
                                _vm.prebookingDate = $$v
                              },
                              expression: "prebookingDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Document",
                              disabled: _vm.isCanceled,
                              name: "prebookingUrl",
                            },
                            model: {
                              value: _vm.prebookingUrl,
                              callback: function ($$v) {
                                _vm.prebookingUrl = $$v
                              },
                              expression: "prebookingUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "imagine-form__sep" }),
                      _vm._v(" "),
                      _vm.publicPrice
                        ? _c(
                            "div",
                            {
                              staticClass: "sale__price-consistency",
                              class: {
                                "sale__price-consistency--error":
                                  !_vm.isPriceConsistent,
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Prix public actuellement proposé : " +
                                  _vm._s(_vm.formatMoney(_vm.publicPrice)) +
                                  "\n                        "
                              ),
                              !_vm.isPriceConsistent
                                ? [
                                    _c("br"),
                                    _vm._v(
                                      "Le prix public doit correspondre au prix total (parking + hors parking).\n                        "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-money-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              required: true,
                              label: "Prix hors parking (XPF)",
                              disabled: _vm.isCanceled,
                              invalid: !_vm.isPriceConsistent,
                              name: "price",
                            },
                            model: {
                              value: _vm.price,
                              callback: function ($$v) {
                                _vm.price = $$v
                              },
                              expression: "price",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-money-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Prix parking (XPF)",
                              disabled: _vm.isCanceled,
                              invalid: !_vm.isPriceConsistent,
                              name: "parkingPrice",
                            },
                            model: {
                              value: _vm.parkingPrice,
                              callback: function ($$v) {
                                _vm.parkingPrice = $$v
                              },
                              expression: "parkingPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-money-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Remise (XPF)",
                              disabled: _vm.isCanceled,
                              name: "discount",
                            },
                            model: {
                              value: _vm.discount,
                              callback: function ($$v) {
                                _vm.discount = $$v
                              },
                              expression: "discount",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Libellé remise",
                              disabled: _vm.isCanceled,
                              name: "discountLabel",
                            },
                            model: {
                              value: _vm.discountLabel,
                              callback: function ($$v) {
                                _vm.discountLabel = $$v
                              },
                              expression: "discountLabel",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isCanceled
                        ? _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _vm.isOptionOngoing
                                ? _c(
                                    "imagine-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelOption()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Lever l'option\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "imagine-button",
                                {
                                  attrs: {
                                    primary: "",
                                    disabled:
                                      !_vm.hasPreBooking &&
                                      !_vm.isPriceConsistent,
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Valider\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "md-step",
                {
                  attrs: {
                    "md-editable":
                      (!_vm.isCanceled && _vm.activeStep === "booking") ||
                      _vm.hasBooking,
                    "md-error": _vm.bookingStepError,
                    "md-label": _vm.bookingStepTitle,
                    "md-done": _vm.hasBooking,
                    id: "booking",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "imagine-form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveBooking.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.prebookingDate,
                              required: true,
                              label: "Date",
                              violation: _vm.errors.bookingDate,
                              disabled: _vm.isCanceled,
                              help: _vm.getLimitDateHelp(
                                _vm.sale ? _vm.sale.bookingDateLimit : null
                              ),
                              name: "bookingDate",
                            },
                            model: {
                              value: _vm.bookingDate,
                              callback: function ($$v) {
                                _vm.bookingDate = $$v
                              },
                              expression: "bookingDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Document",
                              disabled: _vm.isCanceled,
                              name: "bookingUrl",
                            },
                            model: {
                              value: _vm.bookingUrl,
                              callback: function ($$v) {
                                _vm.bookingUrl = $$v
                              },
                              expression: "bookingUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-money-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              label: "Frais de notaire (XPF)",
                              disabled: _vm.isCanceled,
                              name: "lawyerFeesAmount",
                            },
                            model: {
                              value: _vm.lawyerFeesAmount,
                              callback: function ($$v) {
                                _vm.lawyerFeesAmount = $$v
                              },
                              expression: "lawyerFeesAmount",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.purposeChoices,
                              required: true,
                              label: "Type d'achat",
                              disabled: _vm.isCanceled,
                              name: "purpose",
                            },
                            model: {
                              value: _vm.purpose,
                              callback: function ($$v) {
                                _vm.purpose = $$v
                              },
                              expression: "purpose",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.taxExemptionChoices,
                              label: "Défiscalisation",
                              disabled: _vm.isCanceled,
                              name: "taxExemption",
                            },
                            model: {
                              value: _vm.taxExemption,
                              callback: function ($$v) {
                                _vm.taxExemption = $$v
                              },
                              expression: "taxExemption",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.financingTypeChoices,
                              required: true,
                              disabled: _vm.isCanceled,
                              label: "Financement",
                              name: "financingType",
                            },
                            model: {
                              value: _vm.financingType,
                              callback: function ($$v) {
                                _vm.financingType = $$v
                              },
                              expression: "financingType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "slideY" } }, [
                        _vm.financingType === "mortgage"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "imagine-form__row imagine-form__row--limited",
                                },
                                [
                                  _c("imagine-select", {
                                    staticClass: "imagine-form__row__field",
                                    attrs: {
                                      choices: _vm.bankManagerChoices,
                                      label: "Chargé de compte",
                                      disabled: _vm.isCanceled,
                                      name: "bankManager",
                                    },
                                    model: {
                                      value: _vm.bankManager,
                                      callback: function ($$v) {
                                        _vm.bankManager = $$v
                                      },
                                      expression: "bankManager",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "imagine-form__row__field imagine-form__row__field--readonly",
                                    },
                                    [
                                      _vm.selectedBankManager
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.selectedBankManager.email
                                                ) +
                                                "\n                                        " +
                                                _vm._s(
                                                  _vm.selectedBankManager.phone
                                                    ? _vm.selectedBankManager
                                                        .phone.readable
                                                    : ""
                                                ) +
                                                "\n                                    "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "imagine-form__row imagine-form__row--limited",
                                },
                                [
                                  _c("imagine-money-input", {
                                    staticClass: "imagine-form__row__field",
                                    attrs: {
                                      label: "Montant emprunt envisagé (XPF)",
                                      disabled: _vm.isCanceled,
                                      name: "loanEstimatedAmount",
                                    },
                                    model: {
                                      value: _vm.loanEstimatedAmount,
                                      callback: function ($$v) {
                                        _vm.loanEstimatedAmount = $$v
                                      },
                                      expression: "loanEstimatedAmount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("imagine-input", {
                                    staticClass: "imagine-form__row__field",
                                    attrs: {
                                      label: "Durée crédit (années)",
                                      min: "1",
                                      disabled: _vm.isCanceled,
                                      name: "loanEstimatedDurationYears",
                                      step: "1",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.loanEstimatedDurationYears,
                                      callback: function ($$v) {
                                        _vm.loanEstimatedDurationYears = $$v
                                      },
                                      expression: "loanEstimatedDurationYears",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("imagine-input", {
                                    staticClass: "imagine-form__row__field",
                                    attrs: {
                                      label: "Taux max (%)",
                                      disabled: _vm.isCanceled,
                                      min: "0",
                                      name: "loanMaximumRate",
                                      step: "any",
                                      type: "number",
                                    },
                                    model: {
                                      value: _vm.loanMaximumRate,
                                      callback: function ($$v) {
                                        _vm.loanMaximumRate = $$v
                                      },
                                      expression: "loanMaximumRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      !_vm.isCanceled
                        ? _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.cancelBooking()
                                    },
                                  },
                                },
                                [_vm._v("Annuler réservation")]
                              ),
                              _vm._v(" "),
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Valider")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "md-step",
                {
                  class: {
                    "sale__step--force-disabled":
                      _vm.activeStep !== "financing" && !_vm.hasFinancing,
                  },
                  attrs: {
                    "md-editable":
                      _vm.activeStep === "financing" || _vm.hasFinancing,
                    "md-error": _vm.financingStepError,
                    "md-label": _vm.financingStepTitle,
                    "md-done": _vm.hasFinancing,
                    id: "financing",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "imagine-form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveFinancing.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.bankManagerChoices,
                              label: "Chargé de compte",
                              disabled: _vm.isCanceled,
                              name: "bankManager",
                            },
                            model: {
                              value: _vm.bankManager,
                              callback: function ($$v) {
                                _vm.bankManager = $$v
                              },
                              expression: "bankManager",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "imagine-form__row__field imagine-form__row__field--readonly",
                            },
                            [
                              _vm.selectedBankManager
                                ? _c("div", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.selectedBankManager.email) +
                                        "\n                                " +
                                        _vm._s(
                                          _vm.selectedBankManager.phone
                                            ? _vm.selectedBankManager.phone
                                                .readable
                                            : ""
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.bookingDate,
                              required: true,
                              disabled: _vm.isCanceled,
                              label: "Date dépôt dossier",
                              help: _vm.getLimitDateHelp(
                                _vm.sale ? _vm.sale.loanDepositDateLimit : null
                              ),
                              name: "loanDepositDate",
                              violation: _vm.errors.loanDepositDate,
                            },
                            model: {
                              value: _vm.loanDepositDate,
                              callback: function ($$v) {
                                _vm.loanDepositDate = $$v
                              },
                              expression: "loanDepositDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Attestation dépôt",
                              name: "loanDepositUrl",
                              disabled: _vm.isCanceled,
                            },
                            model: {
                              value: _vm.loanDepositUrl,
                              callback: function ($$v) {
                                _vm.loanDepositUrl = $$v
                              },
                              expression: "loanDepositUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.loanDepositDate,
                              required:
                                !!_vm.sale && !!_vm.sale.loanDepositDate,
                              label: "Date accord de principe",
                              name: "loanAgreementDate",
                              disabled: _vm.isCanceled,
                              violation: _vm.errors.loanAgreementDate,
                              help: _vm.getLimitDateHelp(
                                _vm.sale
                                  ? _vm.sale.loanAgreementDateLimit
                                  : null
                              ),
                            },
                            model: {
                              value: _vm.loanAgreementDate,
                              callback: function ($$v) {
                                _vm.loanAgreementDate = $$v
                              },
                              expression: "loanAgreementDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Accord de principe",
                              name: "bookingUrl",
                              disabled: _vm.isCanceled,
                            },
                            model: {
                              value: _vm.loanAgreementUrl,
                              callback: function ($$v) {
                                _vm.loanAgreementUrl = $$v
                              },
                              expression: "loanAgreementUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.loanDepositDate,
                              required:
                                !!_vm.sale && !!_vm.sale.loanAgreementDate,
                              violation: _vm.errors.loanIssueDate,
                              label: "Date offre prêt",
                              help: _vm.getLimitDateHelp(
                                _vm.sale ? _vm.sale.loanIssueDateLimit : null
                              ),
                              name: "loanIssueDate",
                              disabled: _vm.isCanceled,
                            },
                            model: {
                              value: _vm.loanIssueDate,
                              callback: function ($$v) {
                                _vm.loanIssueDate = $$v
                              },
                              expression: "loanIssueDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Offre de prêt",
                              disabled: _vm.isCanceled,
                              name: "loanIssueUrl",
                            },
                            model: {
                              value: _vm.loanIssueUrl,
                              callback: function ($$v) {
                                _vm.loanIssueUrl = $$v
                              },
                              expression: "loanIssueUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.loanTypeChoices,
                              label: "Type cautionnement",
                              disabled: _vm.isCanceled,
                              name: "loanType",
                            },
                            model: {
                              value: _vm.loanType,
                              callback: function ($$v) {
                                _vm.loanType = $$v
                              },
                              expression: "loanType",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.loanGuaranteeTypeChoices,
                              label: "Type assurance",
                              disabled: _vm.isCanceled,
                              name: "loanGuaranteeType",
                            },
                            model: {
                              value: _vm.loanGuaranteeType,
                              callback: function ($$v) {
                                _vm.loanGuaranteeType = $$v
                              },
                              expression: "loanGuaranteeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "slideY" } }, [
                        _vm.loanGuaranteeType === "external"
                          ? _c(
                              "div",
                              { staticClass: "imagine-form__row" },
                              [
                                _c("imagine-textarea", {
                                  staticClass: "imagine-form__row__field",
                                  attrs: {
                                    label: "Suivi assurance",
                                    disabled: _vm.isCanceled,
                                    name: "loanGuaranteeTracking",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      !_vm.isCanceled
                        ? _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.cancelFinancing()
                                    },
                                  },
                                },
                                [_vm._v("Annuler financement")]
                              ),
                              _vm._v(" "),
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Valider")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "md-step",
                {
                  attrs: {
                    "md-editable":
                      _vm.activeStep === "notification" || _vm.hasNotification,
                    "md-label": _vm.notificationStepTitle,
                    "md-done": _vm.hasNotification,
                    id: "notification",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "imagine-form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveNotification.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.loanIssueDate,
                              required: true,
                              label: "Date",
                              violation: _vm.errors.notificationDate,
                              disabled: _vm.isCanceled,
                              name: "notificationDate",
                            },
                            model: {
                              value: _vm.notificationDate,
                              callback: function ($$v) {
                                _vm.notificationDate = $$v
                              },
                              expression: "notificationDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.lawyerChoices,
                              required: true,
                              label: "Notaire",
                              violation: _vm.errors.lawyer,
                              name: "lawyer",
                              disabled: _vm.isCanceled,
                            },
                            model: {
                              value: _vm.lawyer,
                              callback: function ($$v) {
                                _vm.lawyer = $$v
                              },
                              expression: "lawyer",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-select", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              choices: _vm.otherLawyerChoices,
                              disabled:
                                _vm.otherLawyerChoices.length <= 0 ||
                                _vm.isCanceled,
                              label: "Autre notaire",
                              name: "otherlawyer",
                            },
                            model: {
                              value: _vm.otherLawyer,
                              callback: function ($$v) {
                                _vm.otherLawyer = $$v
                              },
                              expression: "otherLawyer",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isCanceled
                        ? _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.cancelNotification()
                                    },
                                  },
                                },
                                [_vm._v("Annuler notification")]
                              ),
                              _vm._v(" "),
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Valider")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "md-step",
                {
                  attrs: {
                    "md-editable":
                      _vm.activeStep === "certificate" || _vm.hasCertificate,
                    "md-error": _vm.certificateStepError,
                    "md-label": _vm.certificateStepTitle,
                    "md-done": _vm.hasCertificate,
                    id: "certificate",
                  },
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "imagine-form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.saveCertificate.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "imagine-form__row imagine-form__row--limited",
                        },
                        [
                          _c("imagine-date-picker", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              "max-date": _vm.today(),
                              "min-date": _vm.notificationDate,
                              required: true,
                              label: "Date",
                              disabled: _vm.isCanceled,
                              help: _vm.getLimitDateHelp(
                                _vm.sale ? _vm.sale.certificateDateLimit : null
                              ),
                              name: "certificateDate",
                            },
                            model: {
                              value: _vm.certificateDate,
                              callback: function ($$v) {
                                _vm.certificateDate = $$v
                              },
                              expression: "certificateDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("imagine-file-input", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              accept: "application/pdf",
                              label: "Document",
                              disabled: _vm.isCanceled,
                              name: "certificateUrl",
                            },
                            model: {
                              value: _vm.certificateUrl,
                              callback: function ($$v) {
                                _vm.certificateUrl = $$v
                              },
                              expression: "certificateUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isCanceled
                        ? _c(
                            "div",
                            { staticClass: "imagine-form__actions" },
                            [
                              _c(
                                "imagine-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.cancelCertificate()
                                    },
                                  },
                                },
                                [_vm._v("Annuler acte de vente")]
                              ),
                              _vm._v(" "),
                              _c(
                                "imagine-button",
                                { attrs: { primary: "", type: "submit" } },
                                [_vm._v("Valider")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showDeleteModal
        ? _c(
            "imagine-modal",
            {
              attrs: {
                "button-label": "Supprimer la vente",
                title: "Supprimer la vente",
              },
              on: {
                act: function ($event) {
                  return _vm.deleteSale()
                },
                close: function ($event) {
                  _vm.showDeleteModal = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Etes vous sûr de vouloir supprimer définitivement cette vente ?"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCancelModal
        ? _c(
            "imagine-modal",
            {
              staticClass: "imagine-modal--wide",
              attrs: {
                "button-disabled": !_vm.cancelReason,
                "button-label": "Annuler la vente",
                title: "Annuler la vente",
              },
              on: {
                act: function ($event) {
                  return _vm.cancelSale()
                },
                close: function ($event) {
                  _vm.showCancelModal = false
                },
              },
            },
            [
              _c(
                "p",
                [
                  _c("imagine-select", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Cause",
                      name: "reason",
                      required: true,
                      choices: _vm.references.cancelSaleReasonChoices(),
                    },
                    model: {
                      value: _vm.cancelReason,
                      callback: function ($$v) {
                        _vm.cancelReason = $$v
                      },
                      expression: "cancelReason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("imagine-textarea", {
                    staticClass: "imagine-form__row__field",
                    attrs: { label: "Observations", name: "note" },
                    model: {
                      value: _vm.cancelNote,
                      callback: function ($$v) {
                        _vm.cancelNote = $$v
                      },
                      expression: "cancelNote",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("imagine-file-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Document associé",
                      name: "url",
                      accept: "application/pdf",
                    },
                    model: {
                      value: _vm.cancelUrl,
                      callback: function ($$v) {
                        _vm.cancelUrl = $$v
                      },
                      expression: "cancelUrl",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }