<template>
    <div class="imagine-secondary-zone contacts__filters search__filters" v-if="mode">
        <transition name="slideY">
            <div class="search__filters__search" v-if="mode === 'query'">
                <imagine-input :autocomplete="false"
                               :clearable="true"
                               :required="false"
                               icon="search"
                               label="Mot clé"
                               name="query"
                               v-model="query"/>
            </div>
        </transition>

        <transition name="slideY">
            <div class="contacts__filters__filters" v-if="mode === 'filters'">
                <contact-search-engine-group
                    class="contacts__filters__filters__group--infos"
                    :used="isFilteringWith(['salesRep', 'area', 'isVIP', 'isFromPF', 'hasEmail', 'areMyProspects', 'lastContact'])"
                    title="Informations">
                    <div class="contacts__filters__filters__group__body__group">
                        <imagine-select :choices="references.salesRepChoices(employees)"
                                        label="Négociateur"
                                        v-if="canFilterSalesRep()"
                                        v-model="salesRep"/>
                        <div @click="areMyProspects = !areMyProspects"
                             class="contacts__filters__filters__group__body__group__criteria"
                             v-else>
                            <span>Mes prospects</span>
                            <imagine-switch v-model="areMyProspects"/>
                        </div>
                        <div @click="isVIP = !isVIP"
                             class="contacts__filters__filters__group__body__group__criteria">
                            <span>VIP uniquement</span>
                            <imagine-switch v-model="isVIP"/>
                        </div>
                        <imagine-select :choices="areas()"
                                        label="Territoire"
                                        v-model="area"/>

                        <template v-if="false" id="oldcriterias">
                            <imagine-select :choices="lastContacts()"
                                            label="Dernière mise à jour"
                                            v-model="lastContact"/>
                            <div @click="isFromPF = !isFromPF"
                                 class="contacts__filters__filters__group__body__group__criteria">
                                <span>Résident polynésien</span>
                                <imagine-switch v-model="isFromPF"/>
                            </div>
                            <div @click="hasEmail = !hasEmail"
                                 class="contacts__filters__filters__group__body__group__criteria">
                                <span>Avec email</span>
                                <imagine-switch v-model="hasEmail"/>
                            </div>
                        </template>
                    </div>
                </contact-search-engine-group>

                <contact-search-engine-group
                    class="contacts__filters__filters__group--prospect"
                    :used="isFilteringWith(['prospectTypes', 'prospectBlock', 'prospectPropertyType', 'prospectArea', 'prospectRentBudget', 'prospectPurchaseBudget', 'prospectNbRoomsMin'])"
                    title="Prospect">
                    <div class="contacts__filters__filters__group__body__group">
                        <imagine-select label="Type"
                                        :choices="references.prospectTransactionChoices()"
                                        v-model="prospectTypes"/>
                        <imagine-select label="Type de bien"
                                        :choices="references.propertyTypeChoices()"
                                        v-model="prospectPropertyType"/>
                        <imagine-select label="Budget d'achat"
                                        v-if="isPurchaseProspect || isNeitherPurchaseNorRentProspect"
                                        :choices="references.buyBudgetChoices()"
                                        v-model="prospectPurchaseBudget"/>
                        <imagine-select label="Budget de location"
                                        v-else
                                        :choices="references.rentBudgetChoices()"
                                        v-model="prospectRentBudget"/>
                    </div>
                    <div class="contacts__filters__filters__group__body__group">
                        <imagine-select label="Secteur géographique"
                                        :choices="references.areaChoices()"
                                        :disabled="!!prospectBlock"
                                        v-model="prospectArea"/>
                        <imagine-select label="Résidence"
                                        :choices="references.groupedBlockChoices(blocks)"
                                        v-model="prospectBlock"/>
                        <imagine-select label="Nb. pièces min."
                                        v-model="prospectNbRoomsMin"
                                        :choices="nbRoomsChoices"/>
                    </div>
                </contact-search-engine-group>

                <contact-search-engine-group
                    class="contacts__filters__filters__group--client"
                    :used="isFilteringWith(['clientTypes', 'clientBlock', 'clientPropertyPurpose'])"
                    title="Client">
                    <div class="contacts__filters__filters__group__body__group">
                        <imagine-select label="Type"
                                        :choices="references.clientTransactionChoices()"
                                        v-model="clientTypes"/>
                        <imagine-select :choices="references.blockChoices(blocks)"
                                        label="Résidences"
                                        :multiple="true"
                                        :implicit-required="true"
                                        v-model="clientBlock"/>
                        <imagine-select :choices="references.purchaseTypeChoices()"
                                        label="Investissement"
                                        v-model="clientPropertyPurpose"/>
                    </div>
                </contact-search-engine-group>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import ImagineSelect from '../../components/ImagineSelect.vue';
import ImagineSwitch from '../../components/ImagineSwitch.vue';
import ImagineInput from '../../components/ImagineInput.vue';
import ContactSearchEngineGroup from './ContactSearchEngineGroup.vue';
import debounce from 'lodash-es/debounce';

export default {
    props: ['mode'],
    components: {ContactSearchEngineGroup, ImagineSelect, ImagineInput, ImagineSwitch},
    computed: {
        ...mapState(['references']),
        ...mapState('block', {blocks: state => state.all}),
        ...mapGetters('contact', ['filters']),
        ...mapState('employee', {employees: state => state.all}),
        query: {
            get() {
                return this.$store.state.contact.query;
            },
            set(value) {
                this.$store.commit('contact/setQuery', value);
            }
        },
        lastContact: {
            get() {
                return this.$store.state.contact.lastContact;
            },
            set(value) {
                this.$store.commit('contact/setLastContact', value);
            }
        },
        salesRep: {
            get() {
                return this.$store.state.contact.salesRep;
            },
            set(value) {
                this.$store.commit('contact/setSalesRep', value);
            }
        },
        isFromPF: {
            get() {
                return this.$store.state.contact.isFromPF;
            },
            set(value) {
                this.$store.commit('contact/setIsFromPF', value);
            }
        },
        isVIP: {
            get() {
                return this.$store.state.contact.isVIP;
            },
            set(value) {
                this.$store.commit('contact/setIsVIP', value);
            }
        },
        area: {
            get() {
                return this.$store.state.contact.area;
            },
            set(value) {
                this.$store.commit('contact/setArea', value);
            }
        },
        hasEmail: {
            get() {
                return this.$store.state.contact.hasEmail;
            },
            set(value) {
                this.$store.commit('contact/setHasEmail', value);
            }
        },
        areMyProspects: {
            get() {
                return this.$store.state.contact.areMyProspects;
            },
            set(value) {
                this.$store.commit('contact/setareMyProspects', value);
            }
        },
        prospectBlock: {
            get() {
                return this.$store.state.contact.prospectBlock;
            },
            set(value) {
                this.$store.commit('contact/setProspectBlock', value);
            }
        },
        prospectPurchaseBudget: {
            get() {
                return this.$store.state.contact.prospectPurchaseBudget;
            },
            set(value) {
                this.$store.commit('contact/setProspectPurchaseBudget', value);
            }
        },
        prospectRentBudget: {
            get() {
                return this.$store.state.contact.prospectRentBudget;
            },
            set(value) {
                this.$store.commit('contact/setProspectRentBudget', value);
            }
        },
        prospectPropertyType: {
            get() {
                return this.$store.state.contact.prospectPropertyType;
            },
            set(value) {
                this.$store.commit('contact/setProspectPropertyType', value);
            }
        },
        prospectArea: {
            get() {
                return this.$store.state.contact.prospectArea;
            },
            set(value) {
                this.$store.commit('contact/setProspectArea', value);
            }
        },
        prospectTypes: {
            get() {
                return this.$store.state.contact.prospectTypes;
            },
            set(value) {
                const wasPurchaseProspect = this.isPurchaseProspect;
                this.$store.commit('contact/setProspectTypes', value);
                if (wasPurchaseProspect && !this.isPurchaseProspect) {
                    this.$store.commit('contact/setProspectPurchaseBudget', null);
                }
            }
        },
        prospectNbRoomsMin: {
            get() {
                return this.$store.state.contact.prospectNbRoomsMin;
            },
            set(value) {
                this.$store.commit('contact/setProspectNbRoomsMin', value);
            }
        },
        clientTypes: {
            get() {
                return this.$store.state.contact.clientTypes;
            },
            set(value) {
                this.$store.commit('contact/setClientTypes', value);
            }
        },
        clientBlock: {
            get() {
                return this.$store.state.contact.clientBlock;
            },
            set(value) {
                this.$store.commit('contact/setClientBlock', value);
            }
        },
        clientPropertyPurpose: {
            get() {
                return this.$store.state.contact.clientPropertyPurpose;
            },
            set(value) {
                this.$store.commit('contact/setClientPropertyPurpose', value);
            }
        },
        isPurchaseProspect() {
            if (!this.prospectTypes || this.prospectTypes.length <= 0) {
                return false;
            }

            return this.prospectTypes.includes('new_build') || this.prospectTypes.includes('old');
        },
        isNeitherPurchaseNorRentProspect() {
            if (!this.prospectTypes || this.prospectTypes.length <= 0) {
                return true;
            }

            return this.prospectTypes.includes('sale');
        },
        nbRoomsChoices() {
            let choices = [];
            for (let i = 1; i <= 10; i++) {
                choices.push(this.makeChoice(i));
            }

            return choices;
        }
    },
    created() {
        this.search = debounce(this._search, 200);
    },
    watch: {
        mode(newVal, oldVal) {
            if (oldVal === 'vip' || newVal === 'vip') {
                this._search();
            }
        },
        query() {
            this.search();
        },
        lastContact() {
            this._search();
        },
        salesRep() {
            this._search();
        },
        isFromPF() {
            this._search();
        },
        isVIP() {
            this._search();
        },
        area() {
            this._search();
        },
        hasEmail() {
            this._search();
        },
        areMyProspects() {
            this._search();
        },
        clientBlock() {
            this._search();
        },
        clientPropertyPurpose() {
            this._search();
        },
        clientTypes() {
            this._search();
        },
        prospectBlock() {
            this._search();
        },
        prospectPurchaseBudget() {
            this._search();
        },
        prospectRentBudget() {
            this._search();
        },
        prospectPropertyType() {
            this._search();
        },
        prospectArea() {
            this._search();
        },
        prospectTypes() {
            this._search();
        },
        prospectNbRoomsMin() {
            this._search();
        }
    },
    data() {
        return {
            search: null
        }
    },
    methods: {
        isFilteringWith(among) {
            if (!this.filters) {
                return false;
            }

            let filterKeys = Object.keys(this.filters);

            if (among) {
                filterKeys = filterKeys.filter(filter => among.includes(filter));
            }

            return this._filtersWithValue(filterKeys).length > 0;
        },
        lastContacts() {
            return [
                {value: 'less3months', label: 'Il y a moins de 3 mois'},
                {value: '3to12months', label: 'De 3 à 12 mois'},
                {value: 'more12months', label: 'Il y a plus d\'un an'}
            ];
        },
        areas() {
            return [
                {value: 'polynesia', label: 'Résident polynésien'},
                {value: 'abroad', label: 'Résident NON polynésien'}
            ];
        },
        _search() {
            this.load();
            this.$store.dispatch('contact/reload')
                .catch(this.serverError)
                .finally(this.unload);
        },
        _filtersWithValue(filterKeys) {
            filterKeys = filterKeys || Object.keys(this.filters);

            return filterKeys.filter(filterKey => this._filterHasValue(this.filters[filterKey]));
        },
        _filterHasValue(filterValue) {
            if (Array.isArray(filterValue)) {
                return filterValue.length > 0;
            }

            return filterValue;
        }
    }
}
</script>
