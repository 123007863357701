<template>
    <imagine-modal :button-disabled="!label || !date"
                   @act="saveCustomEvent()"
                   @close="$emit('canceled')"
                   button-label="Enregistrer"
                   class="imagine-modal--wide"
                   :title="'Nouvel évènement ' + (this.lease ? 'locataire' : 'propriétaire')">
        <p>
            <imagine-input name="label"
                           v-model="label"
                           label="Libellé"
                           :required="true"
                           class="imagine-form__row__field"/>
        </p>

        <p>
            <imagine-date-picker :required="true"
                                 :min-date="lease ? lease.start : mandateProperty.startDate"
                                 :max-date="lease ? lease.end : mandateProperty.endDate"
                                 class="imagine-form__row__field"
                                 label="Date"
                                 name="date"
                                 v-model="date"/>
        </p>

        <p>
            <imagine-file-input name="docUrl"
                                v-model="url"
                                label="Document"
                                accept="application/pdf"
                                class="imagine-form__row__field"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';
import ImagineFileInput from '../../../components/ImagineFileInput.vue';
import ImagineInput from '../../../components/ImagineInput.vue';

export default {
    props: ['lease', 'mandateProperty'],
    components: {ImagineModal, ImagineFileInput, ImagineDatePicker, ImagineInput},
    data() {
        return {
            label: null,
            date: null,
            url: null
        }
    },
    methods: {
        saveCustomEvent() {
            this.load();
            this.$store.dispatch('mandateProperty/saveCustomEvent', {
                lease: this.lease ? this.lease.id : null,
                mandateProperty: this.mandateProperty ? this.mandateProperty.id : null,
                label: this.label,
                date: this.date,
                url: this.url
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
