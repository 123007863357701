<template>
    <div @click.self="gotoContact()"
         class="mdc-list-item contacts__contact">
        <span class="mdc-list-item__graphic">
            <imagine-checkbox :disabled="!canBeExported"
                              :value="isContactChecked"
                              :title="!canBeExported ? exportableDesc : null"
                              @input="$event ? checkContact() : uncheckContact()"/>
        </span>
        <span @click="gotoContact()"
              class="mdc-list-item__text">
            <span class="mdc-list-item__primary-text">
                <template v-if="contact.firstname || contact.lastname">
                    <span class="contacts__contact__lastname"
                          v-if="contact.lastname">{{ contact.lastname }}</span><span
                    v-if="contact.firstname && contact.lastname">,</span>
                    <span class="contacts__contact__firstname"
                          v-if="contact.firstname">
                        {{ contact.firstname }}
                    </span>
                    <span v-if="contact.company">
                        ({{ contact.company }})
                    </span>
                </template>
                <span class="contacts__contact__company"
                      v-else-if="contact.company">
                    {{ contact.company }}
                </span>
            </span>
            <span class="mdc-list-item__secondary-text">
                <span v-if="contact.email">{{ contact.email }}</span>
            </span>
        </span>
        <span @click="gotoContact()"
              class="mdc-list-item__meta">
            <span v-if="contact.isClient">Client</span>
        </span>
    </div>
</template>

<script>
import ImagineCheckbox from '../../components/ImagineCheckbox.vue';
import {mapState} from 'vuex';

export default {
    props: ['contact', 'exportableDesc'],
    components: {ImagineCheckbox},
    computed: {
        ...mapState('contact', {checked: state => state.checked}),
        canBeExported() {
            return this.contact.email;
        },
        isContactChecked() {
            return this.checked.includes(this.contact.id);
        }
    },
    methods: {
        gotoContact() {
            this.$store.commit('contact/resetLastTab');
            this.redirectTo('contact', {id: this.contact.id});
        },
        checkContact() {
            this.$store.commit('contact/addToChecked', this.contact.id);
        },
        uncheckContact() {
            this.$store.commit('contact/removeFromChecked', this.contact.id);
        }
    }
}
</script>
