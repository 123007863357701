import container from '../container';

const repo = container.repos.mailing;

export default {
    namespaced: true,
    state: {
        all: [],
        templates: [],
        quota: null
    },
    actions: {
        reloadAll({state}) {
            return repo.all()
                .then(all => state.all = all);
        },
        reloadTemplates({state}) {
            return repo.templates()
                .then(templates => state.templates = templates);
        },
        reloadQuota({state}) {
            return repo.quota()
                .then(quota => state.quota = quota);
        },
        create(context, mailing) {
            return repo.create(mailing);
        },
        send(context, {id}) {
            return repo.send(id);
        },
        sendTest(context, {id}) {
            return repo.sendTest(id);
        },
        delete(context, {id}) {
            return repo.delete(id);
        },
        stats(context, {id}) {
            return repo.stats(id);
        }
    }
};
