var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "imagine-alert",
      class: {
        "imagine-alert--warning": _vm.type === "warning",
        "imagine-alert--info": _vm.type === "info",
      },
    },
    [
      _c("span", { staticClass: "material-icons" }, [_vm._v(_vm._s(_vm.type))]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "imagine-alert__message" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }