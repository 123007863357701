import Vue from 'vue';
import Vuex from 'vuex';
import contact from './store/contact';
import employee from './store/employee';
import user from './store/user';
import property from './store/property';
import event from './store/event';
import block from './store/block';
import mailing from './store/mailing';
import mandate from './store/mandate';
import contractor from './store/contractor';
import mandateProperty from './store/mandateProperty';
import lease from './store/lease';
import accountEntry from './store/accountEntry';
import sale from './store/sale';
import salePayments from './store/salePayments';
import container from './container';

Vue.use(Vuex);

const VERSION = '1.12.8';

const repo = container.repos.application;
const referencesCollection = container.referencesCollection;

const store = new Vuex.Store({
    modules: {
        contact,
        employee,
        user,
        property,
        event,
        block,
        mailing,
        mandate,
        contractor,
        mandateProperty,
        lease,
        accountEntry,
        sale,
        salePayments
    },
    state: {
        nbLoads: 0,
        progressInc: null,
        progressTotal: null,
        messages: {
            loading: null,
            success: null,
            error: null
        },
        references: referencesCollection,
        version: VERSION
    },
    getters: {
        isLoading: state => state.messages.loading !== null,
        loadingMessage: state => state.messages.loading
    },
    mutations: {
        load(state, message) {
            state.nbLoads++;
            state.messages.loading = message ? message : '';
        },
        prepareProgress(state, total) {
            state.progressTotal = total;
            state.progressInc = 0;
        },
        progress(state) {
            state.progressInc++;
        },
        unload(state) {
            state.progressTotal = null;
            state.progressInc = null;
            state.nbLoads > 0 ? state.nbLoads-- : null;
            if (state.nbLoads === 0) {
                state.messages.loading = null;
            }
        },
        success(state, message) {
            state.messages.success = message;
        },
        error(state, message) {
            state.messages.error = message;
        }
    },
    actions: {
        touchReferences({state, dispatch}) {
            if (!state.references.isInitalized()) {
                return dispatch('reloadReferences');
            }

            return Promise.resolve();
        },
        reloadReferences(context) {
            return repo.references()
                .then(references => context.state.references = context.state.references.init(references));
        }
    }
});

export default store;
