import container from '../container';
import Vue from 'vue';

const repo = container.repos.property;

const MAX_DISPLAYED = 50;

export default {
    namespaced: true,
    state: {
        displayed: [],
        maxDisplayed: MAX_DISPLAYED,
        nbTotal: 0,
        nbFiltered: 0,
        markers: [],
        query: null,
        transaction: null,
        type: null,
        block: null,
        nbRooms: null,
        available: null,
        mode: 'list',
        publishingTab: 'immopf',
        checked: {},
        published: {},
        lastTab: 'infos',
        redirectAfterSave: null,
        lastSaved: null,
        sort: 'updated_desc'
    },
    getters: {
        filters: state => {
            return {
                transaction: state.transaction,
                type: state.type,
                block: state.block,
                nbRooms: state.nbRooms,
                available: state.available
            };
        }
    },
    mutations: {
        setLastTab(state, tab) {
            state.lastTab = tab;
        },
        resetLastTab(state) {
            state.lastTab = 'infos';
        },
        setRedirectAfterSave(state, route) {
            state.redirectAfterSave = route;
        },
        clearRedirectAfterSave(state) {
            state.redirectAfterSave = null;
        },
        setLastSaved(state, property) {
            state.lastSaved = property;
        },
        setQuery(state, query) {
            state.query = query;
        },
        setTransaction(state, transaction) {
            state.transaction = transaction;
        },
        setType(state, type) {
            state.type = type;
        },
        setBlock(state, block) {
            state.block = block;
        },
        setNbRooms(state, block) {
            state.nbRooms = block;
        },
        setAvailable(state, available) {
            state.available = available;
        },
        setSort(state, sort) {
            state.sort = sort;
        },
        changeMode(state, mode) {
            const origin = state.mode;

            state.mode = mode;

            if (mode === 'search') {
                state.transaction = null;
                state.type = null;
                state.block = null;
                state.nbRooms = null;
                state.available = null;
            }

            if (origin === 'search') {
                state.query = null;
            }
        },
        changePublishingTab(state, tabId) {
            state.publishingTab = tabId;
        },
        addToChecked(state, propertyId) {
            if (!state.checked[state.publishingTab].includes(propertyId)) {
                state.checked[state.publishingTab].push(propertyId);
            }
        },
        removeFromChecked(state, propertyId) {
            const index = state.checked[state.publishingTab].indexOf(propertyId);
            if (index > -1) {
                state.checked[state.publishingTab].splice(index, 1);
            }
        }
    },
    actions: {
        reloadMarkers({state, getters}) {
            return repo.markers({filters: getters.filters})
                .then(markers => state.markers = markers);
        },
        reload({state, getters}) {
            return repo.all({limit: state.maxDisplayed, query: state.query, filters: getters.filters, sort: state.sort})
                .then(collection => {
                    state.nbTotal = collection.nbTotal;
                    state.nbFiltered = collection.nbFiltered;
                    state.displayed = collection.entities;
                });
        },
        checkedProperties({state}) {
            return repo.publishableProperties(state.publishingTab, state.checked[state.publishingTab]);
        },
        displayMore({state, dispatch}) {
            state.maxDisplayed += state.maxDisplayed;

            return dispatch('reload');
        },
        touch({state, dispatch}) {
            if (!state.displayed || state.displayed.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        query({state, dispatch}, query) {
            return repo.all({limit: 200, query: query})
                .then(collection => collection.entities);
        },
        one(context, id) {
            return repo.one(id);
        },
        save(context, data) {
            return repo.save(data);
        },
        addEvent(context, {propertyId, description}) {
            return repo.addEvent(propertyId, description);
        },
        remove(context, id) {
            return repo.remove(id);
        },
        duplicate(context, id) {
            return repo.duplicate(id);
        },
        checkAll({state}) {
            return repo.publishableIds(state.publishingTab)
                .then(ids => Vue.set(state.checked, state.publishingTab, ids));
        },
        checkPublished({state}) {
            Vue.set(state.checked, state.publishingTab, state.published[state.publishingTab])

            return Promise.resolve();
        },
        checkFiltered({state, getters}) {
            return repo.publishableIds(state.publishingTab, {filters: getters.filters})
                .then(ids => Vue.set(state.checked, state.publishingTab, ids));
        },
        checkNone({state}) {
            Vue.set(state.checked, state.publishingTab, []);

            return Promise.resolve();
        },
        reloadPublished({state}) {
            return repo.publishableIds(state.publishingTab, {filters: {published: state.publishingTab}})
                .then(ids => {
                    Vue.set(state.published, state.publishingTab, ids);
                    Vue.set(state.checked, state.publishingTab, ids);
                });
        },
        publish(context, {to, ids}) {
            return repo.publish(to, ids);
        },
        book(context, {id, ownerId, type}) {
            return repo.book(id, ownerId, type);
        }
    }
};
