<template>
    <imagine-layout class="blocks" title="Résidences">
        <template v-slot:actions>
            <imagine-icon-button @click="redirectTo('blockAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <transition name="slideY">
            <div class="imagine-secondary-zone blocks__filter">
                <imagine-select :choices="blockStatusChoices"
                                :implicit-required="true"
                                :multiple="true"
                                label="Statuts"
                                name="statuses"
                                v-model="statuses"/>
            </div>
        </transition>

        <div class="mdc-list mdc-list--two-line">
            <template v-for="block in filteredBlocks">
                <div @click="gotoBlock(block)"
                     class="mdc-list-item">
                    <span class="mdc-list-item__graphic imagine-rounded-shape">
                        {{ block.acronym }}
                    </span>
                    <span class="mdc-list-item__text">
                        <span class="mdc-list-item__primary-text">
                            {{ block.name }}
                        </span>
                        <span class="mdc-list-item__secondary-text">
                            <span v-if="block.nbDivisions">{{ block.nbDivisions }} lots</span>
                            <span v-else>Lots indisponibles</span>
                        </span>
                    </span>
                    <span class="mdc-list-item__meta">
                        {{ blockStatus(block.status) }}
                    </span>
                </div>
                <div class="mdc-list-divider"></div>
            </template>
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import {mapState} from 'vuex';

export default {
    components: {ImagineLayout, ImagineIconButton, ImagineSelect},
    computed: {
        ...mapState('block', {blocks: state => state.all}),
        ...mapState(['references']),
        blockStatusChoices() {
            return this.references.blockStatusChoices();
        },
        filteredBlocks() {
            return this.blocks.filter(block => !this.statuses || this.statuses.includes(block.status));
        },
        statuses: {
            get() {
                return this.$store.state.block.statuses;
            },
            set(value) {
                this.$store.commit('block/setStatuses', value);
            }
        }
    },
    created() {
        this.load('Chargement des résidences');
        Promise.all([
            this.$store.dispatch('block/reload'),
            this.$store.dispatch('touchReferences'),
        ])
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        blockStatus(status) {
            return this.references.getLabel('blockStatuses', status);
        },
        gotoBlock(block) {
            this.$store.commit('block/resetLastTab');
            this.redirectTo('block', {id: block.id});
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.blocks {
    &__filter {
        padding: .5rem .5rem .2rem;

        .md-field {
            margin-bottom: 8px;
        }
    }
}
</style>
