var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imagine-login" },
    [
      _vm.isLoading ? _c("imagine-loader") : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "imagine-login__panel mdc-elevation--z1" },
        [
          _c("imagine-logo"),
          _vm._v(" "),
          !_vm.valid
            ? _c("imagine-alert", [_vm._v("Email ou mot de passe incorrect.")])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "imagine-login__panel__form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.authenticate.apply(null, arguments)
                },
              },
            },
            [
              _c("imagine-input", {
                attrs: {
                  invalid: !_vm.valid,
                  autofocus: "",
                  label: "Email",
                  name: "email",
                  required: "",
                  type: "email",
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _vm._v(" "),
              _c("imagine-input", {
                attrs: {
                  invalid: !_vm.valid,
                  label: "Mot de passe",
                  name: "password",
                  required: "",
                  type: "password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _vm._v(" "),
              _c("imagine-button", { attrs: { primary: "", type: "submit" } }, [
                _vm._v("Se connecter"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }