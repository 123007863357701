var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mdc-dialog imagine-modal",
      class: {
        "imagine-modal--side-right": _vm.sidePos === "right",
        "imagine-modal--side-left": _vm.sidePos === "left",
      },
    },
    [
      _c("div", { staticClass: "mdc-dialog__container" }, [
        _c("div", { staticClass: "mdc-dialog__surface" }, [
          _vm.title
            ? _c("h2", { staticClass: "mdc-dialog__title" }, [
                _c(
                  "span",
                  { staticClass: "imagine-modal__title-inner" },
                  [
                    _c(
                      "span",
                      [
                        _vm.sidePos === "right"
                          ? _c("imagine-icon-button", {
                              staticClass:
                                "imagine-modal__title-inner__side imagine-no-ripple",
                              attrs: { icon: "undo" },
                              on: {
                                click: function ($event) {
                                  return _vm.move("left")
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.title) +
                            "\n                    "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.sidePos === "left"
                      ? _c("imagine-icon-button", {
                          staticClass:
                            "imagine-modal__title-inner__side imagine-no-ripple",
                          attrs: { icon: "redo" },
                          on: {
                            click: function ($event) {
                              return _vm.move("right")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mdc-dialog__content" },
            [
              _vm.closable
                ? _c("imagine-icon-button", {
                    staticClass: "imagine-modal__close",
                    attrs: { "data-mdc-dialog-action": "close", icon: "close" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
          _vm._v(" "),
          _vm.hasButtons
            ? _c(
                "footer",
                { staticClass: "mdc-dialog__actions" },
                [
                  _c(
                    "imagine-button",
                    {
                      staticClass: "mdc-dialog__button",
                      attrs: { "data-mdc-dialog-action": "close" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.buttonLabel ? "Annuler" : "Fermer") +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.buttonLabel
                    ? _c(
                        "imagine-button",
                        {
                          staticClass:
                            "mdc-dialog__button mdc-dialog__button--default",
                          attrs: {
                            autofocus: "",
                            primary: "",
                            disabled: _vm.buttonDisabled,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$emit("act")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.buttonLabel) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mdc-dialog__scrim" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }