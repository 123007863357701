var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mdc-tab-bar" }, [
    _c("div", { staticClass: "mdc-tab-scroller" }, [
      _c("div", { staticClass: "mdc-tab-scroller__scroll-area" }, [
        _c(
          "div",
          { staticClass: "mdc-tab-scroller__scroll-content" },
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "button",
              {
                staticClass: "mdc-tab imagine-tab",
                class: {
                  "mdc-tab--active": tab.active,
                  "imagine-tab--disabled": tab.disabled,
                },
                attrs: { tabindex: index },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goToTab(tab)
                  },
                },
              },
              [
                _c("span", { staticClass: "mdc-tab__content" }, [
                  tab.icon
                    ? _c(
                        "span",
                        { staticClass: "mdc-tab__icon material-icons" },
                        [_vm._v(_vm._s(tab.icon))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  tab.svg
                    ? _c("span", {
                        staticClass: "mdc-tab__icon imagine-icons",
                        class: "imagine-icons--" + tab.svg,
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-tab__text-label" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(tab.title) +
                        "\n                            "
                    ),
                    tab.subtitle
                      ? _c("small", [_vm._v("(" + _vm._s(tab.subtitle) + ")")])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "mdc-tab-indicator",
                    class: { "mdc-tab-indicator--active": tab.active },
                  },
                  [
                    _c("span", {
                      staticClass:
                        "mdc-tab-indicator__content mdc-tab-indicator__content--underline",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "mdc-tab__ripple" }),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }