var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "employees",
      attrs: { title: "Employés" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("employeeAdd")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "employees__list mdc-list mdc-list--two-line" },
        [
          _vm._l(_vm.displayedEmployees, function (employee) {
            return [
              _c(
                "div",
                {
                  staticClass: "mdc-list-item employees__list__employee",
                  class: { "mdc-list-item--disabled": !employee.isActive },
                  on: {
                    click: function ($event) {
                      return _vm.redirectTo("employee", { id: employee.id })
                    },
                  },
                },
                [
                  employee.image
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "mdc-list-item__graphic imagine-rounded-shape",
                        },
                        [_c("img", { attrs: { src: employee.image } })]
                      )
                    : _c(
                        "span",
                        {
                          staticClass:
                            "mdc-list-item__graphic imagine-rounded-shape material-icons",
                        },
                        [_vm._v("person")]
                      ),
                  _vm._v(" "),
                  _c("span", { staticClass: "mdc-list-item__text" }, [
                    _c("span", { staticClass: "mdc-list-item__primary-text" }, [
                      _vm._v(
                        _vm._s(employee.firstname) +
                          " " +
                          _vm._s(employee.lastname)
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mdc-list-item__secondary-text employees__employee__position",
                      },
                      [_vm._v(_vm._s(employee.position))]
                    ),
                  ]),
                  _vm._v(" "),
                  !employee.isActive
                    ? _c("span", { staticClass: "mdc-list-item__meta" }, [
                        _c("span", { staticClass: "material-icons" }, [
                          _vm._v("lock"),
                        ]),
                      ])
                    : employee.mainRoleLabel
                    ? _c("span", { staticClass: "mdc-list-item__meta" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(employee.mainRoleLabel)
                        ),
                        employee.service
                          ? _c("small", [
                              _vm._v(
                                ", " +
                                  _vm._s(_vm.serviceLabel(employee.service))
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mdc-list-divider" }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }