var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mailings",
      attrs: { title: "Publipostages" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: true, icon: "email" },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("mailing")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: false, svg: "brevo" },
                on: {
                  click: function ($event) {
                    return _vm.externalRedirectTo(
                      "https://account-app.brevo.com/account/login"
                    )
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.all.length > 0
        ? _c(
            "div",
            { staticClass: "mdc-list mdc-list--two-line" },
            [
              _vm._l(_vm.all, function (mailing) {
                return [
                  _c("mailing", { attrs: { mailing: mailing } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mdc-list-divider" }),
                ]
              }),
            ],
            2
          )
        : _c("p", { staticClass: "mailings__none" }, [
            _vm._v("Aucun publipostage."),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }