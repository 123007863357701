<template>
    <imagine-form-complex-field :show="isShown"
                                class="imagine-address"
                                :disabled="disabled"
                                :title="title"
                                @close="isShown = false"
                                @open="isShown = true">
        <div class="imagine-form__row">
            <imagine-select :choices="references.cityPFChoices()"
                            @input="updatePFAddress"
                            class="imagine-form__row__field"
                            label="Aide à la saisie commune Polynésie"
                            help="Permet de pré-remplir les champs ci-dessous"
                            v-model="cityCode"
                            :disabled="disabled"
                            name="cityPF"/>
        </div>

        <div class="imagine-form__sep"></div>

        <div class="imagine-form__row" v-if="withAddress">
            <imagine-input class="imagine-form__row__field"
                           label="Boite postale / adresse"
                           name="address"
                           :disabled="disabled"
                           v-model="address"/>
        </div>

        <div class="imagine-form__row">
            <imagine-input class="imagine-form__row__field"
                           label="Code postal"
                           name="zipcode"
                           :disabled="disabled"
                           v-model="zipCode"/>

            <imagine-input class="imagine-form__row__field"
                           label="Ville"
                           name="city"
                           :disabled="disabled"
                           v-model="city"/>
        </div>

        <div class="imagine-form__row">
            <imagine-input class="imagine-form__row__field"
                           label="Ile / Autre"
                           name="area"
                           :disabled="disabled"
                           v-model="area"/>

            <imagine-select :choices="references.countryChoices()"
                            class="imagine-form__row__field"
                            label="Pays"
                            name="country"
                            :disabled="disabled"
                            v-model="country"/>
        </div>

        <template v-if="withMap">
            <div class="imagine-form__sep"></div>

            <div class="imagine-form__row">
                <imagine-map :center="[mapCenterLat, mapCenterLng]"
                             :zoom="mapZoom"
                             :is-shown="isShown"
                             @centerMove="onMapCenterMove($event)"
                             :editable-place="editablePlace"
                             @zoom="onMapZoom($event)"
                             @editablePlaceMove="onMapMarkerMove($event)"/>
            </div>

            <div class="imagine-form__row">
                <imagine-input class="imagine-form__row__field"
                               label="Latitude"
                               name="lat"
                               :disabled="disabled"
                               v-model="posLat"/>

                <imagine-input class="imagine-form__row__field"
                               label="Longitude"
                               name="lng"
                               :disabled="disabled"
                               v-model="posLng"/>
            </div>
        </template>
    </imagine-form-complex-field>
</template>

<script>
import ImagineFormComplexField from './ImagineFormComplexField.vue';
import ImagineSelect from './ImagineSelect.vue';
import ImagineInput from './ImagineInput.vue';
import ImagineMap from './ImagineMap.vue';
import {mapState} from 'vuex';

export default {
    props: ['show', 'value', 'withAddress', 'withMap', 'markerIcon', 'disabled'],
    components: {ImagineFormComplexField, ImagineInput, ImagineSelect, ImagineMap},
    computed: {
        ...mapState(['references']),
        editablePlace() {
            return {icon: this.markerIcon, position: [this.posLat, this.posLng]};
        },
        title() {
            let title = 'Adresse postale';

            if (!this.oneLineAddress) {
                return title;
            }

            return title + ' : ' + this.oneLineAddress;
        },
        oneLineAddress() {
            if (!this.city) {
                return null;
            }

            let address = this.address;

            if (address && this.country === 'PF' && !address.toUpperCase().startsWith('BP')) {
                address = 'BP ' + address;
            }

            if (address && this.zipCode) {
                address += ' - ';
            }

            return [
                address,
                this.zipCode,
                this.city,
                (this.country && this.country !== 'PF') ? this.country : null
            ].filter(part => part).join(' ');
        }
    },
    created() {
        this._unserialize(this.value);
    },
    data() {
        return {
            isShown: !!this.show,
            address: null,
            zipCode: null,
            city: null,
            area: null,
            country: null,
            posLat: null,
            posLng: null,
            mapZoom: null,
            mapCenterLat: null,
            mapCenterLng: null,
            cityCode: null
        }
    },
    watch: {
        value(newVal) {
            this._unserialize(newVal);
        },
        address() {
            this.change();
        },
        zipCode() {
            this.change();
        },
        city() {
            this.change();
        },
        area() {
            this.change();
        },
        country() {
            this.change();
        }
    },
    methods: {
        serialize() {
            return {
                address: this.address,
                zipCode: this.zipCode,
                city: this.city,
                area: this.area,
                country: this.country,
                mapZoom: this.mapZoom,
                mapCenterLat: this.mapCenterLat,
                mapCenterLng: this.mapCenterLng,
                posLat: this.posLat,
                posLng: this.posLng
            };
        },
        updatePFAddress() {
            const cityPF = this.references.findCityPFByCode(this.cityCode);

            if (!cityPF) {
                this._reset();

                return;
            }

            const cityPos = cityPF.wgs84_approx_location;

            this.mapCenterLat = cityPos.lat;
            this.mapCenterLng = cityPos.lng;
            this.posLat = cityPos.lat;
            this.posLng = cityPos.lng;

            this.zipCode = cityPF.zip_code;
            this.city = cityPF.city;
            this.area = cityPF.area;
            this.country = 'PF';
        },
        onMapMarkerMove(newPos) {
            this.posLat = newPos.lat;
            this.posLng = newPos.lng;
            this.change();
        },
        onMapCenterMove(newPos) {
            this.mapCenterLat = newPos.lat;
            this.mapCenterLng = newPos.lng;
            this.change();
        },
        onMapZoom(newZoom) {
            this.mapZoom = newZoom;
            this.change();
        },
        change() {
            this.$emit('input', this.serialize());
        },
        _unserialize(value) {
            if (value) {
                this.address = value.address;
                this.zipCode = value.zipCode;
                this.city = value.city;
                this.area = value.area;
                this.country = value.country;
                this.mapCenterLat = value.mapCenterLat;
                this.mapCenterLng = value.mapCenterLng;
                this.mapZoom = value.mapZoom;
                this.posLat = value.posLat;
                this.posLng = value.posLng;

                // const city = this.references.findCityPFByZipCode(this.zipCode);
                // if (city) {
                //     this.cityCode = city.opt_code;
                // }
            } else {
                this.address = null;
                this._reset();
            }
        },
        _reset() {
            this.zipCode = null;
            this.city = null;
            this.area = null;
            this.country = null;
            this.mapZoom = null;
            this.mapCenterLat = null;
            this.mapCenterLng = null;
            this.posLat = null;
            this.posLng = null;
        }
    }
}
</script>

<style lang="scss">
.imagine-address {
    .md-field {
        margin-bottom: 0;
    }
}
</style>
