<template>
    <span class="mandate-status">
        <span :class="{
            'imagine-status--success': !mandate.isArchive && mandate.currentLease && mandate.nbCriticalAlerts <= 0,
            'imagine-status--danger': !mandate.isArchive && mandate.currentLease && mandate.nbCriticalAlerts > 0,
            'imagine-status--error': !mandate.isArchive && !mandate.currentLease,
            'imagine-status--sold': mandate.isArchive}"
              class="material-icons imagine-status">
            lens
        </span>
        <md-tooltip md-direction="right">{{ statusLabel }}</md-tooltip>
    </span>
</template>

<script>
export default {
    props: ['mandate'],
    computed: {
        statusLabel() {
            if (this.mandate.isArchive) {
                return 'Terminé';
            }

            if (this.mandate.ongoingLease) {
                if (this.mandate.nbCriticalAlerts > 0) {
                    return `Loué, ${this.mandate.nbCriticalAlerts} alerte(s) critique(s)`;
                }

                return 'Loué, OK';
            }

            if (this.mandate.currentLease) {
                return 'Nouvel arrivant';
            }

            return 'Vacant';
        }
    }
}
</script>

<style lang="scss">
.mandate-status {
    .imagine-status {
        font-size: 1.2rem;
    }
}
</style>
