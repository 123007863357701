<template>
    <a :class="{'mdc-list-item--activated': $route.meta && $route.meta.menu === route}"
       @click.prevent="click"
       class="mdc-list-item"
       href="#">
        <i class="material-icons mdc-list-item__graphic" v-if="icon">{{ icon }}</i>
        <i :class="'mdc-list-item__graphic fas fa-' + ficon" v-if="ficon"></i>
        <span class="mdc-list-item__text">{{ label }}</span>
    </a>
</template>

<script>
export default {
    props: ['icon', 'route', 'label', 'ficon'],
    methods: {
        click(event) {
            if (this.route) {
                return this.redirectTo(this.route);
            }

            this.$emit('click', event);
        }
    }
}
</script>

<style lang="scss">
.mdc-list-item__graphic.fas {
    font-size: 24px;
}
</style>
