var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-modal--wide",
      attrs: {
        "button-disabled": !_vm.period,
        "button-label": "Enregistrer",
        title: "Appel de loyer",
      },
      on: {
        act: function ($event) {
          return _vm.chargeRent()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-select-period", {
            staticClass: "imagine-form__row__field",
            attrs: { periods: _vm.lease.rentPeriodsToCharge },
            model: {
              value: _vm.period,
              callback: function ($$v) {
                _vm.period = $$v
              },
              expression: "period",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }