var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-calculator",
      attrs: {
        closable: true,
        buttonLabel: "Simuler",
        title: "Simulateur emprunt",
      },
      on: {
        act: function ($event) {
          return _vm.calculate()
        },
        close: function ($event) {
          return _vm.closeCalculator()
        },
      },
    },
    [
      _c("form", { ref: "calculatorForm", staticClass: "imagine-form" }, [
        _c(
          "div",
          { staticClass: "imagine-form__row" },
          [
            _c("imagine-money-input", {
              staticClass: "imagine-form__row__field",
              attrs: {
                label: "Prix (XPF)",
                name: "purchasePrice",
                required: "",
              },
              model: {
                value: _vm.purchasePrice,
                callback: function ($$v) {
                  _vm.purchasePrice = $$v
                },
                expression: "purchasePrice",
              },
            }),
            _vm._v(" "),
            _c("imagine-money-input", {
              staticClass: "imagine-form__row__field",
              attrs: { label: "Apport (XPF)", name: "provision" },
              model: {
                value: _vm.provision,
                callback: function ($$v) {
                  _vm.provision = $$v
                },
                expression: "provision",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "imagine-form__row" },
          [
            _c("imagine-input", {
              staticClass: "imagine-form__row__field",
              attrs: {
                label: "Durée (années)",
                min: "0",
                name: "duration",
                required: "",
                step: "1",
                type: "number",
              },
              model: {
                value: _vm.duration,
                callback: function ($$v) {
                  _vm.duration = $$v
                },
                expression: "duration",
              },
            }),
            _vm._v(" "),
            _c("imagine-input", {
              staticClass: "imagine-form__row__field",
              attrs: {
                label: "Taux d'intérêt (%)",
                max: "100",
                min: "0",
                name: "interestRate",
                required: "",
                step: "any",
                type: "number",
              },
              model: {
                value: _vm.interestRate,
                callback: function ($$v) {
                  _vm.interestRate = $$v
                },
                expression: "interestRate",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResult,
                expression: "showResult",
              },
            ],
            staticClass: "imagine-calculator__result",
          },
          [
            _c("p", [
              _vm._v("Le montant des mensualités s'élève à "),
              _c("br"),
              _c("strong", [_vm._v(_vm._s(_vm.loanMonthlyCost))]),
              _vm._v("."),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Le coût du crédit sera de " + _vm._s(_vm.loanTotalCost) + "."
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }