<template>
    <imagine-layout title="Contacts">
        <template v-slot:actions>
            <imagine-icon-button @click="exportCurrent"
                                 class="mdc-top-app-bar__action-item"
                                 icon="get_app"
                                 v-if="mode === 'filters'"/>
            <imagine-icon-button :active="mode === 'query'"
                                 @click="changeMode('query')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="search"/>
            <imagine-icon-button :active="mode === 'vip'"
                                 @click="changeMode('vip')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="star"/>
            <imagine-icon-button :active="mode === 'filters'"
                                 @click="changeMode('filters')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="filter_list"/>
            <imagine-icon-button @click="redirectTo('contactAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <contacts-list :mode="mode">
            <template v-slot:default="{contact}">
                <div @click="gotoContact(contact)"
                     class="mdc-list-item contacts__contact">
                    <span class="mdc-list-item__text">
                        <span class="mdc-list-item__primary-text">
                            <template v-if="contact.firstname || contact.lastname">
                                <span class="contacts__contact__lastname"
                                      v-if="contact.lastname">{{ contact.lastname }}</span><span
                                v-if="contact.firstname && contact.lastname">,</span>
                                <span class="contacts__contact__firstname"
                                      v-if="contact.firstname">
                                    {{ contact.firstname }}
                                </span>
                                <span v-if="contact.company">
                                    ({{ contact.company }})
                                </span>
                            </template>
                            <span class="contacts__contact__company"
                                  v-else-if="contact.company">
                                {{ contact.company }}
                            </span>
                        </span>
                        <span class="mdc-list-item__secondary-text">
                            <span v-if="contact.email">{{ contact.email }}</span>
                            <span v-if="contact.mobilePhone">{{ contact.mobilePhone.readable }}</span>
                        </span>
                    </span>
                    <span class="mdc-list-item__meta">
                        {{ metas(contact).join(', ') }}
                    </span>
                </div>
            </template>
        </contacts-list>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ContactsList from './elements/ContactsList.vue';
import {mapGetters, mapState} from 'vuex';

export default {
    components: {ImagineLayout, ImagineIconButton, ContactsList},
    computed: {
        ...mapState('contact', {mode: state => state.searchMode}),
        ...mapGetters('contact', ['filters']),
        ...mapState(['references']),
    },
    created() {
        this.load('Chargement des contacts');
        Promise.all(this._dependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        changeMode(newMode) {
            this.$store.commit('contact/changeMode', newMode);
        },
        exportCurrent() {
            window.location.href = '/api/contact/export?' + this.toQueryString(this.filters);
        },
        gotoContact(contact) {
            this.$store.commit('contact/resetLastTab');
            this.redirectTo('contact', {id: contact.id});
        },
        metas(contact) {
            let metas = [];

            if (contact.isClient) {
                metas.push('Client');
            }

            if (contact.isFavorite) {
                metas.push('VIP');
            }
            if (contact.prospectTypes) {
                contact.prospectTypes.map(type => metas.push('Prospect ' + this.references.getLabel('prospectTransactionTypes', type)));
            }

            return metas;
        },
        _dependencies() {
            let dependencies = [
                this.$store.dispatch('contact/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];

            dependencies.push(
                this.$store.dispatch('user/touch')
                    .then(() => {
                        if (this.canFilterSalesRep()) {
                            this.$store.dispatch('employee/touch');
                        }
                    })
            );

            return dependencies;
        }
    }
}
</script>
