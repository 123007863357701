<template>
    <imagine-modal :button-disabled="!date"
                   :title="'Assurance ' + (lease ? 'habitation' : 'PNO')"
                   @act="saveInsurance()"
                   @close="$emit('canceled')"
                   button-label="Enregistrer"
                   class="imagine-modal--wide">
        <p>
            <imagine-date-picker :min-date="lease ? lease.start : mandateProperty.startDate"
                                 class="imagine-form__row__field"
                                 label="Date de fin assurance"
                                 name="insuranceEndDate"
                                 v-model="date"/>
        </p>

        <p>
            <imagine-file-input accept="application/pdf"
                                class="imagine-form__row__field"
                                label="Document"
                                name="insuranceUrl"
                                v-model="url"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineFileInput from '../../../components/ImagineFileInput.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';

export default {
    props: ['lease', 'mandateProperty'],
    components: {ImagineModal, ImagineFileInput, ImagineDatePicker},
    data() {
        return {
            date: this._initDate(),
            url: null
        }
    },
    methods: {
        saveInsurance() {
            this.load();
            this.$store.dispatch(this.lease ? 'lease/saveInsurance' : 'mandateProperty/saveInsurance', {
                id: this.lease ? this.lease.id : this.mandateProperty.id,
                data: {date: this.date, url: this.url}
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        },
        _initDate() {
            if (this.lease) {
                if (this.lease.insuranceEndDate) {
                    return this.lease.insuranceEndDate;
                }

                return this.lease.start.add(1, 'year');
            }

            if (this.mandateProperty.insuranceEndDate) {
                return this.mandateProperty.insuranceEndDate;
            }

            return this.mandateProperty.startDate.add(1, 'year');
        }
    }
}
</script>
