<template>
    <div :class="{'imagine-modal--side-right': sidePos === 'right', 'imagine-modal--side-left': sidePos === 'left'}"
         class="mdc-dialog imagine-modal">
        <div class="mdc-dialog__container">
            <div class="mdc-dialog__surface">
                <h2 class="mdc-dialog__title"
                    v-if="title">
                    <span class="imagine-modal__title-inner">
                        <span>
                            <imagine-icon-button @click="move('left')"
                                                 class="imagine-modal__title-inner__side imagine-no-ripple"
                                                 icon="undo"
                                                 v-if="sidePos === 'right'"/>
                            {{ title }}
                        </span>
                        <imagine-icon-button @click="move('right')"
                                             class="imagine-modal__title-inner__side imagine-no-ripple"
                                             icon="redo"
                                             v-if="sidePos === 'left'"/>
                    </span>
                </h2>
                <div class="mdc-dialog__content">
                    <imagine-icon-button class="imagine-modal__close"
                                         data-mdc-dialog-action="close"
                                         icon="close"
                                         v-if="closable"/>
                    <slot/>
                </div>
                <footer class="mdc-dialog__actions"
                        v-if="hasButtons">
                    <imagine-button class="mdc-dialog__button"
                                    data-mdc-dialog-action="close">
                        {{ buttonLabel ? 'Annuler' : 'Fermer' }}
                    </imagine-button>
                    <imagine-button @click.prevent="$emit('act')"
                                    autofocus
                                    class="mdc-dialog__button mdc-dialog__button--default"
                                    primary
                                    :disabled="buttonDisabled"
                                    v-if="buttonLabel">
                        {{ buttonLabel }}
                    </imagine-button>
                </footer>
            </div>
        </div>
        <div class="mdc-dialog__scrim"></div>
    </div>
</template>

<script>
import {MDCDialog} from '@material/dialog/index';
import ImagineButton from './ImagineButton.vue';
import ImagineIconButton from './ImagineIconButton.vue';

export default {
    props: {
        title: {required: false},
        buttonLabel: {required: false},
        buttonDisabled: {type: Boolean, default: false},
        hasButtons: {default: true},
        closable: {default: false},
        side: {default: false}
    },
    components: {ImagineButton, ImagineIconButton},
    mounted() {
        this._dialog = new MDCDialog(this.$el);
        this._dialog.open();
        this._dialog.listen('MDCDialog:closed', () => this.$emit('close'));
    },
    beforeDestroy() {
        this._dialog.destroy();
    },
    data() {
        return {
            _dialog: null,
            sidePos: this.side ? 'right' : null
        }
    },
    methods: {
        move(direction) {
            this.sidePos = direction;
            this._dialog.open();
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/dialog/mdc-dialog';

.imagine-modal {
    z-index: 101;

    &--booking,
    &--wide {
        .mdc-dialog__container, .mdc-dialog__surface {
            width: 100%;
        }
    }

    &__title-inner {
        margin-top: -40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__side {
            &.mdc-icon-button {
                display: none;
            }
        }
    }

    @media (min-width: 1440px) {
        &--side-right {
            left: 30%;
        }

        &--side-left {
            left: -30%;
        }

        &__title-inner {
            &__side {
                &.mdc-icon-button {
                    display: inline-block;
                }
            }
        }
    }

    .mdc-dialog__surface {
        position: relative;
    }

    .imagine-modal__close {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        border: none;

        &:focus {
            outline: 0;
        }
    }
}

.md-menu-content.md-select-menu, .md-menu-content {
    z-index: 102;
}
</style>
