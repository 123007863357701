<template>
    <imagine-layout :inner="true" :title="title" class="property-edit">
        <template v-slot:actions>
            <imagine-icon-button class="mdc-top-app-bar__navigation-icon"
                                 @click="redirectTo('block', {id: block})"
                                 icon="business"
                                 v-if="block"/>
            <imagine-icon-button @click="print()"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="print"
                                 :disabled="!property || !property.descriptionFallback"
                                 v-if="property"/>
            <imagine-icon-button @click="showDuplicateConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="add_to_photos"
                                 v-if="property"/>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 :disabled="hasTransactions"
                                 icon="delete"
                                 v-if="property"/>
        </template>

        <imagine-tab-bar :tabs="tabs"
                         @tab="changeTab($event)"/>

        <div class="imagine-container property-edit__form" v-show="activeTab === 'infos'">
            <form @submit.prevent="save" autocomplete="off" class="imagine-form">
                <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>
                <imagine-alert v-else-if="publishings.length > 0" type="info">
                    Ce bien est publié sur {{ publishings.join(', ') }}.
                </imagine-alert>

                <div class="imagine-form__row property-edit__form__transactions">
                    <imagine-checkboxes :choices="transactionChoices"
                                        class="imagine-form__row__field"
                                        name="transactions"
                                        v-model="transactionTypes"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-select :choices="propertyTypeChoices"
                                    class="imagine-form__row__field"
                                    label="Type"
                                    name="type"
                                    v-model="type"/>
                    <imagine-select :choices="references.apptTypeChoices()"
                                    class="imagine-form__row__field"
                                    label="Type appt."
                                    name="apparmentType"
                                    @input="updateApptBedrooms()"
                                    v-model="nbRooms"
                                    v-if="type === 'apartment'"/>
                </div>

                <div class="imagine-form__row" v-if="hasGroup">
                    <imagine-select :choices="references.groupedBlockChoices(blocks)"
                                    class="imagine-form__row__field"
                                    label="Résidence imagine"
                                    name="block"
                                    :violation="errors.block"
                                    v-if="isImagine"
                                    v-model="block"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="Résidence NON imagine"
                                   name="otherBlock"
                                   v-if="couldBeInOtherBlock"
                                   v-model="otherBlock"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="Lotissement / servitude"
                                   name="housingDev"
                                   v-if="couldBeInHousing"
                                   v-model="housingDev"/>
                    <imagine-input class="imagine-form__row__field property-edit__form__building"
                                   label="Bât."
                                   name="building"
                                   v-if="hasBuilding && !isLand"
                                   v-model="building"/>
                    <imagine-input class="imagine-form__row__field property-edit__form__floor"
                                   label="Etage"
                                   name="floor"
                                   v-if="hasFloor && !isLand"
                                   v-model="floor"/>
                    <imagine-input class="imagine-form__row__field property-edit__form__division"
                                   label="N° lot"
                                   name="division"
                                   :violation="errors.division"
                                   v-if="hasDivision"
                                   v-model="division"/>
                </div>

                <div class="imagine-form__row" v-if="hasGroup && !isLand">
                    <imagine-input class="imagine-form__row__field"
                                   label="N° parking intérieur 1"
                                   name="insideParking1"
                                   :violation="errors.insideParking1"
                                   v-model="insideParking1"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="N° parking intérieur 2"
                                   name="insideParking2"
                                   :violation="errors.insideParking2"
                                   v-model="insideParking2"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="N° parking extérieur 1"
                                   name="outsideParking1"
                                   :violation="errors.outsideParking1"
                                   v-model="outsideParking1"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="N° parking extérieur 2"
                                   name="outsideParking2"
                                   :violation="errors.outsideParking2"
                                   v-model="outsideParking2"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   help="En mètres carrés"
                                   label="Surface hab."
                                   name="livingArea"
                                   v-if="hasLivingArea"
                                   v-model="livingArea"/>
                    <imagine-input class="imagine-form__row__field"
                                   help="En mètres carrés"
                                   label="Surface totale"
                                   name="totalArea"
                                   v-if="hasLivingArea"
                                   :violation="errors.totalArea"
                                   v-model="totalArea"/>
                    <imagine-input class="imagine-form__row__field"
                                   help="En mètres carrés"
                                   label="Surface terrain"
                                   name="landArea"
                                   v-if="hasLandArea"
                                   v-model="landArea"/>
                </div>

                <div class="imagine-form__row"
                     v-if="hasEditableRooms">
                    <imagine-input class="imagine-form__row__field"
                                   label="Nb de pièces"
                                   name="nbRooms"
                                   type="number"
                                   v-model="nbRooms"/>
                    <imagine-input class="imagine-form__row__field"
                                   label="Nb de chambres"
                                   name="nbBedrooms"
                                   type="number"
                                   v-if="hasBedrooms"
                                   :violation="errors.block"
                                   v-model="nbBedrooms"/>
                </div>

                <imagine-address :show="true"
                                 :with-address="false"
                                 title="Localisation"
                                 :with-map="true"
                                 marker-icon="home_work"
                                 v-if="isLocationUnknown"
                                 v-model="location"/>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="propertyOptionsChoices"
                                        class="imagine-form__row__field"
                                        label="Caractéristiques"
                                        name="options"
                                        v-model="options"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-textarea :help="publishedHelp"
                                      :label="descriptionLabel"
                                      class="imagine-form__row__field"
                                      name="description"
                                      v-model="description"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   label="Lien visite virtuelle"
                                   name="virtualTourUrl"
                                   type="url"
                                   v-model="virtualTourUrl"/>
                </div>

                <div class="imagine-form__row" v-if="hasRegistryNumber">
                    <imagine-input :label="registryNumberLabel"
                                   class="imagine-form__row__field"
                                   name="registryNumber"
                                   v-model="registryNumber"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isPurchase">
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Prix de vente proposé (XPF)"
                                         name="price"
                                         v-model="price"/>
                </div>

                <div class="imagine-form__row"
                     v-if="isPurchase || isRental">
                    <imagine-select :choices="taxExemptionChoices"
                                    class="imagine-form__row__field"
                                    label="Défiscalisation"
                                    name="taxExemption"
                                    v-model="taxExemption"/>

                    <imagine-date-picker class="imagine-form__row__field"
                                         label="Fin défiscalisation"
                                         name="taxExemptionEnd"
                                         v-model="taxExemptionEnd"/>
                </div>

                <div class="imagine-form__row property-edit__form__prices"
                     v-if="isRental">
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Loyer hors charges proposé (XPF/mois)"
                                         name="rent"
                                         v-model="rent"/>
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision pour charges (XPF / mois)"
                                         name="charges"
                                         v-model="charges"/>
                </div>

                <div class="imagine-form__row property-edit__form__prices"
                     v-if="isRental">
                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision taxe d'ordures ménagères (XPF / mois)"
                                         name="waste"
                                         v-model="waste"/>

                    <imagine-money-input class="imagine-form__row__field"
                                         label="Provision taxe d'eau (XPF / mois)"
                                         name="water"
                                         v-model="water"/>
                </div>

                <div class="imagine-form__row property-edit__form__furnished"
                     v-if="isRental">
                    <imagine-switch v-model="isFurnished">
                        <span>Meublé</span>
                    </imagine-switch>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Notes"
                                      name="comment"
                                      v-model="comment"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>

                <div class="property-edit__timeline" v-if="property">
                    <timeline :events="timeline"
                              v-if="timeline && timeline.length > 0"/>

                    <form @submit.prevent="createEvent"
                          class="imagine-form">
                        <div class="imagine-form__row">
                            <imagine-textarea class="imagine-form__row__field"
                                              label="Nouvel évènement : visite, etc."
                                              :required="true"
                                              name="event1"
                                              v-model="eventDescription"/>
                        </div>

                        <div class="imagine-form__actions">
                            <imagine-button primary type="submit">Ajouter évènement</imagine-button>
                        </div>
                    </form>
                </div>
            </form>
        </div>

        <div class="imagine-container">
            <form @submit.prevent="save"
                  class="imagine-form"
                  v-show="activeTab === 'images'">
                <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>
                <imagine-alert v-else-if="publishings.length > 0" type="info">
                    Ce bien est publié sur {{ publishings.join(', ') }}.
                </imagine-alert>

                <div class="imagine-form__row imagine-form__row--alone"
                     v-if="hasPlan">
                    <imagine-file-input class="imagine-form__row__field"
                                        accept="image/*"
                                        :options="{public: true}"
                                        label="Plan principal"
                                        name="plan1"
                                        v-model="plan1Url"/>
                </div>

                <div class="imagine-form__row imagine-form__row--limited"
                     v-if="hasPlan">
                    <imagine-file-input :options="{public: true}"
                                        accept="image/*"
                                        class="imagine-form__row__field"
                                        label="Autre plan 1"
                                        name="plan2"
                                        v-model="plan2Url"/>

                    <imagine-file-input :options="{public: true}"
                                        accept="image/*"
                                        class="imagine-form__row__field"
                                        label="Autre plan 2"
                                        name="plan3"
                                        v-model="plan3Url"/>
                </div>

                <div class="imagine-form__sep" v-if="hasPlan"></div>

                <div class="imagine-form__row imagine-form__row--alone">
                    <imagine-file-input :options="{cropRatio: '16/10', watermark: true, public: true}"
                                        accept="image/*"
                                        class="imagine-form__row__field"
                                        label="Visuel principal"
                                        v-model="photoUrls[0]"/>
                </div>

                <div class="imagine-form__row imagine-form__row--limited" v-for="i in photoLineIndexes()">
                    <imagine-file-input class="imagine-form__row__field"
                                        :label="'Autre visuel ' + (i+j-1)"
                                        accept="image/*"
                                        :options="{cropRatio: '16/10', watermark: true, public: true}"
                                        :key="'property-image-' + i + '-' + j"
                                        v-model="photoUrls[(i+j-1)]"
                                        v-for="j in 2"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <div class="property-edit__prospects" v-show="activeTab === 'prospects_new'">
            <div class="property-edit__prospects__header" v-if="isGranted(['ROLE_CEO', 'ROLE_SALES_REP'])">
                <imagine-switch v-model="onlySalesRepProspects">
                    <span>Mes prospects seulement</span>
                </imagine-switch>
            </div>

            <div class="mdc-list mdc-list--two-line " v-if="matchingContacts.length > 0">
                <div @click="gotoContact(match.contact)"
                     v-for="match in matchingContacts"
                     class="mdc-list-item property-edit__prospects__prospect">
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                <template v-if="match.contact.firstname || match.contact.lastname">
                                    <span class="contacts__contact__lastname"
                                          v-if="match.contact.lastname">{{ match.contact.lastname }}</span><span
                                    v-if="match.contact.firstname && match.contact.lastname">,</span>
                                    <span class="contacts__contact__firstname"
                                          v-if="match.contact.firstname">
                                        {{ match.contact.firstname }}
                                    </span>
                                    <span v-if="match.contact.company">
                                        ({{ match.contact.company }})
                                    </span>
                                </template>
                                <span class="contacts__contact__company"
                                      v-else-if="match.contact.company">
                                    {{ match.contact.company }}
                                </span>
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                <span v-if="match.contact.email">{{ match.contact.email }}</span>
                                <span v-if="match.contact.mobilePhone">{{ match.contact.mobilePhone.readable }}</span>
                            </span>
                        </span>
                    <span class="mdc-list-item__meta property-edit__prospects__prospect__score">
                            {{ Math.round(match.score * 100) }}%
                            <br>{{ match.salesRepName }}
                        </span>
                </div>
            </div>
            <p v-else>Aucun prospect.</p>
        </div>

        <div class="property-edit__prospects" v-show="activeTab === 'prospects_old'">
            <div class="property-edit__prospects__header" v-if="isGranted(['ROLE_CEO', 'ROLE_SALES_REP'])">
                <imagine-switch v-model="onlySalesRepProspects">
                    <span>Mes prospects seulement</span>
                </imagine-switch>
            </div>

            <div class="mdc-list mdc-list--two-line " v-if="oldMatchingContacts.length > 0">
                <div @click="gotoContact(match.contact)"
                     v-for="match in oldMatchingContacts"
                     class="mdc-list-item property-edit__prospects__prospect">
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">
                                <template v-if="match.contact.firstname || match.contact.lastname">
                                    <span class="contacts__contact__lastname"
                                          v-if="match.contact.lastname">{{ match.contact.lastname }}</span><span
                                    v-if="match.contact.firstname && match.contact.lastname">,</span>
                                    <span class="contacts__contact__firstname"
                                          v-if="match.contact.firstname">
                                        {{ match.contact.firstname }}
                                    </span>
                                    <span v-if="match.contact.company">
                                        ({{ match.contact.company }})
                                    </span>
                                </template>
                                <span class="contacts__contact__company"
                                      v-else-if="match.contact.company">
                                    {{ match.contact.company }}
                                </span>
                            </span>
                            <span class="mdc-list-item__secondary-text">
                                <span v-if="match.contact.email">{{ match.contact.email }}</span>
                                <span v-if="match.contact.mobilePhone">{{ match.contact.mobilePhone.readable }}</span>
                            </span>
                        </span>
                    <span class="mdc-list-item__meta property-edit__prospects__prospect__score">
                            {{ Math.round(match.score * 100) }}%
                            <br>{{ match.salesRepName }}
                        </span>
                </div>
            </div>
            <p v-else>Aucun prospect.</p>
        </div>

        <div class="property-edit__transactions" v-show="activeTab === 'clients'">
            <div class="property-edit__transactions__category"
                 v-if="isNewBuild">
                <h6 class="property-edit__transactions__category__title mdc-typography--headline6">
                    <span>
                        Acheteur{{ sale && sale.buyers && sale.buyers.length > 1 ? 's' : '' }}
                        neuf
                    </span>
                    <imagine-button :primary="true"
                                    @click.prevent="showBookingModal = 'new_build'"
                                    v-if="property && property.canBeNewBuildPurchased && canAddSaleOption()">
                        Poser option
                    </imagine-button>
                </h6>

                <div class="mdc-list"
                     v-if="sale && sale.buyers.length > 0">
                    <div @click="gotoSale(sale)"
                         v-for="buyer in sale.buyers"
                         class="mdc-list-item">
                        <span class="mdc-list-item__graphic">
                            <sale-status :sale="sale"/>
                        </span>
                        <span class="mdc-list-item__text">
                            {{ buyer }}
                        </span>
                        <span class="mdc-list-item__meta">
                            <option-remove v-if="sale.step === 'option'"
                                           :sale-id="sale.id"
                                           @removed="reload()"
                                           :placeholder="references.stepLabel(sale.step)"
                                           :sales-rep-id="sale.salesRepId" />
                            <template v-else>{{ references.stepLabel(sale.step) }}</template>
                        </span>
                    </div>
                    <div class="mdc-list-divider"></div>
                </div>

                <p v-else>Pas d'acheteur.</p>
            </div>

            <div class="property-edit__transactions__category">
                <h6 class="property-edit__transactions__category__title mdc-typography--headline6">
                    <span>
                        Autre<template v-if="oldTransactions.length > 1">s</template>
                        acheteur<template v-if="oldTransactions.length > 1">s</template>
                    </span>
                    <imagine-button :primary="true"
                                    @click.prevent="showBookingModal = 'old'">
                        Ajouter acheteur
                    </imagine-button>
                </h6>

                <div class="mdc-list" v-if="oldTransactions.length > 0">
                    <template v-for="oldTransaction in oldTransactions">
                        <div @click="redirectTo('contact', {id: oldTransaction.contact.id})"
                             class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <span class="material-icons imagine-status imagine-status--sold">
                                    lens
                                </span>
                            </span>
                            <span class="mdc-list-item__text">
                                {{ oldTransaction.contact.shortName }}
                            </span>
                            <span class="mdc-list-item__meta"
                                  @click.stop="showRemoveTransactionConfirmation = oldTransaction">
                                <span class="material-icons">delete</span>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>

                <p v-else>Pas d'autre acheteur.</p>
            </div>

            <div class="property-edit__transactions__category">
                <h6 class="property-edit__transactions__category__title mdc-typography--headline6">
                    <span>
                        Mandat<template v-if="property && property.mandates.length > 1">s</template>
                        de gestion
                    </span>
                </h6>

                <div class="mdc-list"
                     :class="{'mdc-list--non-interactive': !isMandatesGranted()}"
                     v-if="property && property.mandates.length > 0">
                    <template v-for="mandateProperty in property.mandates">
                        <div @click="gotoMandate(mandateProperty)"
                             class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <mandate-property-status :mandate="mandateProperty"/>
                            </span>
                            <span class="mdc-list-item__text">
                                Pour {{ mandateProperty.owners.map(owner => owner.shortName).join(',') }}
                            </span>
                            <span class="mdc-list-item__meta"></span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
                <p v-else>Pas en gestion.</p>
            </div>

            <div class="property-edit__transactions__category">
                <h6 class="property-edit__transactions__category__title mdc-typography--headline6">
                    <span>
                        Locataire<template v-if="rentalTransactions.length > 1">s</template>
                    </span>
                </h6>

                <div class="mdc-list mdc-list--non-interactive"
                     v-if="rentalTransactions.length > 0">
                    <template v-for="rental in rentalTransactions">
                        <div class="mdc-list-item">
                            <span class="mdc-list-item__graphic">
                                <span
                                    :class="{'imagine-status--sold': !rental.isOngoing, 'imagine-status--success': rental.isOngoing}"
                                    class="material-icons imagine-status">
                                    lens
                                </span>
                            </span>
                            <span class="mdc-list-item__text">
                                {{ rental.contacts.map(contact => contact.shortName).join(', ') }}
                            </span>
                                <span class="mdc-list-item__meta">
                                <template v-if="rental.hasMandate">[Gestion]</template>
                                <span v-else
                                      class="material-icons"
                                      @click.stop="showRemoveTransactionConfirmation = rental">
                                    delete
                                </span>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
                <p v-else>Aucun locataire.</p>
            </div>
        </div>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       buttonLabel="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer ce bien ?</p>
        </imagine-modal>

        <imagine-modal @act="duplicate"
                       @close="closeConfirm"
                       buttonLabel="Dupliquer"
                       v-if="showDuplicateConfirmation">
            <p>Etes vous sûr de vouloir dupliquer ce bien ?</p>
        </imagine-modal>

        <imagine-modal @act="removeOldTransaction"
                       @close="showRemoveTransactionConfirmation = false"
                       button-label="Supprimer"
                       v-if="showRemoveTransactionConfirmation">
            <p>Etes vous sûr de vouloir supprimer cette transaction ?</p>
        </imagine-modal>

        <imagine-modal :button-disabled="!owner"
                       :button-label="bookingButton"
                       @act="showBookingModal === 'new_build' ? addSaleOption() : book(showBookingModal)"
                       @close="showBookingModal = false"
                       :title="bookingTitle"
                       class="imagine-modal--booking"
                       v-if="this.propertyId && showBookingModal">
            <imagine-contact-autocomplete
                :redirect-after-create="{name: this.$route.name, params: {id: this.propertyId}, query: {ack: showBookingModal}}"
                label="Contact"
                v-model="owner"/>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineAddress from '../components/ImagineAddress.vue';
import ImagineCheckboxes from '../components/ImagineCheckboxes.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineMoneyInput from '../components/ImagineMoneyInput.vue';
import Timeline from './elements/Timeline.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import MandatePropertyStatus from './elements/MandatePropertyStatus.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import SaleStatus from './elements/SaleStatus.vue';
import {mapState} from 'vuex';
import OptionRemove from "./elements/OptionRemove";

const NB_IMAGES = 6;

export default {
    components: {
        OptionRemove,
        SaleStatus,
        MandatePropertyStatus,
        ImagineSwitch,
        ImagineAddress,
        ImagineLayout,
        ImagineModal,
        ImagineButton,
        ImagineIconButton,
        ImagineCheckboxes,
        ImagineInput,
        ImagineAlert,
        ImagineSelect,
        ImagineTextarea,
        ImagineTabBar,
        ImagineFileInput,
        ImagineMoneyInput,
        ImagineDatePicker,
        Timeline,
        ImagineContactAutocomplete
    },
    computed: {
        ...mapState('user', {user: state => state.current}),
        ...mapState('property', {activeTab: state => state.lastTab}),
        ...mapState(['references']),
        ...mapState('block', {blocks: state => state.all}),
        title() {
            if (this.propertyId) {
                return this.property ? 'Edition ' + this.property.reference : '';
            }

            return 'Création bien';
        },
        tabs() {
            return [
                {id: 'infos', title: 'Infos', active: this.activeTab === 'infos'},
                {id: 'images', title: 'Images', active: this.activeTab === 'images'},
                {
                    id: 'prospects_new',
                    title: 'Prospects promotion',
                    active: this.activeTab === 'prospects_new',
                    disabled: !this.property || this.property.matchingContacts.length <= 0
                },
                {
                    id: 'prospects_old',
                    title: 'Prospects agence',
                    active: this.activeTab === 'prospects_old',
                    disabled: !this.property || this.property.oldMatchingContacts.length <= 0
                },
                {id: 'clients', title: 'Clients', active: this.activeTab === 'clients', disabled: !this.propertyId}
            ];
        },
        transactionChoices() {
            let choices = this.references.propertyTransactionChoices();

            if ((this.property && this.property.newBuildTransaction)
                || (this.type && !this.references.imaginePropertyTypes().includes(this.type))) {
                choices.forEach(choice => {
                    if (choice.value === 'new_build') {
                        choice.disabled = true;
                    }
                });
            }

            return choices;
        },
        propertyTypeChoices() {
            let choices = this.references.propertyTypeChoices();

            if (this.isNewBuild) {
                choices.forEach(choice => choice.disabled = !this.references.imaginePropertyTypes().includes(choice.value));
            }

            return choices;
        },
        propertyOptionsChoices() {
            let choices = this.references.propertyOptionChoices();

            if (this.isNewBuild) {
                choices.forEach(choice => {
                    if (this.references.blockPropertyOptionsChoices().map(choice => choice.value).includes(choice.value)) {
                        choice.disabled = true;
                    }
                });
            }

            return choices;
        },
        hasGroup() {
            return this.isImagine || this.couldBeInOtherBlock || this.couldBeInHousing || this.hasFloor || this.hasDivision;
        },
        hasRegistryNumber() {
            return this._isForTransaction('old') || this._isForTransaction('rental');
        },
        registryNumberLabel() {
            return 'N° registre' + (this._isForTransaction('rental') ? ' (si pas en gestion)' : '');
        },
        bookingTitle() {
            const prefix = () => {
                if (this.showBookingModal === 'new_build') {
                    return 'Option sur ';
                }

                if (this.showBookingModal === 'rental') {
                    return 'Locataire ';
                }

                return 'Autre acheteur ';
            };

            return prefix() + this.property.reference;
        },
        bookingButton() {
            if (this.showBookingModal === 'new_build') {
                return 'Poser option';
            }

            return 'Ajouter';
        },
        _hasTransaction() {
            return this.transactionTypes && this.transactionTypes.length > 0;
        },
        _hasType() {
            return this.type;
        },
        isImagine() {
            return this.isNewBuild;
        },
        couldBeInOtherBlock() {
            return this._isAmongTypes(['apartment', 'penthouse']) && this._hasTransaction && !this.isImagine;
        },
        couldBeInHousing() {
            return !this.isImagine
                && this._hasType
                && this._isAmongTypes(['villa', 'land', 'premises_pro', 'premises_business', 'block']);
        },
        hasFloor() {
            return this.isImagine || this.couldBeInOtherBlock;
        },
        hasBuilding() {
            return this.isImagine || this.couldBeInOtherBlock;
        },
        hasDivision() {
            return this.isImagine || this.couldBeInOtherBlock || this.couldBeInHousing;
        },
        hasLandArea() {
            return this._isAmongTypes(['villa', 'land', 'premises_pro', 'premises_business', 'block', 'island']);
        },
        hasLivingArea() {
            return this.isImagine || this._isAmongTypes(['apartment', 'penthouse', 'villa', 'premises_pro', 'premises_business']);
        },
        isLocationUnknown() {
            return !this.isImagine || this.isLand;
        },
        hasEditableRooms() {
            return this._isAmongTypes(['penthouse', 'villa', 'premises_pro', 'premises_business']);
        },
        hasBedrooms() {
            return this._isAmongTypes(['apartment', 'penthouse', 'villa']);
        },
        isPurchase() {
            return this._isForTransaction('new_build') || this._isForTransaction('old');
        },
        isRental() {
            return this._isForTransaction('rental');
        },
        isNewBuild() {
            return this._isForTransaction('new_build');
        },
        hasPlan() {
            return !this._hasType || !this.isLand;
        },
        isLand() {
            return this._isAmongTypes(['land', 'island']);
        },
        descriptionLabel() {
            if (this.isNewBuild) {
                return 'Texte descriptif (si différent de la résidence)';
            }

            return 'Texte descriptif';
        },
        publishedHelp() {
            if (!this.references || !this.references.publishings() || !this.property || !this.property.published || this.property.published.length <= 0) {
                return null;
            }

            return 'Actuellement publié sur ' + this.property.published
                .map(publishingId => this.references.publishingLabel(publishingId))
                .filter(publishing => publishing)
                .map(publishing => '"' + publishing.name + '"')
                .join(', ');
        },
        oldTransactions() {
            return this._propertyTransactions('old');
        },
        rentalTransactions() {
            if (!this.property) {
                return [];
            }

            return this._propertyTransactions('rental').map(transaction => {
                return {isOngoing: false, contact: {id: transaction.contact.id}, id: transaction.id, contacts: [transaction.contact], hasMandate: false};
            })
                .concat(this.property.mandateRentals);
        },
        taxExemptionChoices() {
            return this.references.taxExemptionChoices();
        },
        hasActiveMandate() {
            if (!this.property) {
                return false;
            }

            return this.property.hasActiveMandates;
        },
        sale() {
            if (!this.property) {
                return false;
            }

            return this.property.sale;
        },
        hasTransactions() {
            return (this.sale && this.sale.buyers.length > 0)
                || this.oldTransactions.length > 0
                || this.rentalTransactions.length > 0
                || this.hasActiveMandate;
        },
        publishings() {
            if (!this.property || !this.property.published) {
                return [];
            }

            return this.property.published.map(code => this.references.getLabel('publishings', code).name);
        },
        matchingContacts() {
            if (!this.property) {
                return [];
            }

            const matchingContacts = this.property.matchingContacts;

            if (!this.onlySalesRepProspects || !this.isGranted(['ROLE_CEO', 'ROLE_SALES_REP'])) {
                return matchingContacts;
            }

            return matchingContacts.filter(match => this.user.id === match.salesRepId);
        },
        oldMatchingContacts() {
            if (!this.property) {
                return [];
            }

            const matchingContacts = this.property.oldMatchingContacts;

            if (!this.onlySalesRepProspects || !this.isGranted(['ROLE_CEO', 'ROLE_SALES_REP'])) {
                return matchingContacts;
            }

            return matchingContacts.filter(match => this.user.id === match.salesRepId);
        }
    },
    data() {
        return {
            property: null,
            propertyId: null,
            timeline: [],
            eventDescription: null,
            showRemoveConfirmation: false,
            showDuplicateConfirmation: false,
            transactionTypes: null,
            options: null,
            location: null,
            livingArea: null,
            totalArea: null,
            division: null,
            insideParking1: null,
            insideParking2: null,
            outsideParking1: null,
            outsideParking2: null,
            block: null,
            type: null,
            comment: null,
            description: null,
            landArea: null,
            otherBlock: null,
            housingDev: null,
            floor: null,
            building: null,
            virtualTourUrl: null,
            nbRooms: null,
            nbBedrooms: null,
            taxExemption: null,
            taxExemptionEnd: null,
            rent: null,
            charges: null,
            waste: null,
            water: null,
            isFurnished: null,
            price: null,
            plan1Url: null,
            plan2Url: null,
            plan3Url: null,
            registryNumber: null,
            photoUrls: new Array(NB_IMAGES),
            nbImages: NB_IMAGES,
            errors: {},
            owner: null,
            showBookingModal: false,
            routeAfterSave: null,
            showRemoveTransactionConfirmation: false,
            onlySalesRepProspects: false
        }
    },
    created() {
        this.routeAfterSave = this.$store.state.property.redirectAfterSave;
        this.$store.commit('property/clearRedirectAfterSave');

        this.load();
        this.propertyId = this.$route.params ? parseInt(this.$route.params.id) : null;

        if (!this.propertyId) {
            this.$store.commit('property/resetLastTab');
        }

        Promise.all(this._getDependencies())
            .then(() => {
                if (this.$route.query && this.$route.query.ack) {
                    this.changeTab('clients');
                    this.owner = this.$store.state.contact.lastSaved;
                    this.showBookingModal = this.$route.query.ack;
                }

                if (this.$route.query && this.$route.query.blockId) {
                    this.transactionTypes = this.transactionTypes ? this.transactionTypes.push('new_build') : ['new_build'];
                    this.block = this.$route.query.blockId;
                }
            })
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        changeTab(newTab) {
            this.$store.commit('property/setLastTab', newTab);
        },
        gotoSale(sale) {
            if (this.isSalesGranted()) {
                this.redirectTo('sale', {id: sale.id});

                return;
            }

            this.redirectTo('contact', {id: sale.mainBuyerId});
        },
        updateApptBedrooms() {
            this.nbBedrooms = this.nbRooms - 1;
        },
        createEvent() {
            this.load();
            this.$store.dispatch('property/addEvent', {
                propertyId: this.propertyId,
                description: this.eventDescription
            })
                .then(event => {
                    this.timeline.push(event);
                    this.eventDescription = ' ';
                    this.success('Nouvel évènement sauvegardé.');
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        print() {
            this.externalRedirectTo('/api/property/' + this.propertyId + '/print');
        },
        save() {
            this.errors = {};

            if (this.isNewBuild) {
                if (!this.block) {
                    this.errors['global'] = 'Bien en VEFA sans résidence';
                    this.errors['block'] = 'Bien en VEFA sans résidence';

                    return;
                }

                if (!this.division) {
                    this.errors['global'] = 'N° lot obligatoire pour bien en VEFA';
                    this.errors['division'] = 'Obligatoire';

                    return;
                }
            }

            this.load();

            const location = this.location;
            this.$store.dispatch('property/save', {
                id: this.propertyId,
                transactionTypes: this.transactionTypes,
                options: this.options,
                locationZipCode: location ? location.zipCode : null,
                locationCity: location ? location.city : null,
                locationArea: location ? location.area : null,
                locationCountry: location ? location.country : null,
                locationLat: location ? location.posLat : null,
                locationLng: location ? location.posLng : null,
                mapZoom: location ? location.mapZoom : null,
                mapCenterLat: location ? location.mapCenterLat : null,
                mapCenterLng: location ? location.mapCenterLng : null,
                livingArea: this.livingArea,
                totalArea: this.totalArea,
                division: this.division,
                block: this.block,
                insideParking1: this.insideParking1,
                insideParking2: this.insideParking2,
                outsideParking1: this.outsideParking1,
                outsideParking2: this.outsideParking2,
                type: this.type,
                description: this.description,
                comment: this.comment,
                landArea: this.landArea,
                otherBlock: this.otherBlock,
                housingDev: this.housingDev,
                floor: this.floor,
                building: this.building,
                virtualTourUrl: this.virtualTourUrl,
                plan1Url: this.plan1Url,
                plan2Url: this.plan2Url,
                plan3Url: this.plan3Url,
                photoUrls: this.photoUrls,
                nbRooms: this.nbRooms,
                nbBedrooms: this.nbBedrooms,
                rent: this.rent,
                charges: this.charges,
                water: this.water,
                waste: this.waste,
                taxExemption: this.taxExemption,
                taxExemptionEnd: this.taxExemptionEnd,
                isFurnished: this.isFurnished,
                price: this.price
            })
                .then(property => {
                    this.redirectAfterSave(property)
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        remove() {
            this.load();
            this.$store.dispatch('property/remove', this.propertyId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('properties', {}, () => this.success('Bien supprimé.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        gotoMandate(mandateProperty) {
            if (this.isMandatesGranted()) {
                this.redirectTo('mandateProperty', {id: mandateProperty.id});
            }

            return null;
        },
        duplicate() {
            this.load();
            this.$store.dispatch('property/duplicate', this.propertyId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('properties', {}, () => this.success('Bien dupliqué.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        closeConfirm() {
            this.showRemoveConfirmation = false;
            this.showDuplicateConfirmation = false;
        },
        gotoContact(contact) {
            this.$store.commit('contact/resetLastTab');
            this.redirectTo('contact', {id: contact.id});
        },
        photoLineIndexes() {
            let indexes = [];
            for (let i = 1; i <= NB_IMAGES; i = i + 2) {
                indexes.push(i);
            }

            return indexes;
        },
        redirectAfterSave(property) {
            this.$store.commit('property/setLastSaved', property);

            return Promise.all([this.$store.dispatch('property/reload'), this.$store.dispatch('block/reload')])
                .then(() => {
                    if (!this.routeAfterSave) {
                        this.propertyId = property.id;
                        this._remapProperty(property);
                        this.success('Bien sauvegardé.');

                        return;
                    }

                    this.$router.push(this.routeAfterSave);
                });
        },
        book(type) {
            this.load();
            this.$store.dispatch('property/book', {
                id: this.propertyId,
                ownerId: this.owner.id,
                type: type
            })
                .then(() => {
                    this.reloadProperty()
                        .then(() => {
                            this.showBookingModal = false;
                            this.owner = null;
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        addSaleOption() {
            this.load();
            this.$store.dispatch('sale/quickOption', {
                propertyId: this.propertyId,
                contactId: this.owner.id
            })
                .then(() => {
                    this.reloadProperty()
                        .then(() => {
                            this.showBookingModal = false;
                            this.owner = null;
                            this.success('Option posée pour 72h.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        addMandate() {
            if (this.propertyId) {
                return this.$router.push({name: 'mandateAdd', query: {propertyId: this.propertyId}});
            }

            return this.redirectTo('mandateAdd');
        },
        removeOldTransaction() {
            this.load();
            this.$store.dispatch('contact/removeTransaction', {
                contactId: this.showRemoveTransactionConfirmation.contact.id,
                transactionId: this.showRemoveTransactionConfirmation.id
            })
                .then(() => {
                    this.reloadProperty()
                        .then(() => {
                            this.showRemoveTransactionConfirmation = false;
                            this.success('Transaction supprimée.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch'),
                this.$store.dispatch('contact/touch')
            ];

            if (this.propertyId) {
                dependencies.push(this.reloadProperty());
            }

            return dependencies;
        },
        reload() {
            this.reloadProperty()
                .catch(this.serverError)
                .finally(this.unload);
        },
        reloadProperty() {
            return this.$store.dispatch('property/one', this.propertyId)
                .then(property => this._remapProperty(property));
        },
        _remapProperty(property) {
            this.property = property;

            this.options = property.options;
            this.location = property.location;
            this.livingArea = property.livingArea;
            this.totalArea = property.totalArea;
            this.division = property.division;
            this.insideParking1 = property.insideParking1;
            this.insideParking2 = property.insideParking2;
            this.outsideParking1 = property.outsideParking1;
            this.outsideParking2 = property.outsideParking2;
            this.block = property.block;
            this.type = property.type;
            this.description = property.description;
            this.transactionTypes = property.transactionTypes;
            this.landArea = property.landArea;
            this.otherBlock = property.otherBlock;
            this.housingDev = property.housingDev;
            this.comment = property.comment;
            this.floor = property.floor;
            this.building = property.building;
            this.virtualTourUrl = property.virtualTourUrl;
            this.plan1Url = property.plan1Url;
            this.plan2Url = property.plan2Url;
            this.plan3Url = property.plan3Url;
            this.photoUrls = property.photoUrls;
            this.nbRooms = property.nbRooms;
            this.nbBedrooms = property.nbBedrooms;
            this.rent = property.rent;
            this.charges = property.charges;
            this.water = property.water;
            this.waste = property.waste;
            this.taxExemption = property.taxExemption;
            this.taxExemptionEnd = property.taxExemptionEnd;
            this.isFurnished = property.isFurnished;
            this.price = property.price;
            this.timeline = property.timeline;
        },
        _isForTransaction(transaction) {
            return this._hasTransaction && this.transactionTypes.includes(transaction);
        },
        _isAmongTypes(types) {
            return this.type && types.includes(this.type);
        },
        _propertyTransactions(type) {
            if (!this.property || this.property.transactions.length <= 0) {
                return [];
            }

            return this.property.transactions.filter(transaction => transaction.type === type);
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.property-edit {
    &__form {
        &__floor, &__division, &__building {
            flex: unset;
            width: 70px;
        }

        &__transactions {
            .imagine-checkboxes__container {
                margin: .5rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__prices {
            .md-field {
                margin-bottom: 0;
            }
        }

        &__furnished {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__timeline {
        margin-top: 1rem;
    }

    &__transactions {
        .imagine-status {
            font-size: 1.2rem;
        }

        &__category {
            margin: 1rem 0;

            &__title {
                margin: 0 0 .5rem;
                padding: 0 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            p {
                padding: 0.5rem 1rem;
            }
        }
    }

    &__prospects {
        &__header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__prospect {
            &__score {
                text-align: right;
            }
        }

        p {
            padding: 0.5rem 1rem;
        }
    }
}
</style>
