<template>
    <div class="account"
         :class="{'account--lettering-current': letteringStatus === 'current',
         'account--lettering-error': letteringStatus === 'error',
         'account--lettering-success': letteringStatus === 'success'}"
         v-if="account">
        <h3 class="account__title">
            <imagine-icon-button class="imagine-no-ripple"
                                 icon="arrow_left"
                                 v-if="prev"
                                 @click="$emit('prev')"/>
            <div class="account__title__nonav"
                 v-else></div>

            <span>{{ account.number }} {{ account.label }}</span>
            <span class="account__title__balance">
                <span v-if="account.entries.length > 0">{{ formatMoney(account.balance) }}</span>
                <imagine-icon-button @click="refreshAccount()"
                                     class="mdc-top-app-bar__action-item"
                                     icon="refresh"/>
                <imagine-icon-button @click="exportAccount()"
                                     :disabled="!account.entries || account.entries.length <= 0"
                                     class="mdc-top-app-bar__action-item"
                                     icon="get_app"/>
                <imagine-icon-button v-if="lease"
                                     @click="add()"
                                     class="mdc-top-app-bar__action-item"
                                     icon="add_box"/>
            </span>

            <imagine-icon-button class="imagine-no-ripple"
                                 icon="arrow_right"
                                 v-if="next"
                                 @click="$emit('next')"/>
            <div class="account__title__nonav"
                 v-else></div>
        </h3>

        <div class="account__filters"
             v-if="account.entries.length > 0">
            <div>
                <div class="account__filters__group account__filters__title">Période</div>
                <div class="account__filters__group account__filters__group--dates">
                    <div class="account__filters__group__filter">
                        <imagine-date-picker :dense="true"
                                             label="Début"
                                             name="startDate"
                                             v-model="startDate"/>
                    </div>
                    <div class="account__filters__group__filter">
                        <imagine-date-picker :dense="true"
                                             :min-date="startDate"
                                             label="Fin"
                                             name="endDate"
                                             v-model="endDate"/>
                    </div>
                </div>
            </div>
            <div class="account__filters__group account__filters__group--amounts">
                <div class="account__filters__group__filter account__filters__group__filter--lettering"
                     v-if="lettering">
                    <imagine-select :choices="letteringModeChoices"
                                    label="Lettrage"
                                    :implicit-required="true"
                                    name="letteringMode"
                                    v-model="letteringMode"/>
                </div>
                <div class="account__filters__group__filter account__filters__group__filter--balance">
                    <span>Masquer les soldes</span>
                    <imagine-switch v-model="withoutBalances"/>
                </div>
            </div>
        </div>

        <div v-if="filteredEntries.length > 0">
            <div class="imagine-table-container">
                <table class="imagine-table">
                    <thead>
                    <tr class="imagine-table__row">
                        <th v-if="lease">&nbsp;</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                            Date
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--account-label">
                            Libellé
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header">Pièce</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header">Débit</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header">Crédit</th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header"
                            v-if="!withoutBalances">
                            Solde
                        </th>
                        <th class="imagine-table__row__cell imagine-table__row__cell--header"
                            v-if="lettering">
                            Lettrage
                        </th>
                    </tr>
                    </thead>
                    <tbody class="mdc-data-table__content">
                    <tr class="imagine-table__row account__entry"
                        :class="{
                        'account__entry--editable': lease,
                        'account__entry--first': index === 0,
                        'account__entry--lettering-debit': letteringDebitEntries.includes(accountEntry.id),
                        'account__entry--lettering-credit': letteringCreditEntries.includes(accountEntry.id)}"
                        v-for="(accountEntry, index) in filteredEntries">
                        <td v-if="lease"
                            @click="showRemove(accountEntry)">
                            <span v-if="accountEntry.source === 'itool'">
                                <imagine-icon-button :disabled="true"
                                                     class="imagine-no-ripple"
                                                     icon="cancel"/>
                                <md-tooltip
                                    md-direction="right">Ecriture itool non supprimable dans l'extranet</md-tooltip>
                            </span>
                            <span v-else-if="lettering && letters[accountEntry.id] && letters[accountEntry.id].letter">
                                <imagine-icon-button :disabled="true"
                                                     class="imagine-no-ripple"
                                                     icon="cancel"/>
                                <md-tooltip md-direction="right">Ecriture lettrée non supprimable</md-tooltip>
                            </span>
                            <span v-else>
                                <imagine-icon-button class="imagine-no-ripple"
                                                     icon="cancel"/>
                            </span>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--date"
                            @click="edit(accountEntry)">
                            {{ formatDate(accountEntry.date) }}
                        </td>
                        <td class="imagine-table__row__cell"
                            @click="edit(accountEntry)">
                            <img src="/itool.png" width="16" height="16" v-if="accountEntry.source === 'itool'"/>
                            {{ accountEntry.label }}
                        </td>
                        <td class="imagine-table__row__cell"
                            @click="download(accountEntry.docUrl)"
                            v-if="accountEntry.docUrl">
                            <span>
                                <imagine-icon-button class="imagine-no-ripple"
                                                     icon="insert_drive_file"/>
                                <md-tooltip md-direction="right">{{ accountEntry.docType }}</md-tooltip>
                            </span>
                        </td>
                        <td class="imagine-table__row__cell"
                            @click="edit(accountEntry)"
                            v-else>
                            {{ accountEntry.docType }}
                        </td>
                        <td @click="edit(accountEntry)"
                            class="imagine-table__row__cell imagine-table__row__cell--amount account__entry__debit">
                            <span v-if="accountEntry.debit">{{ formatMoneyAmount(accountEntry.debit) }}</span>
                        </td>
                        <td @click="edit(accountEntry)"
                            class="imagine-table__row__cell imagine-table__row__cell--amount account__entry__credit">
                            <span v-if="accountEntry.credit">{{ formatMoneyAmount(accountEntry.credit) }}</span>
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount"
                            @click="edit(accountEntry)"
                            v-if="!withoutBalances">
                            <span v-if="accountEntry.balance">{{ formatMoneyAmount(accountEntry.balance) }}</span>
                        </td>
                        <td class="imagine-table__row__cell"
                            v-if="lettering && letters[accountEntry.id]">
                            <input type="text"
                                   :tabindex="1000+index"
                                   maxlength="6"
                                   style="width: 100px;"
                                   :placeholder="'Dispo : ' + nextAvailableLetter"
                                   @input="letterChange(accountEntry, $event.data)"
                                   v-model="letters[accountEntry.id].letter"/>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="account__total">
                        <td :colspan="lease ? 4 : 3" class="imagine-table__row__cell account__total__header">
                            {{ isLettering ? 'LETTRAGE' : 'TOTAL' }} :
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount account__total__debit">
                            {{ formatMoneyAmount(totalDebit) }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount account__total__credit">
                            {{ formatMoneyAmount(totalCredit) }}
                        </td>
                        <td class="imagine-table__row__cell imagine-table__row__cell--amount"
                            v-if="!withoutBalances">
                            {{ formatMoneyAmount(totalBalance) }}
                        </td>
                        <td v-if="lettering" class="imagine-table__row__cell">
                            <button @click.prevent="cancelLetters"
                                    :class="{'account__letters-action--disabled': !this.isLettering}"
                                    :disabled="!this.isLettering"
                                    class="account__letters-action">
                                ANNULER
                            </button>
                            <button @click.prevent="saveLetters"
                                    class="account__letters-action"
                                    :class="{'account__letters-action--disabled': this.letteringStatus !== 'success'}"
                                    :disabled="this.letteringStatus !== 'success'">
                                SAUVEGARDER
                            </button>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="account__none" v-else>
            <span v-if="account.entries.length <= 0">Aucune écriture.</span>
            <span v-else>Aucune écriture visible.</span>
        </div>

        <account-entry v-if="lease && showEditModal"
                       :entry="currentEntry"
                       @close="showEditModal = false"
                       :lease="lease"/>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       button-label="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer cette écriture ?</p>
        </imagine-modal>
    </div>
</template>

<script>
import ImagineSwitch from '../../../components/ImagineSwitch.vue';
import ImagineIconButton from '../../../components/ImagineIconButton.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';
import ImagineSelect from '../../../components/ImagineSelect.vue';
import AccountEntry from './AccountEntry.vue';
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineButton from "../../../components/ImagineButton";

export default {
    components: {
        ImagineButton,
        ImagineDatePicker,
        ImagineSwitch,
        ImagineIconButton,
        ImagineSelect,
        AccountEntry,
        ImagineModal
    },
    props: ['account', 'lettering', 'mandate', 'lease', 'prev', 'next'],
    computed: {
        filteredEntries() {
            if (this.account.entries.length <= 0) {
                return [];
            }

            return this._filterDates(this._filterLetterings(this.account.entries));
        },
        letteringModeChoices() {
            return [
                this.makeChoice('all', 'Toutes les écritures'),
                this.makeChoice('only_letters', 'Ecritures lettrées'),
                this.makeChoice('no_letters', 'Ecritures non lettrées')
            ];
        },
        totalDebit() {
            return this._total(
                this.isLettering ? this.filteredEntries.filter(entry => this.letteringDebitEntries.includes(entry.id)) : this.filteredEntries,
                entry => entry.debit
            );
        },
        totalCredit() {
            return this._total(
                this.isLettering ? this.filteredEntries.filter(entry => this.letteringCreditEntries.includes(entry.id)) : this.filteredEntries,
                entry => entry.credit
            );
        },
        totalBalance() {
            return this.totalCredit.subtract(this.totalDebit);
        },
        nextAvailableLetter() {
            return this.account.nextAvailableLetter;
        },
        letteringStatus() {
            if (!this.isLettering) {
                return null;
            }

            if (this.letteringError) {
                return 'error';
            }

            if (this.totalBalance.isZero()) {
                return 'success';
            }

            return 'current';
        }
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            withoutBalances: false,
            letteringMode: 'no_letters',
            showEditModal: false,
            showRemoveConfirmation: false,
            currentEntry: null,
            letters: [],
            isLettering: false,
            letteringError: null,
            letteringDebitEntries: [],
            letteringCreditEntries: []
        }
    },
    created() {
        this._initLetters();
    },
    watch: {
        lease() {
            this._initLetters();
        }
    },
    methods: {
        exportAccount() {
            if (this.lease) {
                window.location.href = '/api/lease/' + this.lease.id + '/account/export';
                return;
            }

            if (this.mandate) {
                window.location.href = '/api/mandate/' + this.mandate.id + '/account/export';
            }
        },
        refreshAccount() {
            this.$emit('refresh');
        },
        formatMoneyAmount(money) {
            return this.$container.types.formatMoneyAmount(money);
        },
        download(docUrl) {
            if (docUrl) {
                this.externalRedirectTo(docUrl);
            }
        },
        add() {
            this.currentEntry = null;
            this.showEditModal = true;
        },
        edit(entry) {
            this.currentEntry = entry;
            this.showEditModal = true;
        },
        showRemove(entry) {
            if (this.lettering && this.letters[entry.id] && this.letters[entry.id].letter) {
                return;
            }

            if (entry.source === 'itool') {
                return;
            }

            this.currentEntry = entry;
            this.showRemoveConfirmation = true;
        },
        remove() {
            this.load();
            this.$store.dispatch('accountEntry/remove', this.currentEntry.id)
                .then(() => {
                    this.$emit('refresh');
                    this.closeConfirm();
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        closeConfirm() {
            this.currentEntry = null;
            this.showRemoveConfirmation = false;
        },
        saveLetters() {
            this.letteringError = null;
            this.load();
            this.$store.dispatch('accountEntry/saveLetters', this.letters.filter(letter => letter))
                .then(() => this.$emit('refresh'))
                .catch(error => {
                    this.serverError(error);
                    this.letteringError = true;
                })
                .finally(this.unload);
        },
        cancelLetters() {
            this._initLetters();
        },
        letterChange(entry, newLetter) {
            if (newLetter) {
                this._markLettering(entry);
            } else {
                this._removeLettering(entry);
            }
        },
        _markLettering(entry) {
            this.isLettering = true;
            if (entry.credit) {
                this.letteringCreditEntries.push(entry.id);
            } else {
                this.letteringDebitEntries.push(entry.id);
            }
        },
        _removeLettering(entry) {
            if (entry.credit) {
                this.letteringCreditEntries = this.letteringCreditEntries.filter(entryId => entryId !== entry.id);
            } else {
                this.letteringDebitEntries = this.letteringDebitEntries.filter(entryId => entryId !== entry.id);
            }
            if (this.letteringCreditEntries.length <= 0 && this.letteringDebitEntries.length <= 0) {
                this.isLettering = false;
            }
        },
        _total(entries, extract) {
            let total = this.$container.types.makeMoney(0);

            if (entries.length <= 0) {
                return total;
            }

            entries.forEach(entry => {
                const toBeAdded = extract(entry);
                if (toBeAdded) {
                    total = total.add(toBeAdded);
                }
            });

            return total;
        },
        _initLetters() {
            if (this.account.entries.length <= 0) {
                return [];
            }

            let letters = [];
            this.account.entries.forEach(entry => {
                letters[entry.id] = {id: entry.id, letter: entry.lettering};
            });

            this.letters = letters;

            this.isLettering = false;
            this.letteringError = null;
            this.letteringDebitEntries = [];
            this.letteringCreditEntries = [];
        },
        _filterDates(entries) {
            if (this.startDate) {
                entries = entries.filter(entry => entry.date.isSameOrAfter(this.startDate, 'days'));
            }

            if (this.endDate) {
                entries = entries.filter(entry => entry.date.isSameOrBefore(this.endDate, 'days'));
            }

            return entries;
        },
        _filterLetterings(entries) {
            if (!this.lettering || this.letteringMode === 'all') {
                return entries;
            }

            return this._rebalance(entries.filter(entry => this.letteringMode === 'only_letters' ? entry.lettering : !entry.lettering));
        },
        _rebalance(entries) {
            let balance = this.$container.types.makeMoney(0);

            let rebalanceds = [];
            entries.forEach(entry => {
                let rebalanced = {};
                rebalanced.id = entry.id;
                rebalanced.type = entry.type;
                rebalanced.source = entry.source;
                rebalanced.date = entry.date;
                rebalanced.label = entry.label;
                rebalanced.credit = entry.credit;
                rebalanced.debit = entry.debit;
                rebalanced.lettering = entry.lettering;
                rebalanced.docType = entry.docType;
                rebalanced.docUrl = entry.docUrl;

                if (entry.debit) {
                    balance = balance.subtract(entry.debit);
                } else {
                    balance = balance.add(entry.credit);
                }

                rebalanced.balance = balance;

                rebalanceds.push(rebalanced);
            });

            return rebalanceds;
        }
    }
}
</script>

<style lang="scss">
@import '../../../scss/config';

.account {
    margin: .5rem auto;
    min-width: 300px;
    max-width: 1400px;
    padding: 0 .5rem;

    .imagine-table__row__cell--account-label {
        max-width: 250px;
    }

    &--lettering-success {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-green;
        }
    }

    &--lettering-current {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-orange;
        }
    }

    &--lettering-error {
        .account__entry--lettering-debit .account__entry__debit,
        .account__entry--lettering-debit .account__entry__credit,
        .account__entry--lettering-credit .account__entry__debit,
        .account__entry--lettering-credit .account__entry__credit {
            border-color: $imagine-error-color;
        }
    }

    &__entry {
        &--first {
            .account__entry__credit,
            .account__entry__debit {
                border-top: 1px solid #000000 !important;
            }
        }

        &--editable {
            cursor: pointer;
        }

        &__debit {
            border-right: 1px solid #000000;
        }

        &--lettering-debit {
            .account__entry__debit {
                border-width: 1px;
                border-style: solid;
            }
        }

        &--lettering-credit {
            .account__entry__credit {
                border-width: 1px;
                border-style: solid;
            }

            .account__entry__debit {
                border-right-width: 0;
            }
        }
    }

    &__total {
        &__header {
            font-weight: bold;
            text-align: right;
        }

        &__debit {
            border-right: 1px solid #000000;
        }

        &__debit, &__credit {
            font-weight: bold;
            border-top: 1px solid #000000;
        }
    }

    &__letters-action {
        font-size: .8rem;
        font-weight: normal;
        padding: 2px;
        width: 100px;

        &--disabled {
            color: grey;
        }
    }

    &__title {
        text-align: center;
        font-size: 1.2rem;
        font-weight: normal;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__nonav {
            width: 48px;
        }

        &__balance {
            display: flex;
            align-items: center;
            min-height: 48px;
        }
    }

    &__filters {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        @media (max-width: map-get($imagine-breakpoints, 'phone')) {
            flex-direction: column;
        }

        &__title {
            color: rgba(0, 0, 0, 0.54);
            font-size: .8rem;
            padding: 0 .5rem;
            margin-bottom: -3px;
        }

        &__group {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &__filter {
                margin-right: .5rem;
                margin-left: 0;

                &--lettering {
                    width: 190px;
                }

                &--balance {
                    display: flex;
                    align-items: center;

                    span {
                        margin: 0 .5em;
                    }
                }

                .imagine-datepicker, .imagine-select {
                    margin-bottom: 0;
                }
            }

            &--dates {
                width: 400px;

                .account__filters__group__filter {
                    margin-right: 0;
                }
            }

            &--amounts {
                justify-content: flex-end;

                .account__filters__group__filter {
                    margin-right: 0;
                    margin-left: .5rem;
                }
            }
        }
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
