var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-switch",
    {
      staticClass: "imagine-switch md-primary",
      class: { "imagine-switch--disabled": _vm.disabled },
      attrs: { disabled: _vm.disabled },
      on: {
        change: function ($event) {
          return _vm.$emit("input", _vm.innerValue)
        },
      },
      model: {
        value: _vm.innerValue,
        callback: function ($$v) {
          _vm.innerValue = $$v
        },
        expression: "innerValue",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }