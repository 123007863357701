var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandates-report",
      attrs: {
        title: _vm.$mq === "phone" ? "Synthèse" : "Synthèse gestion locative",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [_c("mandate-external-services")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.periods.length > 0
        ? _c(
            "div",
            { staticClass: "imagine-secondary-zone blocks__filter" },
            [
              _c("imagine-select-period", {
                staticClass: "imagine-form__row__field",
                attrs: { periods: _vm.periods },
                on: {
                  input: function ($event) {
                    return _vm.reloadPeriodReports()
                  },
                },
                model: {
                  value: _vm.period,
                  callback: function ($$v) {
                    _vm.period = $$v
                  },
                  expression: "period",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("imagine-tab-bar", {
        attrs: { tabs: _vm.tabs },
        on: {
          tab: function ($event) {
            _vm.activeTabId = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.activeTabId === "rents"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "imagine-table-container" }, [
                _vm.rents.length > 0
                  ? _c("table", { staticClass: "imagine-table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Propriétaire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Bien\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Locataire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Loyer hors charges\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Provision charges\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Provision taxe d'eau\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Provision taxe d'ordures ménagères\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Loyer charges comprises\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.rents, function (report) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "imagine-table__row mandates-report__line",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("mandateProperty", {
                                    id: report.leaseInfos.mandatePropertyId,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                { staticClass: "imagine-table__row__cell" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(report.leaseInfos.property.infos) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.tenants
                                          .map((tenant) => tenant.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rent.rent)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rent.charges)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rent.water)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rent.waste)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rent.fullRent)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c("th", { attrs: { colspan: "3" } }, [
                            _vm._v("TOTAL"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalRent)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalCharges)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalWater)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalWaste)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalFullRent)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "mandates-report__none" }, [
                      _vm._v(
                        "\n                    Aucun loyer appelé.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "guarantees"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "imagine-table-container" }, [
                _vm.guarantees.length > 0
                  ? _c("table", { staticClass: "imagine-table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Propriétaire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Bien\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Locataire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Dépôt de garantie\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.guarantees, function (report) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "imagine-table__row mandates-report__line",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("mandateProperty", {
                                    id: report.leaseInfos.mandatePropertyId,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                { staticClass: "imagine-table__row__cell" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(report.leaseInfos.property.infos) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.tenants
                                          .map((tenant) => tenant.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.guarantee)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c("th", { attrs: { colspan: "3" } }, [
                            _vm._v("TOTAL"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalGuarantee)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "mandates-report__none" }, [
                      _vm._v(
                        "\n                    Aucun dépôt de garantie appelé.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "rental_fees"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "imagine-table-container" }, [
                _vm.rentalFees.length > 0
                  ? _c("table", { staticClass: "imagine-table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Propriétaire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Bien\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Locataire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Honoraires de mise en location\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.rentalFees, function (report) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "imagine-table__row mandates-report__line",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("mandateProperty", {
                                    id: report.leaseInfos.mandatePropertyId,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                { staticClass: "imagine-table__row__cell" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(report.leaseInfos.property.infos) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.tenants
                                          .map((tenant) => tenant.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.rentalFees)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c("th", { attrs: { colspan: "3" } }, [
                            _vm._v("TOTAL"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalRentalFees)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "mandates-report__none" }, [
                      _vm._v(
                        "\n                    Aucun honoraire de mise en location appelé.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "unpaid"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "imagine-table-container" }, [
                _vm.unpaid.length > 0
                  ? _c("table", { staticClass: "imagine-table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Propriétaire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Bien\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Locataire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Garant(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            Somme des impayés\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Denière(s) relance(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                             \n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.unpaid, function (report) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "imagine-table__row mandates-report__line",
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "imagine-table__row__cell",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(report.leaseInfos.property.infos) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.tenants
                                          .map((tenant) => tenant.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.leaseInfos.protectors
                                          .map(
                                            (protector) => protector.shortName
                                          )
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.formatMoney(report.balance)
                                          ) +
                                          "\n                                "
                                      ),
                                      _c(
                                        "md-tooltip",
                                        { attrs: { "md-direction": "right" } },
                                        [
                                          _c(
                                            "table",
                                            _vm._l(
                                              report.entries,
                                              function (entry) {
                                                return _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          entry.date
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(_vm._s(entry.label)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    entry.debit
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "- " +
                                                              _vm._s(
                                                                _vm.formatMoney(
                                                                  entry.debit
                                                                )
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    entry.credit
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "+ " +
                                                              _vm._s(
                                                                _vm.formatMoney(
                                                                  entry.credit
                                                                )
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]),
                                                ])
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "imagine-table__row__cell",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: report.leaseInfos.mandatePropertyId,
                                      })
                                    },
                                  },
                                },
                                [
                                  report.lastReminderEvents &&
                                  report.lastReminderEvents.length > 0
                                    ? [
                                        report.lastReminderEvents.length === 1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.formatDate(
                                                      report
                                                        .lastReminderEvents[0]
                                                        .date
                                                    )
                                                  ) +
                                                  "\n                                    [" +
                                                  _vm._s(
                                                    _vm.reminderType(
                                                      report
                                                        .lastReminderEvents[0]
                                                        .type
                                                    )
                                                  ) +
                                                  "]\n                                "
                                              ),
                                            ])
                                          : _c(
                                              "ul",
                                              _vm._l(
                                                report.lastReminderEvents,
                                                function (event) {
                                                  return _c("li", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.formatDate(
                                                            event.date
                                                          )
                                                        ) +
                                                        " [" +
                                                        _vm._s(
                                                          _vm.reminderType(
                                                            event.type
                                                          )
                                                        ) +
                                                        "]\n                                    "
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "imagine-table__row__cell" },
                                [
                                  _c("imagine-icon-button", {
                                    staticClass: "imagine-no-ripple",
                                    attrs: { icon: "send" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showReminderModalFor(
                                          report.leaseInfos
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c("th", { attrs: { colspan: "4" } }, [
                            _vm._v("TOTAL"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalUnpaid)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("th", { attrs: { colspan: "2" } }),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "mandates-report__none" }, [
                      _vm._v(
                        "\n                    Aucun impayé.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "repays"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "imagine-table-container" }, [
                _vm.repays.length > 0
                  ? _c("table", { staticClass: "imagine-table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            N° mandat\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header",
                            },
                            [
                              _vm._v(
                                "\n                            Propriétaire(s)\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Date versement\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                            },
                            [
                              _vm._v(
                                "\n                            Montant versé\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.repays, function (report) {
                          return _c(
                            "tr",
                            {
                              staticClass:
                                "imagine-table__row mandates-report__line",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo("mandate", {
                                    id: report.mandateInfos.id,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                { staticClass: "imagine-table__row__cell" },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.mandateInfos.registryNumber
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        report.mandateInfos.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--text",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.formatDate(report.repayDate)) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "imagine-table__row__cell imagine-table__row__cell--money",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatMoney(report.repayAmount)
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("tfoot", [
                        _c("tr", { staticClass: "imagine-table__row" }, [
                          _c("th", { attrs: { colspan: "3" } }, [
                            _vm._v("TOTAL"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--money",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.formatMoney(_vm.totalRepays)) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _c("div", { staticClass: "mandates-report__none" }, [
                      _vm._v(
                        "\n                    Aucun versement propriétaire.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReminderModal
        ? _c("send-reminder", {
            attrs: { lease: _vm.showReminderModal },
            on: {
              canceled: function ($event) {
                return _vm.hideReminder()
              },
              completed: function ($event) {
                return _vm.reminderSent()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }