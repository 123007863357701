import container from '../container';

const repo = container.repos.mandateProperty;

export default {
    namespaced: true,
    state: {
        all: [],
        current: null,
        actorFilter: null,
        propertyFilter: null,
        statusesFilter: ['rent_no_alert', 'rent_alerts', 'vacant'],
        nbRepayOwners: null
    },
    mutations: {
        setActorFilter(state, actorFilter) {
            state.actorFilter = actorFilter;
        },
        setPropertyFilter(state, propertyFilter) {
            state.propertyFilter = propertyFilter;
        },
        setStatusesFilter(state, statusesFilter) {
            state.statusesFilter = statusesFilter;
        }
    },
    actions: {
        reload(context) {
            return repo.all()
                .then(mandateProperties => context.state.all = mandateProperties);
        },
        one(context, id) {
            return repo.one(id)
                .then(mandateProperty => context.state.current = mandateProperty);
        },
        save(context, {id, data}) {
            return repo.save(id, data);
        },
        saveInsurance(context, {id, data}) {
            return repo.saveInsurance(id, data);
        },
        desactivate(context, {id, date}) {
            return repo.desactivate(id, date);
        },
        sendMail(context, url) {
            return repo.sendMail(url);
        },
        saveCustomEvent(context, data) {
            return repo.saveCustomEvent(data);
        }
    }
};
