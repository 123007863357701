import axios from 'axios';
import { parse, stringify } from 'qs';

class Http {
    constructor() {
        this._logger = console;
    }

    get(url, data) {
        this._logger.info('[http] get from ' + url);

        return new Promise((resolve, reject) => {
            axios.get(
                url,
                {
                    params: data,
                    paramsSerializer: {
                        encode: parse,
                        serialize: stringify,
                    }
                })
                .then((response) => {
                    if (response.status !== 200 && response.status !== 201) {
                        this._logger.warn(response);
                        reject();

                        return;
                    }

                    resolve(response.data);
                })
                .catch((err) => {
                    this._logger.warn(err);
                    reject(err);
                });
        });
    }

    post(url, data, headers) {
        this._logger.info('[http] post to ' + url);

        return new Promise((resolve, reject) => {
            axios.post(url, data, headers ? headers : {})
                .then((response) => {
                    if (response.status !== 200 && response.status !== 201) {
                        reject(response);

                        return;
                    }

                    resolve(response.data);
                })
                .catch((err) => {
                    this._logger.warn(err);
                    reject(err.response);
                });
        });
    }

    put(url, data) {
        this._logger.info('[http] put to ' + url);

        return new Promise((resolve, reject) => {
            axios.put(url, data)
                .then((response) => {
                    if (response.status !== 200 && response.status !== 201) {
                        this._logger.warn(response);
                        reject(response);

                        return;
                    }

                    resolve(response.data);
                })
                .catch((err) => {
                    this._logger.warn(err);
                    reject(err);
                });
        });
    }

    delete(url, data) {
        this._logger.info('[http] delete ' + url);

        return new Promise((resolve, reject) => {
            axios.delete(url, {data})
                .then((response) => {
                    if (response.status !== 200 && response.status !== 204) {
                        this._logger.warn(response);
                        reject(response);

                        return;
                    }

                    resolve(response.data);
                })
                .catch((err) => {
                    this._logger.warn(err);
                    reject(err);
                });
        });
    }
}

export default Http;
