var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "mdc-icon-button imagine-icon-button",
      class: _vm.classes,
      attrs: { disabled: _vm.disabled },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.ficon
        ? [_c("i", { class: "fas fa-" + _vm.ficon })]
        : [_vm._v(_vm._s(_vm.icon ? _vm.icon : "svg"))],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }