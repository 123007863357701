var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "block-edit",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              !_vm.hasTransactions
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__navigation-icon",
                    attrs: { icon: "delete" },
                    on: {
                      click: function ($event) {
                        _vm.showRemoveConfirmation = true
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("imagine-tab-bar", {
        attrs: { tabs: _vm.tabs },
        on: {
          tab: function ($event) {
            return _vm.changeTab($event)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "imagine-container" }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab === "infos",
                expression: "activeTab === 'infos'",
              },
            ],
            staticClass: "imagine-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _vm.errors.global
              ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-input", {
                  staticClass:
                    "imagine-form__row__field imagine-form__row__field--acronym",
                  attrs: {
                    required: true,
                    label: "Sigle",
                    name: "acronym",
                    violation: _vm.errors.acronym,
                  },
                  model: {
                    value: _vm.acronym,
                    callback: function ($$v) {
                      _vm.acronym = $$v
                    },
                    expression: "acronym",
                  },
                }),
                _vm._v(" "),
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: { required: true, label: "Nom complet", name: "name" },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-select", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    choices: _vm.references.blockStatusChoices(),
                    required: true,
                    label: "Statut",
                    name: "status",
                  },
                  model: {
                    value: _vm.status,
                    callback: function ($$v) {
                      _vm.status = $$v
                    },
                    expression: "status",
                  },
                }),
                _vm._v(" "),
                _vm.status !== "project"
                  ? _c("imagine-date-picker", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        label: "Livraison estimée",
                        name: "deliveryDate",
                      },
                      model: {
                        value: _vm.deliveryDate,
                        callback: function ($$v) {
                          _vm.deliveryDate = $$v
                        },
                        expression: "deliveryDate",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-textarea", {
                  staticClass: "imagine-form__row__field",
                  attrs: { label: "Texte descriptif", name: "description" },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("imagine-address", {
              attrs: {
                show: _vm.showAddress,
                "with-address": false,
                "with-map": true,
                "marker-icon": "business",
                title: "Localisation",
              },
              model: {
                value: _vm.location,
                callback: function ($$v) {
                  _vm.location = $$v
                },
                expression: "location",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                false
                  ? _c("imagine-checkboxes", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        choices: _vm.references.blockOptionsChoices(),
                        label: "Prestations",
                        name: "options",
                      },
                      model: {
                        value: _vm.options,
                        callback: function ($$v) {
                          _vm.options = $$v
                        },
                        expression: "options",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-checkboxes", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    choices: _vm.references.blockPropertyOptionsChoices(),
                    label: "Caractéristiques communes à tous les lots",
                    name: "propertyOptions",
                  },
                  model: {
                    value: _vm.propertyOptions,
                    callback: function ($$v) {
                      _vm.propertyOptions = $$v
                    },
                    expression: "propertyOptions",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    help: "Sur https://3d.previsite.com/",
                    label: "Lien visite virtuelle",
                    name: "virtualTourUrl",
                    type: "url",
                  },
                  model: {
                    value: _vm.virtualTourUrl,
                    callback: function ($$v) {
                      _vm.virtualTourUrl = $$v
                    },
                    expression: "virtualTourUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "imagine-form-complex-field",
              {
                attrs: {
                  show: _vm.showSalePaymentInfos,
                  title: "Appels de fonds",
                },
                on: {
                  open: function ($event) {
                    _vm.showSalePaymentInfos = true
                  },
                  close: function ($event) {
                    _vm.showSalePaymentInfos = false
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "imagine-form__row" },
                  [
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: { label: "Société", name: "company" },
                      model: {
                        value: _vm.company,
                        callback: function ($$v) {
                          _vm.company = $$v
                        },
                        expression: "company",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        label: "Email",
                        type: "email",
                        name: "email",
                        violation: _vm.errors.email,
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imagine-form__row" },
                  [
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        label: "Organisme des attestations",
                        name: "approval",
                      },
                      model: {
                        value: _vm.approval,
                        callback: function ($$v) {
                          _vm.approval = $$v
                        },
                        expression: "approval",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imagine-form__row" },
                  [
                    _c("imagine-input", {
                      staticClass: "imagine-form__row__field",
                      attrs: {
                        label: "IBAN",
                        name: "iban",
                        help: "Ex : FR7620800121000001234567823",
                        violation: _vm.errors.iban,
                      },
                      model: {
                        value: _vm.iban,
                        callback: function ($$v) {
                          _vm.iban = $$v
                        },
                        expression: "iban",
                      },
                    }),
                    _vm._v(" "),
                    _c("imagine-input", {
                      staticClass:
                        "imagine-form__row__field imagine-form__row__field--bic",
                      attrs: { label: "BIC", name: "bic" },
                      model: {
                        value: _vm.bic,
                        callback: function ($$v) {
                          _vm.bic = $$v
                        },
                        expression: "bic",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__actions" },
              [
                _c(
                  "imagine-button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Annuler")]
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  { attrs: { primary: "", type: "submit" } },
                  [_vm._v("Sauvegarder")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "imagine-container" }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeTab === "docs",
                expression: "activeTab === 'docs'",
              },
            ],
            staticClass: "imagine-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Visuel principal",
                    accept: "image/*",
                    options: {
                      cropRatio: "16/10",
                      watermark: true,
                      public: true,
                    },
                  },
                  model: {
                    value: _vm.photoUrls[0],
                    callback: function ($$v) {
                      _vm.$set(_vm.photoUrls, 0, $$v)
                    },
                    expression: "photoUrls[0]",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "application/pdf",
                    label: "Plaquette commerciale",
                    name: "booklet",
                  },
                  model: {
                    value: _vm.bookletUrl,
                    callback: function ($$v) {
                      _vm.bookletUrl = $$v
                    },
                    expression: "bookletUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--alone" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Pré-réservation",
                    accept: "application/pdf",
                    name: "bookingFormUrl",
                  },
                  model: {
                    value: _vm.bookingFormUrl,
                    callback: function ($$v) {
                      _vm.bookingFormUrl = $$v
                    },
                    expression: "bookingFormUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan situation (large)",
                    name: "largeMapUrl",
                  },
                  model: {
                    value: _vm.largeMapUrl,
                    callback: function ($$v) {
                      _vm.largeMapUrl = $$v
                    },
                    expression: "largeMapUrl",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan situation (zoom)",
                    name: "zoomMapUrl",
                  },
                  model: {
                    value: _vm.zoomMapUrl,
                    callback: function ($$v) {
                      _vm.zoomMapUrl = $$v
                    },
                    expression: "zoomMapUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--alone" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan masse",
                    name: "groundPlanUrl",
                  },
                  model: {
                    value: _vm.groundPlanUrl,
                    callback: function ($$v) {
                      _vm.groundPlanUrl = $$v
                    },
                    expression: "groundPlanUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan étage 1",
                    name: "floorPlanUrl",
                  },
                  model: {
                    value: _vm.floorPlanUrl,
                    callback: function ($$v) {
                      _vm.floorPlanUrl = $$v
                    },
                    expression: "floorPlanUrl",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan étage 2",
                    name: "floorPlanUrl2",
                  },
                  model: {
                    value: _vm.floorPlanUrl2,
                    callback: function ($$v) {
                      _vm.floorPlanUrl2 = $$v
                    },
                    expression: "floorPlanUrl2",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan étage 3",
                    name: "floorPlanUrl3",
                  },
                  model: {
                    value: _vm.floorPlanUrl3,
                    callback: function ($$v) {
                      _vm.floorPlanUrl3 = $$v
                    },
                    expression: "floorPlanUrl3",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan étage 4",
                    name: "floorPlanUrl4",
                  },
                  model: {
                    value: _vm.floorPlanUrl4,
                    callback: function ($$v) {
                      _vm.floorPlanUrl4 = $$v
                    },
                    expression: "floorPlanUrl4",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan parking 1",
                    name: "floorPlanUrl",
                  },
                  model: {
                    value: _vm.parkingPlanUrl,
                    callback: function ($$v) {
                      _vm.parkingPlanUrl = $$v
                    },
                    expression: "parkingPlanUrl",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan parking 2",
                    name: "floorPlanUrl2",
                  },
                  model: {
                    value: _vm.parkingPlanUrl2,
                    callback: function ($$v) {
                      _vm.parkingPlanUrl2 = $$v
                    },
                    expression: "parkingPlanUrl2",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row imagine-form__row--limited" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan parking 3",
                    name: "floorPlanUrl3",
                  },
                  model: {
                    value: _vm.parkingPlanUrl3,
                    callback: function ($$v) {
                      _vm.parkingPlanUrl3 = $$v
                    },
                    expression: "parkingPlanUrl3",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "image/*",
                    label: "Plan parking 4",
                    name: "floorPlanUrl4",
                  },
                  model: {
                    value: _vm.parkingPlanUrl4,
                    callback: function ($$v) {
                      _vm.parkingPlanUrl4 = $$v
                    },
                    expression: "parkingPlanUrl4",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _vm._l(_vm.photoLineIndexes(), function (i) {
              return _c(
                "div",
                { staticClass: "imagine-form__row imagine-form__row--limited" },
                _vm._l(2, function (j) {
                  return _c("imagine-file-input", {
                    key: "block-image-" + i + "-" + j,
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      label: "Autre visuel " + (i + j - 1),
                      accept: "image/*",
                      options: {
                        cropRatio: "16/10",
                        watermark: true,
                        public: true,
                      },
                    },
                    model: {
                      value: _vm.photoUrls[i + j - 1],
                      callback: function ($$v) {
                        _vm.$set(_vm.photoUrls, i + j - 1, $$v)
                      },
                      expression: "photoUrls[(i+j-1)]",
                    },
                  })
                }),
                1
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__actions" },
              [
                _c(
                  "imagine-button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Annuler")]
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  { attrs: { primary: "", type: "submit" } },
                  [_vm._v("Sauvegarder")]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.block
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab === "planning",
                  expression: "activeTab === 'planning'",
                },
              ],
              staticClass: "block-edit__planning",
            },
            [
              _c(
                "div",
                { staticClass: "block-edit__planning__actions" },
                [
                  _c(
                    "imagine-button",
                    {
                      attrs: { primary: true },
                      on: {
                        click: function ($event) {
                          _vm.currentStage = {}
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                Ajouter une phase\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.buildingStages
                ? _c(
                    "div",
                    { staticClass: "block-edit__planning__stages" },
                    _vm._l(
                      _vm.buildingStages,
                      function (buildingStage, building) {
                        return _c(
                          "div",
                          {
                            staticClass: "block-edit__planning__stages__stage",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "imagine-table-container" },
                              [
                                _c("table", { staticClass: "imagine-table" }, [
                                  _c("thead", [
                                    _c(
                                      "tr",
                                      { staticClass: "imagine-table__row" },
                                      [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cell",
                                          },
                                          [
                                            building
                                              ? _c(
                                                  "h6",
                                                  {
                                                    staticClass:
                                                      "block-edit__planning__stages__stage__title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    Bâtiment " +
                                                        _vm._s(building) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cell",
                                          },
                                          [_vm._v("Appels de fonds")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cel imagine-table__row__cell--datel",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Date prévisionnelle\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cell imagine-table__row__cell--date",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Date réelle\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass:
                                            "imagine-table__row__cell",
                                        }),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(buildingStage, function (stage) {
                                      return _c(
                                        "tr",
                                        { staticClass: "imagine-table__row" },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "imagine-table__row__cell",
                                            },
                                            [_vm._v(_vm._s(stage.label))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "imagine-table__row__cell",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(stage.progress) + "%"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "imagine-table__row__cell imagine-table__row__cell--date",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.formatMonthYear(
                                                      stage.estimatedDate
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "imagine-table__row__cell imagine-table__row__cell--date",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.formatMonthYear(
                                                      stage.actualDate
                                                    )
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "imagine-table__row__cell",
                                              staticStyle: {
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [
                                              _c("imagine-icon-button", {
                                                staticClass:
                                                  "imagine-no-ripple",
                                                attrs: { icon: "edit" },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.currentStage = stage
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("imagine-icon-button", {
                                                staticClass:
                                                  "imagine-no-ripple",
                                                attrs: {
                                                  disabled: !stage.canDelete,
                                                  icon: "delete",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showRemoveStageConfirmation =
                                                      stage
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("tfoot", [
                                    _c(
                                      "tr",
                                      { staticClass: "imagine-table__row" },
                                      [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cell",
                                          },
                                          [_vm._v("TOTAL")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "imagine-table__row__cell",
                                            class: {
                                              "imagine-table__row__cell--danger":
                                                _vm.sumProgressStages(
                                                  buildingStage
                                                ) !== 100,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.sumProgressStages(
                                                    buildingStage
                                                  )
                                                ) +
                                                "%\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass:
                                            "imagine-table__row__cell",
                                        }),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass:
                                            "imagine-table__row__cell",
                                        }),
                                        _vm._v(" "),
                                        _c("th", {
                                          staticClass:
                                            "imagine-table__row__cell",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _c("div", { staticClass: "block-edit__planning__none" }, [
                    _vm._v("\n            Phases indisponibles.\n        "),
                  ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.block
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab === "divisions",
                  expression: "activeTab === 'divisions'",
                },
              ],
              staticClass: "block-edit__divisions",
            },
            [
              _c(
                "div",
                { staticClass: "block-edit__divisions__actions" },
                [
                  _c(
                    "imagine-button",
                    {
                      attrs: {
                        primary: true,
                        disabled: _vm.status === "completed",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addDivision()
                        },
                      },
                    },
                    [_vm._v("\n                Ajouter un lot\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.summary && _vm.nbRemaining > 0
                ? _c("div", { staticClass: "block-edit__divisions__summary" }, [
                    _c("div", { staticClass: "imagine-container" }, [
                      _c("div", { staticClass: "mdc-data-table" }, [
                        _c("table", { staticClass: "mdc-data-table__table" }, [
                          _c("thead", [
                            _c(
                              "tr",
                              { staticClass: "mdc-data-table__header-row" },
                              [
                                _c(
                                  "th",
                                  {
                                    staticClass: "mdc-data-table__header-cell",
                                  },
                                  [_vm._v("Type")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "mdc-data-table__header-cell block-edit__divisions__summary__status",
                                  },
                                  [
                                    _vm._v(
                                      "Vendu\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "mdc-data-table__header-cell",
                                    staticStyle: { "text-align": "right" },
                                  },
                                  [_vm._v("Reste")]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            { staticClass: "mdc-data-table__content" },
                            [
                              _vm._l(_vm.summary, function (type) {
                                return _c(
                                  "tr",
                                  { staticClass: "mdc-data-table__row" },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "mdc-data-table__header-cell",
                                      },
                                      [_vm._v(_vm._s(type.type))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "mdc-data-table__cell block-edit__divisions__summary__status",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "block-edit__divisions__summary__status__progress",
                                          },
                                          [
                                            _c("imagine-progress", {
                                              attrs: {
                                                "progress-rate":
                                                  type.purchased / type.total,
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "block-edit__divisions__summary__status__progress__count",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(type.purchased) +
                                                    " / " +
                                                    _vm._s(type.total) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "mdc-data-table__cell mdc-data-table__cell--numeric",
                                      },
                                      [_vm._v(_vm._s(type.remaining))]
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c(
                                "tr",
                                {
                                  staticClass:
                                    "mdc-data-table__row mdc-data-table__header-row",
                                },
                                [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "mdc-data-table__cell mdc-data-table__header-cell",
                                    },
                                    [_vm._v("TOTAL")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "mdc-data-table__cell mdc-data-table__header-cell block-edit__divisions__summary__status",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "block-edit__divisions__summary__status__progress",
                                        },
                                        [
                                          _c("imagine-progress", {
                                            attrs: {
                                              "progress-rate":
                                                _vm.nbPurchased /
                                                _vm.nbDivisions,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "block-edit__divisions__summary__status__progress__count",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(_vm.nbPurchased) +
                                                  " / " +
                                                  _vm._s(_vm.nbDivisions) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "mdc-data-table__cell mdc-data-table__header-cell mdc-data-table__header-cell--numeric",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.nbRemaining) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.block.divisions.length > 0
                ? _c(
                    "div",
                    { staticClass: "mdc-list mdc-list--two-line" },
                    [
                      _vm._l(_vm.block.divisions, function (division) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.redirectTo("property", {
                                    id: division.property.id,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__graphic" },
                                [
                                  _c("block-division-status", {
                                    attrs: { division: division },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "mdc-list-item__text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("property", {
                                        id: division.property.id,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__primary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(division.number) +
                                          " "
                                      ),
                                      division.price
                                        ? _c("span", [
                                            _vm._v(
                                              "- " +
                                                _vm._s(
                                                  _vm.formatMillionsMoney(
                                                    division.price
                                                  )
                                                )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__secondary-text",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(division.type) +
                                          "\n                            "
                                      ),
                                      division.livingArea
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(division.livingArea) +
                                                " / "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      division.totalArea
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(division.totalArea) + " m²"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__meta" },
                                [
                                  !division.buyers && _vm.canAddSaleOption()
                                    ? _c(
                                        "imagine-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showBooking(division)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Poser option\n                        "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "block-edit__divisions__confirm",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(division.buyers)),
                                          ]),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  division.isOption
                                    ? _c("option-remove", {
                                        attrs: {
                                          "sale-id": division.mainSaleId,
                                          "sales-rep-id": division.salesRepId,
                                        },
                                        on: {
                                          removed: function ($event) {
                                            return _vm.reload()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("div", { staticClass: "block-edit__divisions__none" }, [
                    _vm._v("\n            Lots indisponibles.\n        "),
                  ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showRemoveConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: { act: _vm.remove, close: _vm.closeConfirm },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer cette résidence ?"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bookedDivision && _vm.showBookingModal
        ? _c(
            "imagine-modal",
            {
              staticClass: "imagine-modal--booking",
              attrs: {
                "button-disabled": !_vm.owner,
                title: "Option sur lot " + _vm.bookedDivision.number,
                "button-label": "Poser option",
              },
              on: {
                act: function ($event) {
                  return _vm.book()
                },
                close: function ($event) {
                  _vm.showBookingModal = false
                },
              },
            },
            [
              _c("imagine-contact-autocomplete", {
                attrs: {
                  label: "Contact",
                  "redirect-after-create": {
                    name: this.$route.name,
                    params: { id: this.blockId },
                    query: { ack: "booking" },
                  },
                },
                model: {
                  value: _vm.owner,
                  callback: function ($$v) {
                    _vm.owner = $$v
                  },
                  expression: "owner",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentStage
        ? _c("block-stage", {
            attrs: {
              block: _vm.blockId,
              "default-approval": _vm.block.approval,
              stage: _vm.currentStage,
            },
            on: {
              close: function ($event) {
                _vm.currentStage = null
              },
              completed: function ($event) {
                return _vm.reloadBlock()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showRemoveStageConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Supprimer" },
              on: {
                act: function ($event) {
                  return _vm.removeStage()
                },
                close: function ($event) {
                  return _vm.closeStageConfirm()
                },
              },
            },
            [
              _c("p", [
                _vm._v("Etes vous sûr de vouloir supprimer cette phase ?"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }