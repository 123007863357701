<template>
    <transition-group class="timeline mdc-list mdc-list--non-interactive mdc-list--two-line"
                      name="slideY"
                      tag="div"
                      v-if="events.length > 0">
        <div :key="event.id"
             class="mdc-list-item timeline__event"
             v-for="event in events">
            <span class="mdc-list-item__graphic imagine-rounded-shape">
                <span v-if="event.by && event.by.image">
                    <img :src="event.by.image"/>
                    <md-tooltip md-direction="right"
                                v-if="by(event)">{{ by(event) }}</md-tooltip>
                </span>
                <span class="material-icons" v-else>
                    person
                    <md-tooltip md-direction="right"
                                v-if="by(event)">{{ by(event) }}</md-tooltip>
                </span>
            </span>
            <span class="mdc-list-item__text">
                <span class="mdc-list-item__primary-text">{{ event.title }}</span>
                <span class="mdc-list-item__secondary-text employees__employee__position">{{ event.description }}</span>
            </span>
            <span class="mdc-list-item__meta">
                {{ formatEventDate(event.date) }}
            </span>
        </div>
    </transition-group>
</template>

<script>
export default {
    props: ['events'],
    methods: {
        by(event) {
            if (event.by) {
                let employee = event.by.shortname;

                return `Par ${employee}`;
            }

            return '';
        },
        formatEventDate(date) {
            const localDate = this.$container.types.toTAHT(date);

            if (this.$container.types.isToday(localDate)) {
                return this.$container.types.formatTime(localDate);
            }

            return this.$container.types.formatDateTime(localDate);
        }
    }
}
</script>

<style lang="scss">
@import '../../scss/config';
@import '~@material/list/mdc-list';

.timeline {
    &__event {
        cursor: default;
    }
}
</style>
