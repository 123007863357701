class SaleRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all(params) {
        return this._http.get('/api/sale', params);
    }

    one(id) {
        return this._http.get('/api/sale/' + id)
            .then(sale => this._makeSale(sale));
    }

    cancel(id, data) {
        return this._http.post('/api/sale/' + id + '/cancel', data);
    }

    delete(id) {
        return this._http.delete('/api/sale/' + id);
    }

    option(id, data) {
        return this._http.post('/api/sale/' + id + '/option', data)
            .then(sale => this._makeSale(sale));
    }

    header(id, data) {
        return this._http.post('/api/sale/' + id + '/header', data)
            .then(sale => this._makeSale(sale));
    }

    quickOption(propertyId, contactId) {
        return this._http.post('/api/option', {propertyId, contactId});
    }

    cancelOption(id) {
        return this._http.delete('/api/sale/' + id + '/option')
            .then(sale => this._makeSale(sale));
    }

    cancelSalesRepOption(id) {
        return this._http.delete('/api/option/' + id)
            .then(sale => this._makeSale(sale));
    }

    prebooking(id, data) {
        return this._http.post('/api/sale/' + id + '/prebooking', data)
            .then(sale => this._makeSale(sale));
    }

    cancelBooking(id) {
        return this._http.delete('/api/sale/' + id + '/booking')
            .then(sale => this._makeSale(sale));
    }

    booking(id, data) {
        return this._http.post('/api/sale/' + id + '/booking', data)
            .then(sale => this._makeSale(sale));
    }

    cancelFinancing(id) {
        return this._http.delete('/api/sale/' + id + '/financing')
            .then(sale => this._makeSale(sale));
    }

    financing(id, data) {
        return this._http.post('/api/sale/' + id + '/financing', data)
            .then(sale => this._makeSale(sale));
    }

    cancelNotification(id) {
        return this._http.delete('/api/sale/' + id + '/notification')
            .then(sale => this._makeSale(sale));
    }

    notification(id, data) {
        return this._http.post('/api/sale/' + id + '/notification', data)
            .then(sale => this._makeSale(sale));
    }

    cancelCertificate(id) {
        return this._http.delete('/api/sale/' + id + '/certificate')
            .then(sale => this._makeSale(sale));
    }

    certificate(id, data) {
        return this._http.post('/api/sale/' + id + '/certificate', data)
            .then(sale => this._makeSale(sale));
    }

    stats(block, metric, dimensions) {
        let url = '/api/sale/stat?metric=' + metric;

        if (dimensions) {
            url += '&dimensions=' + dimensions;
        }

        if (block) {
            url += '&filters[block]=' + block;
        }

        return this._http.get(url);
    }

    _makeSale(sale) {
        sale.propertyPrice = this._types.stringToMoney(sale.propertyPrice);
        sale.price = this._types.stringToMoney(sale.price);
        sale.parkingPrice = this._types.stringToMoney(sale.parkingPrice);
        sale.discount = this._types.stringToMoney(sale.discount);
        sale.lawyerFeesAmount = this._types.stringToMoney(sale.lawyerFeesAmount);
        sale.loanEstimatedAmount = this._types.stringToMoney(sale.loanEstimatedAmount);

        sale.prebookingDate = this._types.stringToDate(sale.prebookingDate);
        sale.bookingDateLimit = this._types.stringToDate(sale.bookingDateLimit);
        sale.bookingDate = this._types.stringToDate(sale.bookingDate);
        sale.loanDepositDate = this._types.stringToDate(sale.loanDepositDate);
        sale.loanDepositDateLimit = this._types.stringToDate(sale.loanDepositDateLimit);
        sale.loanAgreementDate = this._types.stringToDate(sale.loanAgreementDate);
        sale.loanAgreementDateLimit = this._types.stringToDate(sale.loanAgreementDateLimit);
        sale.loanIssueDate = this._types.stringToDate(sale.loanIssueDate);
        sale.loanIssueDateLimit = this._types.stringToDate(sale.loanIssueDateLimit);
        sale.notificationDate = this._types.stringToDate(sale.notificationDate);
        sale.certificateDate = this._types.stringToDate(sale.certificateDate);
        sale.certificateDateLimit = this._types.stringToDate(sale.certificateDateLimit);

        return sale;
    }
}

export default SaleRepository;
