var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "imagine-form__complex-field",
      class: {
        "imagine-form__complex-field--show": _vm.show,
        "imagine-form__complex-field--disabled": _vm.disabled,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "imagine-form__complex-field__header",
          on: {
            click: function ($event) {
              _vm.show ? _vm.$emit("close") : _vm.$emit("open")
            },
          },
        },
        [
          _c(
            "h6",
            { staticClass: "imagine-form__complex-field__header__title" },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "material-icons" }, [
            _vm._v(
              _vm._s(_vm.show ? "keyboard_arrow_up" : "keyboard_arrow_down")
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "imagine-form__complex-field__body",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }