<template>
    <imagine-modal @act="sendReceipt()"
                   @close="$emit('canceled')"
                   button-label="Envoyer"
                   class="imagine-modal--wide"
                   title="Quittance de loyer">
        <p>
            <imagine-select-period :periods="lease.receiptPeriods"
                                   v-model="period"/>
        </p>

        <p style="text-align: center;">
            <imagine-button @click="externalRedirectTo(previewUrl)">Prévisualiser</imagine-button>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineSelectPeriod from '../../../components/ImagineSelectPeriod.vue';
import ImagineButton from '../../../components/ImagineButton.vue';

export default {
    props: ['lease'],
    components: {ImagineButton, ImagineSelectPeriod, ImagineModal},
    computed: {
        previewUrl() {
            return '/api/lease/' + this.lease.id + '/doc/rent?date=' + this.period.year + '-' + this.period.month + '-01';
        }
    },
    data() {
        return {
            period: this.lease.receiptPeriods[this.lease.receiptPeriods.length - 1]
        }
    },
    methods: {
        sendReceipt() {
            this.load();
            this.$store.dispatch('lease/sendReceipt', {id: this.lease.id, period: this.period})
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>
