<template>
    <imagine-layout :inner="true" :title="title" class="sale">
        <template v-slot:actions>
            <template v-if="$mq !== 'phone'">
                <imagine-icon-button class="mdc-top-app-bar__action-item"
                                     @click="displayPastErrors = !displayPastErrors"
                                     :title="displayPastErrors ? 'Masquer les retards passés' : 'Voir les retards passés'"
                                     :icon="displayPastErrors ? 'report' : 'report_off'"
                                     v-if="hasPreBooking"/>
                <imagine-icon-button class="mdc-top-app-bar__action-item"
                                     @click="redirectTo('property', {id: sale.property.id})"
                                     title="Fiche bien"
                                     icon="home_work"
                                     v-if="sale"/>
                <imagine-icon-button class="mdc-top-app-bar__action-item"
                                     @click="redirectTo('block', {id: sale.property.blockId})"
                                     title="Fiche résidence"
                                     icon="business"
                                     v-if="sale"/>
                <imagine-icon-button :disabled="!canSendMail"
                                     @click="openMail()"
                                     title="Envoyer email acheteur(s)"
                                     class="mdc-top-app-bar__action-item"
                                     icon="email"/>
            </template>
            <imagine-icon-button v-if="sale && canCancel"
                                 @click="showCancelModal = true"
                                 title="Annuler la vente"
                                 class="mdc-top-app-bar__action-item"
                                 icon="cancel"/>
            <imagine-icon-button v-if="sale && canDelete"
                                 @click="showDeleteModal = true"
                                 title="Supprimer la vente"
                                 class="mdc-top-app-bar__action-item"
                                 icon="delete"/>
        </template>

        <div class="imagine-container">
            <form @submit.prevent="!hasStarted ? saveOption() : saveHeader()"
                  autocomplete="off"
                  class="imagine-form sale__option">
                <imagine-alert class="sale__option__alert"
                               type="info"
                               v-if="isOptionOngoing">
                    Une option est en cours sur ce bien.
                    <strong>
                        Il reste {{ optionDurationHours }}h pour la validation du contrat de pré-réservation.
                    </strong>
                </imagine-alert>
                <imagine-alert class="sale__option__alert"
                               type="info"
                               v-else-if="!hasStarted && maxOptionDurationHours">
                    Une option permet de bloquer un bien pendant {{ maxOptionDurationHours }}h maximum. Au delà,
                    l'option est automatiquement supprimée.
                </imagine-alert>

                <div class="imagine-form__row sale__buyer"
                     v-for="(buyerSlot, slot) in buyerSlots"
                     v-if="isCanceled ? nbBuyers > slot : nbBuyers >= slot">
                    <imagine-contact-autocomplete :label="'Acquéreur ' + (slot + 1)"
                                                  name-only
                                                  :required="slot === 0"
                                                  :disabled="isCanceled"
                                                  @input="selectBuyer()"
                                                  class="imagine-form__row__field"
                                                  name="buyer"
                                                  v-model="buyerSlot.contact"/>
                    <imagine-icon-button icon="contacts"
                                         active
                                         v-if="buyerSlot.contact"
                                         @click="redirectTo('contact', {id: buyerSlot.contact.id})"
                                         class="sale__buyer__goto imagine-no-ripple"/>
                </div>

                <div class="imagine-form__row sale__meta"
                     v-if="canFilterSalesRep()">
                    <imagine-select :choices="salesRepChoices"
                                    :required="true"
                                    :disabled="isCanceled"
                                    class="imagine-form__row__field"
                                    label="Négociateur"
                                    name="salesrep"
                                    v-model="salesRep"/>

                    <imagine-select :choices="otherSalesRepChoices"
                                    :disabled="otherSalesRepChoices.length <= 0 || isCanceled"
                                    class="imagine-form__row__field"
                                    label="Autre négociateur"
                                    name="othersalesrep"
                                    v-model="otherSalesRep"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-textarea class="imagine-form__row__field"
                                      label="Notes"
                                      name="comment"
                                      :disabled="isCanceled"
                                      v-model="comment"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button primary
                                    :disabled="!salesRep"
                                    v-if="!isCanceled"
                                    type="submit">
                        {{ !hasStarted ? 'Poser option' : 'Enregistrer entête' }}
                    </imagine-button>
                </div>
            </form>

            <md-steppers :md-active-step.sync="activeStep"
                         md-linear
                         md-vertical>
                <md-step :md-editable="activeStep === 'prebooking' || hasPreBooking"
                         :md-label="prebookingStepTitle"
                         :md-error="activeStep === 'prebooking' && !isPriceConsistent ? 'Prix incohérent' : null"
                         :md-done="hasPreBooking"
                         id="prebooking">
                    <form @submit.prevent="savePrebooking"
                          autocomplete="off"
                          class="imagine-form">
                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker class="imagine-form__row__field"
                                                 label="Date"
                                                 :max-date="today()"
                                                 :required="true"
                                                 :violation="errors.prebookingDate"
                                                 :disabled="isCanceled"
                                                 name="prebookingDate"
                                                 v-model="prebookingDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Document"
                                                :disabled="isCanceled"
                                                name="prebookingUrl"
                                                v-model="prebookingUrl"/>
                        </div>

                        <div class="imagine-form__sep"></div>

                        <div class="sale__price-consistency"
                             :class="{'sale__price-consistency--error': !isPriceConsistent}"
                             v-if="publicPrice">
                            Prix public actuellement proposé : {{ formatMoney(publicPrice) }}
                            <template v-if="!isPriceConsistent">
                                <br/>Le prix public doit correspondre au prix total (parking + hors parking).
                            </template>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-money-input :required="true"
                                                 class="imagine-form__row__field"
                                                 label="Prix hors parking (XPF)"
                                                 :disabled="isCanceled"
                                                 :invalid="!isPriceConsistent"
                                                 name="price"
                                                 v-model="price"/>

                            <imagine-money-input class="imagine-form__row__field"
                                                 label="Prix parking (XPF)"
                                                 :disabled="isCanceled"
                                                 :invalid="!isPriceConsistent"
                                                 name="parkingPrice"
                                                 v-model="parkingPrice"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-money-input class="imagine-form__row__field"
                                                 label="Remise (XPF)"
                                                 :disabled="isCanceled"
                                                 name="discount"
                                                 v-model="discount"/>

                            <imagine-input class="imagine-form__row__field"
                                           label="Libellé remise"
                                           :disabled="isCanceled"
                                           name="discountLabel"
                                           v-model="discountLabel"/>
                        </div>

                        <div class="imagine-form__actions"
                             v-if="!isCanceled">
                            <imagine-button @click.prevent="cancelOption()"
                                            v-if="isOptionOngoing">
                                Lever l'option
                            </imagine-button>
                            <imagine-button primary
                                            :disabled="!hasPreBooking && !isPriceConsistent"
                                            type="submit">
                                Valider
                            </imagine-button>
                        </div>
                    </form>
                </md-step>

                <md-step :md-editable="!isCanceled && activeStep === 'booking' || hasBooking"
                         :md-error="bookingStepError"
                         :md-label="bookingStepTitle"
                         :md-done="hasBooking"
                         id="booking">
                    <form @submit.prevent="saveBooking"
                          autocomplete="off"
                          class="imagine-form">
                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="prebookingDate"
                                                 :required="true"
                                                 class="imagine-form__row__field"
                                                 label="Date"
                                                 :violation="errors.bookingDate"
                                                 :disabled="isCanceled"
                                                 :help="getLimitDateHelp(sale ? sale.bookingDateLimit : null)"
                                                 name="bookingDate"
                                                 v-model="bookingDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Document"
                                                :disabled="isCanceled"
                                                name="bookingUrl"
                                                v-model="bookingUrl"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-money-input class="imagine-form__row__field"
                                                 label="Frais de notaire (XPF)"
                                                 :disabled="isCanceled"
                                                 name="lawyerFeesAmount"
                                                 v-model="lawyerFeesAmount"/>

                            <imagine-select :choices="purposeChoices"
                                            :required="true"
                                            class="imagine-form__row__field"
                                            label="Type d'achat"
                                            :disabled="isCanceled"
                                            name="purpose"
                                            v-model="purpose"/>

                            <imagine-select :choices="taxExemptionChoices"
                                            class="imagine-form__row__field"
                                            label="Défiscalisation"
                                            :disabled="isCanceled"
                                            name="taxExemption"
                                            v-model="taxExemption"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-select :choices="financingTypeChoices"
                                            :required="true"
                                            :disabled="isCanceled"
                                            class="imagine-form__row__field"
                                            label="Financement"
                                            name="financingType"
                                            v-model="financingType"/>
                        </div>

                        <transition name="slideY">
                            <div v-if="financingType === 'mortgage'">
                                <div class="imagine-form__row imagine-form__row--limited">
                                    <imagine-select :choices="bankManagerChoices"
                                                    class="imagine-form__row__field"
                                                    label="Chargé de compte"
                                                    :disabled="isCanceled"
                                                    name="bankManager"
                                                    v-model="bankManager"/>

                                    <div class="imagine-form__row__field imagine-form__row__field--readonly">
                                        <div v-if="selectedBankManager">
                                            {{ selectedBankManager.email }}
                                            {{ selectedBankManager.phone ? selectedBankManager.phone.readable : '' }}
                                        </div>
                                    </div>
                                </div>

                                <div class="imagine-form__row imagine-form__row--limited">
                                    <imagine-money-input class="imagine-form__row__field"
                                                         label="Montant emprunt envisagé (XPF)"
                                                         :disabled="isCanceled"
                                                         name="loanEstimatedAmount"
                                                         v-model="loanEstimatedAmount"/>

                                    <imagine-input class="imagine-form__row__field"
                                                   label="Durée crédit (années)"
                                                   min="1"
                                                   :disabled="isCanceled"
                                                   name="loanEstimatedDurationYears"
                                                   step="1"
                                                   type="number"
                                                   v-model="loanEstimatedDurationYears"/>

                                    <imagine-input class="imagine-form__row__field"
                                                   label="Taux max (%)"
                                                   :disabled="isCanceled"
                                                   min="0"
                                                   name="loanMaximumRate"
                                                   step="any"
                                                   type="number"
                                                   v-model="loanMaximumRate"/>
                                </div>
                            </div>
                        </transition>

                        <div class="imagine-form__actions"
                             v-if="!isCanceled">
                            <imagine-button @click.prevent="cancelBooking()">Annuler réservation</imagine-button>
                            <imagine-button primary type="submit">Valider</imagine-button>
                        </div>
                    </form>
                </md-step>

                <md-step :md-editable="activeStep === 'financing' || hasFinancing"
                         :class="{'sale__step--force-disabled': activeStep !== 'financing' && !hasFinancing}"
                         :md-error="financingStepError"
                         :md-label="financingStepTitle"
                         :md-done="hasFinancing"
                         id="financing">
                    <form @submit.prevent="saveFinancing"
                          autocomplete="off"
                          class="imagine-form">
                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-select :choices="bankManagerChoices"
                                            class="imagine-form__row__field"
                                            label="Chargé de compte"
                                            :disabled="isCanceled"
                                            name="bankManager"
                                            v-model="bankManager"/>

                            <div class="imagine-form__row__field imagine-form__row__field--readonly">
                                <div v-if="selectedBankManager">
                                    {{ selectedBankManager.email }}
                                    {{ selectedBankManager.phone ? selectedBankManager.phone.readable : '' }}
                                </div>
                            </div>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="bookingDate"
                                                 :required="true"
                                                 :disabled="isCanceled"
                                                 class="imagine-form__row__field"
                                                 label="Date dépôt dossier"
                                                 :help="getLimitDateHelp(sale ? sale.loanDepositDateLimit : null)"
                                                 name="loanDepositDate"
                                                 :violation="errors.loanDepositDate"
                                                 v-model="loanDepositDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Attestation dépôt"
                                                name="loanDepositUrl"
                                                :disabled="isCanceled"
                                                v-model="loanDepositUrl"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="loanDepositDate"
                                                 :required="!!sale && !!sale.loanDepositDate"
                                                 class="imagine-form__row__field"
                                                 label="Date accord de principe"
                                                 name="loanAgreementDate"
                                                 :disabled="isCanceled"
                                                 :violation="errors.loanAgreementDate"
                                                 :help="getLimitDateHelp(sale ? sale.loanAgreementDateLimit : null)"
                                                 v-model="loanAgreementDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Accord de principe"
                                                name="bookingUrl"
                                                :disabled="isCanceled"
                                                v-model="loanAgreementUrl"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="loanDepositDate"
                                                 :required="!!sale && !!sale.loanAgreementDate"
                                                 :violation="errors.loanIssueDate"
                                                 class="imagine-form__row__field"
                                                 label="Date offre prêt"
                                                 :help="getLimitDateHelp(sale ? sale.loanIssueDateLimit : null)"
                                                 name="loanIssueDate"
                                                 :disabled="isCanceled"
                                                 v-model="loanIssueDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Offre de prêt"
                                                :disabled="isCanceled"
                                                name="loanIssueUrl"
                                                v-model="loanIssueUrl"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-select :choices="loanTypeChoices"
                                            class="imagine-form__row__field"
                                            label="Type cautionnement"
                                            :disabled="isCanceled"
                                            name="loanType"
                                            v-model="loanType"/>

                            <imagine-select :choices="loanGuaranteeTypeChoices"
                                            class="imagine-form__row__field"
                                            label="Type assurance"
                                            :disabled="isCanceled"
                                            name="loanGuaranteeType"
                                            v-model="loanGuaranteeType"/>
                        </div>

                        <transition name="slideY">
                            <div class="imagine-form__row"
                                 v-if="loanGuaranteeType === 'external'">
                                <imagine-textarea class="imagine-form__row__field"
                                                  label="Suivi assurance"
                                                  :disabled="isCanceled"
                                                  name="loanGuaranteeTracking"/>
                            </div>
                        </transition>

                        <div class="imagine-form__actions"
                             v-if="!isCanceled">
                            <imagine-button @click.prevent="cancelFinancing()">Annuler financement</imagine-button>
                            <imagine-button primary type="submit">Valider</imagine-button>
                        </div>
                    </form>
                </md-step>

                <md-step :md-editable="activeStep === 'notification' || hasNotification"
                         :md-label="notificationStepTitle"
                         :md-done="hasNotification"
                         id="notification">
                    <form @submit.prevent="saveNotification"
                          autocomplete="off"
                          class="imagine-form">
                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="loanIssueDate"
                                                 :required="true"
                                                 class="imagine-form__row__field"
                                                 label="Date"
                                                 :violation="errors.notificationDate"
                                                 :disabled="isCanceled"
                                                 name="notificationDate"
                                                 v-model="notificationDate"/>
                        </div>

                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-select :choices="lawyerChoices"
                                            :required="true"
                                            class="imagine-form__row__field"
                                            label="Notaire"
                                            :violation="errors.lawyer"
                                            name="lawyer"
                                            :disabled="isCanceled"
                                            v-model="lawyer"/>
                            <imagine-select :choices="otherLawyerChoices"
                                            :disabled="otherLawyerChoices.length <= 0 || isCanceled"
                                            class="imagine-form__row__field"
                                            label="Autre notaire"
                                            name="otherlawyer"
                                            v-model="otherLawyer"/>
                        </div>

                        <div class="imagine-form__actions"
                             v-if="!isCanceled">
                            <imagine-button @click.prevent="cancelNotification()">Annuler notification</imagine-button>
                            <imagine-button primary type="submit">Valider</imagine-button>
                        </div>
                    </form>
                </md-step>

                <md-step :md-editable="activeStep === 'certificate' || hasCertificate"
                         :md-error="certificateStepError"
                         :md-label="certificateStepTitle"
                         :md-done="hasCertificate"
                         id="certificate">
                    <form @submit.prevent="saveCertificate"
                          autocomplete="off"
                          class="imagine-form">
                        <div class="imagine-form__row imagine-form__row--limited">
                            <imagine-date-picker :max-date="today()"
                                                 :min-date="notificationDate"
                                                 :required="true"
                                                 class="imagine-form__row__field"
                                                 label="Date"
                                                 :disabled="isCanceled"
                                                 :help="getLimitDateHelp(sale ? sale.certificateDateLimit : null)"
                                                 name="certificateDate"
                                                 v-model="certificateDate"/>

                            <imagine-file-input accept="application/pdf"
                                                class="imagine-form__row__field"
                                                label="Document"
                                                :disabled="isCanceled"
                                                name="certificateUrl"
                                                v-model="certificateUrl"/>
                        </div>

                        <div class="imagine-form__actions"
                             v-if="!isCanceled">
                            <imagine-button @click.prevent="cancelCertificate()">Annuler acte de vente</imagine-button>
                            <imagine-button primary type="submit">Valider</imagine-button>
                        </div>
                    </form>
                </md-step>
            </md-steppers>
        </div>

        <imagine-modal v-if="showDeleteModal"
                       @act="deleteSale()"
                       @close="showDeleteModal = false"
                       button-label="Supprimer la vente"
                       title="Supprimer la vente">
            <p>Etes vous sûr de vouloir supprimer définitivement cette vente ?</p>
        </imagine-modal>

        <imagine-modal @act="cancelSale()"
                       v-if="showCancelModal"
                       :button-disabled="!cancelReason"
                       @close="showCancelModal = false"
                       button-label="Annuler la vente"
                       class="imagine-modal--wide"
                       title="Annuler la vente">
            <p>
                <imagine-select class="imagine-form__row__field"
                                label="Cause"
                                name="reason"
                                :required="true"
                                :choices="references.cancelSaleReasonChoices()"
                                v-model="cancelReason"/>
            </p>

            <p>
                <imagine-textarea class="imagine-form__row__field"
                                  label="Observations"
                                  name="note"
                                  v-model="cancelNote"/>
            </p>

            <p>
                <imagine-file-input class="imagine-form__row__field"
                                    label="Document associé"
                                    name="url"
                                    accept="application/pdf"
                                    v-model="cancelUrl"/>
            </p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineMoneyInput from '../components/ImagineMoneyInput.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineModal from '../components/ImagineModal.vue';
import {mapState} from 'vuex';

const MAX_BUYERS = 50;

const reserveSlots = (nbSlots) => {
    let slots = [];

    for (let i = 0; i < nbSlots; i++) {
        slots.push({contact: null});
    }

    return slots;
};

export default {
    components: {
        ImagineModal,
        ImagineIconButton,
        ImagineTextarea,
        ImagineLayout,
        ImagineAlert,
        ImagineFileInput,
        ImagineDatePicker,
        ImagineButton,
        ImagineSelect,
        ImagineMoneyInput,
        ImagineContactAutocomplete,
        ImagineInput
    },
    computed: {
        ...mapState(['references']),
        ...mapState('employee', {employees: state => state.all}),
        ...mapState('contractor', {contractors: state => state.all}),
        title() {
            let title = 'Vente ';

            if (this.sale) {
                title += this.sale.property.infos;

                if (this.sale.taxExemption) {
                    title += ' [défisc]';
                }
            }

            return title;
        },
        totalPrice() {
            if (this.price) {
                if (this.parkingPrice) {
                    return this.price.add(this.parkingPrice);
                }

                return this.price;
            }

            return null;
        },
        isPriceConsistent() {
            if (!this.publicPrice || !this.totalPrice) {
                return true;
            }

            return this.publicPrice.equalsTo(this.totalPrice);
        },
        canSendMail() {
            if (!this.sale) {
                return false;
            }

            return this.sale.buyers.some(buyer => buyer.email);
        },
        canCancel() {
            if (!this.sale) {
                return false;
            }

            return !this.isCompleted && !this.isCanceled && this.nbBuyers > 0;
        },
        canDelete() {
            if (!this.sale) {
                return false;
            }

            return !this.isCompleted && !this.isCanceled && this.nbBuyers <= 0;
        },
        isCanceled() {
            if (!this.sale) {
                return false;
            }

            return this.sale.step === 'canceled';
        },
        prebookingStepTitle() {
            let title = this.stepLabel('prebooking');

            if (this.sale) {
                title += this._getStepTitleSuffix(this.sale.prebookingDate, null);
            }

            return title;
        },
        bookingStepTitle() {
            let title = this.stepLabel('booking');

            if (this.sale) {
                title += this._getStepTitleSuffix(this.sale.bookingDate, this.sale.bookingDateLimit);
            }

            return title;
        },
        bookingStepError() {
            if (this.sale) {
                return this._getLimitDateError(this.sale.bookingDate, this.sale.bookingDateLimit)
            }

            return null;
        },
        financingStepTitle() {
            let title = this.stepLabel('financing');

            if (this.needFinancing && this.sale) {
                if (!this.sale.loanDepositDate) {
                    title += this._getStepTitleSuffix(this.sale.loanDepositDate, this.sale.loanDepositDateLimit);
                } else if (!this.sale.loanAgreementDate) {
                    title += this._getStepTitleSuffix(this.sale.loanAgreementDate, this.sale.loanAgreementDateLimit);
                } else {
                    title += this._getStepTitleSuffix(this.sale.loanIssueDate, this.sale.loanIssueDateLimit);
                }
            }

            return title;
        },
        financingStepError() {
            if (this.sale) {
                if (!this.sale.loanDepositDate) {
                    return this._getLimitDateError(this.sale.loanDepositDate, this.sale.loanDepositDateLimit);
                } else if (!this.sale.loanAgreementDate) {
                    return this._getLimitDateError(this.sale.loanAgreementDate, this.sale.loanAgreementDateLimit);
                }

                return this._getLimitDateError(this.sale.loanIssueDate, this.sale.loanIssueDateLimit);
            }

            return null;
        },
        certificateStepTitle() {
            let title = this.stepLabel('certificate');

            if (this.sale) {
                title += this._getStepTitleSuffix(this.sale.certificateDate, this.sale.certificateDateLimit);
            }

            return title;
        },
        certificateStepError() {
            if (this.sale) {
                return this._getLimitDateError(this.sale.certificateDate, this.sale.certificateDateLimit)
            }

            return null;
        },
        notificationStepTitle() {
            let title = this.stepLabel('notification');

            if (this.sale) {
                title += this._getStepTitleSuffix(this.sale.notificationDate, null)
            }

            return title;
        },
        salesRepChoices() {
            return this.references.salesRepChoices(this.employees);
        },
        otherSalesRepChoices() {
            if (!this.salesRep) {
                return [];
            }

            return this.references.salesRepChoices(this.employees, this.salesRep);
        },
        buyerIds() {
            return this.buyerSlots.filter(buyerSlot => buyerSlot.contact).map(buyerSlot => buyerSlot.contact.id);
        },
        nbBuyers() {
            return this.buyerIds.length;
        },
        hasStarted() {
            return this.isOptionOngoing || this.hasPreBooking;
        },
        isOptionOngoing() {
            if (!this.sale) {
                return false;
            }

            return this.sale.step === 'option';
        },
        hasPreBooking() {
            if (!this.sale) {
                return false;
            }

            return !!this.sale.prebookingDate || this.isCompleted;
        },
        hasBooking() {
            if (!this.sale) {
                return false;
            }

            return !!this.sale.bookingDate || this.isCompleted;
        },
        needFinancing() {
            if (!this.sale) {
                return false;
            }

            return this.sale.financingType === 'mortgage';
        },
        hasFinancing() {
            if (!this.sale) {
                return false;
            }

            return this.needFinancing || !!this.sale.loanDepositDate || this.isCompleted;
        },
        hasNotification() {
            if (!this.sale) {
                return false;
            }

            return !!this.sale.notificationDate || this.isCompleted;
        },
        hasCertificate() {
            if (!this.sale) {
                return false;
            }

            return !!this.sale.certificateDate || this.isCompleted;
        },
        isCompleted() {
            if (!this.sale) {
                return false;
            }

            return this.sale.step === 'completed';
        },
        purposeChoices() {
            return this.references.purchaseTypeChoices();
        },
        taxExemptionChoices() {
            return this.references.taxExemptionChoices();
        },
        financingTypeChoices() {
            return this.references.financingChoices();
        },
        bankManagerChoices() {
            return this.references.bankManagerChoices(this.contractors);
        },
        loanTypeChoices() {
            return this.references.loanTypeChoices();
        },
        loanGuaranteeTypeChoices() {
            return this.references.loanGuaranteeTypeChoices();
        },
        lawyerChoices() {
            return this.references.lawyerChoices(this.contractors);
        },
        otherLawyerChoices() {
            if (!this.lawyer) {
                return [];
            }

            return this.references.lawyerChoices(this.contractors, this.lawyer);
        },
        maxOptionDurationHours() {
            if (!this.sale) {
                return null;
            }

            return this.sale.maxOptionDurationHours;
        },
        selectedBankManager() {
            if (this.bankManager && this.contractors) {
                return this.contractors.find(contractor => contractor.id === this.bankManager);
            }

            return null;
        }
    },
    created() {
        this.load();
        this.saleId = this.$route.params ? parseInt(this.$route.params.id) : null;
        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    data() {
        return {
            displayPastErrors: false,
            saleId: null,
            sale: null,
            errors: {},
            activeStep: 'prebooking',
            salesRep: null,
            otherSalesRep: null,
            prebookingDate: null,
            prebookingUrl: null,
            bookingDate: null,
            bookingUrl: null,
            publicPrice: null,
            price: null,
            parkingPrice: null,
            discount: null,
            discountLabel: null,
            buyerSlots: reserveSlots(MAX_BUYERS),
            optionDurationHours: null,
            purpose: null,
            taxExemption: null,
            financingType: null,
            lawyerFeesAmount: null,
            bankManager: null,
            loanEstimatedAmount: null,
            loanEstimatedDurationYears: null,
            loanMaximumRate: null,
            loanDepositDate: null,
            loanDepositUrl: null,
            loanAgreementDate: null,
            loanAgreementUrl: null,
            loanIssueDate: null,
            loanIssueUrl: null,
            loanType: null,
            loanGuaranteeType: null,
            loanGuaranteeTracking: null,
            notificationDate: null,
            lawyer: null,
            otherLawyer: null,
            certificateDate: null,
            certificateUrl: null,
            showCancelModal: false,
            showDeleteModal: false,
            cancelReason: 'financing',
            cancelNote: null,
            cancelUrl: null,
            comment: null
        }
    },
    methods: {
        today() {
            return this.$container.types.today();
        },
        openMail() {
            if (!this.canSendMail) {
                return;
            }

            const buyersEmails = [];
            this.sale.buyers.forEach(buyer => {
                if (buyer.email) {
                    buyersEmails.push(buyer.email);
                }
                if (buyer.otherEmail) {
                    buyersEmails.push(buyer.otherEmail);
                }
            })

            location.href = `mailto:${buyersEmails.join(',')}?cc=${this.sale.salesRep.email}`;
        },
        cancelSale() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/cancel', {
                id: this.saleId,
                data: {
                    reason: this.cancelReason,
                    comment: this.cancelNote,
                    doc: this.cancelUrl
                }
            })
                .then(() => {
                    this.showCancelModal = false;
                    this.redirectTo('sales', {}, () => this.success('Vente annulée.'));
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        deleteSale() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/delete', this.saleId)
                .then(() => {
                    this.showDeleteModal = false;
                    this.redirectTo('sales', {}, () => this.success('Vente supprimée.'));
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        stepLabel(step) {
            return this.references.getLabel('saleSteps', step);
        },
        selectBuyer() {
            this._removeBuyerSlotHoles();
            if (!this.salesRep
                && this.buyerSlots
                && this.buyerSlots.length > 0
                && this.buyerSlots[0]
                && this.buyerSlots[0].contact
                && this.buyerSlots[0].contact.id) {
                this.load();
                this.$store.dispatch('contact/one', this.buyerSlots[0].contact.id)
                    .then(contact => {
                        if (contact.salesRepId) {
                            this.salesRep = contact.salesRepId;
                        } else {
                            // this.salesRep = this.references.firstSalesRepId(this.employees);
                        }
                    })
                    .finally(this.unload);
            }
        },
        saveOption() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/option', {
                id: this.saleId,
                data: {
                    buyers: this.buyerIds,
                    salesRep: this.salesRep,
                    otherSalesRep: this.otherSalesRep,
                    comment: this.comment
                }
            })
                .then(sale => this._remapSale(sale))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        saveHeader() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/header', {
                id: this.saleId,
                data: {
                    buyers: this.buyerIds,
                    salesRep: this.salesRep,
                    otherSalesRep: this.otherSalesRep,
                    comment: this.comment
                }
            })
                .then(sale => {
                    this._remapSale(sale);
                    this.success('Données sauvegardées');
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelOption() {
            this.load();
            this.$store.dispatch('sale/cancelOption', this.saleId)
                .then(sale => this._remapSale(sale))
                .catch(this.serverError)
                .finally(this.unload);
        },
        savePrebooking() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/prebooking', {
                id: this.saleId,
                data: {
                    prebookingDate: this.prebookingDate,
                    prebookingUrl: this.prebookingUrl,
                    price: this.price,
                    parkingPrice: this.parkingPrice,
                    discount: this.discount,
                    discountLabel: this.discountLabel
                }
            })
                .then(sale => this._remapSale(sale))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelBooking() {
            this.load();
            this.$store.dispatch('sale/cancelBooking', this.saleId)
                .then(sale => this._remapSale(sale))
                .catch(this.serverError)
                .finally(this.unload);
        },
        saveBooking() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/booking', {
                id: this.saleId,
                data: {
                    bookingDate: this.bookingDate,
                    bookingUrl: this.bookingUrl,
                    purpose: this.purpose,
                    taxExemption: this.taxExemption,
                    financingType: this.financingType,
                    lawyerFeesAmount: this.lawyerFeesAmount,
                    bankManager: this.bankManager,
                    loanEstimatedAmount: this.loanEstimatedAmount,
                    loanEstimatedDurationYears: this.loanEstimatedDurationYears,
                    loanEstimatedMaxRate: this.loanMaximumRate
                }
            })
                .then(sale => this._remapSale(sale))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelFinancing() {
            this.load();
            this.$store.dispatch('sale/cancelFinancing', this.saleId)
                .then(sale => this._remapSale(sale))
                .catch(this.serverError)
                .finally(this.unload);
        },
        saveFinancing() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/financing', {
                id: this.saleId,
                data: {
                    loanDepositDate: this.loanDepositDate,
                    loanDepositUrl: this.loanDepositUrl,
                    loanAgreementDate: this.loanAgreementDate,
                    loanAgreementUrl: this.loanAgreementUrl,
                    loanIssueDate: this.loanIssueDate,
                    loanIssueUrl: this.loanIssueUrl,
                    loanType: this.loanType,
                    loanGuaranteeType: this.loanGuaranteeType,
                    loanGuaranteeTracking: this.loanGuaranteeTracking,
                    bankManager: this.bankManager
                }
            })
                .then(sale => this._remapSale(sale))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelNotification() {
            this.load();
            this.$store.dispatch('sale/cancelNotification', this.saleId)
                .then(sale => this._remapSale(sale))
                .catch(this.serverError)
                .finally(this.unload);
        },
        saveNotification() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/notification', {
                id: this.saleId,
                data: {
                    notificationDate: this.notificationDate,
                    lawyer: this.lawyer,
                    otherLawyer: this.otherLawyer
                }
            })
                .then(sale => this._remapSale(sale))
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        cancelCertificate() {
            this.load();
            this.$store.dispatch('sale/cancelCertificate', this.saleId)
                .then(sale => this._remapSale(sale))
                .catch(this.serverError)
                .finally(this.unload);
        },
        saveCertificate() {
            this.load();
            this.errors = {};
            this.$store.dispatch('sale/certificate', {
                id: this.saleId,
                data: {
                    certificateDate: this.certificateDate,
                    certificateUrl: this.certificateUrl
                }
            })
                .then(sale => {
                    this._remapSale(sale);
                    this.success('Données sauvegardées');
                })
                .catch(response => this.errors = this.serverError(response))
                .finally(this.unload);
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('employee/touch'),
                this.$store.dispatch('contractor/touchAll'),
                this.$store.dispatch('contact/touch'),
                this.$store.dispatch('sale/one', this.saleId)
                    .then(sale => this._remapSale(sale))
            ];

            dependencies.push(
                this.$store.dispatch('user/touch')
                    .then(() => {
                        if (this.canFilterSalesRep()) {
                            this.$store.dispatch('employee/touch');
                        }
                    })
            );

            return dependencies;
        },
        _getLimitDateError(date, limit) {
            if (!limit) {
                return null;
            }

            if (date && !this.displayPastErrors) {
                return null;
            }

            let compareTo = date ? date : this.today();

            if (compareTo.isAfter(limit)) {
                return 'Date limite dépassée';
            }

            return null;
        },
        _getStepTitleSuffix(doneDate, limitDate) {
            if (doneDate) {
                return ' - OK le ' + this.formatDate(doneDate);
            }

            if (limitDate) {
                return ' - ' + this.getLimitDateHelp(limitDate);
            }

            return '';
        },
        getLimitDateHelp(limitDate) {
            if (limitDate) {
                return 'avant le ' + this.formatDate(limitDate);
            }

            return null;
        },
        _remapSale(sale) {
            this.sale = sale;

            if (sale.step === 'option') {
                this.activeStep = 'prebooking';
            } else if (sale.step === 'available') {
                this.activeStep = null;
            } else if (sale.step === 'completed') {
                this.activeStep = 'certificate';
            } else if (sale.step === 'canceled') {
                this.activeStep = 'prebooking';
            } else {
                this.activeStep = sale.step;
            }
            this.salesRep = sale.salesRep ? sale.salesRep.id : null;
            this.otherSalesRep = sale.otherSalesRep ? sale.otherSalesRep.id : null;
            this.publicPrice = sale.propertyPrice;
            this.price = sale.price ? sale.price : sale.propertyPrice;
            this.parkingPrice = sale.parkingPrice;
            this.discount = sale.discount;
            this.discountLabel = sale.discountLabel;
            this.prebookingDate = sale.prebookingDate ? sale.prebookingDate : this.today();
            this.prebookingUrl = sale.prebookingUrl;
            this.bookingDate = sale.bookingDate ? sale.bookingDate : this.today();
            this.bookingUrl = sale.bookingUrl;
            this.optionDurationHours = sale.optionDurationHours;
            this.purpose = sale.purpose ? sale.purpose : 'main';
            this.taxExemption = sale.taxExemption;
            this.financingType = sale.financingType ? sale.financingType : 'mortgage';
            this.bankManager = sale.bankManager ? sale.bankManager.id : null;
            this.lawyerFeesAmount = sale.lawyerFeesAmount;
            this.loanEstimatedAmount = sale.loanEstimatedAmount ? sale.loanEstimatedAmount : this.totalPrice;
            this.loanEstimatedDurationYears = sale.loanEstimatedDurationYears ?
                sale.loanEstimatedDurationYears :
                this.getLoanDefaultDurationYears();
            this.loanMaximumRate = sale.loanMaximumRate ?
                sale.loanMaximumRate :
                this.getLoanDefaultInterestRate();
            this.loanDepositDate = sale.loanDepositDate ? sale.loanDepositDate : null;
            this.loanDepositUrl = sale.loanDepositUrl;
            this.loanAgreementDate = sale.loanAgreementDate ? sale.loanAgreementDate : null;
            this.loanAgreementUrl = sale.loanAgreementUrl;
            this.loanIssueDate = sale.loanIssueDate ? sale.loanIssueDate : null;
            this.loanIssueUrl = sale.loanIssueUrl;
            this.loanType = sale.loanType ? sale.loanType : 'deposit';
            this.loanGuaranteeType = sale.loanGuaranteeType ? sale.loanGuaranteeType : 'bank';
            this.loanGuaranteeTracking = sale.loanGuaranteeTracking;
            this.notificationDate = sale.notificationDate ? sale.notificationDate : this.today();
            this.lawyer = sale.lawyer ? sale.lawyer.id : null;
            this.otherLawyer = sale.otherLawyer ? sale.otherLawyer.id : null;
            this.certificateDate = sale.certificateDate ? sale.certificateDate : this.today();
            this.certificateUrl = sale.certificateUrl;
            this.comment = sale.comment;

            this.buyerSlots = reserveSlots(MAX_BUYERS);
            sale.buyers.forEach((contact, index) => {
                this.buyerSlots[index].contact = contact;
            });
        },
        _removeBuyerSlotHoles() {
            setTimeout(() => {
                const nbSlots = this.buyerSlots.length;
                const nonEmptySlots = this.buyerSlots.filter(buyerSlot => buyerSlot.contact);
                this.buyerSlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';

.sale {
    .md-steppers.md-vertical .md-stepper-content {
        padding: 0 14px 0 50px;
    }

    &__price-consistency {
        padding-top: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: italic;

        &--error {
            color: #ff1744;
        }
    }

    &__option {
        margin-bottom: 1rem;

        &__alert {
            margin: .5rem;
        }
    }

    &__buyer {
        padding: 0 .5rem;
        display: flex;
        align-items: flex-start;

        &__goto {
            margin-top: .5rem;
        }
    }

    &__meta {
        .md-field {
            margin-bottom: 0;
        }
    }

    &__step--force-disabled {
        pointer-events: none;
    }
}
</style>
