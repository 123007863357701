var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contacts" },
    [
      _c("contact-search-engine", { attrs: { mode: _vm.mode } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "contacts__count",
          class: { "contacts__count--filtered": _vm.nbFiltered < _vm.nbTotal },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 0,
                  expression: "nbFiltered > 0",
                },
              ],
              staticClass: "contacts__count__count",
            },
            [
              _vm.nbFiltered < _vm.nbTotal
                ? _c("span", [
                    _vm._v(_vm._s(_vm.formatNumber(_vm.nbFiltered)) + " / "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.formatNumber(_vm.nbTotal)))]),
              _vm._v(
                "\n            contact" +
                  _vm._s(_vm.nbFiltered > 1 ? "s" : "") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered <= 0,
                  expression: "nbFiltered <= 0",
                },
              ],
            },
            [_vm._v("\n            Aucun contact trouvé.\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 1,
                  expression: "nbFiltered > 1",
                },
              ],
              staticClass: "contacts__count__sort",
            },
            [_vm._v("\n            Triés par nom\n        ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("header"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.displayed.length > 0,
              expression: "displayed.length > 0",
            },
          ],
          staticClass: "mdc-list mdc-list--two-line",
        },
        [
          _vm._l(_vm.displayed, function (contact) {
            return [
              _vm._t("default", null, { contact: contact }),
              _vm._v(" "),
              _c("div", { staticClass: "mdc-list-divider" }),
            ]
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isDisplayLimited,
                  expression: "isDisplayLimited",
                },
              ],
              staticClass: "contacts__see-more",
              on: {
                click: function ($event) {
                  return _vm.displayMore()
                },
              },
            },
            [_c("imagine-button", [_vm._v("Voir plus de contacts")])],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }