import container from '../container';

const repo = container.repos.contact;

const MAX_DISPLAYED = 50;

export default {
    namespaced: true,
    state: {
        displayed: [],
        nbTotal: 0,
        nbFiltered: 0,
        maxDisplayed: MAX_DISPLAYED,
        searchMode: 'query',
        query: null,
        salesRep: null,
        isFromPF: null,
        isVIP: null,
        area: null,
        hasEmail: null,
        areMyProspects: null,
        lastContact: null,
        prospectPurchaseBudget: null,
        prospectRentBudget: null,
        prospectTypes: null,
        prospectNbRoomsMin: null,
        prospectBlock: null,
        prospectPropertyType: null,
        prospectArea: null,
        clientTypes: null,
        clientBlock: null,
        clientPropertyPurpose: null,
        checked: [],
        lastTab: 'infos',
        redirectAfterSave: null,
        lastSaved: null
    },
    getters: {
        filters: (state, getters, rootState) => {
            return {
                salesRep: state.areMyProspects && rootState.user && rootState.user.current ? rootState.user.current.id : state.salesRep,
                isFromPF: state.isFromPF,
                area: state.area,
                isVIP: state.isVIP,
                lastContact: state.lastContact,
                hasEmail: state.hasEmail,
                prospectPurchaseBudget: state.prospectPurchaseBudget,
                prospectRentBudget: state.prospectRentBudget,
                prospectBlock: state.prospectBlock,
                prospectPropertyType: state.prospectPropertyType,
                prospectArea: state.prospectArea,
                prospectTypes: state.prospectTypes,
                prospectNbRoomsMin: state.prospectNbRoomsMin,
                clientBlock: state.clientBlock,
                clientPropertyPurpose: state.clientPropertyPurpose,
                clientTypes: state.clientTypes
            };
        }
    },
    mutations: {
        setLastTab(state, tab) {
            state.lastTab = tab;
        },
        resetLastTab(state) {
            state.lastTab = 'infos';
        },
        setLastSaved(state, contact) {
            state.lastSaved = contact;
        },
        clearRedirectAfterSave(state) {
            state.redirectAfterSave = null;
        },
        setRedirectAfterSave(state, route) {
            state.redirectAfterSave = route;
        },
        setQuery(state, query) {
            state.query = query;
        },
        setSalesRep(state, salesRep) {
            state.salesRep = salesRep;
        },
        setIsFromPF(state, isFromPF) {
            state.isFromPF = isFromPF;
        },
        setIsVIP(state, isVIP) {
            state.isVIP = isVIP;
        },
        setArea(state, area) {
            state.area = area;
        },
        setHasEmail(state, hasEmail) {
            state.hasEmail = hasEmail;
        },
        setareMyProspects(state, areMyProspects) {
            state.areMyProspects = areMyProspects;
        },
        setLastContact(state, lastContact) {
            state.lastContact = lastContact;
        },
        setProspectPurchaseBudget(state, prospectPurchaseBudget) {
            state.prospectPurchaseBudget = prospectPurchaseBudget;
        },
        setProspectRentBudget(state, prospectRentBudget) {
            state.prospectRentBudget = prospectRentBudget;
        },
        setProspectBlock(state, prospectBlock) {
            state.prospectBlock = prospectBlock;
        },
        setProspectPropertyType(state, prospectPropertyType) {
            state.prospectPropertyType = prospectPropertyType;
        },
        setProspectArea(state, prospectArea) {
            state.prospectArea = prospectArea;
        },
        setProspectTypes(state, prospectTypes) {
            state.prospectTypes = prospectTypes;
        },
        setProspectNbRoomsMin(state, nbRooms) {
            state.prospectNbRoomsMin = nbRooms;
        },
        setClientTypes(state, clientTypes) {
            state.clientTypes = clientTypes;
        },
        setClientBlock(state, clientBlock) {
            state.clientBlock = clientBlock;
        },
        setClientPropertyPurpose(state, clientPropertyPurpose) {
            state.clientPropertyPurpose = clientPropertyPurpose;
        },
        changeMode(state, mode) {
            const origin = state.searchMode;

            state.searchMode = mode;

            if (origin !== mode) {
                state.query = null;

                state.salesRep = null;
                state.isFromPF = null;
                state.isVIP = null;
                state.area = null;
                state.hasEmail = null;
                state.areMyProspects = null;
                state.lastContact = null;
                state.prospectPurchaseBudget = null;
                state.prospectRentBudget = null;
                state.prospectBlock = null;
                state.prospectPropertyType = null;
                state.prospectArea = null;
                state.prospectTypes = null;
                state.prospectNbRoomsMin = null;
                state.clientTypes = null;
                state.clientBlock = null;
                state.clientPropertyPurpose = null;
            }
        },
        addToChecked(state, contactId) {
            if (!state.checked.includes(contactId)) {
                state.checked.push(contactId);
            }
        },
        removeFromChecked(state, contactId) {
            const index = state.checked.indexOf(contactId);
            if (index > -1) {
                state.checked.splice(index, 1);
            }
        }
    },
    actions: {
        reload({state, getters}) {
            return repo.all({
                limit: state.maxDisplayed,
                query: state.query,
                vip: state.searchMode === 'vip' ? 1 : 0,
                filters: getters.filters
            })
                .then(collection => {
                    state.nbTotal = collection.nbTotal;
                    state.nbFiltered = collection.nbFiltered;
                    state.displayed = collection.entities;
                });
        },
        displayMore({state, dispatch}) {
            state.maxDisplayed += state.maxDisplayed;

            return dispatch('reload');
        },
        touch({state, dispatch}) {
            if (!state.displayed || state.displayed.length <= 0) {
                return dispatch('reload');
            }

            return Promise.resolve();
        },
        query({state, dispatch}, query) {
            return repo.all({limit: MAX_DISPLAYED, query: query}).then(collection => collection.entities);
        },
        one(context, id) {
            return repo.one(id);
        },
        save(context, data) {
            return repo.save(data);
        },
        addEvent(context, {contactId, description}) {
            return repo.addEvent(contactId, description);
        },
        addToFavorites(context, contactId) {
            return repo.addToFavorites(contactId);
        },
        removeFromFavorites(context, contactId) {
            return repo.removeFromFavorites(contactId);
        },
        remove(context, id) {
            return repo.remove(id);
        },
        checkedContacts({state}) {
            return repo.emailableRecipients(state.checked);
        },
        checkAll({state}) {
            return repo.emailableIds()
                .then(ids => state.checked = ids);
        },
        checkFiltered({state, getters}) {
            return repo.emailableIds({query: state.query, filters: getters.filters})
                .then(ids => state.checked = ids);
        },
        checkNone({state}) {
            state.checked = []

            return Promise.resolve();
        },
        removeTransaction(context, {contactId, transactionId}) {
            return repo.removeTransaction(contactId, transactionId);
        }
    }
};
