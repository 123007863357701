var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "imagine-button mdc-button",
      class: { "mdc-button--raised": _vm.primary },
      attrs: { type: _vm.type, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "mdc-button__ripple" }),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-button__label" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }