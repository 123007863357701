var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("imagine-select", {
    attrs: {
      choices: _vm.periodChoices,
      label: "Période",
      required: true,
      disabled: !_vm.hasMultiplePeriods,
      name: "period",
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", _vm.period)
      },
    },
    model: {
      value: _vm.periodId,
      callback: function ($$v) {
        _vm.periodId = $$v
      },
      expression: "periodId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }