var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandate-properties",
      attrs: {
        title: _vm.$mq === "phone" ? "Locataires" : "Gestion locataires",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: {
                  disabled: _vm.nbRentsToCharge <= 0,
                  icon: "file_copy",
                },
                on: {
                  click: function ($event) {
                    _vm.showChargeRentsConfirmation = true
                  },
                },
              }),
              _vm._v(" "),
              _c("mandate-external-services"),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "imagine-secondary-zone mandate-properties__filters" },
        [
          _c(
            "div",
            { staticClass: "mandate-properties__filters__filter" },
            [
              _c("imagine-input", {
                attrs: {
                  autocomplete: false,
                  clearable: true,
                  required: false,
                  icon: "search",
                  label: "Nom propriétaire OU locataire",
                  name: "actor",
                },
                model: {
                  value: _vm.actorFilter,
                  callback: function ($$v) {
                    _vm.actorFilter = $$v
                  },
                  expression: "actorFilter",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mandate-properties__filters__filter" },
            [
              _c("imagine-input", {
                attrs: {
                  autocomplete: false,
                  clearable: true,
                  required: false,
                  icon: "search",
                  label: "Résidence/Lotissement/Lot",
                  name: "property",
                },
                model: {
                  value: _vm.propertyFilter,
                  callback: function ($$v) {
                    _vm.propertyFilter = $$v
                  },
                  expression: "propertyFilter",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mandate-properties__filters__filter" },
            [
              _c("imagine-select", {
                attrs: {
                  label: "Statut",
                  name: "status",
                  "implicit-required": true,
                  choices: _vm.statusChoices,
                  multiple: true,
                },
                model: {
                  value: _vm.statusesFilter,
                  callback: function ($$v) {
                    _vm.statusesFilter = $$v
                  },
                  expression: "statusesFilter",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mandate-properties__count",
          class: {
            "mandate-properties__count--filtered": _vm.nbFiltered < _vm.nbTotal,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbFiltered > 0,
                  expression: "nbFiltered > 0",
                },
              ],
              staticClass: "mandate-properties__count__count",
            },
            [
              _vm.nbFiltered < _vm.nbTotal
                ? _c("span", [
                    _vm._v(_vm._s(_vm.formatNumber(_vm.nbFiltered)) + " / "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.formatNumber(_vm.nbTotal)))]),
              _vm._v(
                "\n            bien" +
                  _vm._s(_vm.nbFiltered > 1 ? "s" : "") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nbTotal > 0 && _vm.nbFiltered <= 0,
                  expression: "nbTotal > 0 && nbFiltered <= 0",
                },
              ],
            },
            [_vm._v("\n            Aucun bien trouvé.\n        ")]
          ),
          _vm._v(" "),
          _vm.nbTotal > 0
            ? _c("div", { staticClass: "mandate-properties__count__sort" }, [
                _vm._v(
                  "\n            Triés du plus récemment modifié\n            "
                ),
                _c("br"),
                _vm._v("au plus ancien\n        "),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tenants" }, [
        _c("div", { staticClass: "imagine-table-container" }, [
          _vm.filtered.length > 0
            ? _c("table", { staticClass: "imagine-table" }, [
                _c("thead", [
                  _c("tr", { staticClass: "imagine-table__row" }, [
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header",
                      },
                      [_vm._v(" ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header",
                      },
                      [
                        _vm._v(
                          "\n                        Propriétaire(s)\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                      },
                      [
                        _vm._v(
                          "\n                        Bien\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--furnished",
                      },
                      [
                        _vm._v(
                          "\n                        Meublé\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                      },
                      [
                        _vm._v(
                          "\n                        Défiscalisation\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                      },
                      [
                        _vm._v(
                          "\n                        Locataire(s)\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent",
                      },
                      [
                        _vm._v(
                          "\n                        Loyer charges comprises\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                      },
                      [
                        _vm._v(
                          "\n                        Date d'entrée\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass:
                          "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                      },
                      [
                        _vm._v(
                          "\n                        Date de sortie\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.filtered, function (mandateProperty) {
                    return _c(
                      "tr",
                      {
                        staticClass: "imagine-table__row tenants__tenant",
                        class: {
                          "imagine-table__row--disabled":
                            mandateProperty.isArchive,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.redirectTo("mandateProperty", {
                              id: mandateProperty.id,
                            })
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "imagine-table__row__cell" },
                          [
                            _c("mandate-property-status", {
                              attrs: { mandate: mandateProperty },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                mandateProperty.owners
                                  .map((owner) => owner.shortName)
                                  .join(", ")
                              ) +
                              "\n                        (#" +
                              _vm._s(mandateProperty.registryNumber) +
                              ")\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--text",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(mandateProperty.property.infos) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--furnished",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  mandateProperty.isFurnished ? "Oui" : "Non"
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--text",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  mandateProperty.taxExemption
                                    ? _vm.references.getLabel(
                                        "taxExemptions",
                                        mandateProperty.taxExemption
                                      )
                                    : null
                                ) +
                                "\n                        "
                            ),
                            mandateProperty.taxExemptionEnd
                              ? [
                                  _c("br"),
                                  _vm._v(
                                    "Fin " +
                                      _vm._s(
                                        _vm.formatDate(
                                          mandateProperty.taxExemptionEnd
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--text",
                          },
                          [
                            mandateProperty.currentLease
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        mandateProperty.currentLease.tenants
                                          .map((tenant) => tenant.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--rent",
                          },
                          [
                            mandateProperty.currentLease
                              ? [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.formatMoney(
                                              mandateProperty.currentLease.rent
                                                .fullRent
                                            )
                                          ) +
                                          "\n                                "
                                      ),
                                      _vm.rentDetail(
                                        mandateProperty.currentLease.rent
                                      )
                                        ? _c(
                                            "md-tooltip",
                                            {
                                              attrs: {
                                                "md-direction": "bottom",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.rentDetail(
                                                      mandateProperty
                                                        .currentLease.rent
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--date",
                          },
                          [
                            mandateProperty.currentLease
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatDate(
                                          mandateProperty.currentLease.start
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--date",
                          },
                          [
                            mandateProperty.currentLease
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.formatDate(
                                          mandateProperty.currentLease.end
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _c("div", { staticClass: "tenants__none" }, [
                _vm._v(
                  "\n                Aucun bien avec un mandat.\n            "
                ),
              ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.showChargeRentsConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: {
                "button-label":
                  "Générer " + _vm.nbRentsToCharge + " appel(s) de loyer",
              },
              on: {
                act: _vm.chargeRents,
                close: function ($event) {
                  _vm.showChargeRentsConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Etes vous sûr de vouloir générer " +
                    _vm._s(_vm.nbRentsToCharge) +
                    " appel(s) de loyer ?"
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }