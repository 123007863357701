import Types from './services/Types';
import Http from './services/Http';
import ContactRepository from './services/repository/contact';
import PropertyRepository from './services/repository/property';
import UserRepository from './services/repository/user';
import EmployeeRepository from './services/repository/employee';
import EventRepository from './services/repository/event';
import ApplicationRepository from './services/repository/application';
import BlockRepository from './services/repository/block';
import ReferencesCollection from './services/ReferencesCollection';
import FileUploader from './services/FileUploader';
import MailingRepository from './services/repository/mailing';
import MandateRepository from './services/repository/mandate';
import MandatePropertyRepository from './services/repository/mandateProperty';
import ContractorRepository from './services/repository/contractor';
import AccountEntryRepository from './services/repository/accountEntry';
import LeaseRepository from './services/repository/lease';
import SaleRepository from './services/repository/sale';
import SalePaymentsRepository from './services/repository/salePayments';
import orderBy from 'lodash-es/orderBy';
import groupBy from 'lodash-es/groupBy';

const types = new Types();
const http = new Http();
const contact = new ContactRepository(types, http);
const contractor = new ContractorRepository(types, http);
const mailing = new MailingRepository(types, http);
const employee = new EmployeeRepository(types, http);
const user = new UserRepository(types, http);
const property = new PropertyRepository(types, http);
const block = new BlockRepository(types, http);
const mandate = new MandateRepository(types, http);
const mandateProperty = new MandatePropertyRepository(types, http);
const accountEntry = new AccountEntryRepository(types, http);
const lease = new LeaseRepository(types, http);
const event = new EventRepository(types, http);
const sale = new SaleRepository(types, http);
const salePayments = new SalePaymentsRepository(types, http);
const application = new ApplicationRepository(types, http);
const referencesCollection = new ReferencesCollection({orderBy, groupBy});
const fileUploader = new FileUploader(http);

const container = {
    types,
    repos: {
        application,
        contact,
        employee,
        property,
        user,
        event,
        block,
        mailing,
        mandate,
        contractor,
        mandateProperty,
        lease,
        accountEntry,
        sale,
        salePayments
    },
    referencesCollection,
    fileUploader
};

export default container;
