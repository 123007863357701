var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "imagine-error" }, [
    _c(
      "div",
      { staticClass: "imagine-error__message" },
      [_c("imagine-logo"), _vm._v(" "), _c("h1", [_vm._v(_vm._s(_vm.title))])],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }