var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandate-property",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.mandateProperty && !_vm.mandateProperty.isArchived
                ? [
                    _vm.nbAlerts > 0
                      ? _c(
                          "md-badge",
                          {
                            staticClass: "mandate-property__badge",
                            class: {
                              "mandate-property__badge--alert":
                                _vm.hasCriticalAlerts,
                            },
                            attrs: { "md-content": _vm.nbAlerts },
                          },
                          [
                            _c("imagine-icon-button", {
                              staticClass: "mdc-top-app-bar__action-item",
                              attrs: { icon: "notifications" },
                              on: {
                                click: function ($event) {
                                  _vm.showAlertsModal = true
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { disabled: true, icon: "notifications_none" },
                        }),
                    _vm._v(" "),
                    _c("imagine-icon-button", {
                      staticClass: "mdc-top-app-bar__action-item",
                      attrs: {
                        disabled:
                          _vm.mandateProperty &&
                          !_vm.mandateProperty.possibleActions.includes(
                            "desactivate"
                          ),
                        icon: "cancel",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.executeAction("desactivate")
                        },
                      },
                    }),
                  ]
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "imagine-secondary-zone" }, [
        _vm.mandateProperty
          ? _c("div", { staticClass: "mandate-property__infos" }, [
              _c("div", { staticClass: "mandate-property__infos__info" }, [
                _c(
                  "span",
                  [
                    _vm._v(
                      "\n                    Propriétaire" +
                        _vm._s(
                          _vm.mandateProperty.owners.length > 1 ? "s" : ""
                        ) +
                        "\n                    "
                    ),
                    _vm._l(_vm.mandateProperty.owners, function (owner, index) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "mandate-property__infos__info__link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.redirectTo("contact", {
                                  id: owner.id,
                                })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(owner.shortName) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        owner.legalRepresentatives.length > 0
                          ? _c(
                              "small",
                              [
                                _c("br"),
                                _vm._v(
                                  "représenté par\n                            "
                                ),
                                _vm._l(
                                  owner.legalRepresentatives,
                                  function (represented, indexR) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "mandate-property__infos__info__link",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.redirectTo("contact", {
                                                id: represented.id,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(represented.shortName) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      indexR !==
                                      Object.keys(owner.legalRepresentatives)
                                        .length -
                                        1
                                        ? _c("span", [_vm._v(" , ")])
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index !==
                        Object.keys(_vm.mandateProperty.owners).length - 1
                          ? _c("span", [_c("br")])
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.mandateProperty.unpaidGuaranteePercent
                      ? _c("small", [
                          _c("br"),
                          _vm._v(
                            "GLI " +
                              _vm._s(
                                _vm.mandateProperty.unpaidGuaranteePercent
                              ) +
                              "%\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mandate-property__infos__info",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.redirectTo("property", {
                        id: _vm.mandateProperty.property.id,
                      })
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v("\n                    Bien\n                    "),
                    _c(
                      "a",
                      {
                        staticClass: "mandate-property__infos__info__link",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.mandateProperty.property.infos) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mandate-property__infos__info" },
                [
                  !_vm.mandateProperty
                    ? void 0
                    : _vm.currentLease
                    ? [
                        _vm.currentLease.end
                          ? [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._formatFromDate(
                                          "Départ",
                                          _vm.currentLease.end
                                        )
                                      ) +
                                      " de\n                            "
                                  ),
                                  _vm._l(
                                    _vm.currentLease.tenants,
                                    function (tenant, index) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "mandate-property__infos__info__link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.redirectTo(
                                                  "contact",
                                                  { id: tenant.id }
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(tenant.shortName) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        index !==
                                        Object.keys(_vm.currentLease.tenants)
                                          .length -
                                          1
                                          ? _c("span", [_vm._v(" , ")])
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          : [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm._formatFromDate(
                                          "Loué",
                                          _vm.currentLease.start
                                        )
                                      ) +
                                      " à\n                            "
                                  ),
                                  _vm._l(
                                    _vm.currentLease.tenants,
                                    function (tenant, index) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "mandate-property__infos__info__link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.redirectTo(
                                                  "contact",
                                                  { id: tenant.id }
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(tenant.shortName) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        index !==
                                        Object.keys(_vm.currentLease.tenants)
                                          .length -
                                          1
                                          ? _c("span", [_vm._v(" , ")])
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                      ]
                    : [_vm._v("Vacant")],
                ],
                2
              ),
              _vm._v(" "),
              _vm.currentLease
                ? _c("div", { staticClass: "mandate-property__infos__info" }, [
                    _c(
                      "span",
                      [
                        _vm._v(
                          "\n                    Loyer charges comprises " +
                            _vm._s(
                              _vm.formatMoney(_vm.currentLease.rent.fullRent)
                            ) +
                            "\n                    "
                        ),
                        _vm.rentDetail(_vm.currentLease.rent)
                          ? _c(
                              "md-tooltip",
                              { attrs: { "md-direction": "bottom" } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.rentDetail(_vm.currentLease.rent)
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mandate-property__infos__info" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.ongoingLabel) +
                    "\n            "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mandate-property__fields" },
          [
            _c("imagine-textarea", {
              staticClass: "imagine-form__row__field",
              attrs: { label: "Notes", name: "comment" },
              model: {
                value: _vm.comment,
                callback: function ($$v) {
                  _vm.comment = $$v
                },
                expression: "comment",
              },
            }),
            _vm._v(" "),
            _c(
              "imagine-button",
              {
                attrs: { primary: true, type: "submit" },
                on: {
                  click: function ($event) {
                    return _vm.saveComment()
                  },
                },
              },
              [_vm._v("\n                Sauvegarder\n            ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("imagine-tab-bar", {
        attrs: { tabs: _vm.tabs },
        on: {
          tab: function ($event) {
            return _vm.changeTab($event)
          },
        },
      }),
      _vm._v(" "),
      _vm.activeTabId === "log"
        ? _c("div", [
            _vm.mandateProperty &&
            _vm.mandateProperty.possibleActions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mandate-property__actions" },
                  [
                    _c("imagine-select", {
                      attrs: {
                        label: "Voir",
                        "implicit-required": true,
                        name: "logFilter",
                        choices: _vm.logFilterChoices,
                      },
                      model: {
                        value: _vm.logFilter,
                        callback: function ($$v) {
                          _vm.logFilter = $$v
                        },
                        expression: "logFilter",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mandate-property__logs" }, [
              _vm.mandateProperty
                ? _c(
                    "div",
                    { staticClass: "mandate-property__logs__actors" },
                    [
                      _vm._l(
                        _vm.mandateProperty.leases,
                        function (lease, index) {
                          return _vm.showLeaseId === lease.id
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mandate-property__logs__actors__actor",
                                  class: {
                                    "mandate-property__logs__actors__actor--inactive":
                                      lease.end && _vm.today.isAfter(lease.end),
                                  },
                                },
                                [
                                  _c(
                                    "h3",
                                    [
                                      _vm.mandateProperty.leases.hasOwnProperty(
                                        index - 1
                                      )
                                        ? _c("imagine-icon-button", {
                                            staticClass: "imagine-no-ripple",
                                            attrs: { icon: "arrow_left" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showLeaseId =
                                                  _vm.mandateProperty.leases[
                                                    index - 1
                                                  ].id
                                              },
                                            },
                                          })
                                        : _c("div", {
                                            staticClass:
                                              "mandate-property__logs__actors__actor__nonav",
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.redirectTo("lease", {
                                                mandatePropertyId:
                                                  _vm.mandateProperty.id,
                                                id: lease.id,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                            Locataire" +
                                              _vm._s(
                                                lease.tenants.length > 1
                                                  ? "s"
                                                  : ""
                                              ) +
                                              "\n                            " +
                                              _vm._s(
                                                lease.tenants
                                                  .map(
                                                    (tenant) => tenant.shortName
                                                  )
                                                  .join(", ")
                                              ) +
                                              "\n                            "
                                          ),
                                          _c("small", [
                                            _c("br"),
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.formatDate(lease.start)
                                                ) +
                                                "\n                                -\n                                " +
                                                _vm._s(
                                                  lease.end
                                                    ? _vm.formatDate(lease.end)
                                                    : "aujourd'hui"
                                                ) +
                                                "\n                            "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      lease.possibleActions.length > 1
                                        ? _c(
                                            "md-menu",
                                            {
                                              attrs: {
                                                "md-size": "medium",
                                                "md-align-trigger": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "imagine-button",
                                                {
                                                  attrs: {
                                                    primary: true,
                                                    "md-menu-trigger": "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                Nouvelle action\n                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "md-menu-content",
                                                _vm._l(
                                                  lease.possibleActions,
                                                  function (action) {
                                                    return _c(
                                                      "md-menu-item",
                                                      {
                                                        key:
                                                          "mandate-action-" +
                                                          action,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.executeAction(
                                                              action
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    " +
                                                            _vm._s(
                                                              _vm.references.mandateActionLabel(
                                                                action
                                                              )
                                                            ) +
                                                            "\n                                "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "imagine-button",
                                            {
                                              attrs: { primary: true },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.executeAction(
                                                    lease.possibleActions[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.references.mandateActionLabel(
                                                      lease.possibleActions[0]
                                                    )
                                                  ) +
                                                  "\n                        "
                                              ),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _vm.mandateProperty.leases.hasOwnProperty(
                                        index + 1
                                      )
                                        ? _c("imagine-icon-button", {
                                            staticClass: "imagine-no-ripple",
                                            attrs: { icon: "arrow_right" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showLeaseId =
                                                  _vm.mandateProperty.leases[
                                                    index + 1
                                                  ].id
                                              },
                                            },
                                          })
                                        : _c("div", {
                                            staticClass:
                                              "mandate-property__logs__actors__actor__nonav",
                                          }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.filterLogs(lease.logs).length <
                                  lease.logs.length
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mandate-property__logs__actors__actor__filter",
                                        },
                                        [
                                          _vm.filterLogs(lease.logs).length <= 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "Aucun évènement dans " +
                                                    _vm._s(_vm.logFilterLabel) +
                                                    "."
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  "... d'autres évènements sont filtrés."
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mdc-list mdc-list--non-interactive",
                                    },
                                    [
                                      _vm._l(
                                        _vm.filterLogs(lease.logs),
                                        function (log) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mdc-list-item mandate-property__logs__actors__actor__log",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-list-item__graphic",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.formatDate(
                                                            log.date
                                                          )
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-list-item__text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          log.description
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mdc-list-item__meta",
                                                  },
                                                  [
                                                    log.preview
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "imagine-icon-button",
                                                              {
                                                                staticClass:
                                                                  "imagine-no-ripple",
                                                                attrs: {
                                                                  icon: "zoom_in",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.externalRedirectTo(
                                                                        log
                                                                          .preview
                                                                          .url
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "md-tooltip",
                                                              {
                                                                attrs: {
                                                                  "md-direction":
                                                                    "right",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    log.preview
                                                                      .title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    log.email
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "imagine-icon-button",
                                                              {
                                                                staticClass:
                                                                  "imagine-no-ripple",
                                                                attrs: {
                                                                  icon: "send",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.showSendMailConfirmation =
                                                                        log.email
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "md-tooltip",
                                                              {
                                                                attrs: {
                                                                  "md-direction":
                                                                    "right",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    log.email
                                                                      .subject
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    log.doc
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "imagine-icon-button",
                                                              {
                                                                staticClass:
                                                                  "imagine-no-ripple",
                                                                attrs: {
                                                                  icon: "insert_drive_file",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.download(
                                                                        log.doc
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "md-tooltip",
                                                              {
                                                                attrs: {
                                                                  "md-direction":
                                                                    "right",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    log.doc
                                                                      .title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "mdc-list-divider",
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e()
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mandate-property__logs__actors__actor",
                        },
                        [
                          _c(
                            "h3",
                            [
                              _c("div", {
                                staticClass:
                                  "mandate-property__logs__actors__actor__nonav",
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.redirectTo("mandate", {
                                        id: _vm.mandateProperty.mandateId,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Propriétaire" +
                                      _vm._s(
                                        _vm.mandateProperty.owners.length > 1
                                          ? "s"
                                          : ""
                                      ) +
                                      "\n                            " +
                                      _vm._s(
                                        _vm.mandateProperty.owners
                                          .map((owner) => owner.shortName)
                                          .join(", ")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.mandateProperty.possibleActions.length > 1
                                ? _c(
                                    "md-menu",
                                    {
                                      attrs: {
                                        "md-size": "medium",
                                        "md-align-trigger": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "imagine-button",
                                        {
                                          attrs: {
                                            primary: true,
                                            "md-menu-trigger": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Nouvelle action\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "md-menu-content",
                                        _vm._l(
                                          _vm.mandateProperty.possibleActions,
                                          function (action) {
                                            return _c(
                                              "md-menu-item",
                                              {
                                                key: "mandate-action-" + action,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.executeAction(
                                                      action
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      _vm.references.mandateActionLabel(
                                                        action
                                                      )
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "imagine-button",
                                    {
                                      attrs: { primary: true },
                                      on: {
                                        click: function ($event) {
                                          return _vm.executeAction(
                                            _vm.mandateProperty
                                              .possibleActions[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.references.mandateActionLabel(
                                              _vm.mandateProperty
                                                .possibleActions[0]
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "mandate-property__logs__actors__actor__nonav",
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.filterLogs(_vm.mandateProperty.logs).length <
                          _vm.mandateProperty.logs.length
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "mandate-property__logs__actors__actor__filter",
                                },
                                [
                                  _vm.filterLogs(_vm.mandateProperty.logs)
                                    .length <= 0
                                    ? _c("span", [
                                        _vm._v(
                                          "Aucun évènement dans " +
                                            _vm._s(_vm.logFilterLabel) +
                                            "."
                                        ),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "... d'autres évènements sont filtrés."
                                        ),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list mdc-list--non-interactive",
                            },
                            [
                              _vm._l(
                                _vm.filterLogs(_vm.mandateProperty.logs),
                                function (log) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mdc-list-item mandate-property__logs__actors__actor__log",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mdc-list-item__graphic",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.formatDate(log.date)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mdc-list-item__text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(log.description) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mdc-list-item__meta",
                                          },
                                          [
                                            log.preview
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("imagine-icon-button", {
                                                      staticClass:
                                                        "imagine-no-ripple",
                                                      attrs: {
                                                        icon: "zoom_in",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.externalRedirectTo(
                                                            log.preview.url
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "md-tooltip",
                                                      {
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            log.preview.title
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            log.email
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("imagine-icon-button", {
                                                      staticClass:
                                                        "imagine-no-ripple",
                                                      attrs: { icon: "send" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.showSendMailConfirmation =
                                                            log.email
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "md-tooltip",
                                                      {
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            log.email.subject
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            log.doc
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("imagine-icon-button", {
                                                      staticClass:
                                                        "imagine-no-ripple",
                                                      attrs: {
                                                        icon: "insert_drive_file",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.download(
                                                            log.doc
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "md-tooltip",
                                                      {
                                                        attrs: {
                                                          "md-direction":
                                                            "right",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(log.doc.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "mdc-list-divider",
                                    }),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    2
                  )
                : _c("div", { staticClass: "mandate-property__logs__empty" }, [
                    _vm._v("\n                Aucune activité.\n            "),
                  ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "accounts"
        ? _c(
            "div",
            { staticClass: "mandate-property__accounts" },
            [
              _vm._l(_vm.mandateProperty.leases, function (lease, index) {
                return _vm.showLeaseId === lease.id
                  ? _c(
                      "div",
                      { staticStyle: { margin: ".5rem auto" } },
                      [
                        _c("account", {
                          attrs: {
                            account: lease.account,
                            lettering: true,
                            prev: _vm.mandateProperty.leases.hasOwnProperty(
                              index - 1
                            ),
                            next: _vm.mandateProperty.leases.hasOwnProperty(
                              index + 1
                            ),
                            lease: lease,
                          },
                          on: {
                            prev: function ($event) {
                              _vm.showLeaseId =
                                _vm.mandateProperty.leases[index - 1].id
                            },
                            next: function ($event) {
                              _vm.showLeaseId =
                                _vm.mandateProperty.leases[index + 1].id
                            },
                            refresh: function ($event) {
                              return _vm.refresh()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("account", {
                attrs: {
                  account: _vm.mandateProperty.ownerAccount,
                  mandate: { id: _vm.mandateProperty.mandateId },
                },
                on: {
                  refresh: function ($event) {
                    return _vm.refresh()
                  },
                },
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mandateProperty && _vm.showAlertsModal
        ? _c(
            "imagine-modal",
            {
              attrs: { title: _vm.alertsModalTitle },
              on: {
                close: function ($event) {
                  _vm.showAlertsModal = false
                },
              },
            },
            _vm._l(_vm.mandateProperty.alerts, function (alert) {
              return _c(
                "div",
                {
                  staticClass: "mandate-property-alert",
                  class: {
                    "mandate-property-alert--is-critical": alert.isCritical,
                  },
                },
                [
                  alert.isCritical
                    ? _c("span", { staticClass: "material-icons" }, [
                        _vm._v("warning"),
                      ])
                    : _c("span", { staticClass: "material-icons" }, [
                        _vm._v("info"),
                      ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(alert.label))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendMailConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: {
                "button-label": "Envoyer",
                title:
                  "Confirmation email " + _vm.showSendMailConfirmation.subject,
              },
              on: {
                close: function ($event) {
                  _vm.showSendMailConfirmation = false
                },
                act: function ($event) {
                  return _vm.sendMail(_vm.showSendMailConfirmation.sendUrl)
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir envoyer un mail\n            " +
                    _vm._s(
                      _vm.showSendMailConfirmation.recipientType === "owner"
                        ? "au propriétaire"
                        : "au(x) locataire(s)"
                    ) +
                    " ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "desactivate"
        ? _c("desactivate", {
            attrs: { "mandate-property": _vm.mandateProperty },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "save_inventory_in" ||
      _vm.showAction === "save_inventory_out"
        ? _c("save-inventory", {
            attrs: {
              lease: _vm.shownLease,
              type: _vm.showAction === "save_inventory_in" ? "in" : "out",
            },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "lease_end_notice"
        ? _c("lease-end-notice", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "charge_rent"
        ? _c("charge-rent", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "send_reminder"
        ? _c("send-reminder", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "send_receipt"
        ? _c("send-receipt", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "save_insurance"
        ? _c("save-insurance", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "edit_mandate_property_insurance"
        ? _c("save-insurance", {
            attrs: { "mandate-property": _vm.mandateProperty },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "custom_lease"
        ? _c("custom-event", {
            attrs: { lease: _vm.shownLease },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAction === "custom_mandate_property"
        ? _c("custom-event", {
            attrs: { "mandate-property": _vm.mandateProperty },
            on: {
              canceled: function ($event) {
                return _vm.actionCanceled()
              },
              completed: function ($event) {
                return _vm.actionCompleted()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }