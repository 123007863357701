<template>
    <div class="imagine-error">
        <div class="imagine-error__message">
            <imagine-logo/>

            <h1>{{ title }}</h1>
        </div>
    </div>
</template>

<script>
import ImagineLogo from '../components/ImagineLogo.vue';

export default {
    components: {ImagineLogo},
    computed: {
        title() {
            return 'Nous sommes désolés mais cette page est inaccessible';
        }
    }
}
</script>

<style lang="scss">
.imagine-error {
    background-color: #ffffff;
    font: 14px Roboto, sans-serif;
    display: flex;
    height: 100vh;

    &__message {
        margin: auto;
        padding: 1rem;
        text-align: center;

        h1 {
            font-size: 1.8rem;
            font-weight: 500;
        }

        .imagine-logo {
            width: 250px;
            margin: 1rem auto;
        }
    }
}
</style>
