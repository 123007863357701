<template>
    <imagine-layout title="Prestataires"
                    class="contractors">
        <template v-slot:actions>
            <imagine-icon-button :active="filter"
                                 @click="filter = !filter"
                                 class="mdc-top-app-bar__action-item"
                                 icon="filter_list"/>
            <imagine-icon-button @click="redirectTo('contractorAdd')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="add_box"/>
        </template>

        <transition name="slideY">
            <div class="imagine-secondary-zone contractors__filters" v-if="filter">
                <div class="contractors__filters__filter">
                    <imagine-select :choices="references.contractorCategoryChoices()"
                                    label="Catégorie"
                                    name="contractorcategoryfilter"
                                    v-model="category"/>
                </div>
                <div class="contractors__filters__filter">
                    <imagine-input :clearable="true"
                                   :required="false"
                                   label="Entreprise"
                                   name="contractorcompany"
                                   v-model="company"/>
                </div>
            </div>
        </transition>

        <template v-if="contractors.length > 0">
            <div class="contractors__count">
                <div class="contractors__count__count">
                    <span>{{ formatNumber(contractors.length) }}</span>
                    prestataire{{ contractors.length > 1 ? 's' : '' }}
                </div>

                <div class="contractor__count__sort"
                     v-show="contractors.length > 1">
                    Triés par entreprise et nom
                </div>
            </div>

            <div class="mdc-list mdc-list--two-line">
                <template v-for="contractor in displayedContractors">
                    <div @click="redirectTo('contractor', {id: contractor.id})"
                         class="mdc-list-item">
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text">{{ contractor.label }}</span>
                            <span class="mdc-list-item__secondary-text">
                                <template v-if="contractor.email">{{ contractor.email }}</template>
                                <template v-if="contractor.phone">{{ contractor.phone.readable }}</template>
                                <template v-else-if="contractor.mobile">{{ contractor.mobile.readable }}</template>
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            {{ references.contractorCategoryLabel(contractor.category) }}
                        </span>
                    </div>
                    <div class="mdc-list-divider"></div>
                </template>
            </div>
        </template>
        <div class="contractors__none"
             v-else>
            Aucun prestataire.
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineInput from '../components/ImagineInput.vue';
import {mapState} from 'vuex';

export default {
    components: {ImagineInput, ImagineSelect, ImagineLayout, ImagineIconButton},
    computed: {
        ...mapState(['references']),
        ...mapState('contractor', {contractors: state => state.all}),
        displayedContractors() {
            if (!this.contractors) {
                return [];
            }

            return this.contractors
                .filter(contractor => !this.category || contractor.category === this.category)
                .filter(contractor => !this.company || (contractor.company && contractor.company.toUpperCase().includes(this.company.toUpperCase())))
                .sort((a, b) => a.label.localeCompare(b.label, 'fr'));
        }
    },
    data() {
        return {
            filter: false,
            category: null,
            company: null
        }
    },
    created() {
        this.load('Chargement des prestataires');
        Promise.all([this.$store.dispatch('contractor/reloadAll'), this.$store.dispatch('touchReferences')])
            .catch(this.serverError)
            .finally(this.unload);
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.contractors {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            .md-field {
                margin: 0;
            }
        }
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
