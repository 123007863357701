var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mandate",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.activeTabId === "infos" &&
              _vm.mandate &&
              !_vm.mandate.canBeModifiedWithoutAmendment
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: {
                      disabled: true,
                      icon: "post_add",
                      title: "Faire un avenant",
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.mandate
        ? _c("imagine-tab-bar", {
            attrs: { tabs: _vm.tabs },
            on: {
              tab: function ($event) {
                _vm.activeTabId = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTabId === "infos"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c(
                "form",
                {
                  staticClass: "imagine-form",
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.mandateId
                    ? _c("imagine-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          "\n                    Avant de commencer à saisir un nouveau mandat, il est nécessaire de\n                    créer ou de vérifier "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.redirectTo("propertyAdd")
                              },
                            },
                          },
                          [_vm._v("le(s) bien(s)")]
                        ),
                        _vm._v(" et\n                    "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.redirectTo("contactAdd")
                              },
                            },
                          },
                          [_vm._v("le(s) propriétaire(s)")]
                        ),
                        _vm._v(".\n                "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.errors.global
                    ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Date de signature",
                          name: "date",
                          disabled: _vm.isEditingDisabled,
                        },
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-select", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          choices: _vm.salesRepChoices,
                          label: "Négociateur",
                          name: "salesrep",
                        },
                        model: {
                          value: _vm.salesRep,
                          callback: function ($$v) {
                            _vm.salesRep = $$v
                          },
                          expression: "salesRep",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "imagine-form__row imagine-form__row--limited",
                    },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Numéro d'ordre",
                          name: "registryNumber",
                          violation: _vm.errors.registryNumber,
                        },
                        model: {
                          value: _vm.registryNumber,
                          callback: function ($$v) {
                            _vm.registryNumber = $$v
                          },
                          expression: "registryNumber",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Date de début",
                          name: "startDate",
                          disabled: _vm.isEditingDisabled,
                          "min-date": _vm.minMandateDate,
                        },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: true,
                          value: _vm.legalEndDate,
                          label: "Date légale de fin",
                          name: "legalEndDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isEditingDisabled
                    ? _vm._l(_vm.ownerSlots, function (ownerSlot, slot) {
                        return _vm.nbOwners >= slot
                          ? _c(
                              "div",
                              { staticClass: "imagine-form__row" },
                              [
                                _c("imagine-contact-autocomplete", {
                                  staticClass: "imagine-form__row__field",
                                  attrs: {
                                    disabled: _vm.isEditingDisabled,
                                    filter: _vm.canContactBeOwner,
                                    label:
                                      slot > 0
                                        ? "Autre propriétaire"
                                        : "Propriétaire" +
                                          (_vm.nbOwners >= 1
                                            ? " principal (reçoit les virements)"
                                            : ""),
                                    required: slot <= 0,
                                    name: "owner",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.removeOwnerSlotHoles()
                                    },
                                  },
                                  model: {
                                    value: ownerSlot.owner,
                                    callback: function ($$v) {
                                      _vm.$set(ownerSlot, "owner", $$v)
                                    },
                                    expression: "ownerSlot.owner",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e()
                      })
                    : _vm.mandate
                    ? _c("div", { staticClass: "mandate__readonly" }, [
                        _c(
                          "div",
                          { staticClass: "mdc-list mdc-list--two-line" },
                          _vm._l(_vm.mandate.owners, function (owner, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "mdc-list-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectTo("contact", {
                                      id: owner.id,
                                    })
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__text" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mdc-list-item__primary-text",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Propriétaire : " +
                                            _vm._s(owner.shortName) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mdc-list-item__secondary-text",
                                      },
                                      [
                                        owner.email
                                          ? _c("span", [
                                              _vm._v(_vm._s(owner.email)),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        owner.mobilePhone
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  owner.mobilePhone.readable
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "mdc-list-item__meta" },
                                  [
                                    _vm.mandate.owners.length > 1 && index === 0
                                      ? _c("span", [
                                          _vm._v(
                                            "Principal (reçoit les virements)"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          violation: _vm.errors.bankAccount,
                          help: "Format IBAN : FR7620800121000001234567823",
                          label:
                            "Numéro de compte bancaire (si différent du propriétaire)",
                          name: "iban",
                        },
                        model: {
                          value: _vm.bankAccount,
                          callback: function ($$v) {
                            _vm.bankAccount = $$v
                          },
                          expression: "bankAccount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          required: true,
                          label: "Honoraire H.T. (%)",
                          violation: _vm.errors.feesPercent,
                          disabled: _vm.isEditingDisabled,
                          type: "number",
                          min: "0",
                          max: "99.99",
                          step: "any",
                          name: "feesPercent",
                        },
                        model: {
                          value: _vm.feesPercent,
                          callback: function ($$v) {
                            _vm.feesPercent = $$v
                          },
                          expression: "feesPercent",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          "min-date": _vm.date,
                          label: "Date d'effet honoraires",
                          name: "feesStartDate",
                        },
                        model: {
                          value: _vm.feesStartDate,
                          callback: function ($$v) {
                            _vm.feesStartDate = $$v
                          },
                          expression: "feesStartDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          label: "Taux GLI (%)",
                          min: "0",
                          name: "unpaidGuaranteePercent",
                          step: "any",
                          type: "number",
                        },
                        model: {
                          value: _vm.unpaidGuaranteePercent,
                          callback: function ($$v) {
                            _vm.unpaidGuaranteePercent = $$v
                          },
                          expression: "unpaidGuaranteePercent",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "imagine-form__sep" }),
                  _vm._v(" "),
                  !_vm.isEditingDisabled
                    ? _vm._l(_vm.propertySlots, function (propertySlot, slot) {
                        return _vm.nbProperties >= slot
                          ? _c(
                              "div",
                              { staticClass: "imagine-form__row" },
                              [
                                _c("imagine-property-autocomplete", {
                                  staticClass: "imagine-form__row__field",
                                  attrs: {
                                    filter: _vm.canPropertyReceiveMandate,
                                    label: slot > 0 ? "Autre bien" : "Bien",
                                    required: slot <= 0,
                                    violation:
                                      slot <= 0 ? _vm.errors.properties : "",
                                    name: "property",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.removePropertySlotHoles()
                                    },
                                  },
                                  model: {
                                    value: propertySlot.property,
                                    callback: function ($$v) {
                                      _vm.$set(propertySlot, "property", $$v)
                                    },
                                    expression: "propertySlot.property",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e()
                      })
                    : _vm.mandate
                    ? _c("div", { staticClass: "mandate__readonly" }, [
                        _c(
                          "div",
                          { staticClass: "mdc-list mdc-list--two-line" },
                          _vm._l(
                            _vm.mandate.mandateProperties,
                            function (mandateProperty) {
                              return _c(
                                "div",
                                {
                                  staticClass: "mdc-list-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectTo("mandateProperty", {
                                        id: mandateProperty.id,
                                      })
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "mdc-list-item__text" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mdc-list-item__primary-text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Bien : " +
                                              _vm._s(
                                                mandateProperty.property.infos
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mdc-list-item__secondary-text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    Référence " +
                                              _vm._s(
                                                mandateProperty.property
                                                  .reference
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "mdc-list-item__meta" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            mandateProperty.insuranceEndDate
                                              ? "Fin assurance PNO le " +
                                                  _vm.formatDate(
                                                    mandateProperty.insuranceEndDate
                                                  )
                                              : "Pas d'assurance PNO"
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "imagine-form__sep" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-file-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          label: "Document",
                          accept: "application/pdf",
                          name: "doc",
                        },
                        model: {
                          value: _vm.url,
                          callback: function ($$v) {
                            _vm.url = $$v
                          },
                          expression: "url",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-file-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          accept: "application/pdf",
                          label: "Liste mobilier",
                          name: "furnitureUrl",
                        },
                        model: {
                          value: _vm.furnitureUrl,
                          callback: function ($$v) {
                            _vm.furnitureUrl = $$v
                          },
                          expression: "furnitureUrl",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-file-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          accept: "application/pdf",
                          label: "Conditions spéciales",
                          name: "specialUrl",
                        },
                        model: {
                          value: _vm.specialUrl,
                          callback: function ($$v) {
                            _vm.specialUrl = $$v
                          },
                          expression: "specialUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-textarea", {
                        staticClass: "imagine-form__row__field",
                        attrs: { label: "Observations", name: "comment" },
                        model: {
                          value: _vm.comment,
                          callback: function ($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__actions" },
                    [
                      _c(
                        "imagine-button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.back()
                            },
                          },
                        },
                        [_vm._v("Annuler")]
                      ),
                      _vm._v(" "),
                      _c(
                        "imagine-button",
                        { attrs: { primary: true, type: "submit" } },
                        [
                          _vm._v(
                            "\n                        Sauvegarder\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.mandate && _vm.activeTabId === "account"
        ? _c(
            "div",
            [
              _c("account", {
                attrs: { account: _vm.mandate.account, mandate: _vm.mandate },
                on: {
                  refresh: function ($event) {
                    return _vm.refresh()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mandate && _vm.activeTabId === "summary"
        ? _c("div", { staticClass: "mandate__summaries" }, [
            _c("div", { staticClass: "imagine-container" }, [
              _vm.mandate.summaries.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mdc-list mdc-list--two-line mdc-list--non-interactive",
                    },
                    [
                      _vm._l(_vm.mandate.summaries, function (summary) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "mdc-list-item",
                              class: {
                                "mdc-list-item--disabled": summary.isEmpty,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__text" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__primary-text",
                                    },
                                    [_vm._v(_vm._s(summary.period.ucfLabel))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mdc-list-item__secondary-text",
                                    },
                                    [
                                      summary.lastSent
                                        ? [
                                            _vm._v(
                                              "Envoyé le " +
                                                _vm._s(
                                                  _vm.formatDate(
                                                    summary.lastSent
                                                  )
                                                )
                                            ),
                                          ]
                                        : [_vm._v("Non envoyé")],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "mdc-list-item__meta" },
                                [
                                  !summary.isEmpty
                                    ? [
                                        summary.docUrl
                                          ? _c(
                                              "span",
                                              [
                                                _c("imagine-icon-button", {
                                                  staticClass:
                                                    "imagine-no-ripple",
                                                  attrs: {
                                                    icon: "insert_drive_file",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadSummary(
                                                        summary.docUrl
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "right",
                                                    },
                                                  },
                                                  [_vm._v("Télécharger")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !summary.areFeesEntriesDone
                                          ? _c(
                                              "span",
                                              [
                                                _c("imagine-icon-button", {
                                                  staticClass:
                                                    "imagine-no-ripple",
                                                  attrs: { ficon: "percent" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showSendFeesEntriesConfirmation =
                                                        summary.period
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Générer écritures honoraires"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : !summary.arePaymentEntriesDone
                                          ? _c(
                                              "span",
                                              [
                                                _c("imagine-icon-button", {
                                                  staticClass:
                                                    "imagine-no-ripple",
                                                  attrs: {
                                                    icon: "account_balance",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showSendPaymentEntriesConfirmation =
                                                        summary.period
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "right",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Générer écritures versement"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("imagine-icon-button", {
                                                  staticClass:
                                                    "imagine-no-ripple",
                                                  attrs: { icon: "send" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showSummaryMailConfirmation =
                                                        summary.period
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "md-tooltip",
                                                  {
                                                    attrs: {
                                                      "md-direction": "right",
                                                    },
                                                  },
                                                  [_vm._v("Envoyer par mail")]
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    : [
                                        _vm._v(
                                          "\n                                Aucune opération\n                            "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mdc-list-divider" }),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("div", { staticClass: "mandate__summaries__none" }, [
                    _vm._v(
                      "\n                Aucun relevé disponible.\n            "
                    ),
                  ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.mandate && _vm.activeTabId === "tenants"
        ? _c("div", [
            _c("div", { staticClass: "imagine-container" }, [
              _c("div", { staticClass: "mandate-tenants" }, [
                _vm.hasLeases
                  ? _c(
                      "div",
                      [
                        _vm._l(
                          _vm.mandate.mandateProperties,
                          function (mandateProperty) {
                            return [
                              _c(
                                "h3",
                                { staticClass: "mandate-tenants__title" },
                                [_vm._v(_vm._s(mandateProperty.property.infos))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "imagine-table-container" },
                                [
                                  mandateProperty.leases.length > 0
                                    ? _c(
                                        "table",
                                        { staticClass: "imagine-table" },
                                        [
                                          _c("thead", [
                                            _c(
                                              "tr",
                                              {
                                                staticClass:
                                                  "imagine-table__row",
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Locataire(s)\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Date d'entrée\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Date de sortie\n                                    "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Loyer charges comprises\n                                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              mandateProperty.leases,
                                              function (lease) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    staticClass:
                                                      "imagine-table__row mandate-tenants__tenant",
                                                    class: {
                                                      "imagine-table__row--disabled":
                                                        mandateProperty.isArchive,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.redirectTo(
                                                          "mandateProperty",
                                                          {
                                                            id: mandateProperty.id,
                                                          }
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "imagine-table__row__cell imagine-table__row__cell--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              lease.tenants
                                                                .map(
                                                                  (tenant) =>
                                                                    tenant.shortName
                                                                )
                                                                .join(", ")
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "imagine-table__row__cell imagine-table__row__cell--date",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                lease.start
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "imagine-table__row__cell imagine-table__row__cell--date",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                lease.end
                                                              )
                                                            ) +
                                                            "\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "imagine-table__row__cell imagine-table__row__cell--rent",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  _vm.formatMoney(
                                                                    lease.rent
                                                                      .fullRent
                                                                  )
                                                                ) +
                                                                "\n                                        "
                                                            ),
                                                            _vm.rentDetail(
                                                              lease.rent
                                                            )
                                                              ? _c(
                                                                  "md-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      "md-direction":
                                                                        "bottom",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            " +
                                                                        _vm._s(
                                                                          _vm.rentDetail(
                                                                            lease.rent
                                                                          )
                                                                        ) +
                                                                        "\n                                        "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "mandate-tenants__none",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Aucun locataire pour ce bien.\n                            "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "mandate-tenants__none" }, [
                      _vm._v(
                        "\n                    Aucun bail.\n                "
                      ),
                    ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showSummaryMailConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendMailSummary(_vm.showSummaryMailConfirmation)
                },
                close: function ($event) {
                  _vm.showSummaryMailConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir envoyer par email au propriétaire\n            le relevé de " +
                    _vm._s(_vm.showSummaryMailConfirmation.label) +
                    " ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendFeesEntriesConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendFeesEntries(
                    _vm.showSendFeesEntriesConfirmation
                  )
                },
                close: function ($event) {
                  _vm.showSendFeesEntriesConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir réaliser les écritures comptables\n            d'honoraires pour " +
                    _vm._s(_vm.showSendFeesEntriesConfirmation.label) +
                    " ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSendPaymentEntriesConfirmation
        ? _c(
            "imagine-modal",
            {
              attrs: { "button-label": "Envoyer" },
              on: {
                act: function ($event) {
                  return _vm.sendPaymentEntries(
                    _vm.showSendPaymentEntriesConfirmation
                  )
                },
                close: function ($event) {
                  _vm.showSendPaymentEntriesConfirmation = false
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "\n            Etes vous sûr de vouloir réaliser les écritures comptables\n            de versement propriétaire pour " +
                    _vm._s(_vm.showSendPaymentEntriesConfirmation.label) +
                    " ?\n        "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }