<template>
    <imagine-layout :inner="true" class="mandate" :title="title">
        <template v-slot:actions>
            <imagine-icon-button :disabled="true"
                                 class="mdc-top-app-bar__action-item"
                                 icon="post_add"
                                 title="Faire un avenant"
                                 v-if="activeTabId === 'infos' && mandate && !mandate.canBeModifiedWithoutAmendment"/>
        </template>

        <imagine-tab-bar :tabs="tabs"
                         v-if="mandate"
                         @tab="activeTabId = $event"/>

        <div v-if="activeTabId === 'infos'">
            <div class="imagine-container">
                <form @submit.prevent="save"
                      autocomplete="off"
                      class="imagine-form">

                    <imagine-alert type="info"
                                   v-if="!mandateId">
                        Avant de commencer à saisir un nouveau mandat, il est nécessaire de
                        créer ou de vérifier <a @click.prevent="redirectTo('propertyAdd')" href="#">le(s) bien(s)</a> et
                        <a href="#" @click.prevent="redirectTo('contactAdd')">le(s) propriétaire(s)</a>.
                    </imagine-alert>

                    <imagine-alert v-if="errors.global">{{ errors.global }}</imagine-alert>

                    <div class="imagine-form__row">
                        <imagine-date-picker :required="true"
                                             class="imagine-form__row__field"
                                             label="Date de signature"
                                             name="date"
                                             :disabled="isEditingDisabled"
                                             v-model="date"/>

                        <imagine-select :choices="salesRepChoices"
                                        class="imagine-form__row__field"
                                        label="Négociateur"
                                        name="salesrep"
                                        v-model="salesRep"/>
                    </div>

                    <div class="imagine-form__row imagine-form__row--limited">
                        <imagine-input :required="true"
                                       class="imagine-form__row__field"
                                       label="Numéro d'ordre"
                                       name="registryNumber"
                                       :violation="errors.registryNumber"
                                       v-model="registryNumber"/>

                        <imagine-date-picker :required="true"
                                             class="imagine-form__row__field"
                                             label="Date de début"
                                             name="startDate"
                                             :disabled="isEditingDisabled"
                                             :min-date="minMandateDate"
                                             v-model="startDate"/>

                        <imagine-date-picker :disabled="true"
                                             :value="legalEndDate"
                                             class="imagine-form__row__field"
                                             label="Date légale de fin"
                                             name="legalEndDate"/>
                    </div>

                    <template v-if="!isEditingDisabled">
                        <div class="imagine-form__row"
                             v-for="(ownerSlot, slot) in ownerSlots"
                             v-if="nbOwners >= slot">
                            <imagine-contact-autocomplete
                                :disabled="isEditingDisabled"
                                :filter="canContactBeOwner"
                                :label="slot > 0 ? 'Autre propriétaire' : ('Propriétaire' +  (nbOwners >= 1 ? ' principal (reçoit les virements)' : ''))"
                                :required="slot <= 0"
                                @input="removeOwnerSlotHoles()"
                                class="imagine-form__row__field"
                                name="owner"
                                v-model="ownerSlot.owner"/>
                        </div>
                    </template>
                    <div class="mandate__readonly"
                         v-else-if="mandate">
                        <div class="mdc-list mdc-list--two-line">
                            <div @click="redirectTo('contact', {id: owner.id})"
                                 class="mdc-list-item"
                                 v-for="(owner, index) in mandate.owners">
                                <span class="mdc-list-item__text">
                                    <span class="mdc-list-item__primary-text">
                                        Propriétaire : {{ owner.shortName }}
                                    </span>
                                    <span class="mdc-list-item__secondary-text">
                                        <span v-if="owner.email">{{ owner.email }}</span>
                                        <span v-if="owner.mobilePhone">{{ owner.mobilePhone.readable }}</span>
                                    </span>
                                </span>
                                <span class="mdc-list-item__meta">
                                    <span v-if="mandate.owners.length > 1 && index === 0">Principal (reçoit les virements)</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-input :violation="errors.bankAccount"
                                       class="imagine-form__row__field"
                                       help="Format IBAN : FR7620800121000001234567823"
                                       label="Numéro de compte bancaire (si différent du propriétaire)"
                                       name="iban"
                                       v-model="bankAccount"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-input :required="true"
                                       class="imagine-form__row__field"
                                       label="Honoraire H.T. (%)"
                                       :violation="errors.feesPercent"
                                       :disabled="isEditingDisabled"
                                       type="number"
                                       min="0"
                                       max="99.99"
                                       step="any"
                                       name="feesPercent"
                                       v-model="feesPercent"/>

                        <imagine-date-picker :disabled="isEditingDisabled"
                                             :min-date="date"
                                             class="imagine-form__row__field"
                                             label="Date d'effet honoraires"
                                             name="feesStartDate"
                                             v-model="feesStartDate"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-input :disabled="isEditingDisabled"
                                       class="imagine-form__row__field"
                                       label="Taux GLI (%)"
                                       min="0"
                                       name="unpaidGuaranteePercent"
                                       step="any"
                                       type="number"
                                       v-model="unpaidGuaranteePercent"/>
                    </div>

                    <div class="imagine-form__sep"></div>

                    <template v-if="!isEditingDisabled">
                        <div class="imagine-form__row"
                             v-for="(propertySlot, slot) in propertySlots"
                             v-if="nbProperties >= slot">
                            <imagine-property-autocomplete :filter="canPropertyReceiveMandate"
                                                           :label="slot > 0 ? 'Autre bien' : 'Bien'"
                                                           :required="slot <= 0"
                                                           :violation="slot <= 0 ? errors.properties : ''"
                                                           @input="removePropertySlotHoles()"
                                                           class="imagine-form__row__field"
                                                           name="property"
                                                           v-model="propertySlot.property"/>
                        </div>
                    </template>
                    <div class="mandate__readonly"
                         v-else-if="mandate">
                        <div class="mdc-list mdc-list--two-line">
                            <div @click="redirectTo('mandateProperty', {id: mandateProperty.id})"
                                 class="mdc-list-item"
                                 v-for="mandateProperty in mandate.mandateProperties">
                                <span class="mdc-list-item__text">
                                    <span class="mdc-list-item__primary-text">
                                        Bien : {{ mandateProperty.property.infos }}
                                    </span>
                                    <span class="mdc-list-item__secondary-text">
                                        Référence {{ mandateProperty.property.reference }}
                                    </span>
                                </span>
                                <span class="mdc-list-item__meta">
                                    {{
                                        mandateProperty.insuranceEndDate ? 'Fin assurance PNO le ' + formatDate(mandateProperty.insuranceEndDate) : 'Pas d\'assurance PNO'
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="imagine-form__sep"></div>

                    <div class="imagine-form__row">
                        <imagine-file-input :disabled="isEditingDisabled"
                                            class="imagine-form__row__field"
                                            label="Document"
                                            accept="application/pdf"
                                            name="doc"
                                            v-model="url"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-file-input :disabled="isEditingDisabled"
                                            accept="application/pdf"
                                            class="imagine-form__row__field"
                                            label="Liste mobilier"
                                            name="furnitureUrl"
                                            v-model="furnitureUrl"/>
                        <imagine-file-input :disabled="isEditingDisabled"
                                            accept="application/pdf"
                                            class="imagine-form__row__field"
                                            label="Conditions spéciales"
                                            name="specialUrl"
                                            v-model="specialUrl"/>
                    </div>

                    <div class="imagine-form__row">
                        <imagine-textarea class="imagine-form__row__field"
                                          label="Observations"
                                          name="comment"
                                          v-model="comment"/>
                    </div>

                    <div class="imagine-form__actions">
                        <imagine-button @click.prevent="back()">Annuler</imagine-button>
                        <imagine-button :primary="true"
                                        type="submit">
                            Sauvegarder
                        </imagine-button>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="mandate && activeTabId === 'account'">
            <account :account="mandate.account"
                     :mandate="mandate"
                     @refresh="refresh()"/>
        </div>

        <div v-if="mandate && activeTabId === 'summary'"
             class="mandate__summaries">
            <div class="imagine-container">
                <div class="mdc-list mdc-list--two-line mdc-list--non-interactive" v-if="mandate.summaries.length > 0">
                    <template v-for="summary in mandate.summaries">
                        <div class="mdc-list-item"
                             :class="{'mdc-list-item--disabled': summary.isEmpty}">
                            <span class="mdc-list-item__text">
                                <span class="mdc-list-item__primary-text">{{ summary.period.ucfLabel }}</span>
                                <span class="mdc-list-item__secondary-text">
                                    <template
                                        v-if="summary.lastSent">Envoyé le {{ formatDate(summary.lastSent) }}</template>
                                    <template v-else>Non envoyé</template>
                                </span>
                            </span>
                            <span class="mdc-list-item__meta">
                                <template v-if="!summary.isEmpty">
                                    <span v-if="summary.docUrl">
                                        <imagine-icon-button class="imagine-no-ripple"
                                                             @click="downloadSummary(summary.docUrl)"
                                                             icon="insert_drive_file"/>
                                        <md-tooltip md-direction="right">Télécharger</md-tooltip>
                                    </span>
                                    <span v-if="!summary.areFeesEntriesDone">
                                        <imagine-icon-button class="imagine-no-ripple"
                                                             @click="showSendFeesEntriesConfirmation = summary.period"
                                                             ficon="percent"/>
                                        <md-tooltip md-direction="right">Générer écritures honoraires</md-tooltip>
                                    </span>
                                    <span v-else-if="!summary.arePaymentEntriesDone">
                                        <imagine-icon-button class="imagine-no-ripple"
                                                             @click="showSendPaymentEntriesConfirmation = summary.period"
                                                             icon="account_balance"/>
                                        <md-tooltip md-direction="right">Générer écritures versement</md-tooltip>
                                    </span>
                                    <span v-else>
                                        <imagine-icon-button class="imagine-no-ripple"
                                                             @click="showSummaryMailConfirmation = summary.period"
                                                             icon="send"/>
                                        <md-tooltip md-direction="right">Envoyer par mail</md-tooltip>
                                    </span>
                                </template>
                                <template v-else>
                                    Aucune opération
                                </template>
                            </span>
                        </div>
                        <div class="mdc-list-divider"></div>
                    </template>
                </div>
                <div v-else
                     class="mandate__summaries__none">
                    Aucun relevé disponible.
                </div>
            </div>
        </div>

        <div v-if="mandate && activeTabId === 'tenants'">
            <div class="imagine-container">
                <div class="mandate-tenants">
                    <div v-if="hasLeases">
                        <template v-for="mandateProperty in mandate.mandateProperties">
                            <h3 class="mandate-tenants__title">{{ mandateProperty.property.infos }}</h3>

                            <div class="imagine-table-container">
                                <table class="imagine-table"
                                       v-if="mandateProperty.leases.length > 0">
                                    <thead>
                                    <tr class="imagine-table__row">
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                            Locataire(s)
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                                            Date d'entrée
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--date">
                                            Date de sortie
                                        </th>
                                        <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--rent">
                                            Loyer charges comprises
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :class="{'imagine-table__row--disabled': mandateProperty.isArchive}"
                                        @click="redirectTo('mandateProperty', {id: mandateProperty.id})"
                                        class="imagine-table__row mandate-tenants__tenant"
                                        v-for="lease in mandateProperty.leases">
                                        <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                            {{ lease.tenants.map(tenant => tenant.shortName).join(', ') }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                            {{ formatDate(lease.start) }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--date">
                                            {{ formatDate(lease.end) }}
                                        </td>
                                        <td class="imagine-table__row__cell imagine-table__row__cell--rent">
                                        <span>
                                            {{ formatMoney(lease.rent.fullRent) }}
                                            <md-tooltip md-direction="bottom" v-if="rentDetail(lease.rent)">
                                                {{ rentDetail(lease.rent) }}
                                            </md-tooltip>
                                        </span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="mandate-tenants__none"
                                     v-else>
                                    Aucun locataire pour ce bien.
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="mandate-tenants__none"
                         v-else>
                        Aucun bail.
                    </div>
                </div>
            </div>
        </div>

        <imagine-modal @act="sendMailSummary(showSummaryMailConfirmation)"
                       @close="showSummaryMailConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSummaryMailConfirmation">
            <p>
                Etes vous sûr de vouloir envoyer par email au propriétaire
                le relevé de {{ showSummaryMailConfirmation.label }} ?
            </p>
        </imagine-modal>

        <imagine-modal @act="sendFeesEntries(showSendFeesEntriesConfirmation)"
                       @close="showSendFeesEntriesConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSendFeesEntriesConfirmation">
            <p>
                Etes vous sûr de vouloir réaliser les écritures comptables
                d'honoraires pour {{ showSendFeesEntriesConfirmation.label }} ?
            </p>
        </imagine-modal>

        <imagine-modal @act="sendPaymentEntries(showSendPaymentEntriesConfirmation)"
                       @close="showSendPaymentEntriesConfirmation = false"
                       button-label="Envoyer"
                       v-if="showSendPaymentEntriesConfirmation">
            <p>
                Etes vous sûr de vouloir réaliser les écritures comptables
                de versement propriétaire pour {{ showSendPaymentEntriesConfirmation.label }} ?
            </p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineDatePicker from '../components/ImagineDatePicker.vue';
import ImagineTextarea from '../components/ImagineTextarea.vue';
import ImaginePropertyAutocomplete from '../components/ImaginePropertyAutocomplete.vue';
import ImagineContactAutocomplete from '../components/ImagineContactAutocomplete.vue';
import ImagineAlert from '../components/ImagineAlert.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import Account from './elements/mandate/Account.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import {mapState} from 'vuex';

const MAX_OWNER_PER_LEASE = 100;
const MAX_PROPERTIES_PER_MANDATE = 300;

const reserveSlots = (nbSlots) => {
    let slots = [];

    for (let i = 0; i < nbSlots; i++) {
        slots.push({property: null, owner: null});
    }

    return slots;
};

export default {
    components: {
        Account,
        ImagineFileInput,
        ImagineIconButton,
        ImagineContactAutocomplete,
        ImaginePropertyAutocomplete,
        ImagineLayout,
        ImagineInput,
        ImagineButton,
        ImagineDatePicker,
        ImagineAlert,
        ImagineTextarea,
        ImagineTabBar,
        ImagineModal,
        ImagineSelect
    },
    computed: {
        ...mapState('contact', {contacts: state => state.displayed}),
        ...mapState('employee', {employees: state => state.all}),
        ...mapState('property', {properties: state => state.displayed}),
        ...mapState('mandate', {registry: state => state.registry}),
        ...mapState(['references']),
        title() {
            if (this.mandateId) {
                if (this.registryNumber) {
                    return 'Mandat #' + this.registryNumber;
                }

                return 'Mandat';
            }

            if (this.registryNumber) {
                return 'Ajout mandat #' + this.registryNumber;
            }

            return 'Ajout mandat';
        },
        propertyIds() {
            return this.propertySlots.filter(propertySlot => propertySlot.property).map(propertySlot => propertySlot.property.id);
        },
        nbProperties() {
            return this.propertyIds.length;
        },
        ownerIds() {
            return this.ownerSlots.filter(ownerSlot => ownerSlot.owner).map(ownerSlot => ownerSlot.owner.id);
        },
        nbOwners() {
            return this.ownerIds.length;
        },
        tabs() {
            return [
                {id: 'infos', title: 'Infos', active: this.activeTabId === 'infos'},
                {
                    id: 'account',
                    title: 'Comptabilité',
                    active: this.activeTabId === 'account',
                    disabled: !this.mandateId
                },
                {
                    id: 'summary',
                    title: 'Relevés',
                    active: this.activeTabId === 'summary',
                    disabled: !this.mandateId
                },
                {
                    id: 'tenants',
                    title: 'Locataires',
                    active: this.activeTabId === 'tenants',
                    disabled: !this.mandateId
                }
            ];
        },
        hasLeases() {
            if (!this.mandate) {
                return false;
            }

            return this.mandate.mandateProperties.some(mandateProperty => mandateProperty.leases.length > 0);
        },
        legalEndDate() {
            if (!this.startDate) {
                return null;
            }

            return this.$container.types.cloneDate(this.startDate).add(10, 'years');
        },
        salesRepChoices() {
            return this.references.activeSalesRepChoices(this.employees);
        }
    },
    data() {
        return {
            activeTabId: 'infos',
            mandateId: null,
            mandate: null,
            date: this.$container.types.today(),
            registryNumber: null,
            minMandateDate: null,
            startDate: this.$container.types.today(),
            salesRep: null,
            owner: null,
            ownerSlots: reserveSlots(MAX_OWNER_PER_LEASE),
            feesPercent: null,
            feesStartDate: null,
            unpaidGuaranteePercent: null,
            comment: null,
            bankAccount: null,
            url: null,
            furnitureUrl: null,
            specialUrl: null,
            isEditingDisabled: false,
            errors: {},
            propertySlots: reserveSlots(MAX_PROPERTIES_PER_MANDATE),
            showSendFeesEntriesConfirmation: false,
            showSendPaymentEntriesConfirmation: false,
            showSummaryMailConfirmation: false
        }
    },
    created() {
        this.mandateId = this.$route.params.id;
        if (this.mandateId) {
            this.activeTabId = 'account';
        }

        this.load();
        Promise.all([
            this.$store.dispatch('employee/touch'),
            this.$store.dispatch('touchReferences'),
            this.$store.dispatch('contact/touch'),
            this.$store.dispatch('property/touch'),
            this.$store.dispatch('mandate/reloadRegistry')
        ])
            .then(() => {
                if (!this.mandateId) {
                    const forContactId = this.$route.query ? parseInt(this.$route.query.contactId) : null;

                    if (forContactId) {
                        this.owner = this.contacts.find(contact => contact.id === parseInt(forContactId));
                    }

                    const forPropertyId = this.$route.query ? parseInt(this.$route.query.propertyId) : null;

                    if (forPropertyId) {
                        this.property = this.properties.find(property => property.id === parseInt(forPropertyId));
                    }

                    this.registryNumber = this.registry.lastNumber + 1;
                    this.minMandateDate = this.registry.lastDate;
                    this.unload();
                } else {
                    this._reloadMandate();
                }
            })
            .catch(error => {
                this.serverError(error);
                this.unload();
            });
    },
    methods: {
        save() {
            this.errors = {};
            this.load();
            this.$store.dispatch('mandate/save', {
                id: this.mandateId,
                salesRep: this.salesRep,
                registryNumber: this.registryNumber,
                date: this.date,
                startDate: this.startDate,
                owners: this.ownerIds,
                propertyIds: this.propertyIds,
                feesPercent: this.feesPercent,
                feesStartDate: this.feesStartDate,
                unpaidGuaranteePercent: this.unpaidGuaranteePercent,
                comment: this.comment,
                bankAccount: this.bankAccount,
                url: this.url,
                furnitureUrl: this.furnitureUrl,
                specialUrl: this.specialUrl
            })
                .then(mandate => {
                    const isCreated = !this.mandateId;
                    this.mandateId = mandate.id;

                    if (isCreated) {
                        this.unload();

                        return this.redirectTo('mandates');
                    }

                    this._remapMandate(mandate);
                    this.unload();
                    this.success('Mandat sauvegardé.');
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        removePropertySlotHoles() {
            setTimeout(() => {
                const nbSlots = this.propertySlots.length;
                const nonEmptySlots = this.propertySlots.filter(propertySlot => propertySlot.property);
                this.propertySlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        removeOwnerSlotHoles() {
            setTimeout(() => {
                const nbSlots = this.ownerSlots.length;
                const nonEmptySlots = this.ownerSlots.filter(ownerSlot => ownerSlot.owner);
                this.ownerSlots = nonEmptySlots.concat(reserveSlots(nbSlots - nonEmptySlots.length));
            }, 50);
        },
        canPropertyReceiveMandate(property) {
            return !property.canBeNewBuildPurchased && !property.hasActiveMandates && !this.propertyIds.includes(property.id);
        },
        canContactBeOwner(contact) {
            return !this.ownerIds.includes(contact.id);
        },
        downloadSummary(url) {
            if (url) {
                this.externalRedirectTo(url);
            }
        },
        sendMailSummary(period) {
            this.load();
            this.$store.dispatch('mandate/sendSummary', {
                mandate: this.mandate.id,
                month: period.month,
                year: period.year
            })
                .then(() => {
                    this._reloadMandate()
                        .then(() => {
                            this.showSummaryMailConfirmation = false;
                            this.success('Relevé envoyé.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        sendFeesEntries(period) {
            this.load();
            this.$store.dispatch('mandate/sendFeesEntries', {
                mandate: this.mandate.id,
                month: period.month,
                year: period.year
            })
                .then(() => {
                    this._reloadMandate()
                        .then(() => {
                            this.showSendFeesEntriesConfirmation = false;
                            this.success('Ecritures effectuées.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        sendPaymentEntries(period) {
            this.load();
            this.$store.dispatch('mandate/sendPaymentEntries', {
                mandate: this.mandate.id,
                month: period.month,
                year: period.year
            })
                .then(() => {
                    this._reloadMandate()
                        .then(() => {
                            this.showSendPaymentEntriesConfirmation = false;
                            this.success('Ecritures effectuées.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        },
        refresh() {
            this.load();
            this._reloadMandate()
                .catch(this.serverError)
                .finally(this.unload);
        },
        _reloadMandate() {
            return this.$store.dispatch('mandate/one', this.mandateId)
                .then(mandate => this._remapMandate(mandate))
                .catch(this.serverError)
                .finally(this.unload);
        },
        _remapMandate(mandate) {
            this.isEditingDisabled = !mandate.canBeModifiedWithoutAmendment;

            this.mandate = mandate;
            this.salesRep = mandate.salesRepId;
            this.date = mandate.date;
            this.registryNumber = mandate.registryNumber;
            this.startDate = mandate.startDate;
            this.feesPercent = mandate.feesPercent;
            this.feesStartDate = mandate.feesStartDate;
            this.unpaidGuaranteePercent = mandate.unpaidGuaranteePercent;
            this.comment = mandate.comment;
            this.bankAccount = mandate.bankAccount;
            this.url = mandate.url;
            this.furnitureUrl = mandate.furnitureUrl;
            this.specialUrl = mandate.specialUrl;

            mandate.owners.forEach((owner, index) => {
                this.ownerSlots[index].owner = owner;
            });

            mandate.mandateProperties.forEach((mandateProperty, index) => {
                this.propertySlots[index].property = mandateProperty.property;
            });
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.mandate {
    &__readonly {
        flex: 1;
        font-size: 1rem;

        .mdc-list {
            padding: 0;
        }
    }

    &__summaries {
        &__none {
            padding: 2rem;
            text-align: center;
            font-size: 1.25rem;
        }
    }
}

.mandate-tenants {
    &__title {
        font-weight: normal;
        font-size: 1.2rem;
        margin: 2rem .5rem .5rem;
    }

    &__tenant {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
