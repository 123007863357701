var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "contractors",
      attrs: { title: "Prestataires" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: _vm.filter, icon: "filter_list" },
                on: {
                  click: function ($event) {
                    _vm.filter = !_vm.filter
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "add_box" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("contractorAdd")
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("transition", { attrs: { name: "slideY" } }, [
        _vm.filter
          ? _c(
              "div",
              { staticClass: "imagine-secondary-zone contractors__filters" },
              [
                _c(
                  "div",
                  { staticClass: "contractors__filters__filter" },
                  [
                    _c("imagine-select", {
                      attrs: {
                        choices: _vm.references.contractorCategoryChoices(),
                        label: "Catégorie",
                        name: "contractorcategoryfilter",
                      },
                      model: {
                        value: _vm.category,
                        callback: function ($$v) {
                          _vm.category = $$v
                        },
                        expression: "category",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "contractors__filters__filter" },
                  [
                    _c("imagine-input", {
                      attrs: {
                        clearable: true,
                        required: false,
                        label: "Entreprise",
                        name: "contractorcompany",
                      },
                      model: {
                        value: _vm.company,
                        callback: function ($$v) {
                          _vm.company = $$v
                        },
                        expression: "company",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.contractors.length > 0
        ? [
            _c("div", { staticClass: "contractors__count" }, [
              _c("div", { staticClass: "contractors__count__count" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.formatNumber(_vm.contractors.length))),
                ]),
                _vm._v(
                  "\n                prestataire" +
                    _vm._s(_vm.contractors.length > 1 ? "s" : "") +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.contractors.length > 1,
                      expression: "contractors.length > 1",
                    },
                  ],
                  staticClass: "contractor__count__sort",
                },
                [
                  _vm._v(
                    "\n                Triés par entreprise et nom\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mdc-list mdc-list--two-line" },
              [
                _vm._l(_vm.displayedContractors, function (contractor) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "mdc-list-item",
                        on: {
                          click: function ($event) {
                            return _vm.redirectTo("contractor", {
                              id: contractor.id,
                            })
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "mdc-list-item__text" }, [
                          _c(
                            "span",
                            { staticClass: "mdc-list-item__primary-text" },
                            [_vm._v(_vm._s(contractor.label))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "mdc-list-item__secondary-text" },
                            [
                              contractor.email
                                ? [_vm._v(_vm._s(contractor.email))]
                                : _vm._e(),
                              _vm._v(" "),
                              contractor.phone
                                ? [_vm._v(_vm._s(contractor.phone.readable))]
                                : contractor.mobile
                                ? [_vm._v(_vm._s(contractor.mobile.readable))]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mdc-list-item__meta" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.references.contractorCategoryLabel(
                                  contractor.category
                                )
                              ) +
                              "\n                    "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mdc-list-divider" }),
                  ]
                }),
              ],
              2
            ),
          ]
        : _c("div", { staticClass: "contractors__none" }, [
            _vm._v("\n        Aucun prestataire.\n    "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }