<template>
    <imagine-autocomplete :label="label"
                          :propositions="contactPropositions"
                          :required="required"
                          :create="!!redirectAfterCreate"
                          @create="gotoCreate()"
                          @first="proposeAllContacts"
                          @input="$emit('input', innerValue)"
                          @search="reloadPropositions($event)"
                          entity="contact"
                          :name="name"
                          :disabled="disabled"
                          :violation="violation"
                          help="Saisir nom et cliquer sur résultat"
                          v-model="innerValue"/>
</template>

<script>
import ImagineAutocomplete from './ImagineAutocomplete.vue';
import {mapState} from 'vuex';
import debounce from 'lodash-es/debounce';

export default {
    props: {
      value: null,
      label: null,
      redirectAfterCreate: null,
      name: null,
      required: null,
      violation: null,
      filter: null,
      disabled: null,
      nameOnly: {type: Boolean, default: false}
    },
    components: {ImagineAutocomplete},
    computed: {
        ...mapState('contact', {all: state => state.displayed ? state.displayed : []})
    },
    data() {
        return {
            search: null,
            contactPropositions: [],
            innerValue: this._contactToProposition(this.value, !this.nameOnly)
        }
    },
    created() {
          this.refreshContactPropositions = debounce(this._refreshContactPropositions, 200);
    },
    watch: {
        value(newVal) {
          this.innerValue = this._contactToProposition(newVal, !this.nameOnly);
        }
    },
    methods: {
        reloadPropositions(term) {
            this.term = term;
            this.refreshContactPropositions();
        },
        proposeAllContacts() {
            this.contactPropositions = this._contactsToPropositions(this._restrict(this.all));
        },
        gotoCreate() {
            this.$store.commit('contact/setRedirectAfterSave', this.redirectAfterCreate);
            this.redirectTo('contactAdd');
        },
        _refreshContactPropositions() {
            this.$store.dispatch('contact/query', this.term)
                .then(contacts => this.contactPropositions = this._contactsToPropositions(this._restrict(contacts)));
        },
        _contactsToPropositions(contacts) {
            if (!contacts) {
                return [];
            }

            return contacts.map(contact => this._contactToProposition(contact, true));
        },
        _contactToProposition(contact, full) {
            if (!contact) {
                return null;
            }

            const getContactName = contact => {
                if (contact.name) {
                    return contact.name;
                }

                if (contact.label) {
                    return contact.label;
                }

                let name = '';

                if (contact.lastname) {
                    name = contact.lastname;
                    if (contact.firstname) {
                        name += ', ';
                    }
                }

                if (contact.firstname) {
                    name += contact.firstname;
                }

                if (contact.company && (!name || full)) {
                    if (name) {
                        name += ' ';
                    }
                    name += contact.company;
                }

                return name;
            };

            const name = getContactName(contact);

            if (!name) {
                return null;
            }

            return {id: contact.id, label: name};
        },
        _restrict(contacts) {
            if (this.filter) {
                return contacts.filter(this.filter);
            }

            return contacts;
        }
    }
}
</script>
