var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "mdc-list-item",
      class: {
        "mdc-list-item--activated":
          _vm.$route.meta && _vm.$route.meta.menu === _vm.route,
      },
      attrs: { href: "#" },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.click.apply(null, arguments)
        },
      },
    },
    [
      _vm.icon
        ? _c("i", { staticClass: "material-icons mdc-list-item__graphic" }, [
            _vm._v(_vm._s(_vm.icon)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.ficon
        ? _c("i", { class: "mdc-list-item__graphic fas fa-" + _vm.ficon })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "mdc-list-item__text" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }