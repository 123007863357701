<template>
    <imagine-layout class="sales" title="Administration des ventes">
        <template v-slot:actions>
            <imagine-icon-button @click="exportSales()"
                                 class="mdc-top-app-bar__action-item"
                                 icon="get_app"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :active="mode === 'search'"
                                 @click="changeMode('search')"
                                 icon="search"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('list')"
                                 :active="mode === 'list'"
                                 icon="filter_list"/>
            <imagine-icon-button @click="redirectTo('salesStats')"
                                 class="mdc-top-app-bar__action-item"
                                 icon="insights"/>
        </template>

        <transition name="slideY">
            <div class="imagine-secondary-zone search__filters" v-if="mode === 'search'">
                <div class="search__filters__search">
                    <imagine-input :autocomplete="false"
                                   :clearable="true"
                                   :required="false"
                                   icon="search"
                                   label="Référence / mot clé"
                                   name="query"
                                   v-model="query"/>
                </div>
            </div>
        </transition>

        <transition name="slideY">
            <div class="imagine-secondary-zone sales__filters" v-if="mode !== 'search'">
                <div class="sales__filters__filter">
                    <imagine-select :choices="references.groupedBlockChoices(blocks, ['ongoing', 'completed'])"
                                    label="Résidence"
                                    name="block"
                                    v-model="block"/>
                </div>
                <div class="sales__filters__filter">
                    <imagine-select :choices="saleStatusChoices"
                                    :implicit-required="true"
                                    :multiple="true"
                                    label="Statuts"
                                    name="statuses"
                                    v-model="statuses"/>
                </div>
                <div class="sales__filters__filter sales__filters__filter--statuses">
                    <imagine-select :choices="saleStepsChoices"
                                    :implicit-required="true"
                                    :multiple="true"
                                    label="Etapes"
                                    name="steps"
                                    v-model="steps"/>
                </div>
                <div class="sales__filters__filter"
                     v-if="canFilterSalesRep()">
                    <imagine-select :choices="lastUpdateChoices"
                                    label="Dernière modification"
                                    v-model="lastUpdate"/>
                </div>
                <div class="sales__filters__filter"
                     v-if="canFilterSalesRep()">
                    <imagine-select :choices="references.salesRepChoices(employees)"
                                    label="Négociateur"
                                    v-model="salesRep"/>
                </div>
            </div>
        </transition>

        <div :class="{'sales__count--filtered': nbFiltered < nbTotal}"
             class="sales__count">
            <div class="sales__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">
                    {{ formatNumber(nbFiltered) }} /
                </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                vente{{ nbFiltered > 1 ? 's' : '' }}
            </div>
            <div v-show="nbFiltered <= 0">
                Aucune vente trouvé.
            </div>

            <div class="sales__count__sort"
                 v-show="nbFiltered > 1">
                Trié par résidence/lot
            </div>
        </div>

        <template v-if="displayed.length > 0">
            <div class="mdc-list mdc-list--two-line sales__list">
                <template v-for="sale in displayed">
                    <div @click="redirectTo('sale', {id: sale.id})"
                         class="mdc-list-item sales__list__item"
                         :class="{'sales__list__item--canceled': sale.isCanceled}">
                        <span class="mdc-list-item__graphic">
                            <sale-status :sale="sale"/>
                        </span>
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text sales__list__item__title">
                                {{ sale.property }}
                                {{ sale.taxExemption ? '[défisc]'  : '' }}
                            </span>
                            <span class="mdc-list-item__secondary-text sales__list__item__owner">
                                <template v-if="sale.buyers.length > 0">
                                    {{ sale.buyers.join(', ') }}
                                    <template v-if="sale.salesRep">
                                        ({{ sale.salesRep }} {{ sale.otherSalesRep }})
                                    </template>
                                </template>
                                <template v-else>Pas d'acquéreur(s)</template>
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">{{ stepLabel(sale.step) }}</span>
                    </div>
                    <div class="mdc-list-divider"></div>
                </template>
            </div>

            <div @click="displayMore()"
                 class="sales__see-more"
                 v-show="isDisplayLimited">
                <imagine-button>Voir plus de biens</imagine-button>
            </div>
        </template>
        <div class="sales__none"
             v-else>
            Aucune vente avec ces critères.
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineButton from '../components/ImagineButton.vue';
import SaleStatus from './elements/SaleStatus.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import {mapGetters, mapState} from 'vuex';
import debounce from "lodash-es/debounce";

export default {
    components: {ImagineInput, ImagineIconButton, SaleStatus, ImagineLayout, ImagineSelect, ImagineButton},
    computed: {
        ...mapState(['references']),
        ...mapState('block', {blocks: state => state.all}),
        ...mapState('sale', {
            displayed: state => state.displayed,
            nbFiltered: state => state.nbFiltered,
            nbTotal: state => state.nbTotal,
            maxDisplayed: state => state.maxDisplayed,
            mode: state => state.mode
        }),
        ...mapGetters('sale', ['filters']),
        ...mapState('employee', {employees: state => state.all}),
        saleStatusChoices() {
            return this.references.saleStatusChoices();
        },
        saleStepsChoices() {
            return this.references.saleStepsChoices();
        },
        lastUpdateChoices() {
            return [
                {value: 'less1week', label: 'Il y a moins d\'1 semaine'},
                {value: '1weekTo1Month', label: 'De 1 semaine à 1 mois'},
                {value: 'more1month', label: 'Il y a plus d\'un mois'}
            ];
        },
        isDisplayLimited() {
            if (this.displayed.length <= 0) {
                return false;
            }

            return this.displayed.length < this.nbFiltered;
        },
        query: {
            get() {
                return this.$store.state.sale.query;
            },
            set(value) {
                this.$store.commit('sale/setQuery', value);
            }
        },
        block: {
            get() {
                return this.$store.state.sale.block;
            },
            set(value) {
                this.$store.commit('sale/setBlock', value);
            }
        },
        salesRep: {
            get() {
                return this.$store.state.sale.salesRep;
            },
            set(value) {
                this.$store.commit('sale/setSalesRep', value);
            }
        },
        steps: {
            get() {
                return this.$store.state.sale.steps;
            },
            set(value) {
                this.$store.commit('sale/setSteps', value);
            }
        },
        statuses: {
            get() {
                return this.$store.state.sale.statuses;
            },
            set(value) {
                this.$store.commit('sale/setStatuses', value);
            }
        },
        lastUpdate: {
            get() {
                return this.$store.state.sale.lastUpdate;
            },
            set(value) {
                this.$store.commit('sale/setLastUpdate', value);
            }
        }
    },
    data() {
        return {
            search: null
        };
    },
    created() {
        this.search = debounce(this._search, 200);

        this.load('Chargement des ventes');

        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    watch: {
        query() {
            this.search();
        },
        block() {
            this._search();
        },
        salesRep() {
            this._search();
        },
        statuses() {
            this._search();
        },
        steps() {
            this._search();
        },
        lastUpdate() {
            this._search();
        }
    },
    methods: {
        changeMode(newMode) {
            this.$store.commit('sale/changeMode', newMode);
        },
        displayMore() {
            this.load();
            this.$store.dispatch('sale/displayMore')
                .catch(this.serverError)
                .finally(this.unload);
        },
        stepLabel(step) {
            return this.references.stepLabel(step);
        },
        exportSales() {
            window.location.href = '/api/sale/export?' + this.toQueryString(this.filters);
        },
        _search() {
            this.load();
            this.$store.dispatch('sale/reload')
                .catch(this.serverError)
                .finally(this.unload)
        },
        _getDependencies() {
            let dependencies = [
                this.$store.dispatch('sale/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];

            dependencies.push(
                this.$store.dispatch('user/touch')
                    .then(() => {
                        if (this.canFilterSalesRep()) {
                            this.$store.dispatch('employee/touch');
                        }
                    })
            );

            return dependencies;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.sales {
    &__filters {
        padding: 8px 8px 2px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }

            &--statuses {
                .md-menu.md-select {
                    flex: unset;
                    width: 90%;

                    .md-input {
                        font-size: .9rem;
                    }
                }
            }
        }
    }

    .imagine-status {
        font-size: 1.2rem;
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.sales__count--filtered {
            .sales__count__count {
                font-weight: 500;
            }
        }
    }

    &__list {
        &__item {
            &--canceled {
                .sales__list__item__title, .sales__list__item__owner {
                    text-decoration: line-through;
                }
            }
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
