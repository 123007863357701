var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-field",
    {
      staticClass: "imagine-select",
      class: {
        "md-invalid": _vm.invalid || _vm.violation,
        "md-required": _vm.required || _vm.externalRequired,
      },
      staticStyle: { position: "relative" },
    },
    [
      _c("label", { attrs: { for: _vm.name } }, [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
        _vm.multiple &&
        !_vm.noSelectAll &&
        _vm.innerValue &&
        _vm.innerValue.length < _vm.choices.length &&
        _vm.innerValue.length > 0
          ? _c("span", [
              _vm._v("\n            -\n            "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectAll.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Tout sélectionner")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.multiple &&
        !_vm.noSelectAll &&
        _vm.innerValue &&
        _vm.innerValue.length === _vm.choices.length &&
        _vm.innerValue.length > 0
          ? _c("span", [
              _vm._v("\n            -\n            "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.unselectAll.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Tout désélectionner")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.multiple &&
      !_vm.noSelectAll &&
      _vm.innerValue &&
      _vm.innerValue.length < _vm.choices.length &&
      _vm.innerValue.length === 0
        ? _c(
            "span",
            { staticStyle: { top: "0", right: "5px", position: "absolute" } },
            [
              _vm._v("\n        -\n        "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.selectAll.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Tout sélectionner")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.choices && _vm.choices.length
        ? _c(
            "md-select",
            {
              attrs: {
                name: _vm.name,
                id: _vm.name,
                disabled: _vm.disabled,
                multiple: _vm.multiple,
                "md-dense": "",
              },
              on: {
                "md-selected": function ($event) {
                  return _vm.select($event)
                },
              },
              model: {
                value: _vm.innerValue,
                callback: function ($$v) {
                  _vm.innerValue = $$v
                },
                expression: "innerValue",
              },
            },
            [
              !(_vm.required || _vm.implicitRequired)
                ? _c("md-option", {
                    key: "choice-" + _vm.name + "-empty",
                    attrs: { value: "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.choices, function (choice) {
                return [
                  choice.choices && choice.choices.length
                    ? [
                        _c(
                          "md-optgroup",
                          { attrs: { label: choice.title } },
                          _vm._l(choice.choices, function (subChoice) {
                            return _c(
                              "md-option",
                              {
                                key: "choice-" + subChoice.value,
                                attrs: {
                                  value: subChoice.value,
                                  disabled: subChoice.disabled,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(subChoice.label) +
                                    "\n                    "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ]
                    : _c(
                        "md-option",
                        {
                          key:
                            "choice-" +
                            (choice.value ? choice.value : _vm.randomId()),
                          attrs: {
                            value: choice.value,
                            disabled: choice.disabled,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(choice.label) +
                              "\n            "
                          ),
                        ]
                      ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.help
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v("\n        " + _vm._s(_vm.help) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.violation
        ? _c("span", { staticClass: "md-error" }, [
            _vm._v("\n        " + _vm._s(_vm.violation) + "\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }