<template>
    <button @click.prevent="$emit('click')"
            :class="classes"
            :disabled="disabled"
            class="mdc-icon-button imagine-icon-button">
        <template v-if="ficon">
            <i :class="'fas fa-' + ficon"></i>
        </template>
        <template v-else>{{ icon ? icon : 'svg' }}</template>
    </button>
</template>

<script>
import {MDCRipple} from '@material/ripple/index';

export default {
    props: {icon: String, svg: String, active: Boolean, disabled: Boolean, ficon: String},
    computed: {
        classes() {
            let classes = [];

            if (this.active) {
                classes.push('imagine-icon-button--active');
            }

            if (this.icon) {
                classes.push('material-icons');
            }

            if (this.svg) {
                classes.push('imagine-icons');
                classes.push('material-icons');
                classes.push('imagine-icons--' + this.svg);
            }

            return classes;
        }
    },
    data() {
        return {
            _ripple: null
        }
    },
    mounted() {
        const hasAlreadyRippleEffect =
            () => this.$el.classList
                && (this.$el.classList.contains('mdc-top-app-bar__action-item')
                    || this.$el.classList.contains('mdc-top-app-bar__navigation-icon')
                    || this.$el.classList.contains('imagine-no-ripple'));

        if (!hasAlreadyRippleEffect()) {
            this._ripple = new MDCRipple(this.$el);
        }
    },
    beforeDestroy() {
        if (this._ripple) {
            this._ripple.destroy()
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/icon-button/mdc-icon-button';

.imagine-icon-button.imagine-icon-button--active {
    &:after {
        background-color: #ffffff !important;
        opacity: .24 !important;
    }
}
</style>
