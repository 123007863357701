<template>
    <imagine-layout :title="$mq === 'phone' ? 'Synthèse' : 'Synthèse gestion locative'" class="mandates-report">
        <template v-slot:actions>
            <mandate-external-services/>
        </template>

        <div class="imagine-secondary-zone blocks__filter"
             v-if="periods.length > 0">
            <imagine-select-period :periods="periods"
                                   @input="reloadPeriodReports()"
                                   class="imagine-form__row__field"
                                   v-model="period"/>
        </div>

        <imagine-tab-bar :tabs="tabs"
                         @tab="activeTabId = $event"/>

        <div v-if="activeTabId === 'rents'">
            <div class="imagine-container">
                <div class="imagine-table-container">
                    <table class="imagine-table"
                           v-if="rents.length > 0">
                        <thead>
                        <tr class="imagine-table__row">
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Propriétaire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Bien
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Locataire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Loyer hors charges
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Provision charges
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Provision taxe d'eau
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Provision taxe d'ordures ménagères
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Loyer charges comprises
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                            class="imagine-table__row mandates-report__line"
                            v-for="report in rents">
                            <td class="imagine-table__row__cell">
                                {{ report.leaseInfos.owners.map(owner => owner.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.property.infos }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.tenants.map(tenant => tenant.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rent.rent) }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rent.charges) }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rent.water) }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rent.waste) }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rent.fullRent) }}
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="imagine-table__row">
                            <th colspan="3">TOTAL</th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalRent) }}
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalCharges) }}
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalWater) }}
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalWaste) }}
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalFullRent) }}
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                    <div class="mandates-report__none"
                         v-else>
                        Aucun loyer appelé.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTabId === 'guarantees'">
            <div class="imagine-container">
                <div class="imagine-table-container">
                    <table class="imagine-table"
                           v-if="guarantees.length > 0">
                        <thead>
                        <tr class="imagine-table__row">
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Propriétaire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Bien
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Locataire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Dépôt de garantie
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                            class="imagine-table__row mandates-report__line"
                            v-for="report in guarantees">
                            <td class="imagine-table__row__cell">
                                {{ report.leaseInfos.owners.map(owner => owner.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.property.infos }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.tenants.map(tenant => tenant.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.guarantee) }}
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="imagine-table__row">
                            <th colspan="3">TOTAL</th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalGuarantee) }}
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                    <div class="mandates-report__none"
                         v-else>
                        Aucun dépôt de garantie appelé.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTabId === 'rental_fees'">
            <div class="imagine-container">
                <div class="imagine-table-container">
                    <table class="imagine-table"
                           v-if="rentalFees.length > 0">
                        <thead>
                        <tr class="imagine-table__row">
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Propriétaire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Bien
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Locataire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Honoraires de mise en location
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                            class="imagine-table__row mandates-report__line"
                            v-for="report in rentalFees">
                            <td class="imagine-table__row__cell">
                                {{ report.leaseInfos.owners.map(owner => owner.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.property.infos }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.tenants.map(tenant => tenant.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.rentalFees) }}
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="imagine-table__row">
                            <th colspan="3">TOTAL</th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalRentalFees) }}
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                    <div class="mandates-report__none"
                         v-else>
                        Aucun honoraire de mise en location appelé.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTabId === 'unpaid'">
            <div class="imagine-container">
                <div class="imagine-table-container">
                    <table class="imagine-table"
                           v-if="unpaid.length > 0">
                        <thead>
                        <tr class="imagine-table__row">
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Propriétaire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Bien
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Locataire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Garant(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--money">
                                Somme des impayés
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Denière(s) relance(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                &nbsp;
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="imagine-table__row mandates-report__line"
                            v-for="report in unpaid">
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell">
                                {{ report.leaseInfos.owners.map(owner => owner.shortName).join(', ') }}
                            </td>
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.property.infos }}
                            </td>
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.tenants.map(tenant => tenant.shortName).join(', ') }}
                            </td>
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.leaseInfos.protectors.map(protector => protector.shortName).join(', ') }}
                            </td>
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell imagine-table__row__cell--money">
                                <span>
                                    {{ formatMoney(report.balance) }}
                                    <md-tooltip md-direction="right">
                                        <table>
                                            <tr v-for="entry in report.entries">
                                                <td>{{ formatDate(entry.date) }}</td>
                                                <td>{{ entry.label }}</td>
                                                <td><span
                                                    v-if="entry.debit">- {{ formatMoney(entry.debit) }}</span></td>
                                                <td><span
                                                    v-if="entry.credit">+ {{ formatMoney(entry.credit) }}</span></td>
                                            </tr>
                                        </table>
                                    </md-tooltip>
                                </span>
                            </td>
                            <td @click="redirectTo('mandateProperty', {id: report.leaseInfos.mandatePropertyId})"
                                class="imagine-table__row__cell">
                                <template v-if="report.lastReminderEvents && report.lastReminderEvents.length > 0">
                                    <span v-if="report.lastReminderEvents.length === 1">
                                        {{ formatDate(report.lastReminderEvents[0].date) }}
                                        [{{ reminderType(report.lastReminderEvents[0].type) }}]
                                    </span>
                                    <ul v-else>
                                        <li v-for="event in report.lastReminderEvents">
                                            {{ formatDate(event.date) }} [{{ reminderType(event.type) }}]
                                        </li>
                                    </ul>
                                </template>
                            </td>
                            <td class="imagine-table__row__cell">
                                <imagine-icon-button @click="showReminderModalFor(report.leaseInfos)"
                                                     class="imagine-no-ripple"
                                                     icon="send"/>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="imagine-table__row">
                            <th colspan="4">TOTAL</th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalUnpaid) }}
                            </th>
                            <th colspan="2"></th>
                        </tr>
                        </tfoot>
                    </table>
                    <div class="mandates-report__none"
                         v-else>
                        Aucun impayé.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTabId === 'repays'">
            <div class="imagine-container">
                <div class="imagine-table-container">
                    <table class="imagine-table"
                           v-if="repays.length > 0">
                        <thead>
                        <tr class="imagine-table__row">
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                N° mandat
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header">
                                Propriétaire(s)
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Date versement
                            </th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--header imagine-table__row__cell--text">
                                Montant versé
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr @click="redirectTo('mandate', {id: report.mandateInfos.id})"
                            class="imagine-table__row mandates-report__line"
                            v-for="report in repays">
                            <td class="imagine-table__row__cell">
                                {{ report.mandateInfos.registryNumber }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ report.mandateInfos.owners.map(owner => owner.shortName).join(', ') }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--text">
                                {{ formatDate(report.repayDate) }}
                            </td>
                            <td class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(report.repayAmount) }}
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="imagine-table__row">
                            <th colspan="3">TOTAL</th>
                            <th class="imagine-table__row__cell imagine-table__row__cell--money">
                                {{ formatMoney(totalRepays) }}
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                    <div class="mandates-report__none"
                         v-else>
                        Aucun versement propriétaire.
                    </div>
                </div>
            </div>
        </div>

        <send-reminder :lease="showReminderModal"
                       @canceled="hideReminder()"
                       @completed="reminderSent()"
                       v-if="showReminderModal"/>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineTabBar from '../components/ImagineTabBar.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import MandateExternalServices from './elements/mandate/MandateExternalServices.vue';
import ImagineSelectPeriod from '../components/ImagineSelectPeriod.vue';
import SendReminder from './elements/mandate/SendReminder.vue';
import {mapState} from 'vuex';

export default {
    components: {
        SendReminder,
        ImagineSelectPeriod,
        MandateExternalServices, ImagineIconButton, ImagineLayout, ImagineTabBar, ImagineSelect
    },
    computed: {
        ...mapState('mandate', {periodReports: state => state.periodReports, periods: state => state.periods}),
        rents() {
            if (!this.periodReports || this.periodReports.length <= 0) {
                return [];
            }

            return this.periodReports.rents;
        },
        unpaid() {
            if (!this.periodReports || this.periodReports.length <= 0) {
                return [];
            }

            return this.periodReports.unpaid;
        },
        rentalFees() {
            if (!this.periodReports || this.periodReports.length <= 0) {
                return [];
            }

            return this.periodReports.rentalFees;
        },
        guarantees() {
            if (!this.periodReports || this.periodReports.length <= 0) {
                return [];
            }

            return this.periodReports.guarantees;
        },
        repays() {
            if (!this.periodReports || this.periodReports.length <= 0) {
                return [];
            }

            return this.periodReports.repays;
        },
        tabs() {
            return [
                {
                    id: 'rents',
                    title: 'Loyers appelés',
                    active: this.activeTabId === 'rents',
                    disabled: this.rents.length <= 0
                },
                {
                    id: 'guarantees',
                    title: 'D.G. appelés',
                    active: this.activeTabId === 'guarantees',
                    disabled: this.guarantees.length <= 0
                },
                {
                    id: 'rental_fees',
                    title: 'H.M.L. appelés',
                    active: this.activeTabId === 'rental_fees',
                    disabled: this.rentalFees.length <= 0
                },
                {
                    id: 'unpaid',
                    title: 'Impayés',
                    active: this.activeTabId === 'unpaid',
                    disabled: this.unpaid.length <= 0
                },
                {
                    id: 'repays',
                    title: 'Versements prop.',
                    active: this.activeTabId === 'repays',
                    disabled: this.repays.length <= 0
                }
            ];
        },
        totalRent() {
            return this._total(this.periodReports.rents, report => report.rent.rent);
        },
        totalCharges() {
            return this._total(this.periodReports.rents, report => report.rent.charges);
        },
        totalWater() {
            return this._total(this.periodReports.rents, report => report.rent.water);
        },
        totalWaste() {
            return this._total(this.periodReports.rents, report => report.rent.waste);
        },
        totalFullRent() {
            return this._total(this.periodReports.rents, report => report.rent.fullRent);
        },
        totalGuarantee() {
            return this._total(this.periodReports.guarantees, report => report.guarantee);
        },
        totalRentalFees() {
            return this._total(this.periodReports.rentalFees, report => report.rentalFees);
        },
        totalRepays() {
            return this._total(this.periodReports.repays, report => report.repayAmount);
        },
        totalUnpaid() {
            return this._total(this.periodReports.unpaid, report => report.balance);
        }
    },
    data() {
        return {
            activeTabId: 'rents',
            period: null,
            showReminderModal: false
        }
    },
    created() {
        this.$store.dispatch('mandate/reloadPeriods')
            .then(() => {
                this.period = this.periods[this.periods.length - 1];
                this.reloadPeriodReports();
            })
            .catch(this.serverError);
    },
    methods: {
        reloadPeriodReports() {
            if (!this.period) {
                return;
            }
            this.load('Chargement synthèse');
            this.$store.dispatch('mandate/periodReports', {
                month: this.period.month,
                year: this.period.year
            })
                .catch(this.serverError)
                .finally(this.unload);
        },
        showReminderModalFor(lease) {
            this.showReminderModal = lease;
        },
        reminderSent() {
            this.hideReminder();
            this.reloadPeriodReports();
        },
        hideReminder() {
            this.showReminderModal = false;
        },
        reminderType(type) {
            if (type === 'rent_R1') {
                return 'Rel.1';
            }

            if (type === 'rent_R2') {
                return 'Rel.2';
            }

            if (type === 'rent_RP') {
                return 'Garants';
            }

            if (type === 'rent_RL') {
                return 'M.D.';
            }

            return 'N.A.';
        },
        _total(reports, extract) {
            let total = this.$container.types.makeMoney(0);

            if (reports.length <= 0) {
                return total;
            }

            reports.forEach(report => {
                const amount = extract(report);
                if (amount) {
                    total = total.add(amount);
                }
            });

            return total;
        }
    }
}
</script>

<style lang="scss">
.mandates-report {
    &__line {
        cursor: pointer;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }

    .imagine-table__row__cell--money {
        text-align: right;
    }
}
</style>
