<template>
    <imagine-modal :button-disabled="!period"
                   @act="chargeRent()"
                   @close="$emit('canceled')"
                   button-label="Enregistrer"
                   class="imagine-modal--wide"
                   title="Appel de loyer">
        <p>
            <imagine-select-period :periods="lease.rentPeriodsToCharge"
                                   class="imagine-form__row__field"
                                   v-model="period"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineSelectPeriod from '../../../components/ImagineSelectPeriod.vue';

export default {
    props: ['lease'],
    components: {ImagineSelectPeriod, ImagineModal},
    data() {
        return {
            period: this.defaultPeriod()
        }
    },
    methods: {
        chargeRent() {
            this.load();
            this.$store.dispatch('accountEntry/save', {
                lease: this.lease.id,
                type: 'charge_rent',
                month: this.period ? this.period.month : null,
                year: this.period ? this.period.year : null
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        },
        defaultPeriod() {
            if (this.lease.rentPeriodsToCharge.length > 0) {
                return this.lease.rentPeriodsToCharge[0];
            }

            return null;
        }
    }
}
</script>
