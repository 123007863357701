class BlockRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all() {
        return this._http.get('/api/block');
    }

    one(id) {
        return this._http.get('/api/block/' + id)
            .then(block => this._makeBlock(block));
    }

    save(data) {
        return this._http.post('/api/block' + (data.id ? '/' + data.id : ''), data)
            .then(block => this._makeBlock(block));
    }

    remove(id) {
        return this._http.delete('/api/block/' + id);
    }

    createStage(blockId, data) {
        return this._http.post('/api/block/' + blockId + '/stage', data);
    }

    updateStage(blockId, stageId, data) {
        return this._http.post('/api/block/' + blockId + '/stage/' + stageId, data);
    }

    removeStage(blockId, stageId) {
        return this._http.delete('/api/block/' + blockId + '/stage/' + stageId);
    }

    _makeBlock(block) {
        block.deliveryDate = this._types.stringToDate(block.deliveryDate);
        block.divisions = block.divisions.map(division => this._makeDivision(division));
        block.stages = block.stages.map(stage => this._makeStage(stage));

        return block;
    }

    _makeDivision(division) {
        division.price = this._types.stringToMoney(division.price);

        return division;
    }

    _makeStage(stage) {
        stage.estimatedDate = this._types.stringToDate(stage.estimatedDate);
        stage.actualDate = this._types.stringToDate(stage.actualDate);

        return stage;
    }
}

export default BlockRepository;
