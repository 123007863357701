var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "block-division-status" },
    [
      _c(
        "span",
        {
          staticClass: "material-icons imagine-status",
          class: {
            "imagine-status--available": _vm.division && !_vm.division.buyers,
            "imagine-status--option":
              _vm.division && _vm.division.buyers && _vm.division.isOption,
            "imagine-status--booked":
              _vm.division && _vm.division.buyers && !_vm.division.isOption,
          },
        },
        [_vm._v("\n        lens\n    ")]
      ),
      _vm._v(" "),
      _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
        _vm._v(_vm._s(_vm.statusLabel)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }