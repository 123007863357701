var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "mailing",
      attrs: { title: "Nouveau publipostage" },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { icon: "email" },
                on: {
                  click: function ($event) {
                    return _vm.redirectTo("mailings")
                  },
                },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: true, icon: "add_box" },
              }),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { active: false, svg: "brevo" },
                on: {
                  click: function ($event) {
                    return _vm.externalRedirectTo(
                      "https://account-app.brevo.com/account/login"
                    )
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("contacts-list", {
        attrs: { mode: "filters" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("p", { staticClass: "mailing__disclaimer" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.disclaimer) +
                      "\n                "
                  ),
                  _vm.quota && _vm.quota.credits !== -1
                    ? _c("span", [
                        _vm._v(
                          "Quota en cours : " +
                            _vm._s(_vm.quota.credits) +
                            " " +
                            _vm._s(_vm.quota.type) +
                            "."
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mailing__header" }, [
                  _c(
                    "div",
                    { staticClass: "mailing__header__select" },
                    [
                      _c(
                        "md-menu",
                        {
                          attrs: {
                            "md-align-trigger": "",
                            "md-size": "medium",
                          },
                        },
                        [
                          _c(
                            "md-button",
                            { attrs: { "md-menu-trigger": "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mailing__header__select__menu",
                                },
                                [
                                  _c("imagine-checkbox", {
                                    attrs: {
                                      disabled: true,
                                      "indeterminate-support": 1,
                                      indeterminate:
                                        _vm.nbChecked > 0 && !_vm.allSelected,
                                      value: _vm.allSelected,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          height: "24",
                                          viewBox: "0 0 24 24",
                                          width: "24",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: { d: "M7 10l5 5 5-5z" },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            d: "M0 0h24v24H0z",
                                            fill: "none",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "md-menu-content",
                            [
                              _c(
                                "md-menu-item",
                                { on: { click: _vm.checkAll } },
                                [_vm._v("Tous")]
                              ),
                              _vm._v(" "),
                              _vm.isFiltering
                                ? _c(
                                    "md-menu-item",
                                    { on: { click: _vm.checkFiltered } },
                                    [
                                      _vm._v(
                                        "\n                                Résultats de recherche\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "md-menu-item",
                                { on: { click: _vm.checkNone } },
                                [_vm._v("Aucun")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "publishing__header__select__count" },
                        [
                          _vm.nbChecked <= 0
                            ? _c("span", [_vm._v("Aucun contact sélectionné")])
                            : _vm.nbChecked === 1
                            ? _c("span", [_vm._v("1 contact sélectionné")])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(_vm.nbChecked) +
                                    " contacts sélectionnés"
                                ),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "imagine-button",
                        {
                          attrs: {
                            disabled: _vm.nbChecked === 0,
                            primary: true,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showConfirmationModal = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Paramétrer publipostage\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ contact }) {
              return [
                _c("mailing-contact", {
                  attrs: {
                    contact: contact,
                    "exportable-desc": _vm.disclaimer,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.showConfirmationModal
        ? _c(
            "imagine-modal",
            {
              staticClass: "mailing__email",
              attrs: {
                "button-disabled": !_vm.isEmailSendable,
                "button-label": "Créer publipostage (SANS envoi)",
              },
              on: {
                act: function ($event) {
                  return _vm.create()
                },
                close: function ($event) {
                  _vm.showRecipients = false
                  _vm.showConfirmationModal = false
                },
              },
            },
            [
              _c(
                "p",
                [
                  _vm._v(
                    "\n            A :\n            " +
                      _vm._s(_vm.nbChecked) +
                      " destinataires\n            "
                  ),
                  _vm.nbChecked < _vm.maxPreviewable
                    ? [
                        _vm._v("\n                -\n                "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleRecipients()
                              },
                            },
                          },
                          [
                            _vm.showRecipients
                              ? _c("span", [_vm._v("Masquer")])
                              : _c("span", [_vm._v("Voir")]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "slideY" } }, [
                _vm.nbChecked < 200 &&
                _vm.showRecipients &&
                _vm.checkedContacts.length
                  ? _c(
                      "ul",
                      _vm._l(_vm.checkedContacts, function (checkedContact) {
                        return _c("li", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(checkedContact) +
                              "\n                "
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("imagine-input", {
                    attrs: { required: true, label: "Objet", name: "subject" },
                    model: {
                      value: _vm.subject,
                      callback: function ($$v) {
                        _vm.subject = $$v
                      },
                      expression: "subject",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.emailTemplateChoices.length > 1
                ? _c(
                    "p",
                    [
                      _c("imagine-select", {
                        attrs: {
                          choices: _vm.emailTemplateChoices,
                          required: true,
                          label: "Choisir un template",
                          name: "emailTemplate",
                        },
                        model: {
                          value: _vm.template,
                          callback: function ($$v) {
                            _vm.template = $$v
                          },
                          expression: "template",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.template === "custom"
                ? _c(
                    "p",
                    { staticClass: "mailing__email__body" },
                    [
                      _c("imagine-textarea", {
                        attrs: {
                          required: true,
                          label: "Message",
                          name: "message",
                        },
                        model: {
                          value: _vm.body,
                          callback: function ($$v) {
                            _vm.body = $$v
                          },
                          expression: "body",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.template === "custom"
                ? _c(
                    "p",
                    [
                      _c("imagine-file-input", {
                        attrs: {
                          label: "Pièce jointe",
                          accept: "application/pdf",
                          maxSizeM: 2,
                          options: { public: true },
                          name: "attachmentUrl",
                          violation: _vm.errors.attachmentUrl,
                        },
                        on: {
                          invalid: function ($event) {
                            _vm.fileWanted = true
                            _vm.fileValid = false
                          },
                          valid: function ($event) {
                            _vm.fileValid = true
                          },
                        },
                        model: {
                          value: _vm.attachmentUrl,
                          callback: function ($$v) {
                            _vm.attachmentUrl = $$v
                          },
                          expression: "attachmentUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.template === "custom"
                ? _c(
                    "p",
                    [
                      _c("imagine-input", {
                        attrs: {
                          label: "Nom pièce jointe",
                          name: "attachmentName",
                          violation: _vm.errors.attachmentName,
                        },
                        model: {
                          value: _vm.attachmentName,
                          callback: function ($$v) {
                            _vm.attachmentName = $$v
                          },
                          expression: "attachmentName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }