var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-modal",
    {
      staticClass: "imagine-modal--wide",
      attrs: {
        "button-disabled": !_vm.date,
        title: "Assurance " + (_vm.lease ? "habitation" : "PNO"),
        "button-label": "Enregistrer",
      },
      on: {
        act: function ($event) {
          return _vm.saveInsurance()
        },
        close: function ($event) {
          return _vm.$emit("canceled")
        },
      },
    },
    [
      _c(
        "p",
        [
          _c("imagine-date-picker", {
            staticClass: "imagine-form__row__field",
            attrs: {
              "min-date": _vm.lease
                ? _vm.lease.start
                : _vm.mandateProperty.startDate,
              label: "Date de fin assurance",
              name: "insuranceEndDate",
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("imagine-file-input", {
            staticClass: "imagine-form__row__field",
            attrs: {
              accept: "application/pdf",
              label: "Document",
              name: "insuranceUrl",
            },
            model: {
              value: _vm.url,
              callback: function ($$v) {
                _vm.url = $$v
              },
              expression: "url",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }