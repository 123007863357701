<template>
    <div class="mdc-tab-bar">
        <div class="mdc-tab-scroller">
            <div class="mdc-tab-scroller__scroll-area">
                <div class="mdc-tab-scroller__scroll-content">
                    <button :class="{'mdc-tab--active': tab.active, 'imagine-tab--disabled': tab.disabled}"
                            :tabindex="index"
                            @click.prevent="goToTab(tab)"
                            class="mdc-tab imagine-tab"
                            v-for="(tab, index) in tabs">
                        <span class="mdc-tab__content">
                            <span class="mdc-tab__icon material-icons"
                                  v-if="tab.icon">{{ tab.icon }}</span>
                            <span :class="'imagine-icons--'+tab.svg"
                                  class="mdc-tab__icon imagine-icons"
                                  v-if="tab.svg"></span>
                            <span class="mdc-tab__text-label">
                                {{ tab.title }}
                                <small v-if="tab.subtitle">({{ tab.subtitle }})</small>
                            </span>
                        </span>
                        <span :class="{'mdc-tab-indicator--active': tab.active}"
                              class="mdc-tab-indicator">
                            <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
                        </span>
                        <span class="mdc-tab__ripple"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {MDCTabBar} from '@material/tab-bar/index';

export default {
    props: ['tabs'],
    mounted() {
        this._tabs = new MDCTabBar(this.$el);
    },
    beforeDestroy() {
        this._tabs.destroy();
    },
    data() {
        return {
            _tabs: null
        }
    },
    methods: {
        goToTab(tab) {
            if (!tab.active) {
                this.$emit('tab', tab.id);
            }
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/tab-bar/mdc-tab-bar';
@import '~@material/tab-scroller/mdc-tab-scroller';
@import '~@material/tab-indicator/mdc-tab-indicator';
@import '~@material/tab/mdc-tab';

.imagine-tab {
    &.imagine-tab--disabled {
        opacity: .3;
        pointer-events: none;
    }

    .mdc-tab__icon {
        @media (max-width: map-get($imagine-breakpoints, 'phone')) {
            display: none;
        }
    }
}
</style>
