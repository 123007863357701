<template>
    <imagine-input :label="label"
                   :name="name"
                   :required="required"
                   :help="help"
                   :disabled="disabled"
                   @input="$emit('input', _fromFormatedStringToMoney(innerValue))"
                   v-model="innerValue"/>
</template>

<script>
import ImagineInput from './ImagineInput.vue';

export default {
    components: {ImagineInput},
    props: {
        required: {type: Boolean, default: false},
        label: {required: true},
        name: {required: true},
        value: {default: null},
        help: {default: null},
        disabled: {default: false}
    },
    data() {
        return {
            innerValue: this._fromMoneyToFormatedString(this.value)
        }
    },
    watch: {
        value(newVal) {
            this.innerValue = this._fromMoneyToFormatedString(newVal);
        },
        innerValue(newVal) {
            const formated = this._fromInputToFormatedString(newVal);
            this.$nextTick(() => this.innerValue = formated);
        }
    },
    methods: {
        _fromInputToFormatedString(input) {
            return input ? this._fromMoneyToFormatedString(this.$container.types.makeMoney(this._stringToInt(input))) : '';
        },
        _fromMoneyToFormatedString(money) {
            return money ? this.$container.types.formatMoneyAmount(money) : null;
        },
        _fromFormatedStringToMoney(string) {
            return string ? this.$container.types.makeMoney(this._stringToInt(string)) : null;
        },
        _stringToInt(string) {
            if (!string) {
                return '';
            }

            let sign = 1;
            if (string.charAt(0) === '-') {
                sign = -1;
                string = string.substring(1);
            }

            let value = string.replace(/\D/g, '');

            return ((value ? value : 0) * sign).toString();
        }
    }
}
</script>
