var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "lease",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.lease && !_vm.lease.canBeModifiedWithoutAmendment
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: {
                      disabled: true,
                      icon: "post_add",
                      title: "Faire un avenant",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lease
                ? _c("imagine-icon-button", {
                    staticClass: "mdc-top-app-bar__action-item",
                    attrs: {
                      disabled: _vm.lease && !_vm.lease.canBeDeleted,
                      icon: "delete",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.deleteLease()
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "imagine-container" }, [
        _c(
          "form",
          {
            staticClass: "imagine-form",
            attrs: { autocomplete: "off" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.save.apply(null, arguments)
              },
            },
          },
          [
            _vm.mandateProperty &&
            _vm.mandateProperty.canBeModifiedWithoutAmendment
              ? _c("imagine-alert", { attrs: { type: "info" } }, [
                  _vm._v(
                    "\n                La création du premier bail rendra impossible la modification de la date, du propriétaire ou\n                des biens du mandat qui s'y rattache.\n            "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.leaseId
              ? _c("imagine-alert", { attrs: { type: "info" } }, [
                  _vm._v(
                    "\n                Avant de commencer à saisir un bail, il est nécessaire de créer le(s) locataires et le(s)\n                éventuel(s) garant(s) "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.redirectTo("contactAdd")
                        },
                      },
                    },
                    [_vm._v("dans les contacts")]
                  ),
                  _vm._v(".\n            "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.errors.global
              ? _c("imagine-alert", [_vm._v(_vm._s(_vm.errors.global))])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-date-picker", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    required: true,
                    label: "Date de signature",
                    disabled: _vm.isEditingDisabled,
                    name: "date",
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.tenantSlots, function (tenantSlot, slot) {
              return (
                _vm.isEditingDisabled
                  ? _vm.nbTenants > slot
                  : _vm.nbTenants >= slot
              )
                ? _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-contact-autocomplete", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          filter: _vm.canContactBeTenant,
                          label: slot > 0 ? "Autre locataire" : "Locataire",
                          required: slot <= 0,
                          disabled: _vm.isEditingDisabled,
                          name: "tenant",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.removeSlotHoles()
                          },
                        },
                        model: {
                          value: tenantSlot.tenant,
                          callback: function ($$v) {
                            _vm.$set(tenantSlot, "tenant", $$v)
                          },
                          expression: "tenantSlot.tenant",
                        },
                      }),
                      _vm._v(" "),
                      _vm.mandateProperty &&
                      _vm.mandateProperty.unpaidGuaranteePercent <= 0
                        ? _c("imagine-contact-autocomplete", {
                            staticClass: "imagine-form__row__field",
                            attrs: {
                              disabled:
                                !tenantSlot.tenant || _vm.isEditingDisabled,
                              filter: _vm.canContactBeProtector,
                              label: "Garant",
                              name: "protector",
                            },
                            model: {
                              value: tenantSlot.protector,
                              callback: function ($$v) {
                                _vm.$set(tenantSlot, "protector", $$v)
                              },
                              expression: "tenantSlot.protector",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e()
            }),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-date-picker", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    "min-date": _vm.minLeaseDate,
                    required: true,
                    disabled: _vm.isEditingDisabled,
                    label: "Date entrée",
                    name: "startDate",
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                }),
                _vm._v(" "),
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    required: true,
                    disabled: _vm.isEditingDisabled,
                    label: "Durée (années)",
                    min: "1",
                    name: "duration",
                    step: "1",
                    type: "number",
                  },
                  model: {
                    value: _vm.duration,
                    callback: function ($$v) {
                      _vm.duration = $$v
                    },
                    expression: "duration",
                  },
                }),
                _vm._v(" "),
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    required: true,
                    disabled: _vm.isEditingDisabled,
                    label: "Délai pré-avis (mois)",
                    min: "1",
                    name: "endDelay",
                    step: "1",
                    type: "number",
                  },
                  model: {
                    value: _vm.endDelay,
                    callback: function ($$v) {
                      _vm.endDelay = $$v
                    },
                    expression: "endDelay",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    required: true,
                    label: "Loyer hors charges (XPF / mois)",
                    disabled: _vm.isEditingDisabled,
                    name: "rent",
                  },
                  model: {
                    value: _vm.rent,
                    callback: function ($$v) {
                      _vm.rent = $$v
                    },
                    expression: "rent",
                  },
                }),
                _vm._v(" "),
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Provision pour charges (XPF / mois)",
                    disabled: _vm.isEditingDisabled,
                    name: "charges",
                  },
                  model: {
                    value: _vm.charges,
                    callback: function ($$v) {
                      _vm.charges = $$v
                    },
                    expression: "charges",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Provision taxe d'ordures ménagères (XPF / mois)",
                    disabled: _vm.isEditingDisabled,
                    name: "waste",
                  },
                  model: {
                    value: _vm.waste,
                    callback: function ($$v) {
                      _vm.waste = $$v
                    },
                    expression: "waste",
                  },
                }),
                _vm._v(" "),
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Provision taxe d'eau (XPF / mois)",
                    disabled: _vm.isEditingDisabled,
                    name: "water",
                  },
                  model: {
                    value: _vm.water,
                    callback: function ($$v) {
                      _vm.water = $$v
                    },
                    expression: "water",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Dépôt de garantie (XPF)",
                    help: "Par défaut = 1.5x loyer hors charges",
                    disabled: _vm.isEditingDisabled,
                    name: "guarantee",
                  },
                  model: {
                    value: _vm.guarantee,
                    callback: function ($$v) {
                      _vm.guarantee = $$v
                    },
                    expression: "guarantee",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    required: true,
                    label: "Taux H.T. des H.M.L. (%)",
                    help: "Par défaut = 60%",
                    disabled: _vm.isEditingDisabled,
                    min: "0",
                    name: "feesPercent",
                    step: "any",
                    type: "number",
                  },
                  model: {
                    value: _vm.feesPercent,
                    callback: function ($$v) {
                      _vm.feesPercent = $$v
                    },
                    expression: "feesPercent",
                  },
                }),
                _vm._v(" "),
                _c("imagine-money-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Base des H.M.L. (XPF)",
                    help: _vm.feesHelp,
                    disabled: _vm.isEditingDisabled,
                    name: "feesBase",
                  },
                  model: {
                    value: _vm.feesBase,
                    callback: function ($$v) {
                      _vm.feesBase = $$v
                    },
                    expression: "feesBase",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Bail (doc)",
                    name: "leaseUrl",
                    accept: "application/pdf",
                  },
                  model: {
                    value: _vm.leaseUrl,
                    callback: function ($$v) {
                      _vm.leaseUrl = $$v
                    },
                    expression: "leaseUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "imagine-form__sep" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-date-picker", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    "min-date": _vm.startDate,
                    label: "Date état des lieux d'entrée",
                    name: "inventoryInDate",
                  },
                  model: {
                    value: _vm.inventoryInDate,
                    callback: function ($$v) {
                      _vm.inventoryInDate = $$v
                    },
                    expression: "inventoryInDate",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    label: "Etat des lieux d'entrée (doc)",
                    name: "inventoryInUrl",
                    accept: "application/pdf",
                  },
                  model: {
                    value: _vm.inventoryInUrl,
                    callback: function ($$v) {
                      _vm.inventoryInUrl = $$v
                    },
                    expression: "inventoryInUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__row" },
              [
                _c("imagine-date-picker", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    "min-date": _vm.startDate,
                    label: "Date de fin assurance habitation",
                    name: "insuranceEndDate",
                  },
                  model: {
                    value: _vm.insuranceEndDate,
                    callback: function ($$v) {
                      _vm.insuranceEndDate = $$v
                    },
                    expression: "insuranceEndDate",
                  },
                }),
                _vm._v(" "),
                _c("imagine-file-input", {
                  staticClass: "imagine-form__row__field",
                  attrs: {
                    accept: "application/pdf",
                    label: "Assurance habitation (doc)",
                    name: "insuranceUrl",
                  },
                  model: {
                    value: _vm.insuranceUrl,
                    callback: function ($$v) {
                      _vm.insuranceUrl = $$v
                    },
                    expression: "insuranceUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.leaseId
              ? [
                  _c("div", { staticClass: "imagine-form__sep" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          "min-date": _vm.startDate,
                          label: "Date de réception du pré-avis de départ",
                          name: "endNoticeDate",
                        },
                        model: {
                          value: _vm.endNoticeDate,
                          callback: function ($$v) {
                            _vm.endNoticeDate = $$v
                          },
                          expression: "endNoticeDate",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-file-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Courrier pré-avis (doc)",
                          name: "endNoticeUrl",
                          accept: "application/pdf",
                        },
                        model: {
                          value: _vm.endNoticeUrl,
                          callback: function ($$v) {
                            _vm.endNoticeUrl = $$v
                          },
                          expression: "endNoticeUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "imagine-form__sep" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          "min-date": _vm.startDate,
                          label: "Date état des lieux de sortie",
                          name: "inventoryOutDate",
                        },
                        model: {
                          value: _vm.inventoryOutDate,
                          callback: function ($$v) {
                            _vm.inventoryOutDate = $$v
                          },
                          expression: "inventoryOutDate",
                        },
                      }),
                      _vm._v(" "),
                      _c("imagine-file-input", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          label: "Etat des lieux de sortie (doc)",
                          name: "inventoryOutUrl",
                          accept: "application/pdf",
                        },
                        model: {
                          value: _vm.inventoryOutUrl,
                          callback: function ($$v) {
                            _vm.inventoryOutUrl = $$v
                          },
                          expression: "inventoryOutUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "imagine-form__row" },
                    [
                      _c("imagine-date-picker", {
                        staticClass: "imagine-form__row__field",
                        attrs: {
                          "min-date": _vm.startDate,
                          label: "Date de sortie",
                          name: "endDate",
                        },
                        model: {
                          value: _vm.endDate,
                          callback: function ($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "imagine-form__actions" },
              [
                _c(
                  "imagine-button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v("Annuler")]
                ),
                _vm._v(" "),
                _c(
                  "imagine-button",
                  { attrs: { primary: true, type: "submit" } },
                  [
                    _vm._v(
                      "\n                    Sauvegarder\n                "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }