var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "imagine-loader",
      class: { "imagine-loader--with-message": _vm.loadingMessage },
    },
    [
      _vm.loadingMessage
        ? _c("div", { staticClass: "imagine-loader__message" }, [
            _vm._v("\n        " + _vm._s(_vm.loadingMessage) + "\n        "),
            _vm.progressTotal
              ? _c("span", [
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(_vm.progressInc) +
                      " / " +
                      _vm._s(_vm.progressTotal)
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "imagine-loader__progress mdc-linear-progress",
          class: { "mdc-linear-progress--indeterminate": !_vm.progressTotal },
        },
        [
          _c("div", { staticClass: "mdc-linear-progress__buffering-dots" }),
          _vm._v(" "),
          _c("div", { staticClass: "mdc-linear-progress__buffer" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mdc-linear-progress__bar mdc-linear-progress__primary-bar",
              style: _vm.barStyle,
            },
            [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "mdc-linear-progress__bar mdc-linear-progress__secondary-bar",
      },
      [_c("span", { staticClass: "mdc-linear-progress__bar-inner" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }