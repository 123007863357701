var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "imagine-layout",
    {
      staticClass: "sale-payments",
      attrs: { inner: true, title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.$mq !== "phone"
                ? [
                    _vm.salePayments
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { title: "Fiche bien", icon: "home_work" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("property", {
                                id: _vm.salePayments.sale.propertyId,
                              })
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.salePayments
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { title: "Fiche résidence", icon: "business" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectTo("block", {
                                id: _vm.salePayments.sale.blockId,
                              })
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    false
                      ? _c("imagine-icon-button", {
                          staticClass: "mdc-top-app-bar__action-item",
                          attrs: { svg: "socredo" },
                          on: {
                            click: function ($event) {
                              return _vm.externalRedirectTo(
                                "https://www.socredo.pf/connexion/auth/login"
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("imagine-icon-button", {
                staticClass: "mdc-top-app-bar__action-item",
                attrs: { disabled: !_vm.canSendMail, icon: "email" },
                on: {
                  click: function ($event) {
                    return _vm.openMail()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.salePayments
        ? _c("div", { staticClass: "imagine-container" }, [
            _c("div", { staticClass: "sale-payments__header" }, [
              _c("div", { staticClass: "sale-payments__header__info" }, [
                _c("b", [
                  _vm._v(
                    "Acheteur" +
                      _vm._s(
                        _vm.salePayments.sale.buyers.length > 1 ? "s" : ""
                      ) +
                      " :"
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.salePayments.sale.buyers.length > 0
                        ? _vm.salePayments.sale.buyers.join(", ")
                        : "N/A"
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sale-payments__header__info" }, [
                _c("b", [_vm._v("Défisc :")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.salePayments.sale.taxExemption
                        ? _vm.references.getLabel(
                            "taxExemptions",
                            _vm.salePayments.sale.taxExemption
                          )
                        : "N/A"
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sale-payments__header__info" }, [
                _c("b", [
                  _vm._v(
                    "Négociateur" +
                      _vm._s(_vm.salePayments.salesReps.length > 1 ? "s" : "") +
                      " :"
                  ),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.salePayments.salesReps.length > 0
                        ? _vm.salePayments.salesReps.join(", ")
                        : "N/A"
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sale-payments__header__info" }, [
                _c("b", [_vm._v("Signature acte vente :")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.salePayments.saleDate
                        ? _vm.formatDate(_vm.salePayments.saleDate)
                        : "N/A"
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sale-payments__header__info" }, [
                _c("hr"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "sale-payments__header__info sale-payments__header__info--price",
                },
                [
                  _c("b", [_vm._v("Prix d'achat :")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.salePayments.price
                          ? _vm.formatMoney(_vm.salePayments.price)
                          : "N/A"
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "sale-payments__header__info sale-payments__header__info--price",
                },
                [
                  _c("b", [_vm._v("Prix parking :")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.salePayments.parkingPrice
                          ? _vm.formatMoney(_vm.salePayments.parkingPrice)
                          : "N/A"
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "sale-payments__header__info sale-payments__header__info--price",
                },
                [
                  _c("b", [_vm._v("Remise :")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.salePayments.discount
                          ? _vm.formatMoney(
                              _vm.salePayments.discount.multiply(-1)
                            )
                          : "N/A"
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.salePayments.withLawyerFees
                ? _c(
                    "div",
                    {
                      staticClass:
                        "sale-payments__header__info sale-payments__header__info--price",
                    },
                    [
                      _c("b", [_vm._v("Frais notaire (défisc.) :")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.salePayments.lawyerFees
                              ? _vm.formatMoney(_vm.salePayments.lawyerFees)
                              : "N/A"
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "sale-payments__header__info sale-payments__header__info--price sale-payments__header__info--total",
                },
                [
                  _c("b", [_vm._v("Total à payer :")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.salePayments.expected
                          ? _vm.formatMoney(_vm.salePayments.expected)
                          : "N/A"
                      )
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.salePayments.milestones.length > 0
              ? _c("div", { staticClass: "imagine-table-container" }, [
                  _c("table", { staticClass: "imagine-table" }, [
                    _c("thead", [
                      _c("tr", { staticClass: "imagine-table__row" }, [
                        _c("th", { staticClass: "imagine-table__row__cell" }),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "imagine-table__row__cell" },
                          [
                            !_vm.hasOnePaymentOnMilestone
                              ? _c(
                                  "imagine-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleRegroup()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.salePayments &&
                                            _vm.salePayments
                                              .areMilestonesRegrouped
                                            ? "Dégrouper"
                                            : "Grouper"
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v("Part"),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v("A payer"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--date",
                          },
                          [_vm._v("Date d'échéance")]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--date",
                          },
                          [_vm._v("Date de paiement")]
                        ),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v("Payé"),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v("Reste à payer (ou frais de notaire)"),
                        ]),
                        _vm._v(" "),
                        _c("td"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.salePayments.milestones, function (milestone) {
                        return _c("tr", { staticClass: "imagine-table__row" }, [
                          _c(
                            "td",
                            { staticClass: "imagine-table__row__cell" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "material-icons imagine-status",
                                  class: {
                                    "imagine-status--sold": milestone.isDone,
                                    "imagine-status--error": milestone.isLate,
                                    "imagine-status--danger": milestone.isClose,
                                    "imagine-status--success":
                                      !milestone.isDone &&
                                      !milestone.isLate &&
                                      !milestone.isClose,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            lens\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "imagine-table__row__cell" },
                            [_vm._v(_vm._s(milestone.label))]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--amount",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(milestone.progress) +
                                  "%\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--amount",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.formatMoney(milestone.expectedAmount)
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--date",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.formatDate(milestone.expectedDate)
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--date",
                            },
                            [
                              _vm._l(
                                milestone.payments,
                                function (payment, index) {
                                  return [
                                    index > 0 ? _c("br") : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updatePayment(
                                                  payment
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.formatDate(payment.date)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        payment.note
                                          ? _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "right",
                                                },
                                              },
                                              [_vm._v(_vm._s(payment.note))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--amount",
                            },
                            [
                              _vm._l(
                                milestone.payments,
                                function (payment, index) {
                                  return [
                                    index > 0 ? _c("br") : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.updatePayment(
                                                  payment
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.formatMoney(
                                                    payment.amount
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        payment.note
                                          ? _c(
                                              "md-tooltip",
                                              {
                                                attrs: {
                                                  "md-direction": "right",
                                                },
                                              },
                                              [_vm._v(_vm._s(payment.note))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--amount",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.formatMoney(milestone.due)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "imagine-table__row__cell imagine-table__row__cell--actions",
                            },
                            [
                              _c("imagine-icon-button", {
                                staticClass: "imagine-no-ripple",
                                attrs: { icon: "add_task" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addPayment(milestone)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("imagine-icon-button", {
                                staticClass: "imagine-no-ripple",
                                attrs: { svg: "word" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editDoc(milestone)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("tfoot", [
                      _c("tr", { staticClass: "imagine-table__row" }, [
                        _c("th", { staticClass: "imagine-table__row__cell" }),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v("TOTAL"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--amount",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.salePayments.totalProgress) +
                                "%\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass:
                              "imagine-table__row__cell imagine-table__row__cell--amount",
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.formatMoney(_vm.salePayments.expected)
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.formatMoney(_vm.salePayments.paid)) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "imagine-table__row__cell" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.formatMoney(_vm.salePayments.due)) +
                              "\n                    "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th"),
                      ]),
                    ]),
                  ]),
                ])
              : _c("div", { staticClass: "imagine-container" }, [
                  _c("div", { staticClass: "sale-payments__none" }, [
                    _vm._v(
                      "Planning introuvable pour la résidence ou le bâtiment."
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showPaymentModal
        ? _c(
            "imagine-modal",
            {
              attrs: {
                title: _vm.paymentId
                  ? "Modification encaissement"
                  : "Ajout encaissement",
                "button-label": "Sauvegarder",
              },
              on: {
                act: function ($event) {
                  return _vm.saveSalePayment()
                },
                close: function ($event) {
                  _vm.showPaymentModal = false
                },
              },
            },
            [
              _c(
                "p",
                [
                  _c("imagine-money-input", {
                    staticClass: "imagine-form__row__field",
                    attrs: { label: "Montant", name: "amount" },
                    model: {
                      value: _vm.amount,
                      callback: function ($$v) {
                        _vm.amount = $$v
                      },
                      expression: "amount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("imagine-date-picker", {
                    staticClass: "imagine-form__row__field",
                    attrs: {
                      "min-date": _vm.salePayments.saleDate,
                      label: "Date",
                      name: "date",
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                [
                  _c("imagine-textarea", {
                    staticClass: "imagine-form__row__field",
                    attrs: { name: "note", label: "Commentaire" },
                    model: {
                      value: _vm.note,
                      callback: function ($$v) {
                        _vm.note = $$v
                      },
                      expression: "note",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }