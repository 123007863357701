class LeaseRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    save(data) {
        return this._http.post('/api/lease' + (data.id ? '/' + data.id : ''), data);
    }

    saveInventory(id, data) {
        return this._http.post('/api/lease/' + id + '/inventory', data);
    }

    saveInsurance(id, data) {
        return this._http.post('/api/lease/' + id + '/insurance', data);
    }

    saveEnd(id, data) {
        return this._http.post('/api/lease/' + id + '/end', data);
    }

    sendReminder(id, model) {
        return this._http.post('/api/lease/' + id + '/reminder?model=' + model);
    }

    sendReceipt(id, period) {
        return this._http.post('/api/lease/' + id + '/doc/rent?date=' + period.year + '-' + period.month + '-01');
    }

    delete(id) {
        return this._http.delete('/api/lease/' + id);
    }
}

export default LeaseRepository;
