<template>
    <imagine-modal :button-disabled="!endDate"
                   @act="leaseEndNotice()"
                   @close="$emit('canceled')"
                   button-label="Enregistrer"
                   class="imagine-modal--wide"
                   title="Pré-avis de résiliation du bail">
        <p>
            <imagine-date-picker :min-date="lease.start"
                                 class="imagine-form__row__field"
                                 label="Date de réception du pré-avis"
                                 name="noticeDate"
                                 v-model="noticeDate"/>
        </p>

        <p>
            <imagine-date-picker :min-date="lease.start"
                                 :required="true"
                                 class="imagine-form__row__field"
                                 label="Date de sortie"
                                 name="feesDate"
                                 v-model="endDate"/>
        </p>

        <p>
            <imagine-file-input class="imagine-form__row__field"
                                label="Courrier pré-avis (doc)"
                                name="url"
                                :required="true"
                                accept="application/pdf"
                                v-model="url"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';
import ImagineFileInput from '../../../components/ImagineFileInput.vue';

export default {
    props: ['lease'],
    components: {ImagineModal, ImagineDatePicker, ImagineFileInput},
    data() {
        return {
            noticeDate: this.$container.types.today(),
            endDate: null,
            url: null
        }
    },
    watch: {
        noticeDate(newVal) {
            if (newVal) {
                this.endDate = newVal.clone();
                this.endDate = this.endDate.add(3, 'months');
            }
        }
    },
    methods: {
        leaseEndNotice() {
            this.load();
            this.$store.dispatch('lease/saveEnd', {
                id: this.lease.id,
                data: {noticeDate: this.noticeDate, endDate: this.endDate, url: this.url}
            })
                .then(() => this.$emit('completed'))
                .catch(this.serverError)
                .finally(this.unload);
        }
    }
}
</script>




