var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "sale-status" },
    [
      _c(
        "span",
        {
          staticClass: "material-icons imagine-status",
          class: {
            "imagine-status--sold": _vm.sale.isDone,
            "imagine-status--error": _vm.sale.isLate,
            "imagine-status--success": !_vm.sale.isLate,
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(!_vm.hasBuyers ? "lens_blur" : "lens") +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("md-tooltip", { attrs: { "md-direction": "right" } }, [
        _vm._v(_vm._s(_vm.statusLabel)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }