import container from '../container';

const repo = container.repos.lease;

export default {
    namespaced: true,
    state: {
        nbRentsToCharge: null
    },
    mutations: {},
    actions: {
        save(context, {data}) {
            return repo.save(data);
        },
        saveInventory(context, {id, data}) {
            return repo.saveInventory(id, data);
        },
        saveInsurance(context, {id, data}) {
            return repo.saveInsurance(id, data);
        },
        saveEnd(context, {id, data}) {
            return repo.saveEnd(id, data);
        },
        sendReminder(context, {id, model}) {
            return repo.sendReminder(id, model);
        },
        delete(context, id) {
            return repo.delete(id);
        },
        sendReceipt(contex, {id, period}) {
            return repo.sendReceipt(id, period);
        }
    }
};
