var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "imagine-autocomplete" },
    [
      _c("imagine-input", {
        attrs: {
          autocomplete: false,
          clearable: true,
          required: _vm.required,
          disabled: _vm.disabled,
          help: _vm.selected ? _vm.selected : _vm.help,
          label: _vm.label,
          violation: _vm.violation,
          icon: "search",
          name: _vm.name + "-term",
        },
        on: {
          focusin: function ($event) {
            return _vm.autocompleteFocus()
          },
          focusout: function ($event) {
            return _vm.hidePropositions()
          },
          input: function ($event) {
            return _vm.$emit("search", _vm.term)
          },
          clear: function ($event) {
            return _vm.removeSelected()
          },
        },
        model: {
          value: _vm.term,
          callback: function ($$v) {
            _vm.term = $$v
          },
          expression: "term",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPropositions,
              expression: "showPropositions",
            },
          ],
          staticClass:
            "imagine-autocomplete__propositions md-menu-content-bottom-start md-menu-content-small md-menu-content md-theme-default",
          style: {
            maxWidth: _vm.inputWidth + "px",
            width: _vm.inputWidth + "px",
            top: _vm.inputBottom + "px",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "md-menu-content-container md-scrollbar md-theme-default",
            },
            [
              _c(
                "ul",
                { staticClass: "md-list md-theme-default md-dense" },
                [
                  _vm.create
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "md-list-item md-menu-item md-theme-default",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-list-item-default md-list-item-container md-button-clean",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-list-item-content md-ripple md-disabled",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.ucFirstEntity) +
                                      " introuvable ?\n                            "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.$emit("create")
                                        },
                                      },
                                    },
                                    [_vm._v("créer un " + _vm._s(_vm.entity))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.create && _vm.firstPropositions.length <= 0
                    ? _c(
                        "li",
                        {
                          staticClass:
                            "md-list-item md-menu-item md-theme-default",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-list-item-default md-list-item-container md-button-clean",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-list-item-content md-ripple md-disabled",
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.ucFirstEntity) +
                                      " introuvable\n                        "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.firstPropositions, function (proposition) {
                    return _c(
                      "li",
                      {
                        staticClass:
                          "md-list-item md-menu-item md-theme-default",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "md-list-item-button md-list-item-container md-button-clean",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.select(proposition)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "md-list-item-content md-ripple" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(proposition.label) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }