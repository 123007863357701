<template>
    <div class="mdc-linear-progress">
        <div class="mdc-linear-progress__buffer"></div>
        <div :style="'transform: scaleX(' + progressRate + ')'"
             class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
            <span class="mdc-linear-progress__bar-inner"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['progressRate']
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/linear-progress/mdc-linear-progress';

.mdc-linear-progress__bar-inner {
    left: 0;
}
</style>
