var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("imagine-autocomplete", {
    attrs: {
      label: _vm.label,
      propositions: _vm.contactPropositions,
      required: _vm.required,
      create: !!_vm.redirectAfterCreate,
      entity: "contact",
      name: _vm.name,
      disabled: _vm.disabled,
      violation: _vm.violation,
      help: "Saisir nom et cliquer sur résultat",
    },
    on: {
      create: function ($event) {
        return _vm.gotoCreate()
      },
      first: _vm.proposeAllContacts,
      input: function ($event) {
        return _vm.$emit("input", _vm.innerValue)
      },
      search: function ($event) {
        return _vm.reloadPropositions($event)
      },
    },
    model: {
      value: _vm.innerValue,
      callback: function ($$v) {
        _vm.innerValue = $$v
      },
      expression: "innerValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }