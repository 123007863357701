var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-field",
    [
      _c("label", { attrs: { for: _vm.name } }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _c("md-textarea", {
        attrs: {
          id: _vm.name,
          name: _vm.name,
          required: _vm.required,
          disabled: _vm.disabled,
          "md-autogrow": "",
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", _vm.innerValue)
          },
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      }),
      _vm._v(" "),
      _vm.help
        ? _c("span", { staticClass: "md-helper-text" }, [
            _vm._v(_vm._s(_vm.help)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }