var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.events.length > 0
    ? _c(
        "transition-group",
        {
          staticClass:
            "timeline mdc-list mdc-list--non-interactive mdc-list--two-line",
          attrs: { name: "slideY", tag: "div" },
        },
        _vm._l(_vm.events, function (event) {
          return _c(
            "div",
            { key: event.id, staticClass: "mdc-list-item timeline__event" },
            [
              _c(
                "span",
                { staticClass: "mdc-list-item__graphic imagine-rounded-shape" },
                [
                  event.by && event.by.image
                    ? _c(
                        "span",
                        [
                          _c("img", { attrs: { src: event.by.image } }),
                          _vm._v(" "),
                          _vm.by(event)
                            ? _c(
                                "md-tooltip",
                                { attrs: { "md-direction": "right" } },
                                [_vm._v(_vm._s(_vm.by(event)))]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        { staticClass: "material-icons" },
                        [
                          _vm._v("\n                person\n                "),
                          _vm.by(event)
                            ? _c(
                                "md-tooltip",
                                { attrs: { "md-direction": "right" } },
                                [_vm._v(_vm._s(_vm.by(event)))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "mdc-list-item__text" }, [
                _c("span", { staticClass: "mdc-list-item__primary-text" }, [
                  _vm._v(_vm._s(event.title)),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "mdc-list-item__secondary-text employees__employee__position",
                  },
                  [_vm._v(_vm._s(event.description))]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mdc-list-item__meta" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatEventDate(event.date)) +
                    "\n        "
                ),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }