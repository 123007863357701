import container from '../container';

const repo = container.repos.salePayments;

const MAX_DISPLAYED = 50;
const DEFAULT_STATUSES = ['ok', 'late'];

export default {
    namespaced: true,
    state: {
        displayed: [],
        maxDisplayed: MAX_DISPLAYED,
        nbTotal: 0,
        nbFiltered: 0,
        query: null,
        block: null,
        building: null,
        statuses: DEFAULT_STATUSES,
        mode: 'list'
    },
    getters: {
        filters: state => {
            return {
                block: state.block,
                statuses: state.statuses,
                building: state.building
            };
        }
    },
    mutations: {
        setBlock(state, block) {
            state.block = block;
        },
        setStatuses(state, statuses) {
            state.statuses = statuses;
        },
        setBuilding(state, building) {
            state.building = building;
        },
        setQuery(state, query) {
            state.query = query;
        },
        changeMode(state, mode) {
            const origin = state.mode;

            state.mode = mode;

            if (mode === 'search') {
                state.block = null;
                state.building = null;
                state.statuses = DEFAULT_STATUSES;
            }

            if (origin === 'search') {
                state.query = null;
            }
        },
    },
    actions: {
        reload({state, getters}) {
            return repo.all({limit: state.maxDisplayed, query: state.query, filters: getters.filters})
                .then(collection => {
                    state.nbTotal = collection.nbTotal;
                    state.nbFiltered = collection.nbFiltered;
                    state.displayed = collection.entities;
                });
        },
        displayMore({state, dispatch}) {
            state.maxDisplayed += state.maxDisplayed;

            return dispatch('reload');
        },
        one(context, saleId) {
            return repo.one(saleId);
        },
        savePayment(context, payment) {
            return repo.savePayment(payment);
        },
        toggleRegrouped(context, salePaymentId) {
            return repo.toggleRegrouped(salePaymentId);
        }
    }
};
