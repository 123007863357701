<template>
    <imagine-layout class="sales-payments" title="Appels de fonds">
        <template v-slot:actions>
            <imagine-icon-button @click="exportSalePayments()"
                                 class="mdc-top-app-bar__action-item"
                                 icon="get_app"/>
            <imagine-icon-button @click="exportSalePayments2()"
                                 class="mdc-top-app-bar__action-item"
                                 icon="science"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 :active="mode === 'search'"
                                 @click="changeMode('search')"
                                 icon="search"/>
            <imagine-icon-button class="mdc-top-app-bar__action-item"
                                 @click="changeMode('list')"
                                 :active="mode === 'list'"
                                 icon="filter_list"/>
            <imagine-icon-button @click="externalRedirectTo('https://www.socredo.pf/connexion/auth/login')"
                                 class="mdc-top-app-bar__action-item"
                                 v-if="false"
                                 svg="socredo"/>
        </template>


        <transition name="slideY">
            <div class="imagine-secondary-zone search__filters" v-if="mode === 'search'">
                <div class="search__filters__search">
                    <imagine-input :autocomplete="false"
                                   :clearable="true"
                                   :required="false"
                                   icon="search"
                                   label="Référence / mot clé"
                                   name="query"
                                   v-model="query"/>
                </div>
            </div>
        </transition>

        <transition name="slideY">
            <div class="imagine-secondary-zone sales-payments__filters" v-if="mode !== 'search'">
                <div class="sales-payments__filters__filter">
                    <imagine-select :choices="references.groupedBlockChoices(blocks, ['ongoing', 'completed'])"
                                    label="Résidence"
                                    name="block"
                                    v-model="block"/>
                </div>
                <div class="sales-payments__filters__filter"
                     v-if="false">
                    <imagine-select :choices="salePaymentsStatusChoices"
                                    :implicit-required="true"
                                    :multiple="true"
                                    label="Statuts"
                                    name="statuses"
                                    v-model="statuses"/>
                </div>
                <div class="sales-payments__filters__filter">
                    <imagine-input :clearable="true"
                                   :required="false"
                                   label="Bâtiment"
                                   name="building"
                                   v-model="building"/>
                </div>
            </div>
        </transition>

        <div :class="{'sales-payments__count--filtered': nbFiltered < nbTotal}"
             class="sales-payments__count">
            <div class="sales-payments__count__count"
                 v-show="nbFiltered > 0">
                <span v-if="nbFiltered < nbTotal">
                    {{ formatNumber(nbFiltered) }} /
                </span>
                <span>{{ formatNumber(nbTotal) }}</span>
                vente{{ nbFiltered > 1 ? 's' : '' }} payables
            </div>
            <div v-show="nbFiltered <= 0">
                Aucune vente payable trouvé.
            </div>

            <div class="sales-payments__count__sort"
                 v-show="nbFiltered > 1">
                Trié par résidence/lot
            </div>
        </div>

        <template v-if="displayed.length > 0">
            <div class="mdc-list mdc-list--two-line sales-payments__list">
                <template v-for="salePayments in displayed">
                    <div @click="redirectTo('salePayments', {id: salePayments.sale.id})"
                         class="mdc-list-item sales-payments__list__item">
                        <span class="mdc-list-item__graphic">
                            <span :class="{
                                'imagine-status--sold': salePayments.isDone,
                                'imagine-status--error': salePayments.isLate,
                                'imagine-status--success': !salePayments.isLate}"
                                  class="material-icons imagine-status">
                                lens
                            </span>
                        </span>
                        <span class="mdc-list-item__text">
                            <span class="mdc-list-item__primary-text sales-payments__list__item__title">
                                {{ salePayments.sale.property }}
                                {{ salePayments.sale.taxExemption ? '[défisc]'  : '' }}
                            </span>
                            <span class="mdc-list-item__secondary-text sales-payments__list__item__owner">
                                <template v-if="salePayments.sale.buyers.length > 0">
                                    {{ salePayments.sale.buyers.join(', ') }}
                                    <template v-if="salePayments.sale.salesRep">
                                        ({{ salePayments.sale.salesRep }} {{ salePayments.sale.otherSalesRep }})
                                    </template>
                                </template>
                                <template v-else>Pas d'acquéreur(s)</template>
                            </span>
                        </span>
                        <span class="mdc-list-item__meta">
                            {{ salePayments.paidPercent }}% / {{ salePayments.toBePaidPercent }}%
                        </span>
                    </div>
                    <div class="mdc-list-divider"></div>
                </template>
            </div>

            <div @click="displayMore()"
                 class="sales-payments__see-more"
                 v-show="isDisplayLimited">
                <imagine-button>Voir plus de ventes payables</imagine-button>
            </div>
        </template>
        <div class="sales-payments__none"
             v-else>
            Aucune vente payable.
        </div>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import {mapState} from 'vuex';
import ImagineModal from "../components/ImagineModal";
import ImagineDatePicker from "../components/ImagineDatePicker";
import debounce from "lodash-es/debounce";

export default {
    components: {
        ImagineDatePicker,
        ImagineModal, ImagineIconButton, ImagineLayout, ImagineSelect, ImagineButton, ImagineInput
    },
    computed: {
        ...mapState(['references']),
        ...mapState('block', {blocks: state => state.all}),
        ...mapState('salePayments', {
            displayed: state => state.displayed,
            nbFiltered: state => state.nbFiltered,
            nbTotal: state => state.nbTotal,
            maxDisplayed: state => state.maxDisplayed,
            mode: state => state.mode
        }),
        salePaymentsStatusChoices() {
            return this.references.salePaymentsStatusChoices();
        },
        isDisplayLimited() {
            if (this.displayed.length <= 0) {
                return false;
            }

            return this.displayed.length < this.nbFiltered;
        },
        query: {
            get() {
                return this.$store.state.salePayments.query;
            },
            set(value) {
                this.$store.commit('salePayments/setQuery', value);
            }
        },
        block: {
            get() {
                return this.$store.state.salePayments.block;
            },
            set(value) {
                this.$store.commit('salePayments/setBlock', value);
            }
        },
        statuses: {
            get() {
                return this.$store.state.salePayments.statuses;
            },
            set(value) {
                this.$store.commit('salePayments/setStatuses', value);
            }
        },
        building: {
            get() {
                return this.$store.state.salePayments.building;
            },
            set(value) {
                this.$store.commit('salePayments/setBuilding', value);
            }
        }
    },
    data() {
        return {
            minDate: this.$container.types.daysAgo(365),
            maxDate: this.$container.types.today()
        }
    },
    created() {
        this.search = debounce(this._search, 200);

        this.load('Chargement des appels de fonds');

        Promise.all(this._getDependencies())
            .catch(this.serverError)
            .finally(this.unload);
    },
    watch: {
        query() {
            this.search();
        },
        block() {
            this._search();
        },
        statuses() {
            this._search();
        },
        building() {
            this._search();
        }
    },
    methods: {
        changeMode(newMode) {
            this.$store.commit('salePayments/changeMode', newMode);
        },
        displayMore() {
            this.load();
            this.$store.dispatch('salePayments/displayMore')
                .catch(this.serverError)
                .finally(this.unload);
        },
        exportSalePayments() {
            window.location.href = '/api/sale-payment/export';
        },
        exportSalePayments2() {
            window.location.href = '/api/sale-payment/export2';
        },
        _search() {
            this.load();
            this.$store.dispatch('salePayments/reload')
                .catch(this.serverError)
                .finally(this.unload)
        },
        _getDependencies() {
            return [
                this.$store.dispatch('salePayments/reload'),
                this.$store.dispatch('touchReferences'),
                this.$store.dispatch('block/touch')
            ];
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/list/mdc-list';

.sales-payments {
    &__filters {
        padding: 8px;

        @media (min-width: map-get($imagine-breakpoints, 'desktop')) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__filter {
            flex: 1;
            margin-left: 8px;
            margin-right: 8px;
            //margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            cursor: pointer;

            &--bordered {
                border: 1px dotted #ffffff;
                padding: .5rem;
            }

            .md-field {
                margin: 0;
            }

            &--statuses {
                .md-menu.md-select {
                    flex: unset;
                    width: 90%;

                    .md-input {
                        font-size: .9rem;
                    }
                }
            }
        }
    }

    .imagine-status {
        font-size: 1.2rem;
    }

    &__count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        &.sales-payments__count--filtered {
            .sales-payments__count__count {
                font-weight: 500;
            }
        }
    }

    &__see-more {
        cursor: pointer;
        text-align: center;
        padding: 1rem;
    }

    &__none {
        padding: 2rem;
        text-align: center;
        font-size: 1.25rem;
    }
}
</style>
