<template>
    <span class="sale-status">
        <span
            :class="{'imagine-status--sold': sale.isDone, 'imagine-status--error': sale.isLate, 'imagine-status--success': !sale.isLate}"
            class="material-icons imagine-status">
            {{ !hasBuyers ? 'lens_blur' : 'lens'  }}
        </span>
        <md-tooltip md-direction="right">{{ statusLabel }}</md-tooltip>
    </span>
</template>

<script>
export default {
    props: ['sale'],
    computed: {
        hasBuyers() {
            return this.sale.buyers && this.sale.buyers.length > 0;
        },
        statusLabel() {
            if (!this.hasBuyers) {
                return 'Disponible';
            }

            if (this.sale.isDone) {
                return 'Terminé';
            }

            if (this.sale.isLate) {
                return 'En retard';
            }

            return 'OK';
        }
    }
}
</script>
