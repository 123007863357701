class EmployeeRepository {
    constructor(types, http) {
        this._types = types;
        this._http = http;
    }

    all() {
        return this._http.get('/api/employee');
    }

    save(data) {
        return this._http.post('/api/employee' + (data.id ? '/' + data.id : ''), data);
    }

    delete(id) {
        return this._http.delete('/api/employee/' + id);
    }

    state(id, state) {
        return this._http.put('/api/employee/' + id + '/state', {state});
    }
}

export default EmployeeRepository;
