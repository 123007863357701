<template>
    <imagine-layout :inner="true" :title="title" class="employee-edit">
        <template v-slot:actions>
            <imagine-icon-button @click="toggleActive"
                                 class="mdc-top-app-bar__navigation-icon"
                                 :icon="isActive ? 'lock_open' : 'lock'"
                                 v-if="employee"/>
            <imagine-icon-button @click="showRemoveConfirmation = true"
                                 class="mdc-top-app-bar__navigation-icon"
                                 icon="delete"
                                 v-if="employee"/>
        </template>

        <div class="imagine-container">
            <form @submit.prevent="save" autocomplete="off" class="imagine-form">
                <div class="employee-edit__avatar-row">
                    <div class="imagine-form__row employee-edit__avatar-row__photo">
                        <imagine-file-input :rounded="true"
                                            accept="image/*"
                                            class="imagine-form__row__field"
                                            label="Photo"
                                            :options="{cropRatio: '1/1'}"
                                            v-model="photo"/>
                    </div>

                    <div class="employee-edit__avatar-row__infos">
                        <div class="imagine-form__row">
                            <imagine-input class="imagine-form__row__field"
                                           :required="true"
                                           label="Prénom"
                                           name="firstname"
                                           v-model="firstname"/>

                            <imagine-input class="imagine-form__row__field"
                                           :required="true"
                                           label="Nom"
                                           name="lastname"
                                           v-model="lastname"/>
                        </div>

                        <div class="imagine-form__row">
                            <imagine-input class="imagine-form__row__field"
                                           :required="true"
                                           label="Poste"
                                           name="position"
                                           v-model="position"/>
                        </div>
                    </div>
                </div>

                <div class="imagine-form__row">
                    <imagine-input class="imagine-form__row__field"
                                   help="Utilisé pour la connexion et les notifications"
                                   :required="true"
                                   label="Email"
                                   name="email"
                                   type="email"
                                   v-model="email"
                                   :violation="errors.email"/>

                    <imagine-input class="imagine-form__row__field"
                                   help="Sans tiret ni point, ajouter +33 si métropole ou autre"
                                   :required="true"
                                   label="Vini"
                                   name="mobilePhone"
                                   v-model="mobilePhone"
                                   :violation="errors.phone"/>
                </div>

                <div class="imagine-form__sep"></div>

                <div class="imagine-form__row">
                    <imagine-input
                        :violation="errors.plainPassword"
                        help="Recommandé : 16 caractères avec chiffres et lettres"
                        :label="employee ? 'Nouveau mot de passe' : 'Mot de passe'"
                        class="imagine-form__row__field"
                        name="password"
                        type="password"
                        v-model="password"/>

                    <imagine-input
                        :violation="errors.plainPassword"
                        help="Recommandé : 16 caractères avec chiffres et lettres"
                        class="imagine-form__row__field"
                        label="Confirmer"
                        name="passwordBis"
                        type="password"
                        v-model="passwordBis"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-select :choices="groupChoices"
                                    class="imagine-form__row__field"
                                    label="Groupe"
                                    v-model="group"/>

                    <imagine-select :choices="serviceChoices"
                                    class="imagine-form__row__field"
                                    label="Service"
                                    required
                                    v-model="service"
                                    v-if="group === 'ROLE_SALES_REP' || group === 'ROLE_CEO'"/>
                </div>

                <div class="imagine-form__row">
                    <imagine-checkboxes :choices="references.notificationChoices()"
                                        label="Recevoir emails"
                                        name="notifications"
                                        v-model="notifications"/>
                </div>

                <div class="imagine-form__actions">
                    <imagine-button @click.prevent="back()">Annuler</imagine-button>
                    <imagine-button primary type="submit">Sauvegarder</imagine-button>
                </div>
            </form>
        </div>

        <imagine-modal @act="remove"
                       @close="closeConfirm"
                       buttonLabel="Supprimer"
                       v-if="showRemoveConfirmation">
            <p>Etes vous sûr de vouloir supprimer cet employé ?</p>
        </imagine-modal>
    </imagine-layout>
</template>

<script>
import ImagineLayout from '../components/ImagineLayout.vue';
import ImagineModal from '../components/ImagineModal.vue';
import ImagineButton from '../components/ImagineButton.vue';
import ImagineIconButton from '../components/ImagineIconButton.vue';
import ImagineSwitch from '../components/ImagineSwitch.vue';
import ImagineInput from '../components/ImagineInput.vue';
import ImagineSelect from '../components/ImagineSelect.vue';
import ImagineFileInput from '../components/ImagineFileInput.vue';
import ImagineCheckboxes from '../components/ImagineCheckboxes.vue';
import {mapState} from 'vuex';

export default {
    components: {
        ImagineSwitch,
        ImagineLayout,
        ImagineModal,
        ImagineSelect,
        ImagineInput,
        ImagineButton,
        ImagineIconButton,
        ImagineFileInput,
        ImagineCheckboxes
    },
    computed: {
        ...mapState('employee', {employees: state => state.all}),
        ...mapState(['references']),
        employee() {
            if (!this.employees || !this.employeeId) {
                return null;
            }

            return this.employees.find(employee => employee.id === this.employeeId);
        },
        title() {
            return this.employee ? (this.employee.firstname + ' ' + this.employee.lastname) : 'Création employé';
        },
        groupChoices() {
            return this.references.roleChoices();
        },
        serviceChoices() {
            return this.references.serviceChoices();
        }
    },
    data() {
        return {
            showRemoveConfirmation: false,
            employeeId: null,
            firstname: null,
            lastname: null,
            email: null,
            position: null,
            mobilePhone: null,
            isActive: null,
            group: null,
            service: null,
            password: null,
            passwordBis: null,
            photo: null,
            notifications: [],
            errors: {}
        }
    },
    created() {
        this.employeeId = this.$route.params ? parseInt(this.$route.params.id) : null;

        this.load();
        Promise.all([this.$store.dispatch('touchReferences'), this.$store.dispatch('employee/touch')])
            .then(this._mapEmployee)
            .catch(this.serverError)
            .finally(this.unload);
    },
    methods: {
        save() {
            this.errors = {};

            if (!this._validatePassword()) {
                return;
            }

            this.load();
            this.$store.dispatch('employee/save', {
                id: this.employeeId,
                lastname: this.lastname,
                firstname: this.firstname,
                position: this.position,
                email: this.email,
                mobilePhone: this.mobilePhone,
                group: this.group,
                service: this.service,
                password: this.password,
                photoUrl: this.photo,
                notifications: this.notifications
            })
                .then(employee => {
                    this.employeeId = employee.id;

                    this.$store.dispatch('employee/reload')
                        .then(() => {
                            this._mapEmployee();
                            this.success('Employé sauvegardé.');
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        remove() {
            this.load();
            this.$store.dispatch('employee/remove', this.employeeId)
                .then(() => {
                    this.closeConfirm();
                    this.redirectTo('employees', {}, () => this.success('Employé supprimé.'));
                })
                .catch(this.serverError)
                .finally(this.unload);
        },
        toggleActive() {
            this.load();
            this.$store.dispatch('employee/state', {id: this.employeeId, state: !this.isActive})
                .then(() => {
                    this.$store.dispatch('employee/reload')
                        .then(() => {
                            this.isActive = !this.isActive;
                            this.success(this.employee.firstname + (this.isActive ? ' peut se connecter' : ' ne peut plus se connecter'))
                        })
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(response => {
                    this.errors = this.serverError(response);
                    this.unload();
                });
        },
        closeConfirm() {
            this.showRemoveConfirmation = false;
        },
        _mapEmployee() {
            let employee = this.employeeId ? this.employees.find(employee => employee.id === this.employeeId) : null;

            if (employee) {
                this.lastname = employee.lastname;
                this.firstname = employee.firstname;
                this.position = employee.position;
                this.email = employee.email;
                this.mobilePhone = employee.phone.readable;
                this.group = employee.mainRole;
                this.service = employee.service;
                this.isActive = employee.isActive;
                this.photo = employee.image;
                this.notifications = employee.notifications;
            }
        },
        _validatePassword() {
            if (!this.employee && !this.password) {
                this.errors.plainPassword = 'Mot de passe obligatoire';
                return false;
            }

            if (this.password) {
                if (this.password !== this.passwordBis) {
                    this.errors.plainPassword = 'Les mots de passe ne correspondent pas';
                    return false;
                }

                if (this.password.length < 8) {
                    this.errors.plainPassword = 'Le mot de passe doit faire 8 caractères minimum';
                    return false;
                }
            }

            return true;
        }
    }
}
</script>

<style lang="scss">
@import '../scss/config';
@import '~@material/elevation/mdc-elevation';

.employee-edit {
    &__avatar-row {
        display: flex;
        align-items: center;

        &__photo {
            width: 275px;

            @media (max-width: map-get($imagine-breakpoints, 'phone')) {
                display: none;
            }

            .imagine-file__preview {
                img {
                    width: 200px;
                }
            }
        }

        &__infos {
            flex: 1;
        }
    }

    &__prospects {
        text-align: center;
    }
}
</style>
