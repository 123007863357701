import container from '../container';

const repo = container.repos.sale;

const MAX_DISPLAYED = 50;
const DEFAULT_STATUSES = ['ok', 'late'];
const DEFAULT_STEPS = ['available', 'option', 'prebooking', 'booking'];

export default {
    namespaced: true,
    state: {
        displayed: [],
        maxDisplayed: MAX_DISPLAYED,
        nbTotal: 0,
        nbFiltered: 0,
        lastUpdate: null,
        block: null,
        salesRep: null,
        statuses: DEFAULT_STATUSES,
        steps: DEFAULT_STEPS,
        query: null,
        mode: 'list'
    },
    getters: {
        filters: state => {
            return {
                block: state.block,
                salesRep: state.salesRep,
                statuses: state.statuses,
                lastUpdate: state.lastUpdate,
                steps: state.steps
            };
        }
    },
    mutations: {
        setBlock(state, block) {
            state.block = block;
        },
        setStatuses(state, statuses) {
            state.statuses = statuses;
        },
        setSalesRep(state, salesRep) {
            state.salesRep = salesRep;
        },
        setSteps(state, steps) {
            state.steps = steps;
        },
        setLastUpdate(state, lastUpdate) {
            state.lastUpdate = lastUpdate;
        },
        setQuery(state, query) {
            state.query = query;
        },
        changeMode(state, mode) {
            const origin = state.mode;

            state.mode = mode;

            if (mode === 'search') {
                state.lastUpdate = null;
                state.block = null;
                state.salesRep = null;
                state.statuses = DEFAULT_STATUSES;
                state.steps = DEFAULT_STEPS;
            }

            if (origin === 'search') {
                state.query = null;
            }
        },
    },
    actions: {
        reload({state, getters}) {
            return repo.all({limit: state.maxDisplayed, query: state.query, filters: getters.filters})
                .then(collection => {
                    state.nbTotal = collection.nbTotal;
                    state.nbFiltered = collection.nbFiltered;
                    state.displayed = collection.entities;
                });
        },
        displayMore({state, dispatch}) {
            state.maxDisplayed += state.maxDisplayed;

            return dispatch('reload');
        },
        one(context, id) {
            return repo.one(id);
        },
        cancel(context, {id, data}) {
            return repo.cancel(id, data);
        },
        delete(context, id) {
            return repo.delete(id);
        },
        option(context, {id, data}) {
            return repo.option(id, data);
        },
        header(context, {id, data}) {
            return repo.header(id, data);
        },
        quickOption(context, {propertyId, contactId}) {
            return repo.quickOption(propertyId, contactId);
        },
        cancelOption(context, id) {
            return repo.cancelOption(id);
        },
        cancelSalesRepOption(context, id) {
            return repo.cancelSalesRepOption(id);
        },
        prebooking(context, {id, data}) {
            return repo.prebooking(id, data);
        },
        cancelBooking(context, id) {
            return repo.cancelBooking(id);
        },
        booking(context, {id, data}) {
            return repo.booking(id, data);
        },
        cancelFinancing(context, id) {
            return repo.cancelFinancing(id);
        },
        financing(context, {id, data}) {
            return repo.financing(id, data);
        },
        cancelNotification(context, id) {
            return repo.cancelNotification(id);
        },
        notification(context, {id, data}) {
            return repo.notification(id, data);
        },
        cancelCertificate(context, id) {
            return repo.cancelCertificate(id);
        },
        certificate(context, {id, data}) {
            return repo.certificate(id, data);
        },
        stats(context, {block, metric, dimensions}) {
            return repo.stats(block, metric, dimensions);
        }
    }
};
