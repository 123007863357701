<template>
    <md-field :class="{'md-invalid': invalid || violation, 'md-required': required || externalRequired}"
              class="imagine-select"
              style="position: relative;">
        <label :for="name">
            {{ label }}
            <span v-if="multiple && !noSelectAll && innerValue && innerValue.length < choices.length && innerValue.length > 0">
                -
                <a href="#" @click.prevent="selectAll">Tout sélectionner</a>
            </span>
            <span v-if="multiple && !noSelectAll && innerValue && innerValue.length === choices.length && innerValue.length > 0">
                -
                <a href="#" @click.prevent="unselectAll">Tout désélectionner</a>
            </span>
        </label>
        <span style="top: 0;right: 5px;position: absolute;" v-if="multiple && !noSelectAll && innerValue && innerValue.length < choices.length && innerValue.length === 0">
            -
            <a href="#" @click.prevent="selectAll">Tout sélectionner</a>
        </span>
        <md-select v-model="innerValue"
                   :name="name"
                   :id="name"
                   :disabled="disabled"
                   :multiple="multiple"
                   md-dense
                   @md-selected="select($event)"
                   v-if="choices && choices.length">
            <md-option v-if="!(required || implicitRequired)"
                       value=""
                       :key="'choice-'+name+'-empty'"/>
            <template v-for="choice in choices">
                <template v-if="choice.choices && choice.choices.length">
                    <md-optgroup :label="choice.title">
                        <md-option :value="subChoice.value"
                                   :disabled="subChoice.disabled"
                                   :key="'choice-'+subChoice.value"
                                   v-for="subChoice in choice.choices">
                            {{ subChoice.label }}
                        </md-option>
                    </md-optgroup>
                </template>
                <md-option v-else
                           :value="choice.value"
                           :disabled="choice.disabled"
                           :key="'choice-'+(choice.value ? choice.value : randomId())">
                    {{ choice.label }}
                </md-option>
            </template>
        </md-select>
        <span class="md-helper-text"
              v-if="help">
            {{ help }}
        </span>
        <span class="md-error"
              v-if="violation">
            {{ violation }}
        </span>
    </md-field>
</template>

<script>
//the "required" option will not be passed to md-select because of a bug with readonly props :
//<https://stackoverflow.com/questions/56512608/vue-material-component-md-select-required-attirbute-not-working>
//<https://github.com/vuematerial/vue-material/issues/2137>

//instead :
//* required will remove the blank choice and add star
//* implicit-required will only remove the blank choice
//* external-required will only add a star (validation is considered to be done outside)

export default {
    props: {
        name: {type: String},
        invalid: {type: Boolean, default: false},
        help: {default: null},
        violation: {type: String, default: null},
        label: {type: String},
        choices: {type: Array},
        disabled: {type: Boolean},
        required: {type: Boolean},
        implicitRequired: {type: Boolean},
        externalRequired: {type: Boolean},
        multiple: {type: Boolean},
        noSelectAll: {type: Boolean, default: false},
        value: {}
    },
    data() {
        return {
            innerValue: this.value
        }
    },
    watch: {
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    methods: {
        select() {
            this.$emit('input', this.innerValue);
        },
        selectAll() {
            if (this.multiple) {
                this.innerValue = this.choices.map(choice => choice.value);
                this.select();
            }
        },
      unselectAll() {
        if (this.multiple) {
          this.innerValue = [];
          this.select();
        }
      }
    }
}
</script>

<style lang="scss">
.imagine-select {
    .md-select {
        .md-icon {
            position: absolute;
            right: .5rem;
            top: 12px;
        }
    }
}
</style>
