<template>
    <imagine-modal :button-disabled="!label || !date"
                   :side="true"
                   title="Ecriture locataire"
                   @act="saveEntry()"
                   @close="$emit('close')"
                   button-label="Sauvegarder"
                   class="imagine-modal--wide">
        <p>
            <imagine-input name="label"
                           :disabled="partialEditing"
                           v-model="label"
                           label="Libellé"
                           :required="true"
                           class="imagine-form__row__field"/>
        </p>

        <p>
            <imagine-date-picker :min-date="lease.start"
                                 :disabled="partialEditing"
                                 :required="true"
                                 class="imagine-form__row__field"
                                 label="Date"
                                 name="date"
                                 v-model="date"/>
        </p>

        <p>
            <imagine-money-input class="imagine-form__row__field"
                                 :disabled="partialEditing"
                                 label="Crédit"
                                 name="credit"
                                 v-model="credit"/>
            <imagine-money-input class="imagine-form__row__field"
                                 :disabled="partialEditing"
                                 label="Débit"
                                 name="debit"
                                 v-model="debit"/>
        </p>

        <p>
            <imagine-input name="docType"
                           v-model="docType"
                           label="Pièce"
                           class="imagine-form__row__field"/>
        </p>

        <p>
            <imagine-file-input name="docUrl"
                                v-model="docUrl"
                                label="Pièce (doc)"
                                accept="application/pdf"
                                class="imagine-form__row__field"/>
        </p>
    </imagine-modal>
</template>

<script>
import ImagineModal from '../../../components/ImagineModal.vue';
import ImagineDatePicker from '../../../components/ImagineDatePicker.vue';
import ImagineInput from '../../../components/ImagineInput.vue';
import ImagineMoneyInput from '../../../components/ImagineMoneyInput.vue';
import ImagineSelect from '../../../components/ImagineSelect.vue';
import ImagineFileInput from '../../../components/ImagineFileInput.vue';

export default {
    props: ['lease', 'entry'],
    components: {
        ImagineModal,
        ImagineDatePicker,
        ImagineInput,
        ImagineMoneyInput,
        ImagineSelect,
        ImagineFileInput
    },
    computed: {
        partialEditing() {
            return this.entry && this.entry.source === 'itool';
        }
    },
    data() {
        return {
            id: this.entry ? this.entry.id : null,
            date: this.entry ? this.entry.date : this.$container.types.today(),
            label: this.entry ? this.entry.label : null,
            credit: this.entry ? this.entry.credit : null,
            debit: this.entry ? this.entry.debit : null,
            docType: this.entry ? this.entry.docType : null,
            docUrl: this.entry ? this.entry.docUrl : null
        }
    },
    methods: {
        saveEntry() {
            this.load();
            this.$store.dispatch('accountEntry/save', {
                id: this.id,
                lease: this.lease.id,
                label: this.label,
                date: this.date,
                mode: this.mode,
                credit: this.credit,
                debit: this.debit,
                docType: this.docType,
                docUrl: this.docUrl
            })
                .then(() => {
                    this.$store.dispatch('mandateProperty/one', this.lease.mandatePropertyId)
                        .then(() => this.$emit('close'))
                        .catch(this.serverError)
                        .finally(this.unload);
                })
                .catch(error => {
                    this.serverError(error);
                    this.unload();
                });
        }
    }
}
</script>
